var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    [
      _c(
        "v-expansion-panel-header",
        {
          attrs: { "disable-icon-rotate": "" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [_c("v-icon", [_vm._v(" mdi-garage ")])]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._v(" Porte de garage ")]
      ),
      _c(
        "v-expansion-panel-content",
        [
          _c(
            "v-list",
            { staticClass: "mx-0" },
            [
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [
                    _c("b", [_vm._v("Système de pose de la PG ")]),
                  ]),
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-combobox", {
                        attrs: {
                          value: _vm.getPorteGarage.type_pose,
                          items: _vm.listeTypePosePG,
                          label: "Moyen de fixation de la PG",
                          "prepend-icon": "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.setTextBox("type_pose", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [_c("b", [_vm._v("Quantité")])]),
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          outlined: "",
                          label: "Quantité",
                        },
                        on: {
                          keydown: function ($event) {
                            return _vm.setTextBox(
                              "quantite",
                              $event.target.value
                            )
                          },
                        },
                        model: {
                          value: _vm.getPorteGarage.quantite,
                          callback: function ($$v) {
                            _vm.$set(_vm.getPorteGarage, "quantite", $$v)
                          },
                          expression: "getPorteGarage.quantite",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }