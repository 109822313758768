var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-form",
        [
          _c("v-checkbox", {
            attrs: {
              "input-value": _vm.getUser.timeDefault.lieu_depart,
              "prepend-icon": "mdi-home-outline",
              label: "Départ de l'entreprise ?",
              color: _vm.getTurquoisePoupin,
              "true-value": "Entreprise",
              "false-value": "Domicile",
            },
            on: {
              change: function ($event) {
                return _vm.setLieuDepart($event)
              },
            },
          }),
          _c("x-time-picker", {
            attrs: {
              title:
                _vm.getUser.timeDefault.lieu_depart === "Entreprise"
                  ? "Arrivée sur l'entreprise"
                  : "Départ du domicile",
              usertime: _vm.getUser.timeDefault.heure_depart,
            },
            on: {
              timepicked: function ($event) {
                return _vm.setDepart($event)
              },
            },
          }),
          _c("x-time-picker", {
            attrs: {
              title: "Arrivée premier chantier",
              usertime: _vm.getUser.timeDefault.heure_arrivee_premier_chantier,
            },
            on: {
              timepicked: function ($event) {
                return _vm.setPremierChantier($event)
              },
            },
          }),
          _c("v-select", {
            attrs: {
              value: _vm.getUser.timeDefault.temps_pause,
              items: _vm.temps,
              "item-text": "name",
              "item-value": "value",
              "prepend-icon": "mdi-coffee-outline",
              label: "Temps de pause",
              color: _vm.getTurquoisePoupin,
            },
            on: {
              change: function ($event) {
                return _vm.setTempsPause($event)
              },
            },
          }),
          _c("x-time-picker", {
            attrs: {
              title: "Départ du dernier chantier",
              usertime: _vm.getUser.timeDefault.heure_depart_dernier_chantier,
            },
            on: {
              timepicked: function ($event) {
                return _vm.setDernierChantier($event)
              },
            },
          }),
          _c("x-time-picker", {
            attrs: {
              title:
                _vm.getUser.timeDefault.lieu_arrivee === "Entreprise"
                  ? "Départ de l'entreprise"
                  : "Arrivée domicile",
              usertime: _vm.getUser.timeDefault.heure_arrivee,
            },
            on: {
              timepicked: function ($event) {
                return _vm.setFin($event)
              },
            },
          }),
          _c("v-checkbox", {
            attrs: {
              "input-value": _vm.getUser.timeDefault.lieu_arrivee,
              "prepend-icon": "mdi-home-outline",
              label: "Repasse par l'entreprise le soir ?",
              color: _vm.getTurquoisePoupin,
              "true-value": "Entreprise",
              "false-value": "Domicile",
            },
            on: {
              change: function ($event) {
                return _vm.setLieuArrivee($event)
              },
            },
          }),
          _c(
            "v-row",
            { attrs: { justify: "end" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: { color: "black", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v(" Annuler ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: {
                    type: "submit",
                    outlined: "",
                    color: _vm.getTurquoisePoupin,
                  },
                  on: { click: _vm.setHoraireDefaut },
                },
                [_vm._v(" Enregistrer ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout, centered: "" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "blue", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.snack = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Fermer ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snack,
            callback: function ($$v) {
              _vm.snack = $$v
            },
            expression: "snack",
          },
        },
        [_vm._v(" " + _vm._s(_vm.errText) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }