<template>
  <v-data-table
    :headers="headers"
    :items="getJourneeList"
    :items-per-page="15"
    class="elevation-1"
  >
    <template v-slot:item="row">
      <tr disabled="">
        <!-- Si la journée a été corrigée, on affiche la journée corrigée avec les infos saisies à l'origine -->
        <td>
          {{
            row.item.journeeCorrigee
              ? row.item.journeeCorrigee.date
              : row.item.date | fullDate
          }}
        </td>
        <!-- <td>{{ row.item.journeeCorrigee ? row.item.journeeCorrigee.lieu_depart + '' + comparaisonCorrectionOrigine(row.item, 1) : row.item.lieu_depart }}</td> -->
        <td>
          {{
            row.item.journeeCorrigee
              ? row.item.journeeCorrigee.lieu_depart
              : row.item.lieu_depart
          }}
        </td>
        <td>
          {{
            row.item.journeeCorrigee
              ? row.item.journeeCorrigee.heure_depart
              : row.item.heure_depart | simpleTime
          }}
        </td>
        <td>
          {{
            row.item.journeeCorrigee
              ? row.item.journeeCorrigee.temps_pause
              : row.item.temps_pause | humantime
          }}
        </td>
        <td>
          {{
            row.item.journeeCorrigee
              ? row.item.journeeCorrigee.heure_depart_dernier_chantier
              : row.item.heure_depart_dernier_chantier | simpleTime
          }}
        </td>
        <td>
          {{
            row.item.journeeCorrigee
              ? row.item.journeeCorrigee.lieu_arrivee === "Entreprise"
                ? "Oui"
                : "Non"
              : row.item.lieu_arrivee === "Entreprise"
              ? "Oui"
              : "Non"
          }}
        </td>
        <td>
          {{
            row.item.journeeCorrigee
              ? row.item.journeeCorrigee.lieu_arrivee === "Entreprise" ||
                row.item.journeeCorrigee.trajetSoir
                ? row.item.journeeCorrigee.heure_arrivee
                : ""
              : (row.item.lieu_arrivee === "Entreprise"
                  ? row.item.heure_arrivee
                  : "") | simpleTime
          }}
        </td>
        <td>
          {{
            row.item.journeeCorrigee
              ? row.item.journeeCorrigee.lieu_arrivee === "Entreprise"
                ? row.item.journeeCorrigee.heure_depart_entreprise
                : ""
              : (row.item.lieu_arrivee === "Entreprise"
                  ? row.item.heure_depart_entreprise
                  : "") | simpleTime
          }}
        </td>
        <td>
          {{
            row.item.journeeCorrigee
              ? row.item.journeeCorrigee.heuresTauxNormal +
                row.item.journeeCorrigee.heuresMajorees
              : (row.item.heuresTauxNormal + row.item.heuresMajorees)
                | humantime
          }}
        </td>
        <td>
          {{
            row.item.journeeCorrigee
              ? row.item.journeeCorrigee.villeLaPlusLointaine
                ? row.item.journeeCorrigee.villeLaPlusLointaine.nom
                : ""
              : row.item.villeLaPlusLointaine.nom
          }}
        </td>
        <td>{{ row.item.user.nom }} {{ row.item.user.prenom }}</td>
        <td>{{ row.item.user.agence.libelle }}</td>

        <td
          v-if="
            row.item.validated === false && row.item.journeeCorrigee !== null
          "
        >
          <v-layout row>
            <v-flex>
              <v-btn
                fab
                dark
                tile
                elevation="0"
                small
                color="green"
                @click="validate(row.item)"
              >
                <v-icon dark>mdi-check</v-icon>
              </v-btn>
            </v-flex>
            <v-flex v-if="row.item.journeeCorrigee.commentaire">
              <v-btn
                fab
                dark
                small
                tile
                elevation="0"
                color="purple"
                @click="
                  setCommentaireLecture(row.item.journeeCorrigee.commentaire)
                "
              >
                <v-icon dark>mdi-comment-outline</v-icon>
              </v-btn>
            </v-flex>
            <v-flex>
              <v-badge
                avatar
                bordered
                color="grey"
                icon="mdi-calendar-edit"
                overlap
              >
                <v-btn
                  fab
                  dark
                  small
                  tile
                  elevation="0"
                  color="orange"
                  @click="reverseCorrectionAlert(row.item)"
                >
                  <v-icon dark>mdi-undo-variant</v-icon>
                </v-btn>
              </v-badge>
            </v-flex>
          </v-layout>
        </td>
        <td v-else-if="row.item.validated === false">
          <v-layout row>
            <v-flex>
              <v-btn
                fab
                dark
                tile
                elevation="0"
                small
                color="green"
                @click="validate(row.item)"
              >
                <v-icon dark>mdi-check</v-icon>
              </v-btn>
            </v-flex>
            <v-flex>
              <v-btn
                fab
                dark
                tile
                elevation="0"
                small
                color="blue"
                @click="modifier(row.item)"
              >
                <v-icon dark>mdi-calendar-edit</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </td>
        <td v-else-if="row.item.validated === true">
          <v-layout row>
            <v-flex>
              <v-btn
                fab
                dark
                tile
                elevation="0"
                small
                color="red"
                @click="unvalidateAlert(row.item)"
              >
                <v-icon dark>mdi-lock-open-outline</v-icon>
              </v-btn>
            </v-flex>
            <v-flex v-if="row.item.journeeCorrigee">
              <v-btn
                v-if="row.item.journeeCorrigee.commentaire"
                fab
                dark
                tile
                elevation="0"
                small
                color="purple"
                @click="
                  setCommentaireLecture(row.item.journeeCorrigee.commentaire)
                "
              >
                <v-icon dark>mdi-comment-outline</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "X-gestion-datatable",
  data() {
    return {
      headers: [
        {
          text: "Date",
          align: "start",
          sortable: true,
          value: "date",
        },
        { text: "Départ", value: "lieu_depart" },
        { text: "Heure de début", value: "heure_depart" },
        { text: "Pause", value: "temps_pause" },
        { text: "Dernier chantier", value: "heure_depart_dernier_chantier" },
        { text: "Chargement le soir", value: "lieu_arrivee" },
        { text: "Retour entreprise", value: "heure_arrivee" },
        { text: "Heure de fin", value: "heure_depart_entreprise" },
        { text: "Heures" },
        { text: "Ville la plus lointaine", value: "villeLaPlusLointaine.nom" },
        { text: "Utilisateur", value: "user.nom" },
        { text: "Agence", value: "user.agence.libelle" },
        { text: "Actions", sortable: false },
      ],
      cancelItemCorrection: null,
    };
  },
  methods: {
    modifier(item) {
      //Je crée un objet journeeCorrigée dans la journée que je passe dans le currentJoournee de mon module time
      this.$store.dispatch("time/initialiseCorrection", item);
      this.$emit("show-correction");
    },
    validate(item) {
      item.validated = true;
      this.$store.dispatch("time/setJournee", item);
      this.$store.dispatch("time/updateJournee").then(() => {
        this.$emit("refresh");
      });
    },
    unvalidate() {
      return new Promise((resolve) => {
        this.cancelItemCorrection.validated = false;
        resolve();
      })
        .then(() => {
          this.$store.dispatch("time/setJournee", this.cancelItemCorrection);
        })
        .then(() => {
          this.$store.dispatch("time/updateJournee").then(() => {
            this.$emit("refresh");
          });
          if (this.modalConfirmValidation) {
            this.modalConfirmValidation = false;
          }
        });
    },
    unvalidateAlert(time) {
      this.cancelItemCorrection = time;
      Swal.fire({
        title: "Attention !",
        text: `Vous êtes sur le point d'annuler la validation de l'horaire de ${
          time.user.nom
        } ${time.user.prenom} pour le ${moment(time.date).format("LL")}`,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Oui, l'annuler",
        confirmButtonColor: "#69d27a",
        showCancelButton: true,
        cancelButtonText: "Non, revenir en arrière",
        cancelButtonColor: "#d97502",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.unvalidate();
        } else {
          this.cancelItemCorrection = null;
        }
      });
    },
    reverseCorrection() {
      return new Promise((resolve) => {
        this.cancelItemCorrection.date_correction = null;
        this.cancelItemCorrection.journeeCorrigee = null;
        resolve();
      })
        .then(() => {
          this.$store.dispatch("time/setJournee", this.cancelItemCorrection);
        })
        .then(() => {
          this.$store.dispatch("time/updateJournee").then(() => {
            this.$emit("refresh");
          });
        });
    },
    reverseCorrectionAlert(time) {
      this.cancelItemCorrection = time;
      Swal.fire({
        title: "Attention !",
        text: `Vous êtes sur le point de supprimer la correction de l'horaire de ${
          time.user.nom
        } ${time.user.prenom} pour le ${moment(time.date).format("LL")}`,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Oui, la supprimer",
        confirmButtonColor: "#69d27a",
        showCancelButton: true,
        cancelButtonText: "Non, revenir en arrière",
        cancelButtonColor: "#d97502",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.reverseCorrection();
        } else {
          this.cancelItemCorrection = null;
        }
      });
    },
    setCommentaireLecture(commentaire) {
      this.$store
        .dispatch("time/setCommentaireLecture", commentaire)
        .then(() => {
          this.$emit("show-commentaire-lecture");
        });
    },

    comparaisonCorrectionOrigine(item, typeValeur) {
      let strValeur;
      switch (typeValeur) {
        case 1:
          if (item.journeeCorrigee.lieu_depart !== item.lieu_depart) {
            strValeur = " (" + item.lieu_depart + ")";
          }

          break;
        case 2:
          break;
        case 3:
          break;
        case 4:
          break;
        case 5:
          break;
        case 6:
          break;

        default:
          break;
      }

      return strValeur;
    },
  },
  computed: {
    ...mapGetters("time", {
      getJourneeList: "getJourneeList",
    }),
  },
};
</script>

<style scoped></style>
