var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    [
      _c(
        "v-list-item-avatar",
        [
          _c(
            "v-icon",
            {
              attrs: {
                color: _vm.getEtatAvancementDate(_vm.champ_recherche).couleur,
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.getEtatAvancementDate(_vm.champ_recherche).icone) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _c("v-list-item-content", [
        _c("b", [
          _vm._v(
            _vm._s(_vm.getEtatAvancementDate(_vm.champ_recherche).message)
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }