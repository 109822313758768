import Vue from "vue";
import VueRouter from "vue-router";
///Vues
import Home from "../views/Home.vue";
import Signup from "../views/Signup";
import Login from "../views/Login";
import Admin from "../views/Admin";
import GestionTimes from "../views/GestionTimes";
import Commande from "../views/Commande";
import Direction from "../views/Direction";
import Livraison from "../views/Livraison";
import PageLeaflet from "../views/PageLeaflet";
//Store
import store from "../store";
import InfosUtiles from "../views/InfosUtiles";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Liste interventions",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/times",
    name: "Mes heures",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/TimeList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/order",
    name: "Commande magasin",
    component: Commande,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manageTimes",
    name: "Gestion des heures",
    component: GestionTimes,
    meta: {
      requiresAuth: true,
      is_gestionnaire: true,
    },
  },
  {
    path: "/infosUtiles",
    name: "Informations utiles",
    component: InfosUtiles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/signup",
    name: "Sign Up",
    component: Signup,
    meta: {
      is_admin: true,
    },
  },
  {
    path: "/login",
    name: "Connexion",
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: "/admin",
    name: "Administration",
    component: Admin,
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: "/livraison",
    name: "Livraison",
    component: Livraison,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/PageLeaflet",
    name: "PageLeaflet",
    component: PageLeaflet,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/direction",
    name: "Chantiers et intervention",
    component: Direction,
    meta: {
      requiresAuth: true,
      is_be: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.commit("auth/INITIALISATION_STORE");
  let connected = await store.getters["auth/isConnected"];
  let direction = await store.getters["auth/isDirection"];
  let be = await store.getters["auth/isBE"];
  let gestionnaire = await store.getters["auth/isGestionnaire"];
  let admin = await store.getters["auth/isAdmin"];
  let responsableAlu = await store.getters["auth/isResponsableAlu"];

  if (to.matched.some((record) => record.meta.requiresAuth) && !connected) {
    next({ name: "Connexion" });
  } else if (to.matched.some((record) => record.meta.is_admin) && !admin) {
    next({ name: "Tableau de bord" });
  } else if (
    to.matched.some((record) => record.meta.is_direction) &&
    !direction &&
    !admin
  ) {
    next({ name: "Tableau de bord" });
  } else if (
    to.matched.some((record) => record.meta.is_be) &&
    !be &&
    !direction &&
    !admin
  ) {
    next({ name: "Tableau de bord" });
  } else if (
    to.matched.some((record) => record.meta.is_gestionnaire) &&
    !gestionnaire &&
    !direction &&
    !admin &&
    !responsableAlu
  ) {
    next({ name: "Tableau de bord" });
  } else if (to.matched.some((record) => record.meta.guest) && connected) {
    next({ name: "Tableau de bord" });
  } else {
    next();
  }
});

export default router;
