var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.modaleAjoutLivraisonIsVisible,
            "max-width": "500",
          },
          on: {
            "click:outside": function ($event) {
              return _vm.closeModaleAjoutLivraison()
            },
          },
        },
        [
          _c(
            "v-stepper",
            {
              attrs: { vertical: "" },
              model: {
                value: _vm.stepValue,
                callback: function ($$v) {
                  _vm.stepValue = $$v
                },
                expression: "stepValue",
              },
            },
            [
              _c(
                "v-stepper-step",
                { attrs: { step: "1", complete: _vm.stepValue > 0 } },
                [_vm._v(" Entrez le N. de Commande ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c("v-text-field", {
                    attrs: { label: "N.Commande", hint: "5 chiffres" },
                    model: {
                      value: _vm.numCommande,
                      callback: function ($$v) {
                        _vm.numCommande = $$v
                      },
                      expression: "numCommande",
                    },
                  }),
                  _c("v-select", {
                    attrs: {
                      label: "Type de commande",
                      items: _vm.typeCommande,
                    },
                    model: {
                      value: _vm.commandeInterne,
                      callback: function ($$v) {
                        _vm.commandeInterne = $$v
                      },
                      expression: "commandeInterne",
                    },
                  }),
                  _c(
                    "v-row",
                    [
                      _c("v-col"),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "green",
                                "x-large": "",
                                elevation: "2",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.findCommandeByNum()
                                },
                              },
                            },
                            [_vm._v(" Valider ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                { attrs: { step: "2", complete: _vm.stepValue > 1 } },
                [_vm._v(" Informations Commande ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  !_vm.newCommandeForLivraison
                    ? _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            { staticClass: "font-weight-bold" },
                            [
                              _c("v-col", [
                                _vm._v(
                                  "Aucune commande ne correspond au N. " +
                                    _vm._s(_vm.numCommande) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-col"),
                              _c("v-col"),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    elevation: "2",
                                    color: "red",
                                    "x-large": "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.stepValue -= 1
                                    },
                                  },
                                },
                                [_vm._v(" Retour ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newCommandeForLivraison
                    ? _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Client ")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      " : " + _vm.newCommandeForLivraison.client
                                    )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm.newCommandeForLivraison.atelier
                                ? _c("v-col", [_vm._v("Atelier")])
                                : _vm._e(),
                              _vm.newCommandeForLivraison.fournisseur
                                ? _c("v-col", [_vm._v("Fournisseur")])
                                : _vm._e(),
                              _c("v-col", [_vm._v("Description")]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.newCommandeForLivraison.atelier
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            elevation: "0",
                                            color: _vm.chooseColorAtelier(
                                              _vm.newCommandeForLivraison
                                                .atelier
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.newCommandeForLivraison
                                                .atelier
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.newCommandeForLivraison.fournisseur
                                    ? _c(
                                        "v-btn",
                                        { attrs: { elevation: "0" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.newCommandeForLivraison
                                                .fournisseur
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("v-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.newCommandeForLivraison.description
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "font-weight-bold" },
                            [
                              _c("v-col", [_vm._v("Type commande")]),
                              _c("v-col", [_vm._v("Famille")]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _vm.newCommandeForLivraison.type === "CDI"
                                ? _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: _vm.getTurquoisePoupin,
                                            elevation: "0",
                                          },
                                        },
                                        [_vm._v(" Interne ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.newCommandeForLivraison.type === "CDE"
                                ? _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        { attrs: { elevation: "0" } },
                                        [_vm._v(" Externe ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.newCommandeForLivraison.famille
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "text-center" },
                            [
                              _c("v-col"),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "green",
                                        "x-large": "",
                                        elevation: "2",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.createNewLivraison(
                                            _vm.newCommandeForLivraison
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Créer livraison ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }