var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "ma-0 pa-0",
      attrs: {
        value: _vm.visible,
        persistent: _vm.persistent,
        "max-width": _vm.max_width,
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "mb-2" }, [
            _c("span", [_vm._v(_vm._s(_vm.titre))]),
          ]),
          _vm.subtitle
            ? _c("v-card-subtitle", [_vm._v(_vm._s(_vm.subtitle_text))])
            : _vm._e(),
          _c(
            "v-card-text",
            [
              _c("time-form", {
                on: {
                  close: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
              _vm.commentaire && !_vm.quelBouton
                ? _c(
                    "v-btn",
                    {
                      attrs: { small: "", outlined: "", color: "blue" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("open-commentaire")
                        },
                      },
                    },
                    [_vm._v("Ajouter un commentaire")]
                  )
                : _vm.commentaire
                ? _c(
                    "v-btn",
                    {
                      attrs: { small: "", outlined: "", color: "orange" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close-commentaire")
                        },
                      },
                    },
                    [_vm._v("Modifier le commentaire")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }