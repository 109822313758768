<template>
  <v-container>
    <v-row justify="center">
      <x-info></x-info>
    </v-row>
  </v-container>
</template>

<script>
import XInfo from "../components/info_poseurs/x-info";
export default {
  name: "InfosUtiles",
  components: {XInfo},
  created() {
    this.$store.dispatch('auth/tokenIsValid');
  }
}
</script>

<style scoped>

</style>