<template>
  <v-card class="ma-0 pa-0">
    <v-card-text class="mx-0 pa-1">
      <v-list class="mx-0 pa-0">
        <!-- Libel date prévi 1ère pose -->
        <v-list-item>
          <v-list-item-avatar>
            <v-icon :color="getEtatAvancementDate('-1').couleur">
              {{ getEtatAvancementDate("-1").icone }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <b>{{ getEtatAvancementDate("-1").message }}</b>
          </v-list-item-content>
        </v-list-item>

        <!-- Date prévi 1ère pose -->
        <v-list-item>
          <v-list-item-avatar>
            <v-icon> </v-icon>
          </v-list-item-avatar>
          <v-list-item-action>
            <x-date-picker
              :title="''"
              :date="getChantier.delai_theorique_premiere_pose | toIso"
              :afficherSemaine="true"
              @datepicked="setValueDatePicker('-1', $event, false, 'TE')"
            ></x-date-picker>
          </v-list-item-action>
        </v-list-item>

        <!-- Libel date finition pose -->
        <v-list-item>
          <v-list-item-avatar>
            <v-icon :color="getEtatAvancementDate('-2').couleur">
              {{ getEtatAvancementDate("-2").icone }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <b>{{ getEtatAvancementDate("-2").message }}</b>
          </v-list-item-content>
        </v-list-item>

        <!-- Date finition pose -->
        <v-list-item>
          <v-list-item-avatar>
            <v-icon> </v-icon>
          </v-list-item-avatar>
          <v-list-item-action>
            <x-date-picker
              :title="''"
              :afficherSemaine="true"
              :date="getChantier.delai_theorique_finition | toIso"
              @datepicked="setValueDatePicker('-2', $event, false, 'TE')"
            ></x-date-picker>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-expansion-panels>
        <!-- ------------------ SIGNATURE CLIENT ------------------ -->
        <x-avancement-vente></x-avancement-vente>

        <!-- ------------------ LANCEMENT EN FABRICATION ------------------ -->
        <x-avancement-ordonnancement></x-avancement-ordonnancement>

        <!-- ------------------ 1ERE POSE ------------------ -->
        <x-avancement-premiere-pose></x-avancement-premiere-pose>

        <!-- ------------------ FINITIONS ------------------ -->
        <x-avancement-finition></x-avancement-finition>

        <!-- ------------------ RECEPTION ------------------ -->
        <x-avancement-reception></x-avancement-reception>

        <!-- ------------------ PORTE DE GARAGE ------------------ -->
        <x-avancement-porte-garage></x-avancement-porte-garage>
      </v-expansion-panels>

      <!-- <v-card-subtitle>ANNEXES</v-card-subtitle>
      <v-list>
        <v-list-item>
          <v-list-item-avatar><v-icon :color="getEau.couleur">{{ getEau.icone }}</v-icon></v-list-item-avatar>
          <v-list-item-content>
            <b>Eau</b> {{ getEau.message }}
          </v-list-item-content>
          <v-list-item-action>
            <v-icon
                color="light-blue lighten-1"
                @click="setEau"
                :disabled="getEau.disabled"
            >{{ getEau.action }}</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon
                :color="getElectricite.couleur"
            >
              {{ getElectricite.icone }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <b>Électricité</b> {{ getElectricite.message }}
          </v-list-item-content>
          <v-list-item-action>
            <v-icon
                color="light-blue lighten-1"
                @click="setElectricite"
                :disabled="getElectricite.disabled"
            >{{ getElectricite.action }}</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list> -->
    </v-card-text>
    <!--Ma modale d'affichage des commandes-->
    <x-modale-commandes
      :visible="commandes"
      :commandes="getChantier.commandes"
      @close="commandes = false"
    ></x-modale-commandes>

    <!--En cours de chargement-->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Chargement en cours
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import XDatePicker from "../../time/X-date-picker.vue";
import XModaleCommandes from "../commandes/X-modale-commandes.vue";
import XAvancementVente from "./X-avancement-vente.vue";
import XAvancementOrdonnancement from "./X-avancement-ordonnancement.vue";
import XAvancementPremierePose from "./X-avancement-premiere-pose.vue";
import XAvancementFinition from "./X-avancement-finition.vue";
import XAvancementReception from "./X-avancement-reception.vue";
import XAvancementPorteGarage from "./X-avancement-porte-garage.vue";

export default {
  name: "X-avancement",
  components: {
    XDatePicker,
    XModaleCommandes,
    XAvancementVente,
    XAvancementOrdonnancement,
    XAvancementPremierePose,
    XAvancementFinition,
    XAvancementReception,
    XAvancementPorteGarage,
  },
  data() {
    return {
      etats: [
        { nom: "En cours", value: "EC" },
        { nom: "Terminé", value: "TE" },
      ],
      commandes: false,
      loading: false,
      reception_default: false,
    };
  },
  methods: {
    loadCommandes() {
      this.loading = true;
      this.$store.dispatch("chantier/returnCommandesChantier").then(() => {
        this.commandes = true;
        this.loading = false;
      });
    },
    getEtat(etape) {
      let message;
      let icone;
      let couleur;
      let action;
      let disabled;
      if (etape === "NC") {
        message = "Non Commencé";
        icone = "mdi-close-circle-outline";
        couleur = "red";
        action = "mdi-skip-next";
        disabled = false;
      } else if (etape === "EC") {
        message = "En Cours";
        icone = "mdi-dots-horizontal-circle-outline";
        couleur = "yellow darken-2";
        action = "mdi-skip-next";
        disabled = false;
      } else if (etape === "TE") {
        message = "Terminée, en attente de facturation";
        icone = "mdi-check-circle-outline";
        couleur = "blue";
        action = "mdi-checkbox-marked";
        disabled = false;
      } else if (etape === "FA") {
        message = "Terminée et facturée";
        icone = "mdi-check-circle-outline";
        couleur = "green";
        action = "";
        disabled = true;
      } else {
        message = "Veuillez signaler cet affichage au service informatique";
        icone = "mdi-help";
        action = "";
        couleur = "purple";
        disabled = true;
      }
      return {
        message: message,
        icone: icone,
        couleur: couleur,
        action: action,
        disabled: disabled,
      };
    },
    getDate(evenement) {
      let date = moment(evenement).toDate();

      if (date.getFullYear() === 1) {
        return {
          message: "Non effectué",
          icone: "mdi-close-circle-outline",
          couleur: "red",
          action: "mdi-checkbox-marked",
          disabled: false,
        };
      }
      return {
        message: moment(evenement).format("ll"),
        icone: "mdi-check-circle-outline",
        couleur: "green",
        action: "",
        disabled: true,
      };
    },
    setVerifAppui() {
      Swal.fire({
        text: `Valider la vérification des détails d'appuis ?`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Oui, valider",
        confirmButtonColor: "#69d27a",
        showCancelButton: true,
        cancelButtonText: "Non",
        cancelButtonColor: "#d97502",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("chantier/setAvancementVerifAppui").then(() => {
            this.$store.dispatch("chantier/updateAvancement");
          });
        }
      });
    },
    setEtatPremierePose() {
      Swal.fire({
        text: `Modifier l'avancement de la première pose ?`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Oui, valider",
        confirmButtonColor: "#69d27a",
        showCancelButton: true,
        cancelButtonText: "Non",
        cancelButtonColor: "#d97502",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("chantier/setAvancementEtatPremierePose")
            .then(() => {
              this.$store.dispatch("chantier/updateAvancement");
            });
        }
      });
    },
    setPriseCoteFinition() {
      Swal.fire({
        text: `Valider la prise de cotes pré-finitions ?`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Oui, valider",
        confirmButtonColor: "#69d27a",
        showCancelButton: true,
        cancelButtonText: "Non",
        cancelButtonColor: "#d97502",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("chantier/setAvancementCoteFinition")
            .then(() => {
              this.$store.dispatch("chantier/updateAvancement");
            });
        }
      });
    },
    setEtatFinitions() {
      Swal.fire({
        text: `Modifier l'avancement des finition ?`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Oui, valider",
        confirmButtonColor: "#69d27a",
        showCancelButton: true,
        cancelButtonText: "Non",
        cancelButtonColor: "#d97502",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("chantier/setAvancementEtatFinition")
            .then(() => {
              this.$store.dispatch("chantier/updateAvancement");
            });
        }
      });
    },
    setReception() {
      Swal.fire({
        text: `Valider la réception du chantier ?`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Oui, valider",
        confirmButtonColor: "#69d27a",
        showCancelButton: true,
        cancelButtonText: "Non",
        cancelButtonColor: "#d97502",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("chantier/setAvancementReception").then(() => {
            this.$store.dispatch("chantier/updateAvancement");
          });
        }
      });
    },
    setLeveeReserves() {
      Swal.fire({
        text: `Valider la levée des réserves ?`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Oui, valider",
        confirmButtonColor: "#69d27a",
        showCancelButton: true,
        cancelButtonText: "Non",
        cancelButtonColor: "#d97502",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("chantier/setAvancementLeveeReserves")
            .then(() => {
              this.$store.dispatch("chantier/updateAvancement");
            });
        }
      });
    },
    setEau() {
      Swal.fire({
        text: `L'eau a été connectée sur ce chantier ?`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Oui, valider",
        confirmButtonColor: "#69d27a",
        showCancelButton: true,
        cancelButtonText: "Non",
        cancelButtonColor: "#d97502",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("chantier/setAvancementEau").then(() => {
            this.$store.dispatch("chantier/updateAvancement");
          });
        }
      });
    },
    setElectricite() {
      Swal.fire({
        text: `L'éléctricité a été connectée sur ce chantier ?`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Oui, valider",
        confirmButtonColor: "#69d27a",
        showCancelButton: true,
        cancelButtonText: "Non",
        cancelButtonColor: "#d97502",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("chantier/setAvancementElectricite").then(() => {
            this.$store.dispatch("chantier/updateAvancement");
          });
        }
      });
    },

    setValueCheckboxDate(stypeValeur, sEtat, nbStep) {
      // Type de valeur pour savoir quel champ mettre à jour dans la classe 'avancement'
      // check pour savoir si c'est coché ou pas
      // valeur si on met autre chose que la date du jour
      let payload = {
        typeValeur: stypeValeur,
        nouvelleValeur: moment().toDate(),
        isChecked: false,
        datePrevi: moment("01/01/1900").toDate(),
        etat: sEtat,
      };

      if (nbStep === 3) {
        if (payload.etat === "" || payload.etat === "NC") {
          payload.etat = "EC";
        } else if (payload.etat === "EC") {
          payload.etat = "TE";
        } else if (payload.etat === "TE") {
          payload.etat = "NC";
          payload.nouvelleValeur = moment("01/01/0001").toDate();
          payload.isChecked = true;
        }
      } else if (nbStep === 2) {
        if (
          payload.etat === "" ||
          payload.etat === "NC" ||
          payload.etat === "EC"
        ) {
          payload.etat = "TE";
        } else if (payload.etat === "TE") {
          payload.etat = "NC";
          payload.nouvelleValeur = moment("01/01/0001").toDate();
          payload.isChecked = true;
        }
      }

      // Dispatch dans le front
      this.$store.dispatch("chantier/setAvancementDate", payload).then(() => {
        this.$store.dispatch("chantier/updateAvancement");
      });
    },
    setValueDatePicker(stypeValeur, valeur, check, sEtat) {
      // Type de valeur pour savoir quel champ mettre à jour dans la classe 'avancement'
      // check pour savoir si c'est coché ou pas
      // valeur si on met autre chose que la date du jour
      let payload = {
        typeValeur: stypeValeur,
        nouvelleValeur: moment(valeur)
          .toDate()
          .toISOString(),
        isChecked: check,
        datePrevi: moment(valeur).toDate(),
        etat: sEtat,
      };

      switch (payload.typeValeur) {
        case "-1":
          // Dispatch dans le front
          this.$store
            .dispatch("chantier/setChantierDateTheorique", payload)
            .then(() => {
              this.$store.dispatch("chantier/updateDateTheoriquePremierePose");
            });
          break;

        case "-2":
          // Dispatch dans le front
          this.$store
            .dispatch("chantier/setChantierDateTheorique", payload)
            .then(() => {
              this.$store.dispatch("chantier/updateDateTheoriqueFinition");
            });
          break;
        case "verif_details_dappui_premiere_pose.date_previ":
        case "prise_cote_bso_le.date_previ":
        case "prise_cote_finitions_le.date_previ":
        case "habillage_tableaux_le.date_previ":
        case "pret_a_poser_premiere_pose.date_previ":
        case "pret_a_poser_finition.date_previ":
        case "pv_reception_signe_le.date_previ":
        case "levee_reserve_le.date_previ":
        case "pv_levee_reserve_le.date_previ":
          payload.nouvelleValeur = moment("01/01/1900").toDate();
          // Dispatch dans le front
          this.$store
            .dispatch("chantier/setChantierAvancementDatePrevi", payload)
            .then(() => {
              this.$store.dispatch("chantier/updateAvancement");
            });
          break;
      }
    },
  },
  computed: {
    ...mapGetters("chantier", {
      getModaleAvancement: "getModaleAvancement",
      getChantier: "getChantier",
      getEtatAvancementDate: "getEtatAvancementDate",
    }),

    getDateMiseEnFab() {
      return this.getDate(this.getChantier.avancement.date_mise_en_fab);
    },
    getVerifDetailsDAppui() {
      return this.getDate(
        this.getChantier.avancement.verification_details_dappui_premiere_pose
      );
    },
    getEtatPremierePose() {
      return this.getEtat(this.getChantier.avancement.premiere_pose);
    },
    getPriseDeCotesFinitions() {
      return this.getDate(this.getChantier.avancement.prise_cote_finitions);
    },
    getEtatFinitions() {
      return this.getEtat(this.getChantier.avancement.finitions);
    },
    getReception() {
      return this.getDate(this.getChantier.avancement.reception_effectuee);
    },
    getLeveeReserves() {
      return this.getDate(this.getChantier.avancement.levee_des_reserves);
    },
    getEau() {
      let data;
      if (this.getChantier.avancement.eau) {
        data = {
          message: "Connectée",
          couleur: "green",
          icone: "mdi-check-circle-outline",
          disabled: true,
        };
      } else {
        data = {
          message: "Non connectée",
          couleur: "orange",
          icone: "mdi-close-octagon-outline",
          action: "mdi-checkbox-marked",
          disabled: false,
        };
      }
      return data;
    },
    getElectricite() {
      let data;
      if (this.getChantier.avancement.electricite) {
        data = {
          message: "Connectée",
          couleur: "green",
          icone: "mdi-check-circle-outline",
          disabled: true,
        };
      } else {
        data = {
          message: "Non connectée",
          couleur: "orange",
          icone: "mdi-close-octagon-outline",
          action: "mdi-checkbox-marked",
          disabled: false,
        };
      }
      return data;
    },
    getCouleurIcon() {
      return this.getDate(
        this.getChantier.avancement.verification_details_dappui_premiere_pose
      );
    },
  },
};
</script>

<style scoped></style>
