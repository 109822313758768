<template>
    <v-card class="ma-0">
      <v-card-title>
        Contacts chantier
      </v-card-title>
      <v-card-subtitle>{{ getChantier.nom_client }}</v-card-subtitle>
      <v-card-text>
        <v-simple-table
            v-if="
            getChantier.maitre_doeuvre
            ||
            getChantier.telephone_client
            ||
            getChantier.mis_en_fab_par"
        >
          <template>
            <thead>
            <tr>
              <th>Contact</th>
              <th>Numéro</th>
              <th><span>Mail</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Client</td>
              <td><v-btn text small @click="call(getChantier.telephone_client)" :disabled="isPhone(getChantier.telephone_client)"><v-icon color="green" class="mr-1">mdi-phone</v-icon></v-btn></td>
              <td><v-icon color="blue" @click="sendMail(getChantier.mail_client)" :disabled="isEmail(getChantier.mail_client)">mdi-email</v-icon></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-subtitle v-else>Pas d'enregistrements pour ce chantier</v-card-subtitle>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="hideModale()">Fermer</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "X-modale-contact-reno",
  mounted() {
    console.log('Dans le mounted de X-modale-contact-reno')
    let that = this;
    window.onpopstate = function() {
      that.handleBackBtn()
    }
  },
  beforeDestroy() {
    window.onpopstate = function() {
    }
  },
  methods: {
    call(number) {
      window.open(`tel:${number}`);
    },
    sendMail(email) {
      window.open(`mailto:${email}`);
    },
    isPhone(number) {
      return !/([0-9]{2}(\s?|-)){5}/.test(number)
    },
    isEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !re.test(String(email).toLowerCase());
    },
    handleBackBtn() {
      this.$emit('closeModaleContacts');
    },
    // Cache la modale en remettant le boolean a false
    hideModale() {      
      this.$emit("closeModale");      
    },
  },
  computed: {
    ...mapGetters('chantier', {
      getChantier: 'getChantier'
    })
  },
}
</script>

<style scoped>

</style>