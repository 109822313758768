<template>
  <v-dialog
      :value="visible"
      @click:outside="$emit('close')"
      content-class="ma-2"
      max-width="1600px"
      scrollable
  >
    <v-card>
      <v-card-title class="ma-0 pa-0">
        <v-tabs
            v-model="tabs"
            fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
              href="#mobile-tabs-5-1"
              class="primary--text"
          >
            INTERNE
          </v-tab>

          <v-tab
              href="#mobile-tabs-5-2"
              class="primary--text"
          >
            EXTERNE
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text class="ma-0 pa-0">
      <v-tabs-items v-model="tabs">
        <v-tab-item
            :value="'mobile-tabs-5-1'"
        >
          <v-card flat class="ma-0">
            <v-card-text class="ma-0">
              <v-data-table
                  :headers="headersInt"
                  :items="commandesInternes"
                  :items-per-page="5"
                  :header-props="{
                    'sort-by-text':'Trier par'
                  }"
                  :footer-props="{
                    'items-per-page-text':'Commandes / page'
                  }"
              >
                <template v-slot:[`item.etat`]="{ item }">
                  <v-chip
                      :color="getOptions(item.etat).color"
                      :outlined="getOptions(item.etat).outlined"
                      :dark="getOptions(item.etat).dark"
                  >
                    {{ item.etat }}
                  </v-chip>
                </template>
                <template v-slot:[`item.passee_le`]="{ item }">
                  {{ dateFormat(item.passee_le) }}
                </template><template v-slot:[`item.date_livraison_prevue`]="{ item }">
                {{ dateFormat(item.date_livraison_prevue) }}
              </template>
                <template v-slot:[`item.date_livraison_confirmee`]="{ item }">
                  {{ dateFormat(item.date_livraison_confirmee) }}
                </template>
                <template v-slot:[`item.date_livraison_reelle`]="{ item }">
                  {{ dateFormat(item.date_livraison_reelle) }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item
            :value="'mobile-tabs-5-2'"
        >
          <v-card flat class="ma-0">
            <v-card-text class="ma-0">
              <v-data-table
                  :headers="headersExt"
                  :items="commandesExternes"
                  :items-per-page="5"
                  :header-props="{
                    'sort-by-text':'Trier par'
                  }"
                  :footer-props="{
                    'items-per-page-text':'Commandes par page'
                  }"
              >
                <template v-slot:[`item.etat`]="{ item }">
                  <v-chip
                      :color="getOptions(item.etat).color"
                      :outlined="getOptions(item.etat).outlined"
                      :dark="getOptions(item.etat).dark"
                  >
                    {{ item.etat }}
                  </v-chip>
                </template>
                <template v-slot:[`item.passee_le`]="{ item }">
                  {{ dateFormat(item.passee_le) }}
                </template><template v-slot:[`item.date_livraison_prevue`]="{ item }">
                {{ dateFormat(item.date_livraison_prevue) }}
              </template>
                <template v-slot:[`item.date_livraison_confirmee`]="{ item }">
                  {{ dateFormat(item.date_livraison_confirmee) }}
                </template>
                <template v-slot:[`item.date_livraison_reelle`]="{ item }">
                  {{ dateFormat(item.date_livraison_reelle) }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">FERMER</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  name: "XModaleCommandes",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    commandes: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      tabs: null,
      headersInt: [
        { text: 'Etat', value: 'etat' },
        { text: 'Atelier', value: 'atelier' },
        { text: 'Famille', value: 'famille' },
        { text: 'Sous famille', value: 'sous_famille' },
        { text: 'Description', value: 'description' },
        { text: 'Passée par', value: 'passee_par' },
        { text: 'Passée le', value: 'passee_le' },
        { text: 'Commande n°', value: 'numero_commande' },
        { text: 'Délai demandé', value: 'date_livraison_prevue' },
        { text: 'Prévision sortie atelier', value: 'date_livraison_confirmee' },
        { text: 'Sortie atelier', value: 'date_livraison_reelle' },
      ],
      headersExt: [
        { text: 'Etat', value: 'etat' },
        { text: 'Description', value: 'description' },
        { text: 'Fournisseur', value: 'fournisseur' },
        { text: 'Passée par', value: 'passee_par' },
        { text: 'Passée le', value: 'passee_le' },
        { text: 'N° commande', value: 'numero_commande' },
        { text: 'Livraison prévue', value: 'date_livraison_prevue' },
        { text: 'Livraison confirmée', value: 'date_livraison_confirmee' },
        { text: 'Livraison réelle', value: 'date_livraison_reelle' },
      ],
    }
  },
  methods: {
    getOptions (etat) {
      switch (etat) {
        case 'Lancé en fabrication':
          return {
            color: '',
            outlined: true,
            dark: false
          }
        case 'Pris en compte':
          return {
            color: 'yellow darken-1',
            outlined: false,
            dark: false
          };
        case 'En cours de fab':
          return {
            color: 'blue lighten-2',
            outlined: false,
            dark: true
          };
        case 'Terminé':
          return {
            color: 'green darken-1',
            outlined: false,
            dark: true
          };
        case 'Bloqué':
          return {
            color: 'red darken-2',
            outlined: false,
            dark: true
          };
        case 'Prévisionnel':
          return {
            color: 'blue darken-4',
            outlined: false,
            dark: true
          };
        case 'Posé':
          return {
            color: 'orange lighten-2',
            outlined: false,
            dark: true
          };
        case 'En cours de finition':
          return {
            color: 'blue-grey',
            outlined: false,
            dark: true
          };
        case 'Livré sur le chantier':
          return {
            color: 'grey',
            outlined: false,
            dark: true
          };
        case 'En cours':
          return {
            color: '',
            outlined: true,
            dark: false
          };
        case 'Validée':
          return {
            color: 'yellow darken-2',
            outlined: false,
            dark: false
          };
        case 'Partiellement Recue':
          return {
            color: 'light-green lighten-2',
            outlined: false,
            dark: false
          };
        case 'Recue':
          return {
            color: 'green darken-2',
            outlined: false,
            dark: true
          };
        case 'Annulée':
          return {
            color: 'orange darken-4',
            outlined: false,
            dark: true
          };
        case 'Partiellement Facturée':
          return {
            color: 'grey lighten-2',
            outlined: false,
            dark: false
          };
        case 'Facturée':
          return {
            color: 'grey darken-1',
            outlined: false,
            dark: true
          };
        case 'Agence : Réception Partielle':
          return {
            color: 'blue-grey',
            outlined: false,
            dark: true
          };
        case 'Agence : Réception Totale':
          return {
            color: 'grey',
            outlined: false,
            dark: true
          };
        case 'Supprimé':
          return {
            color: 'black',
            outlined: false,
            dark: true
          };
        default :
          return {
            color: 'deep-purple',
            outlined: true,
            dark: false
          }
      }
    },
    dateFormat(date) {
      let year = moment(date).toDate().getFullYear();
      if( year === 1 || year === 1899) {
        return ''
      }
      return moment(date).format('L')
    }
  },
  computed: {
    commandesInternes() {
      return _.filter(this.commandes, { type: 'CDI'})
    },
    commandesExternes() {
      return _.filter(this.commandes, { type: "CDE"})
    }
  },
}
</script>
<style scoped>

</style>