import contacts from "@/assets/contacts/contacts.json";

const state = {
  windowWidth: window.innerWidth,
  contacts: contacts,
};

const getters = {
  getWindowWidth(state) {
    return state.windowWidth;
  },
  getContacts(state) {
    return state.contacts;
  },
};

const mutations = {
  SET_WINDOW_WIDTH(state) {
    state.windowWidth = window.innerWidth;
  },
};

const actions = {
  setWindowWidth({ commit }) {
    commit("SET_WINDOW_WIDTH");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
