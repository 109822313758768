<template>
  <div>
    <v-card max-width="800px" class="ma-0" flat>
      <v-card-title class="mb-2">INTERVENTIONS</v-card-title>
      <!--<v-card-subtitle class="mb-2"><v-icon color="#7f2626">mdi-alert</v-icon>(<span style="color: #7f2626">ATTENTION</span> les interventions riquent de ne pas être à jour en raison de l'abscence du responsable informatique)</v-card-subtitle>-->
      <v-card-subtitle v-if="userInSession.role == 'Poseur'">
        <v-avatar size="23px" class="yellow darken-1 mr-2"
          ><v-icon x-small class="black--text">mdi-wrench</v-icon></v-avatar
        ><span class="mr-2"><b>Première pose</b></span>
        <v-avatar size="23px" class="pink darken-1 mr-2"
          ><v-icon x-small class="white--text">mdi-wrench</v-icon></v-avatar
        ><span class="mr-2"><b>Finitions</b></span>
        <v-avatar size="23px" class="orange darken-3 mr-2"
          ><v-icon x-small class="white--text">mdi-wrench</v-icon></v-avatar
        ><span class="mr-2"><b>S.A.V.</b></span>
      </v-card-subtitle>
      <v-divider v-show="false"></v-divider>
      <v-card-subtitle
        class="jourAujourdhui white--text text-center rounded"
      >
        AUJOURD'HUI
      </v-card-subtitle>
      <v-divider v-show="false"></v-divider>

      <v-list>
        <v-row justify="center">
          <v-subheader v-if="getInterventionsToday.length === 0"
            >Pas de données</v-subheader
          >
          
          <x-intervention-card
            v-for="(item, index) in getInterventionsToday"
            :key="index"
            :inter="item"
            :today="true"
          ></x-intervention-card>
        </v-row>
      </v-list>
      <v-divider v-show="false"></v-divider>

      <div v-if="getInterventionsTomorrow.length > 0">
        <v-card-subtitle class="jourDemain white--text text-center rounded">
          {{ getInterventionsTomorrow[0].date_intervention | jourDate }}
        </v-card-subtitle>
        <v-divider v-show="false"></v-divider>
        <v-list>
          <v-row justify="center">
            <x-intervention-card
              v-for="(item, index) in getInterventionsTomorrow"
              :key="index"
              :inter="item"
            ></x-intervention-card>
          </v-row>
        </v-list>
      </div>
      <v-divider v-show="false"></v-divider>
      <div
        v-if="
          getInterventionsOtherDays.length > 0 &&
            userInSession.agence.libelle !== 'NEUF'
        "
      >
        <v-card-subtitle class="jourSuivant white--text text-center rounded">
          
          {{ getInterventionsOtherDays[0].date_intervention | jourDate }}
        </v-card-subtitle>
        <v-divider v-show="false"></v-divider>
        <v-list>
          <v-carousel light :show-arrows="true" hide-delimiters height="150">
            <v-carousel-item
              v-for="(inter, i) in getInterventionsOtherDays"
              :key="i"
              class="align-center"
            >
              <v-row justify="center">
                <x-intervention-card :inter="inter"></x-intervention-card>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-list>
      </div>
      
      <div>
        <v-card-subtitle class="jourPasses white--text text-center rounded">
          INTERVENTIONS PASSEES
        </v-card-subtitle>
        <v-divider v-show="false"></v-divider>
        <v-list>
          <v-carousel light :show-arrows="true" hide-delimiters height="150" >
            <v-carousel-item
              v-for="(inter, i) in getInterventionsPasse"
              :key="i * calculTempsTotalPasse()"
              class="align-center"
            >
              <v-row justify="center">
                <x-intervention-card
                  :inter="inter"
                  :today="true"
                ></x-intervention-card>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-list>
      </div>
    </v-card>
    <!--Ma modale de détails chantier-->
    <x-chantier-modale></x-chantier-modale>
    <!--Ma modale de bon de commande-->
    <!-- <x-commande-modale></x-commande-modale> -->
    <!--Ma modale d'incidents-->
    <!-- <x-incident-modale></x-incident-modale> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import XInterventionCard from "./X-intervention-card";
import XChantierModale from "../chantier/X-chantier-modale";
// import XIncidentModale from "@/components/incident/X-incident-modale";

export default {
  name: "JourneeCard",
  components: {
    // XIncidentModale,
    XInterventionCard,
    XChantierModale,
  },
  data() {
    return {
      couleurJourSuivant: "#90aea9",
      couleurAujourdhui: "",
    };
  },


  created() {
    this.returnInterventionsPoseur();
  },

  computed: {
    ...mapGetters(["getGris"]),

    ...mapGetters("intervention", {
      getInterventionsToday: "getInterventionsToday",
      getInterventionsTomorrow: "getInterventionsTomorrow",
      getInterventionsOtherDays: "getInterventionsOtherDays",
      getInterventionsPasse: "getInterventionsPasse",
    }),

    ...mapGetters("auth", {
      userInSession: "userInSession",
    }),
  },
  methods: {

  /**
   * On attribu une key qui change a chaque modif de données pour forcer le carroussel a se reload
   */
  calculTempsTotalPasse(){
    let totalHeures = 0;
    this.getInterventionsPasse.forEach(inter => {
      totalHeures += inter.chantier.temps_pose_deja_realise;
    });
    console.log(totalHeures)
    return parseInt(totalHeures);
  },


    ...mapActions("incident", {
      historique: "showModaleHistorique",
    }),

    ...mapActions("intervention", {
      returnInterventionsPoseur: "returnInterventionsPoseur",
    }),
  },
};
</script>

<style scoped>


.jourAujourdhui{
  background-color: #65a2bc;
}


.jourSuivant{
  background-color: #7bb1c6;
}

.jourDemain{
  background-color: #7bb1c6;
}

.jourPasses{
  background-color: #d2a16f;
}

</style>

