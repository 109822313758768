<template>
  <form @submit="addSociete">
    <v-text-field type="text" label="Libellé Société" :value="getSociete.libelle" @change="setLibelleSociete"></v-text-field>
    <v-text-field type="number" label="Id Société (ERP)" :value="getSociete.idERP" @change="setIdSociete"></v-text-field>
    <v-btn type="submit">Enregistrer</v-btn>
  </form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SocieteForm",
  data() {
    return {
    }
  },
  methods: {
    addSociete(e) {
      this.$store.dispatch('societe/addSociete');
      e.preventDefault();
    },
    setLibelleSociete(e) {
      this.$store.dispatch('societe/setLibelleSociete', e);
    },
    setIdSociete(e) {
      this.$store.dispatch('societe/setIdSociete', e);
    }
  },
  computed: {
    ...mapGetters('societe', {
      getSociete: 'getSociete'
    }),
  },
}
</script>

<style scoped>

</style>