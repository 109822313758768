import Vue from 'vue';

const state = {
    columns: [],
    charges: [],
    chantiers_ordo: [],
    count_libelles: [],
    count_bois: [],
    count_alu: [],
    count_pvc: [],
    libelles_historique: [],
    bois_historique: [],
    alu_historique: [],
    pvc_historique: [],
    libelles_ht_historique: [],
    bois_ht_historique: [],
    alu_ht_historique: [],
    pvc_ht_historique: [],
    ht_historique: []
};

const getters = {
    getColumns(state) {
        return state.columns;
    },
    getCharges(state) {
        return state.charges;
    },
    getChantiersOrdo(state) {
        return state.chantiers_ordo;
    },
    getCountLibelle(state) {
        return state.count_libelles;
    },
    getCountBois(state) {
        return state.count_bois;
    },
    getCountAlu(state) {
        return state.count_alu;
    },
    getCountPVC(state) {
        return state.count_pvc;
    },
    getLibelleHistorique(state) {
        return state.libelles_historique;
    },
    getBoisHistorique(state) {
        return state.bois_historique;
    },
    getAluHistorique(state) {
        return state.alu_historique;
    },
    getPVCHistorique(state) {
        return state.pvc_historique;
    },
    getHTHistorique(state) {
        return state.bois_ht_historique + state.alu_ht_historique + state.pvc_ht_historique;
    },
    getLibelleHTHistorique(state) {
        return state.libelles_ht_historique;
    },
    getBoisHTHistorique(state) {
        return state.bois_ht_historique;
    },
    getAluHTHistorique(state) {
        return state.alu_ht_historique;
    },
    getPVCHTHistorique(state) {
        return state.pvc_ht_historique;
    },
};

const mutations = {
    SET_COLUMNS(state, payload) {
        state.columns = payload;
    },
    SET_CHARGES(state, payload) {
        state.charges = payload;
    },
    SET_CHANTIERS_ORDO(state, payload) {
        state.chantiers_ordo = payload;
    },
    SET_COUNT_LIBELLE(state, payload) {
        state.count_libelles = payload;
    },
    SET_COUNT_BOIS(state, payload) {
        state.count_bois = payload;
    },
    SET_COUNT_ALU(state, payload) {
        state.count_alu = payload;
    },
    SET_COUNT_PVC(state, payload) {
        state.count_pvc = payload;
    },
    SET_COUNT_LIBELLE_HISTORIQUE(state, payload) {
        state.libelles_historique = payload;
    },
    SET_COUNT_BOIS_HISTORIQUE(state, payload) {
        state.bois_historique = payload;
    },
    SET_COUNT_ALU_HISTORIQUE(state, payload) {
        state.alu_historique = payload;
    },
    SET_COUNT_PVC_HISTORIQUE(state, payload) {
        state.pvc_historique = payload;
    },
    SET_HT_BOIS_HISTORIQUE(state, payload) {
        state.bois_ht_historique = payload;
    },
    SET_HT_ALU_HISTORIQUE(state, payload) {
        state.alu_ht_historique = payload;
    },
    SET_HT_PVC_HISTORIQUE(state, payload) {
        state.pvc_ht_historique = payload;
    },
    SET_HT_LIBELLE_HISTORIQUE(state, payload) {
        state.libelles_ht_historique = payload;
    } 
};

const actions = {
    returnCharge({commit}, payload) {
        return Vue.axios.post('/api/ordonnancement/', { semaine : payload })
            .then(
                res => {
                    commit('SET_COLUMNS', res.data.colonnes);
                    commit('SET_CHARGES', res.data.charges);
                }
            )
            .catch( err => {
                console.log(err);
            })
    },
    returnChantiersOrdo({commit}, payload) {
        Vue.axios.post('/api/ordonnancement/ordo', payload)
            .then(
                res => {
                    commit('SET_CHANTIERS_ORDO', res.data)
                }
            )
            .catch( err => {
                console.log(err);
            })
    },
    returnCountOrdo({commit}, payload) {
        return Vue.axios.post('/api/ordonnancement/count', { semaine : payload })
            .then(
                res => {
                    commit('SET_COUNT_LIBELLE', res.data.libelles);
                    commit('SET_COUNT_BOIS', res.data.bois);
                    commit('SET_COUNT_ALU', res.data.alu);
                    commit('SET_COUNT_PVC', res.data.pvc);
                }
            )
            .catch( err => {
                console.log(err);
            })
    },
    returnHistoriqueOrdo({commit}, payload) {
        return Vue.axios.post('/api/ordonnancement/historique', { semaine : payload.semaine, annee: payload.annee })
            .then(
                res => {
                    commit('SET_COUNT_LIBELLE_HISTORIQUE', res.data.libelles);
                    commit('SET_COUNT_BOIS_HISTORIQUE', res.data.bois);
                    commit('SET_COUNT_ALU_HISTORIQUE', res.data.alu);
                    commit('SET_COUNT_PVC_HISTORIQUE', res.data.pvc);
                }
            )
            .catch( err => {
                console.log(err);
            })
    },
    returnHistoriqueOrdoHT({commit}, payload) {
        
        return Vue.axios.post('/api/ordonnancement/historiqueHT', { semaine : payload.semaine, annee: payload.annee })
            .then(
                res => {
                    commit('SET_HT_LIBELLE_HISTORIQUE', res.data.libelles);
                    commit('SET_HT_BOIS_HISTORIQUE', res.data.bois);
                    commit('SET_HT_ALU_HISTORIQUE', res.data.alu);
                    commit('SET_HT_PVC_HISTORIQUE', res.data.pvc);
                }
            )
            .catch( err => {
                console.log(err);
            })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};