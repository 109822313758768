<template>
  <v-dialog
      :value="visible"
      max-width="850px"
      @click:outside="$emit('close')"
      content-class="ma-2 pa-0"
      scrollable
  >
    <v-card>
      <v-card-title>
        {{ getChantier && getChantier.nom_client ? getChantier.nom_client.split(/[0-9]/)[0] : '' }}
        <v-tabs
            v-model="tabs"
            fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
              href="#mobile-tabs-5-1"
              class="primary--text"
          >
            Avancement
          </v-tab>

          <v-tab
              href="#mobile-tabs-5-2"
              class="primary--text"
          >
            Commandes
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text>
        <v-tabs-items v-model="tabs">
          <v-tab-item
              :value="'mobile-tabs-5-1'"

          >
            <x-avancement @close="$emit('close')"></x-avancement>
          </v-tab-item>
          <v-tab-item
              :value="'mobile-tabs-5-2'"
          >
            <x-chantier-commandes :commandes="getChantier.commandes"></x-chantier-commandes>
            
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import XAvancement from "@/components/chantier/avancement/X-avancement";
import XChantierCommandes from '../commandes/X-chantier-commandes.vue';

export default {
  name: "X-modale-notes-chantier",
  components: {XAvancement,XChantierCommandes},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      tabs: null,
      notes: [
        { commentaire: 'Un commentaire' },
        { commentaire: 'Deux commentaire' },
        { commentaire: 'Trois commentaire' },
      ],
      loading : false
    }
  },

  computed: {
    ...mapGetters('chantier', {
      getChantier: 'getChantier'
    })
  },
}
</script>

<style scoped>

</style>