var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-dialog",
        {
          staticClass: "ma-0 pa-0",
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.modalPanier,
            callback: function ($$v) {
              _vm.modalPanier = $$v
            },
            expression: "modalPanier",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Mon panier ")]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _vm.getPanier.length !== 0
                    ? _c(
                        "v-container",
                        _vm._l(_vm.getPanier, function (item) {
                          return _c(
                            "v-card",
                            { key: item.id, attrs: { outlined: "" } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "ma-1 px-0 py-2" },
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { "two-line": "" } },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        {
                                          staticClass: "my-0 pa-0",
                                          attrs: {
                                            tile: "",
                                            size: "65",
                                            color: "white",
                                          },
                                        },
                                        [
                                          item.img
                                            ? _c("v-img", {
                                                attrs: {
                                                  src: require(`@/assets/catalog/${item.img}`),
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "my-0 pa-0" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "overline" },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                {
                                  staticClass: "pa-0 ma-0",
                                  staticStyle: { height: "50px" },
                                },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticStyle: { height: "60px" },
                                          attrs: { justify: "center" },
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "my-0 mx-0 pa-0",
                                              attrs: { text: "", color: "red" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeFromCart(
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-delete-outline"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("v-select", {
                                            staticClass: "my-0 mr-2 pa-0",
                                            staticStyle: { width: "0px" },
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              items: _vm.getSelectList,
                                              color: _vm.getBleu,
                                              label: "Qte",
                                            },
                                            model: {
                                              value: item.quantite,
                                              callback: function ($$v) {
                                                _vm.$set(item, "quantite", $$v)
                                              },
                                              expression: "item.quantite",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c(
                        "v-container",
                        { staticClass: "fill-height", attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center", justify: "center" } },
                            [_c("h3", [_vm._v("Panier vide")])]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _vm.getPanier.length !== 0
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "red", dark: "" },
                          on: { click: _vm.showConfirm },
                        },
                        [_c("v-icon", [_vm._v("mdi-cart-remove")])],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "black", text: "" },
                      on: { click: _vm.showPanier },
                    },
                    [_vm._v("Fermer")]
                  ),
                  _vm.getPanier.length !== 0
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: _vm.getTurquoisePoupin,
                            outlined: "",
                          },
                          on: { click: _vm.passerCommande },
                        },
                        [_vm._v("Commander")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.modalConfirm,
            callback: function ($$v) {
              _vm.modalConfirm = $$v
            },
            expression: "modalConfirm",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " Êtes vous sûr(e) de vouloir supprimer tous les éléments de votre panier ? "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("span"),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "red" },
                      on: { click: _vm.showConfirm },
                    },
                    [_vm._v("Annuler")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "green" },
                      on: { click: _vm.emptyCart },
                    },
                    [_vm._v("Confirmer")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          on: { "click:outside": _vm.resetValidation },
          model: {
            value: _vm.modalCustom,
            callback: function ($$v) {
              _vm.modalCustom = $$v
            },
            expression: "modalCustom",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Commande spéciale ")]),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "py-3" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "customForm",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          rules: [(v) => !!v || "Le nom est obligatoire"],
                          outlined: "",
                          label: "Nom de l'article",
                          color: _vm.getBleu,
                        },
                        model: {
                          value: _vm.nomCustom,
                          callback: function ($$v) {
                            _vm.nomCustom = $$v
                          },
                          expression: "nomCustom",
                        },
                      }),
                      _c("v-select", {
                        attrs: {
                          rules: [
                            (v) => !!v || "La quantité doit être renseignée",
                          ],
                          outlined: "",
                          label: "Qte",
                          items: _vm.getSelectList,
                          color: _vm.getBleu,
                        },
                        model: {
                          value: _vm.qteCustom,
                          callback: function ($$v) {
                            _vm.qteCustom = $$v
                          },
                          expression: "qteCustom",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("span"),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "black" },
                      on: { click: _vm.showCustom },
                    },
                    [_vm._v("Fermer")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.valid,
                        outlined: "",
                        color: _vm.getTurquoisePoupin,
                      },
                      on: { click: _vm.validate },
                    },
                    [_vm._v("Ajouter")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-breadcrumbs",
        { staticClass: "pa-0 ma-0" },
        _vm._l(_vm.getBreadcrumbs, function (crumb, index) {
          return _c(
            "v-breadcrumbs-item",
            { key: index, staticClass: "pa-0 ma-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "pa-1 ma-1",
                  attrs: {
                    small: "",
                    text: "",
                    color: _vm.getTurquoisePoupin,
                    disabled: crumb.disabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.navigateCrumbs(crumb, index)
                    },
                  },
                },
                [_vm._v(_vm._s(crumb.name))]
              ),
              _c("v-breadcrumbs-divider", { staticClass: "pa-1 ma-1" }, [
                _vm._v(" > "),
              ]),
            ],
            1
          )
        }),
        1
      ),
      _vm.hasChildren
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            _vm._l(_vm.getDisplayList, function (item) {
              return _c(
                "v-flex",
                {
                  key: item.id,
                  attrs: { xs4: "", sm4: "", md3: "", lg2: "", xl1: "" },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "ma-1",
                      attrs: { "max-width": "130px", color: "white" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateCards(item)
                        },
                      },
                    },
                    [
                      _c(
                        "v-img",
                        {
                          attrs: {
                            src: require(`@/assets/catalog/${item.img}`),
                          },
                        },
                        [
                          _c(
                            "v-layout",
                            { staticClass: "fill-height" },
                            [
                              _c(
                                "v-flex",
                                { attrs: { "align-self-end": "" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-card-subtitle",
                                        {
                                          staticClass: "py-0 mb-3",
                                          style: `
                    background-color: ${_vm.getBleu};
                    width: 100%; color: ${_vm.getBlanc};
                    `,
                                        },
                                        [_vm._v(" " + _vm._s(item.name) + " ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : !_vm.hasChildren
        ? _c(
            "div",
            _vm._l(_vm.getDisplayList, function (item) {
              return _c(
                "v-card",
                {
                  key: item.id,
                  staticClass: "mx-auto",
                  attrs: { outlined: "" },
                },
                [
                  _c(
                    "v-list-item",
                    { attrs: { "three-line": "" } },
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { tile: "", size: "80", color: "white" } },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require(`@/assets/catalog/${item.img}`),
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-list-item-content", [
                        _c("div", { staticClass: "overline mb-2" }, [
                          _vm._v(" " + _vm._s(item.name) + " "),
                        ]),
                      ]),
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-spacer"),
                          _vm.getQuantitePanier(item) !== 0
                            ? _c("span", { style: `color: ${_vm.getOrange}` }, [
                                _vm._v(
                                  _vm._s(_vm.getQuantitePanier(item)) +
                                    " dans le panier"
                                ),
                              ])
                            : _vm._e(),
                          _c("v-select", {
                            staticClass: "ma-2",
                            staticStyle: { width: "90px" },
                            attrs: {
                              dense: "",
                              outlined: "",
                              filled: "",
                              items: _vm.getSelectList,
                              color: _vm.getBleu,
                              label: "Qte",
                            },
                            model: {
                              value: item.quantite,
                              callback: function ($$v) {
                                _vm.$set(item, "quantite", $$v)
                              },
                              expression: "item.quantite",
                            },
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                fab: "",
                                tile: "",
                                dark: "",
                                color: _vm.getTurquoisePoupin,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addToCart(item)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-cart-plus")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c("div", { staticStyle: { height: "70px" } }),
      _c(
        "v-btn",
        {
          staticClass: "white--text",
          attrs: {
            elevation: "2",
            fab: "",
            bottom: "",
            fixed: "",
            right: "",
            color: _vm.getBleu,
          },
          on: { click: _vm.showPanier },
        },
        [
          _vm.getPanier.length !== 0
            ? _c(
                "v-badge",
                {
                  attrs: {
                    color: _vm.getOrange,
                    bordered: "",
                    content: _vm.getPanier.length,
                  },
                },
                [_c("v-icon", [_vm._v("mdi-cart-outline")])],
                1
              )
            : _c("v-icon", [_vm._v("mdi-cart-outline")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }