<template>
  <v-card>
    <v-card-title>Déclarer un incident</v-card-title>
    <v-card-subtitle>{{ getChantier.nom_client }}</v-card-subtitle>
    <v-card-text>
      <!-- <v-subheader>Envoyer à :</v-subheader>
        <v-checkbox
          v-model="selected"
          value="jerome"
          :rules="rules"
          label="Jérôme Legrand"
          dense
        ></v-checkbox>
        <v-checkbox
          v-model="selected"
          value="julien"
          :rules="rules"
          label="Julien Jeusselin"
          dense
        ></v-checkbox>
        <v-checkbox
          v-if="isConducteur || isAdmin"
          v-model="selected"
          value="moi"
          label="Recevoir une copie"
          dense
        ></v-checkbox> -->
      <v-select
        v-model="responsabilite"
        label="Responsabilité"
        :items="getResponsabiltes"
        item-text="libelle"
        return-object
        prepend-icon="mdi-account-circle-outline"
      ></v-select>
      <v-combobox
        v-if="responsabilite.libelle === 'Fournisseur'"
        v-model="fournisseur"
        label="Fournisseur"
        :items="getFournisseurs"
        item-text="libelle"
        return-object
        prepend-icon="mdi-domain"
      ></v-combobox>
      <v-select
        v-if="responsabilite && Object.keys(responsabilite).length > 0"
        v-model="cause"
        label="Cause"
        :items="responsabilite.causes"
        item-text="libelle"
        return-object
        prepend-icon="mdi-alert-circle-outline"
      ></v-select>
      <v-select
        v-model="process"
        label="Process concerné"
        :items="getProcesses"
        item-text="libelle"
        return-object
        prepend-icon="mdi-cog-outline"
      ></v-select>
      <v-textarea
        v-model="text"
        outlined
        height="150px"
        label="Commentaire"
        prepend-icon="mdi-comment-text-outline"
      ></v-textarea>

      <v-file-input
        v-model="file"
        outlined
        label="1 à 4 photos"
        :disabled="files.length >= 4"
        hint="De 1 à 4 photos"
        truncate-length="15"
      ></v-file-input>

      <v-card v-for="(item, index) in files" :key="index + 'd'">
        <v-card-subtitle>
          {{ item.name }}
        </v-card-subtitle>
        <v-progress-linear
          value="100"
          striped
          color="green"
          height="8px"
          class="mb-2"
        ></v-progress-linear>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-snackbar
        :color="snackBarColor"
        v-model="snack"
        :timeout="timeout"
        centered
      >
        <v-row>
          <v-col class="text-center white--text">
            {{ snackBarText }}
            <v-icon v-if="snackBarColor === 'green'">mdi-check</v-icon>
          </v-col>
        </v-row>
      </v-snackbar>
      <v-spacer></v-spacer>
      <v-btn text @click="hideModaleIncident">Annuler</v-btn>
      <v-btn outlined color="green" @click="sendIncident">Envoyer</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "X-incident-modale",
  data() {
    return {
      file: null,
      files: [],
      text: "",
      // selected: ["jerome", "julien"],
      responsabilite: {},
      cause: null,
      fournisseur: null,
      process: {},
      snackBarText: "no errors",
      snackBarColor: "red",
      snack: false,
      timeout: 4000,
    };
  },
  created() {
    if (this.isAdmin || this.isConducteur || this.userInSession.role == 'PoseurRenovation') {
      this.$store.dispatch("incident/returnResponsabilites");
      this.$store.dispatch("incident/returnFournisseurs");
      this.$store.dispatch("incident/returnProcesses");
    }
  },
  methods: {
    hideModaleIncident() {
      this.$emit("closeModale");
      this.$store.dispatch("incident/hideModaleIncident");
    },
    // ENVOI D'UN INCIDENT
    sendIncident() {
      let tabReceptionMail = [];

      if (this.userInSession.agence.libelle == "NEUF") {
        tabReceptionMail = [
          "coordinateurPlanningNeufRuille",
          "responsableOrdonnancement",
        ];
      }
      if (this.userInSession.agence.libelle == "RENO RUILLEE") {
        tabReceptionMail = ["responsableRenoRuille"];
      }

      let payload = {
        file: this.file,
        files: this.files,
        text: this.text,
        destinataires: tabReceptionMail,
        responsabilite: this.responsabilite,
        cause: this.cause,
        fournisseur: this.fournisseur,
        process: this.process,
      };

      console.log(tabReceptionMail)
      this.snack = false;

      if (this.snack == false) {
        this.$store
          .dispatch("incident/sendIncident", payload)
          .then(() => {
            this.hideModaleIncident();
            this.file = null;
            this.files = [];
            this.text = "";
            this.responsabilite = {};
            this.cause = null;
            this.fournisseur = null;
            this.process = {};
            this.$emit("closeModale");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // A retester
    // if(typeof(this.responsabilite) == 'undefined' || this.responsabilite == null || typeof(this.responsabilite.length) == 'undefined'){
    //     this.snackBarColor = "red";
    //     this.snack = true;
    //     this.snackBarText = "La responsabilité est obligatoire. Merci de les saisir.";
    //     return
    // }

    // if(typeof(this.cause) == 'undefined' || this.cause == null){
    //     this.snackBarColor = "red";
    //     this.snack = true;
    //     this.snackBarText = "La cause est obligatoire. Merci de les saisir.";
    //     return
    // }

    // if(typeof(this.process) == 'undefined' || this.process == null || typeof(this.process.length) == 'undefined'){
    //     this.snackBarColor = "red";
    //     this.snack = true;
    //     this.snackBarText = "Le process concerné est obligatoire. Merci de les saisir.";
    //     return
    // }
  },
  computed: {
    ...mapGetters("incident", {
      getModaleIncident: "getModaleIncident",
      getResponsabiltes: "getResponsabilites",
      getFournisseurs: "getFournisseurs",
      getProcesses: "getProcesses",
    }),

    ...mapGetters("chantier", {
      getChantier: "getChantier",
    }),
    ...mapGetters("auth", {
      userInSession: "userInSession",
    }),
    ...mapGetters("auth", {
      isConducteur: "isConducteur",
      isAdmin: "isAdmin",
    }),

    rules() {
      return [
        this.selected.length > 0 ||
          "Au moins un des deux destinataires doit être sélectionné",
      ];
    },
  },
  watch: {
    file(newValue) {
      if (this.file && this.files?.length < 4) {
        this.files.push(newValue);
      }
    },
  },
};
</script>

<style scoped></style>
