var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.visible,
        "content-class": "ma-2",
        "max-width": "1600px",
        scrollable: "",
      },
      on: {
        "click:outside": function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "fixed-tabs": "" },
                  model: {
                    value: _vm.tabs,
                    callback: function ($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs",
                  },
                },
                [
                  _c("v-tabs-slider"),
                  _c(
                    "v-tab",
                    {
                      staticClass: "primary--text",
                      attrs: { href: "#mobile-tabs-5-1" },
                    },
                    [_vm._v(" INTERNE ")]
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass: "primary--text",
                      attrs: { href: "#mobile-tabs-5-2" },
                    },
                    [_vm._v(" EXTERNE ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tabs,
                    callback: function ($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "mobile-tabs-5-1" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "ma-0", attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "ma-0" },
                            [
                              _c("v-data-table", {
                                attrs: {
                                  headers: _vm.headersInt,
                                  items: _vm.commandesInternes,
                                  "items-per-page": 5,
                                  "header-props": {
                                    "sort-by-text": "Trier par",
                                  },
                                  "footer-props": {
                                    "items-per-page-text": "Commandes / page",
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: `item.etat`,
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                color: _vm.getOptions(item.etat)
                                                  .color,
                                                outlined: _vm.getOptions(
                                                  item.etat
                                                ).outlined,
                                                dark: _vm.getOptions(item.etat)
                                                  .dark,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.etat) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.passee_le`,
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.dateFormat(item.passee_le)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.date_livraison_prevue`,
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.dateFormat(
                                                  item.date_livraison_prevue
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.date_livraison_confirmee`,
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.dateFormat(
                                                  item.date_livraison_confirmee
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.date_livraison_reelle`,
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.dateFormat(
                                                  item.date_livraison_reelle
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "mobile-tabs-5-2" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "ma-0", attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "ma-0" },
                            [
                              _c("v-data-table", {
                                attrs: {
                                  headers: _vm.headersExt,
                                  items: _vm.commandesExternes,
                                  "items-per-page": 5,
                                  "header-props": {
                                    "sort-by-text": "Trier par",
                                  },
                                  "footer-props": {
                                    "items-per-page-text": "Commandes par page",
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: `item.etat`,
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                color: _vm.getOptions(item.etat)
                                                  .color,
                                                outlined: _vm.getOptions(
                                                  item.etat
                                                ).outlined,
                                                dark: _vm.getOptions(item.etat)
                                                  .dark,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.etat) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.passee_le`,
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.dateFormat(item.passee_le)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.date_livraison_prevue`,
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.dateFormat(
                                                  item.date_livraison_prevue
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.date_livraison_confirmee`,
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.dateFormat(
                                                  item.date_livraison_confirmee
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.date_livraison_reelle`,
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.dateFormat(
                                                  item.date_livraison_reelle
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("FERMER")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }