<template>
  <v-container class="mx-2">
    <!-- Bouton début chargement -->
    <v-container>
      <v-row>
        <v-col align-self="end"
          >
            <v-btn v-if="!chargementIsStarted" elevation="2" color="yellow" x-large @click="sendMailDebutChargement()">
              Début du chargement
            </v-btn>
            <v-btn v-if="chargementIsStarted && !chargementIsFinished" elevation="0" color="blue" x-large>
              Charg. en cours <v-icon>mdi-dolly</v-icon>
            </v-btn>
            <v-btn v-if="chargementIsFinished" elevation="0" color="green" x-large>
              Charg. terminé <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-col
        >
        <v-col></v-col>
        <v-col></v-col>
        <v-col></v-col>
        <v-col></v-col>
        <v-spacer></v-spacer>
        <v-col
          ><v-btn
            elevation="2"
            color="blue"
            x-large
            @click="openModaleAjoutLivraison()"
            >Ajouter livraison</v-btn
          ></v-col
        >
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="livraisons"
      item-key="_id"
      class="elevation-1"
      :items-per-page="livraisons.length"
    >
      <template v-if="selected_livraison"
        ><v-row>{{ selected_livraison.chargee }}</v-row>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Liste des livraisons</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <!-- Affichage du panneau si la demande est urgente et donc absolument a mettre dans le camion -->
      <template v-slot:[`item.urgence`]="{ item }">
        <v-chip v-if="item.priorite === true" color="error">
          <v-icon small>mdi-alert</v-icon>
        </v-chip>
      </template>
      <!-- Affichage du nom du client et du n° de devis -->
      <template v-slot:[`item.client`]="{ item }">
        <v-row>
          {{ `${String(item.client)}` }}
        </v-row>
        <v-row>{{ " N°affaire" + " " + `${String(item.compteur)}` }}</v-row>
      </template>
      <!-- Affichage de la semaine de livraison -->
      <template v-slot:[`item.semaine_livraison`]="{ item }">
        <v-col
          >Demandée :
          {{ currentWeek(item.date_livraison_prevue) }}
        </v-col>
        <v-col>
          <span
            v-if="!item.date_livraison_reelle && !item.date_livraison_partielle"
            >Non livrée</span
          >
          <span v-if="item.date_livraison_reelle">Réelle :</span>
          <span
            v-if="item.date_livraison_partielle && !item.date_livraison_reelle"
            >Partielle :
          </span>
          <span v-if="item.date_livraison_reelle"
            >{{ currentWeek(item.date_livraison_reelle) }}
          </span>
          <span
            v-if="item.date_livraison_partielle && !item.date_livraison_reelle"
            >{{ currentWeek(item.date_livraison_partielle) }}</span
          ></v-col
        >
      </template>
      <!-- Affichage du type de la commande -->
      <template v-slot:[`item.type_commande`]="{ item }">
        <v-row>
          <v-col>
            <template v-if="item.commande.type === 'CDI'">
              <v-btn
                elevation="0"
                :color="getTurquoisePoupin"
                class="datatable-btn"
                x-medium
              >
                Interne</v-btn
              >
            </template>
            <template v-if="item.commande.type === 'CDE'">
              <v-btn
                elevation="0"
                color="#90caf9"
                class="datatable-btn"
                x-medium
              >
                Externe</v-btn
              >
            </template>
          </v-col>
        </v-row>

        <v-row class="text-center font-weight-bold">
          <v-col>{{ item.commande.numero_commande }}</v-col>
        </v-row>
      </template>
      <!-- Affichage de l'atelier -->
      <template v-slot:[`item.atelier`]="{ item }">
        <v-col v-if="item.commande.type === 'CDI'">
          <v-btn
            elevation="0"
            :color="chooseColorAtelier(item.commande.atelier)"
            >{{ item.commande.atelier }}</v-btn
          >
        </v-col>
        <v-col v-if="item.commande.type === 'CDE'">
          <v-btn elevation="0">{{ item.commande.fournisseur }}</v-btn>
        </v-col>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <!-- Description -->
        <v-row
          ><v-col>{{ item.commande.description }}</v-col>
        </v-row>
        <!-- Famille/Sous-famille -->
        <v-row
          ><v-col v-if="item.commande.famille">{{
            item.commande.famille + " " + item.commande.sous_famille
          }}</v-col>
        </v-row>
      </template>
      <!-- Bouton pour ouvrir le descriptif de la commande -->
      <template v-slot:[`item.details_commande`]="{ item }">
        <v-icon @click="openModaleRecapitulatifCommande(item)" large
          >mdi-clipboard-text-outline</v-icon
        >
      </template>
      <template v-slot:[`item.etat_chargement`]="{ item }">
        <v-col>
          <v-btn
            v-if="item.chargee === 0"
            elevation="2"
            color="red"
            rounded
            x-large
            @click="openModaleChargee(item)"
          >
            <v-icon> mdi-dolly</v-icon></v-btn
          >
          <v-btn
            v-if="item.chargee === 1"
            elevation="2"
            color="yellow"
            rounded
            x-large
            @click="openModaleChargee(item)"
          >
            <v-icon>mdi-truck-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="item.chargee === 2"
            elevation="2"
            color="green"
            rounded
            x-large
            @click="openModaleChargee(item)"
          >
            <v-icon>mdi-truck-check-outline</v-icon>
          </v-btn>
        </v-col>
      </template>
      <template v-slot:[`item.etat_livraison`]="{ item }">
        <!--Liste Boutons statut commande et livraison -->
        <!--État de la livraison -->
        <v-col>
          {{ etatLivraison(item) }}                 
        </v-col>
        <v-col v-if="item.commande">
            {{ '(' + item.commande.etat + ')' }}
        </v-col>
      </template>
      <template v-slot:[`item.commentaire_historique`]="{ item }">
        <v-row justify="center">
          <v-icon @click="openModalecommentaireLivraison(item)" x-large
            >mdi-comment-text-outline</v-icon
          ></v-row
        >
      </template>
    </v-data-table>
    <!-- Bouton confirmation fin de chargement -->
    <v-container>
      <v-row>
        <v-col></v-col>
        <v-col></v-col>
        <v-col></v-col>
        <v-col></v-col>
        <v-spacer></v-spacer>
        <v-col
          ><v-btn
            elevation="2"
            color="green"
            x-large
            @click="sendMailRecapLivraison()"
            >Chargement terminé</v-btn
          ></v-col
        >
      </v-row>
    </v-container>

    <!-- Modales  -->

    <x-modale-recapitulatif-commande
      v-if="modaleRecapitulatifCommandeIsVisible"
      :isVisible="modaleRecapitulatifCommandeIsVisible"
      :commande="selected_livraison.commande"
      :id_livraison="selected_livraison._id"
      :key="selected_livraison._id + selected_livraison.numero_commande"
      @closeModaleRecapitulatifCommande="closeModaleRecapitulatifCommande"
    ></x-modale-recapitulatif-commande>

    <x-modale-commentaire-livraison
      v-if="modaleCommentaireIsVisible"
      :modaleCommentaireIsVisible="modaleCommentaireIsVisible"
      @closeModaleCommentaire="closeModaleCommentaire"
      :selected_livraison="selected_livraison"
    ></x-modale-commentaire-livraison>

    <x-statut-modale-livraison
      v-if="modaleStatutCommandeisVisible"
      :modaleStatutCommandeisVisible="modaleStatutCommandeisVisible"
      @closeModaleStatutCommande="closeModaleStatutCommande"
      :selected_livraison="selected_livraison"
    ></x-statut-modale-livraison>

    <x-chargee-modale-livraison
      v-if="modaleChargeeIsVisible"
      :modaleChargeeIsVisible="modaleChargeeIsVisible"
      @closeModaleChargee="closeModaleChargee"
      @closeModaleChargeeOutside="closeModaleChargeeOutside"
      :selected_livraison="selected_livraison"
    ></x-chargee-modale-livraison>

    <x-ajout-livraison
      :modaleAjoutLivraisonIsVisible="modaleAjoutLivraisonIsVisible"
      @closeModaleAjoutLivraison="closeModaleAjoutLivraison"
    ></x-ajout-livraison>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import XModaleCommentaireLivraison from "./X-modale-commentaire-livraison";
import XChargeeModaleLivraison from "./X-chargee-Modale-livraison";
import XStatutModaleLivraison from "./X-statut-modale-livraison";
import XModaleRecapitulatifCommande from "../livraison/X-modale-recapitulatif-commande";
import XAjoutLivraison from "./X-ajout-livraison.vue";
export default {
  name: "X-livraison-datatable",
  components: {
    XModaleCommentaireLivraison,
    XChargeeModaleLivraison,
    XStatutModaleLivraison,
    XModaleRecapitulatifCommande,
    XAjoutLivraison,
  },
  data() {
    return {
      // Correspond à la livraison choisie par l'utilisateur
      selected_livraison: null,
      // Variable liée à la modale commentaire
      modaleCommentaireIsVisible: false,
      // Variable liée à la modale récapitulatif commande
      modaleRecapitulatifCommandeIsVisible: false,
      // Variable liées à la modale modification statut de la commande
      modaleStatutCommandeisVisible: false,
      // Variable liée à la modale chargee
      modaleChargeeIsVisible: false,
      // Variable liée à la modale ajout livraison
      modaleAjoutLivraisonIsVisible: false,
      // Variables liées aux boutons chargement
      chargementIsStarted: false,
      chargementIsFinished: false,
      // headers datatable
      headers: [
        { text: "", value: "urgence" },
        {
          text: "Client",
          align: "start",
          value: "client",
        },
        { text: "S. de livraison", value: "semaine_livraison" },
        { text: "Type", value: "type_commande" },
        { text: "Atelier", value: "atelier" },
        { text: "Commande", value: "description" },
        { text: "", value: "details_commande" },
        { text: "Chargement", value: "etat_chargement" },
        { text: " Etat Commande/Livraison", value: "etat_livraison" },
        { text: "Commentaire / Historique", value: "commentaire_historique" },
      ],
    };
  },
  props: {
    livraisons: {
      type: Array,
    },
    reload: {
      type: Number,
    },
  },

  methods: {
    dateFormat(date) {
      let year = moment(date).toDate().getFullYear();
      if (year === 1 || year === 1899) {
        return "";
      }
      return moment(date).format("L");
    },

    currentWeek(date) {
      return moment(date).format("W");
    },
    // Permet de définir la couleur de la pastille atelier
    chooseColorAtelier(atelier) {
      let color = "white";
      switch (atelier) {
        case "BOIS":
          color = this.getVertBois;
          break;
        case "ALU":
          color = this.getOrangeAlu;
          break;
        case "PVC":
          color = this.getJaunePVC;
          break;
        case "Magasin":
          color = this.getRedMagasin;
          break;
        case "Volet":
          color = this.getPurpleVolet;
          break;
      }
      return color;
    },

    etatLivraison(item){

      if(!item.date_livraison_reelle){
          return "Pas livré";
      }else{
        if (moment(item.date_livraison_reelle).year() > 2010){
          return "Livré totalement le " + moment(item.date_livraison_reelle).format("DD-MM-yyyy");
        }else{
          if (moment(item.date_livraison_partielle).year() > 2010){
            return "Livré partiellement le " + moment(item.date_livraison_partielle).format("DD-MM-yyyy");
          }else{
            return "Pas livré"
          }
        }      
      }
    },

    // Appel la fonction permettant d'envoyer un email recapitulant les livraisons chargées
    sendMailRecapLivraison() {
      Swal.fire({
        text: "Confirmer la fin du chargement?",
        showConfirmButton: true,
        confirmButtonText: "Oui",
        confirmButtonColor: "green",
        showCancelButton: true,
        cancelButtonText: "Non",
        cancelButtonColor: "red",
      }).then((result) => {
        if (result.isConfirmed) {
          let templateChargeeTotale =
            this.createTemplateForEmailLivraisonsChargeesTotale(
              this.livraisonsChargees
            );
          let templateChargeePartielle =
            this.createTemplateForEmailLivraisonsChargeesPartielles(
              this.livraisonsChargeesPartielles
            );
          this.$store
            .dispatch("livraison/sendMailRecapLivraison", {
              user: this.ConnectedUser,
              agenceID: this.actualQuery.agence,
              livraisonsChargees: templateChargeeTotale,
              livraisonsChargeesPartielles: templateChargeePartielle,
            })
            .then(
              Swal.fire({
                text: "Le mail a été envoyé avec succès",
                showConfirmButton: true,
                confirmButtonText: "OK",
                confirmButtonColor: "green",
              }),
              (this.chargementIsFinished = true)
            );
        }
      });
    },
    sendMailDebutChargement() {
      this.$store
        .dispatch("livraison/sendMailDebutChargement", {
          user: this.ConnectedUser,
          agenceID: this.actualQuery.agence,
        })
        .then((this.chargementIsStarted = true));
    },
    // Créer un template html des livraisons chargees pour le mail récapitulatif des livraisons
    createTemplateForEmailLivraisonsChargeesTotale(tabLivraisons) {
      let template = "";
      tabLivraisons.forEach(
        (livraison) =>
          (template +=
            "<br>" +
            " <strong>Client</strong> : " +
            livraison.client +
            " <strong>Num. Commande :</strong> " +
            livraison.commande.idERP +
            " <strong>Contenu : </strong>" +
            livraison.commande.description)
      );
      return template;
    },
    // Créer un template html des livraisons chargees partiellement pour le mail récapitulatif des livraisons
    createTemplateForEmailLivraisonsChargeesPartielles(tabLivraisons) {
      let template = "";
      tabLivraisons.forEach(
        (livraison) =>
          (template +=
            "<br>" +
            " <strong>Client</strong> : " +
            livraison.client +
            " <strong>Num. Commande :</strong> " +
            livraison.commande.idERP +
            " <strong>Contenu : </strong>" +
            livraison.commande.description +
            " <strong>commentaire : </strong>" +
            livraison.commentaire_chargement[
              livraison.commentaire_chargement.length - 1
            ])
      );
      return template;
    },
    // Fonctions liées à la modale recaptulatif commande
    openModaleRecapitulatifCommande(livraison) {
      this.selected_livraison = livraison;
      this.modaleRecapitulatifCommandeIsVisible = true;
    },
    closeModaleRecapitulatifCommande() {
      this.modaleRecapitulatifCommandeIsVisible = false;
    },
    // Fonctions liées à la modale de modification du statut de la commande
    openModaleStatutCommande(livraison) {
      this.selected_livraison = livraison;
      this.modaleStatutCommandeisVisible = true;
    },

    closeModaleStatutCommande() {
      this.modaleStatutCommandeisVisible = false;
    },
    // Fonctions liées à la modale "chargee"
    openModaleChargee(livraison) {
      this.selected_livraison = livraison;
      this.modaleChargeeIsVisible = true;
    },
    closeModaleChargeeOutside() {
      this.modaleChargeeIsVisible = false;
    },
    closeModaleChargee(payload) {
      if (payload.chargee_value) {
        if (payload.chargee_value === 2) {
          this.$store.dispatch("livraison/setTabLivraisonsChargeesTotales", {
            livraison: payload.livraison,
          });
        }
        if (payload.chargee_value === 1) {
          this.$store.dispatch("livraison/setTabLivraisonsChargeesPartielles", {
            livraison: payload.livraison,
          });
        }
      }

      this.modaleChargeeIsVisible = false;
    },
    // Fonctions liées à la modale commentaire
    openModalecommentaireLivraison(livraison) {
      this.selected_livraison = livraison;
      this.modaleCommentaireIsVisible = true;
    },
    closeModaleCommentaire() {
      this.modaleCommentaireIsVisible = false;
    },
    openModaleAjoutLivraison() {
      this.modaleAjoutLivraisonIsVisible = true;
    },
    closeModaleAjoutLivraison() {
      this.modaleAjoutLivraisonIsVisible = false;
    },
  },
  computed: {
    ...mapGetters([
      "getTurquoisePoupin",
      "getOrangeAlu",
      "getVertBois",
      "getJaunePVC",
      "getRedMagasin",
      "getPurpleVolet",
      "getCouleurCommandeInterne",
    ]),
    ...mapGetters("auth", {
      ConnectedUser: "userInSession", // Récupère user connecté
    }),
    ...mapGetters("livraison", {
      actualQuery: "getQuery", // Récupère liste des filtres
    }),
    ...mapGetters("livraison", {
      livraisonsChargees: "getTabLivraisonsChargeesTotales", // Récupère la liste des livraisons chargées
    }),
    ...mapGetters("livraison", {
      livraisonsChargeesPartielles: "getTabLivraisonsChargeesPartielles", // Récupère la liste des livraisons chargées
    }),
  },
  watch: {
    // Permet de remettre à false les variables liées aux boutonx début/fin chargement
    livraisonsChargees: function (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        (this.chargementIsStarted = false), (this.chargementIsFinished = false);
      }
    },
    livraisonsChargeesPartielles: function (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        (this.chargementIsStarted = false), (this.chargementIsFinished = false);
      }
    },
  },
};
</script>
<style scoped>
.table-row {
  border-bottom: 1px solid;
}
</style>