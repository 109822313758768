var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "userform",
          attrs: { method: "post" },
          on: { submit: _vm.addUser },
        },
        [
          _c("v-text-field", {
            attrs: {
              type: "text",
              value: _vm.getUser.username,
              label: "Nom d'utilisateur",
            },
            on: { change: _vm.setUsername },
          }),
          _c("v-text-field", {
            attrs: { type: "text", value: _vm.getUser.nom, label: "Nom" },
            on: { change: _vm.setNom },
          }),
          _c("v-text-field", {
            attrs: { type: "text", value: _vm.getUser.prenom, label: "Prénom" },
            on: { change: _vm.setPrenom },
          }),
          _c("v-text-field", {
            attrs: {
              type: "number",
              value: _vm.getUser.idERP,
              label: "Id ERP",
            },
            on: { change: _vm.setIdERP },
          }),
          _c("v-text-field", {
            attrs: { type: "email", value: _vm.getUser.email, label: "E-mail" },
            on: { change: _vm.setMail },
          }),
          _c("v-text-field", {
            attrs: {
              type: "text",
              value: _vm.getUser.phone,
              label: "Téléphone",
            },
            on: { change: _vm.setPhone },
          }),
          _c("v-text-field", {
            attrs: {
              type: "password",
              value: _vm.getUser.password,
              label: "Mot de passe",
            },
            on: { change: _vm.setPassword },
          }),
          _c("v-select", {
            attrs: {
              items: _vm.getAgences,
              rules: [(v) => !!v || "Agence obligatoire"],
              value: _vm.getUser.agence,
              "item-text": "libelle",
              "item-value": "_id",
              label: "Agence",
            },
            on: { change: _vm.setAgence },
          }),
          _c("v-select", {
            attrs: {
              items: _vm.getSocietes,
              rules: [(v) => !!v || "Société obligatoire"],
              value: _vm.getUser.societe,
              "item-text": "libelle",
              "item-value": "_id",
              label: "Société",
            },
            on: { change: _vm.setSociete },
          }),
          _c("v-select", {
            attrs: {
              items: _vm.roles,
              rules: [(v) => !!v || "Role obligatoire"],
              value: _vm.getUser.role,
              "item-text": "nom",
              "item-value": "nom",
              label: "Rôle",
            },
            on: { change: _vm.setRole },
          }),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Fermer")]
          ),
          _c(
            "v-btn",
            { attrs: { type: "submit", outlined: "", color: "green" } },
            [_vm._v("Créer")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }