<template>
  <v-container>
    <chantier-component></chantier-component>
  </v-container>
</template>

<script>
import ChantierComponent from "@/components/chantier/ChantierComponent";

export default {
  name: "Direction",
  components: {ChantierComponent}
}
</script>

<style scoped>

</style>