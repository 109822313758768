<!-- ------------------ SIGNATURE CLIENT ------------------ -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header disable-icon-rotate>
      Porte de garage
      <template v-slot:actions>
        <v-icon>
          mdi-garage
        </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list class="mx-0">
        <v-list-item>
          <!-- <v-list-item-avatar>
              <v-icon>              
              </v-icon>
            </v-list-item-avatar> -->
          <v-list-item-content>
            <b>Système de pose de la PG </b>
          </v-list-item-content>
          <v-list-item-action>
            <v-combobox
              :value="getPorteGarage.type_pose"
              :items="listeTypePosePG"
              label="Moyen de fixation de la PG"
              prepend-icon=""
              @change="setTextBox('type_pose', $event)"
            ></v-combobox>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <!-- <v-list-item-avatar>
              <v-icon>              
              </v-icon>
            </v-list-item-avatar> -->
          <v-list-item-content>
            <b>Quantité</b>
          </v-list-item-content>
          <v-list-item-action>
            <v-text-field
              type="number"
              v-model="getPorteGarage.quantite"
              outlined
              label="Quantité"
              @keydown="setTextBox('quantite', $event.target.value)"
            >
            </v-text-field>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "X-avancement-porte-garage",
  components: {},
  data() {
    return {
      jalons: [
        "demande_prix_recue_le",
        "devis_envoye_le",
        "devis_receptionne_valide_le",
        "avenant_encours_non_valide",
        "avenant_valide_le",
        "choix_finaux_client_realises",
      ],
      commandes: false,
      loading: false,
      listeTypePosePG: ["", "Bastaing", "Suspente"],
    };
  },
  methods: {
    setTextBox(stypeValeur, sValeur) {
      // Type de valeur pour savoir quel champ mettre à jour dans la classe 'avancement'
      // check pour savoir si c'est coché ou pas
      // valeur si on met autre chose que la date du jour
      let payload = {
        typeValeur: stypeValeur,
        nouvelleValeur: sValeur,
      };

      // Dispatch dans le front
      this.$store
        .dispatch("chantier/setAvancementPorteGarage", payload)
        .then(() => {
          this.$store.dispatch("chantier/updateAvancement");
        });
    },
  },
  computed: {
    ...mapGetters("chantier", {
      getEtatAvancementDate: "getEtatAvancementDate",
      getPorteGarage: "getPorteGarage",
    }),
  },
};
</script>

<style scoped></style>
