var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "elevation-1",
    attrs: {
      headers: _vm.headers,
      items: _vm.getJourneeList,
      "items-per-page": 15,
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (row) {
          return [
            _c("tr", { attrs: { disabled: "" } }, [
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("fullDate")(
                        row.item.journeeCorrigee
                          ? row.item.journeeCorrigee.date
                          : row.item.date
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      row.item.journeeCorrigee
                        ? row.item.journeeCorrigee.lieu_depart
                        : row.item.lieu_depart
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("simpleTime")(
                        row.item.journeeCorrigee
                          ? row.item.journeeCorrigee.heure_depart
                          : row.item.heure_depart
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("humantime")(
                        row.item.journeeCorrigee
                          ? row.item.journeeCorrigee.temps_pause
                          : row.item.temps_pause
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("simpleTime")(
                        row.item.journeeCorrigee
                          ? row.item.journeeCorrigee
                              .heure_depart_dernier_chantier
                          : row.item.heure_depart_dernier_chantier
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      row.item.journeeCorrigee
                        ? row.item.journeeCorrigee.lieu_arrivee === "Entreprise"
                          ? "Oui"
                          : "Non"
                        : row.item.lieu_arrivee === "Entreprise"
                        ? "Oui"
                        : "Non"
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("simpleTime")(
                        row.item.journeeCorrigee
                          ? row.item.journeeCorrigee.lieu_arrivee ===
                              "Entreprise" ||
                            row.item.journeeCorrigee.trajetSoir
                            ? row.item.journeeCorrigee.heure_arrivee
                            : ""
                          : row.item.lieu_arrivee === "Entreprise"
                          ? row.item.heure_arrivee
                          : ""
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("simpleTime")(
                        row.item.journeeCorrigee
                          ? row.item.journeeCorrigee.lieu_arrivee ===
                            "Entreprise"
                            ? row.item.journeeCorrigee.heure_depart_entreprise
                            : ""
                          : row.item.lieu_arrivee === "Entreprise"
                          ? row.item.heure_depart_entreprise
                          : ""
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("humantime")(
                        row.item.journeeCorrigee
                          ? row.item.journeeCorrigee.heuresTauxNormal +
                              row.item.journeeCorrigee.heuresMajorees
                          : row.item.heuresTauxNormal + row.item.heuresMajorees
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      row.item.journeeCorrigee
                        ? row.item.journeeCorrigee.villeLaPlusLointaine
                          ? row.item.journeeCorrigee.villeLaPlusLointaine.nom
                          : ""
                        : row.item.villeLaPlusLointaine.nom
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  _vm._s(row.item.user.nom) + " " + _vm._s(row.item.user.prenom)
                ),
              ]),
              _c("td", [_vm._v(_vm._s(row.item.user.agence.libelle))]),
              row.item.validated === false && row.item.journeeCorrigee !== null
                ? _c(
                    "td",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    dark: "",
                                    tile: "",
                                    elevation: "0",
                                    small: "",
                                    color: "green",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.validate(row.item)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { dark: "" } }, [
                                    _vm._v("mdi-check"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          row.item.journeeCorrigee.commentaire
                            ? _c(
                                "v-flex",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        fab: "",
                                        dark: "",
                                        small: "",
                                        tile: "",
                                        elevation: "0",
                                        color: "purple",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setCommentaireLecture(
                                            row.item.journeeCorrigee.commentaire
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { dark: "" } }, [
                                        _vm._v("mdi-comment-outline"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            [
                              _c(
                                "v-badge",
                                {
                                  attrs: {
                                    avatar: "",
                                    bordered: "",
                                    color: "grey",
                                    icon: "mdi-calendar-edit",
                                    overlap: "",
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        fab: "",
                                        dark: "",
                                        small: "",
                                        tile: "",
                                        elevation: "0",
                                        color: "orange",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reverseCorrectionAlert(
                                            row.item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { dark: "" } }, [
                                        _vm._v("mdi-undo-variant"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : row.item.validated === false
                ? _c(
                    "td",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    dark: "",
                                    tile: "",
                                    elevation: "0",
                                    small: "",
                                    color: "green",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.validate(row.item)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { dark: "" } }, [
                                    _vm._v("mdi-check"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    dark: "",
                                    tile: "",
                                    elevation: "0",
                                    small: "",
                                    color: "blue",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modifier(row.item)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { dark: "" } }, [
                                    _vm._v("mdi-calendar-edit"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : row.item.validated === true
                ? _c(
                    "td",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    dark: "",
                                    tile: "",
                                    elevation: "0",
                                    small: "",
                                    color: "red",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.unvalidateAlert(row.item)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { dark: "" } }, [
                                    _vm._v("mdi-lock-open-outline"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          row.item.journeeCorrigee
                            ? _c(
                                "v-flex",
                                [
                                  row.item.journeeCorrigee.commentaire
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            tile: "",
                                            elevation: "0",
                                            small: "",
                                            color: "purple",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setCommentaireLecture(
                                                row.item.journeeCorrigee
                                                  .commentaire
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { dark: "" } },
                                            [_vm._v("mdi-comment-outline")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }