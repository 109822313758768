var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "my-0 py-0" },
    [
      _c(
        "v-layout",
        { staticClass: "ma-2", attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            {
              staticClass: "mx-2",
              attrs: { xs12: "", sm6: "", md4: "", lg5: "" },
            },
            [
              _c("x-date-picker", {
                attrs: {
                  title: "Depuis le",
                  required: false,
                  date: _vm._f("toIso")(_vm.getQuery.dateDebut),
                },
                on: { datepicked: _vm.setDateDebut, clean: _vm.clearDateDebut },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "mx-2",
              attrs: { xs12: "", sm6: "", md4: "", lg5: "" },
            },
            [
              _c("x-date-picker", {
                attrs: {
                  title: "Jusqu au",
                  required: false,
                  date: _vm._f("toIso")(_vm.getQuery.dateFin),
                },
                on: { datepicked: _vm.setDateFin, clean: _vm.clearDateFin },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "mx-2",
              attrs: { xs12: "", sm6: "", md6: "", lg5: "" },
            },
            [
              _c("v-select", {
                attrs: {
                  label: "Agence",
                  "prepend-icon": "mdi-home",
                  value: _vm.getQuery.agence ? _vm.getQuery.agence : null,
                  color: _vm.getTurquoisePoupin,
                  items: _vm.getAgences,
                  "item-text": "libelle",
                  "item-value": "_id",
                  clearable: "",
                },
                on: { "click:clear": _vm.clearAgence, change: _vm.setAgence },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "mx-2",
              attrs: { xs12: "", sm6: "", md6: "", lg5: "" },
            },
            [
              _c("v-select", {
                attrs: {
                  label: "Poseur",
                  "prepend-icon": "mdi-account",
                  value: _vm.getQuery.poseur ? _vm.getQuery.poseur : null,
                  color: _vm.getTurquoisePoupin,
                  items: _vm.getUsers,
                  "item-value": "_id",
                  clearable: "",
                },
                on: { "click:clear": _vm.clearPoseur, change: _vm.setPoseur },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function (data) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(data.item.nom) +
                            " " +
                            _vm._s(data.item.prenom) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item",
                    fn: function (data) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(data.item.nom) +
                            " " +
                            _vm._s(data.item.prenom) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "mx-2",
              attrs: { xs7: "", sm2: "", md2: "", lg2: "", xl2: "" },
            },
            [
              _c("v-switch", {
                attrs: {
                  label: "Non-validé",
                  color: _vm.getTurquoisePoupin,
                  "prepend-icon": "mdi-check",
                  "true-value": false,
                  "false-value": true,
                },
                on: { change: _vm.setValidated },
                model: {
                  value: _vm.switchValidated,
                  callback: function ($$v) {
                    _vm.switchValidated = $$v
                  },
                  expression: "switchValidated",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }