import Vue from "vue";
import router from "../../router";
import _ from "lodash";

const state = {
  token: {},
  user: {},
  error: {},
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  userInSession: (state) => {
    return state.user;
  },
  isConnected: (state) => {
    return (
      Object.keys(state.token).length !== 0 &&
      Object.keys(state.user).length !== 0
    );
  },
  isAdmin: (state) => {
    return state.user ? state.user.role == "Admin" : false;
  },
  isDirection: (state) => {
    return state.user ? state.user.role == "Direction" : false;
  },
  isBE: (state) => {
    return state.user ? state.user.role == "BE" : false;
  },
  isPoseur: (state) => {
    return state.user ? state.user.role == "Poseur" : false;
  },
  isGestionnaire: (state) => {
    return state.user ? state.user.role == "Gestionnaire" : false;
  },
  isConducteur: (state) => {
    return state.user ? state.user.role == "Conducteur" : false;
  },
  isLivreur: (state) => {
    return state.user ? state.user.role == "Livreur" : false;
  },
  isResponsableAlu: (state) => {
    return state.user ? state.user.role == "ResponsableAlu" : false;
  },
  isResponsablePVC: (state) => {
    return state.user ? state.user.role == "ResponsablePvc" : false;
  },
  isResponsableBOIS: (state) => {
    return state.user ? state.user.role == "ResponsableBois" : false;
  },
  isPoseurReno: (state) => {
    return state.user ? state.user.role == "PoseurRenovation" : false;
  },
  isResponsableReno: (state) => {
    return state.user ? state.user.role == "ResponsableRenovation" : false;
  },
  getToken: (state) => {
    return state.token;
  },
  getError(state) {
    return state.error;
  },
};

const mutations = {
  AUTH_LOGOUT(state) {
    state.user = {};
    state.token = {};
  },
  AUTH_LOGIN(state, data) {
    state.user = data.user;
    state.token = data.token;
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("token", data.token);
  },
  SET_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem("token", token);
  },
  INITIALISATION_STORE(state) {
    if (
      localStorage.getItem("token") &&
      Object.keys(state.token).length === 0
    ) {
      state.token = localStorage.getItem("token");
    }
    if (localStorage.getItem("user") && Object.keys(state.user).length === 0) {
      state.user = JSON.parse(localStorage.getItem("user"));
    }
  },
  SET_ERROR(state, err) {
    state.error = err;
  },
  INITIALISATION_FAVORIS() {
    state.user.chantiers_favoris = [];
  },
  ADD_FAVORIS(state, payload) {
    state.user.chantiers_favoris.push(payload);
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  REMOVE_FAVORIS(state, payload) {
    let index = _.indexOf(state.user.chantiers_favoris, payload._id);
    state.user.chantiers_favoris.splice(index, 1);
    localStorage.setItem("user", JSON.stringify(state.user));
  },
};

const actions = {
  logout({ commit }) {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    commit("AUTH_LOGOUT");
  },
  loginUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .post("/api/auth/login", {
          username: user.username,
          password: user.password,
        })
        .then((res) => {
          commit("AUTH_LOGIN", res.data);

          let is_admin;
          let is_be;
          if (res.data.user.role == "Admin") {
            is_admin = true;
          } else if (res.data.user.role == "BE") {
            is_be = true;
          } else {
            is_be = false;
            is_admin = false;
          }

          if (localStorage.getItem("token") != null && is_admin) {
            router.push("Admin");
          } else if (localStorage.getItem("token") != null && is_be) {
            router.push("Ordonnancement");
          } else {
            router.push("/");
          }
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  },
  setError({ commit }, err) {
    commit("SET_ERROR", err);
  },
  nullError({ commit }) {
    commit("SET_ERROR", null);
  },
  tokenIsValid() {
    Vue.axios.get("/api/auth/valid");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
