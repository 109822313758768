<template>
    <v-container>
      <v-row style="z-index: 1">
        <v-card elevation="0">
          <v-card-title>
            <v-col>
            <v-checkbox
              v-model="filtreProchainesSemaine"    
              @click="changeLabelCheckbox"            
              :label="lblCheckBoxAfficheToutChantier"
            ></v-checkbox>
            </v-col>
          </v-card-title>
        </v-card>
      </v-row>
      <v-row>     
        <v-col>
          <v-card>
            <v-card-title>
              <v-col>
                <v-btn
                  color="green"
                  class="white--text"
                  @click="rechercheAvecfiltres()"
                >
                  Rechercher
                </v-btn>
              </v-col>              
              <v-col style="z-index: 6">                
                <v-select
                  :items="listInterventionsWithoutCoordonnees"
                  item-color="blue"
                  label="Liste des interventions n'ayant pas de coordonnées"
                  dense
                  @focus="mapOffset = 50"
                >
                </v-select>
              </v-col>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="top: 5em; left: 0; right: 0; bottom: 0; z-index: 5;">
        <v-col>
          <!-- Conteneur autour de Leaflet Map -->
          <div
            class="leaflet-map-container"
          >
            <leaflet-map-component
              ref="leafletMap"
              style="height: 60em; width: 130em"
            ></leaflet-map-component>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>

import { mapActions,mapGetters } from "vuex";
import moment from 'moment';
import Vue from "vue";
import { CordonneesGpsInterventionModelView } from "../service/planningpose/LeafletMap/CoordonnesGpsInterventionModelView";
import LeafletMapComponent from "../components/planningPose/LeafletComponents/LeafletMapComponent.vue";



export default {
  name: 'PageLeaflet',
  components: {
    LeafletMapComponent
  },

  data() {
    return {
      listeAgence: [],
      listInterventionsWithoutCoordonnees: [],
      selectedItem: null,
      listConducteursTravaux: [],
      idAgence: 0,
      mapOffset: 0,
      PremiereSemaineFiltre: moment().isoWeek(),
      DeuxiemeSemaineFiltre: moment().isoWeek(),
      anneeFiltre: moment().year(),
      filtreProchainesSemaine: true,
      modalConfirmMultiReverse: false,
      modalCommentaire: false,
      modalCommentaireLecture: false,
      leafletMap: "",
      dateDebutCalendar: "",
      dateFinCalendar: "",
      lblCheckBoxAfficheToutChantier: "Afficher tout les chantiers",
    };
  },


  methods: {

  async rechercheAvecfiltres() {
    let listCoordonnees = await this.recupListeInterventionSemaine();

    if (this.$refs.leafletMap) {
      if (this.$refs.leafletMap) {    
        this.$refs.leafletMap.listCoordonnees = listCoordonnees;
        this.$refs.leafletMap.LoadIcons();  
      }
    }
  },


  async recupListeInterventionSemaine() {
    let listCordonnees = [];

    await Vue.axios
      .get("/api/gpsCoordonnees/listeInterventions/" + this.userInSession.idERP + "/" + this.filtreProchainesSemaine)
      .then(
        async (res) => {
          if (res.status === 200) {
            
            if (Array.isArray(res.data.interventions)) {
              for (let co of res.data.interventions) {
                let x = 0;
                let y = 0;            

                //console.log("data = ", co)
                if (co.coordonneeX != null) {
                  x = parseFloat(co.coordonneeX);
                  y = parseFloat(co.coordonneeY);
                  listCordonnees.push(
                    new CordonneesGpsInterventionModelView(
                      co.compteur,
                      x,
                      y,
                      co.rue,
                      co.cp,
                      co.ville,
                      co.name,
                      co.typeInter,
                      co.isReady
                    )
                  );
                } else {
                  this.listInterventionsWithoutCoordonnees.push(co.name);
                }
              }
            } else {
              console.error("listCordonnees is not an array");
            }
          } else {
            console.error(res,"Failed to retrieve data");
          }
        }
      )
      .catch((error) => {
        console.error("Error fetching data:", error);
      });    
    return listCordonnees;
  },

  changeLabelCheckbox(){

    if(this.filtreProchainesSemaine){
        this.lblCheckBoxAfficheToutChantier = "Afficher tout les chantiers";
    }else{
        this.lblCheckBoxAfficheToutChantier = "Afficher seulement les 6 semaines à venir";
    }

  },

    ...mapActions("coordonneesGPS", {
      returnListeGPSPourUnConducteur: "returnListeGPSPourUnConducteur"
    }),

  },
  
  computed: {
    ...mapGetters("coordonneesGPS", {
      getAllGPS: "getGps",
    }),
    ...mapGetters("auth", {
      userInSession: "userInSession",
    }), 
  },
  

  created() {
    console.log("created", this.userInSession);
    this.mapOffset = 0;
    this.idAgence = 0;
    this.semaineFiltre = moment().isoWeek();
    this.anneeFiltre = moment().year();
    this.listeAgence = [];
    this.listConducteursTravaux = [];
    this.rechercheAvecfiltres();
  },
}
</script>
