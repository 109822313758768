export class CordonneesGpsInterventionModelView {
  Compteur=0;
  CoordonneeX=0;
  CoordonneeY=0;
  Rue="";
  CodePostal=0;
  Ville="";
  Name="";
  TypeInter=0;
  IsReady = false;

  constructor(
    compteur,
    coordonneeX,
    coordonneeY,
    rue,
    codePostal,
    ville,
    name,
    typeInter,
    isReady
  ) {
    this.Compteur = compteur;
    this.CoordonneeX = coordonneeX;
    this.CoordonneeY = coordonneeY;
    this.Rue = rue;
    this.CodePostal = codePostal;
    this.Ville = ville;
    this.Name = name;
    this.TypeInter = typeInter;
    this.IsReady = isReady;
  }
}
