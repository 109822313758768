<template>
  <v-container class="my-0 py-0">
    <v-layout row wrap class="ma-2">
      <v-flex xs12 sm6 md4 lg5 class="mx-2">
        <x-date-picker
            :title="'Depuis le'"
            :required="false"
            :date="getQuery.dateDebut | toIso"
            @datepicked="setDateDebut"
            @clean="clearDateDebut"
        >
        </x-date-picker>
      </v-flex>
      <v-flex xs12 sm6 md4 lg5 class="mx-2">
        <x-date-picker
            :title="'Jusqu au'"
            :required="false"
            :date="getQuery.dateFin | toIso"
            @datepicked="setDateFin"
            @clean="clearDateFin"
        >
        </x-date-picker>
      </v-flex>
      <v-flex xs12 sm6 md6 lg5 class="mx-2">
        <v-select
            label="Agence"
            prepend-icon="mdi-home"
            :value="getQuery.agence ? getQuery.agence : null"
            :color="getTurquoisePoupin"
            :items="getAgences"
            item-text="libelle"
            item-value="_id"
            clearable
            @click:clear="clearAgence"
            @change="setAgence"
        >
        </v-select>
      </v-flex>
      <v-flex xs12 sm6 md6 lg5 class="mx-2">
        <v-select
            label="Poseur"
            prepend-icon="mdi-account"
            :value="getQuery.poseur ? getQuery.poseur : null"
            :color="getTurquoisePoupin"
            :items="getUsers"
            item-value="_id"
            clearable
            @click:clear="clearPoseur"
            @change="setPoseur"
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.nom }} {{ data.item.prenom }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.nom }} {{ data.item.prenom }}
          </template>
        </v-select>
      </v-flex>
      <v-flex xs7 sm2 md2 lg2 xl2 class="mx-2">
        <v-switch
            label="Non-validé"
            :color="getTurquoisePoupin"
            prepend-icon="mdi-check"
            :true-value="false"
            :false-value="true"
            v-model="switchValidated"
            @change="setValidated"
        >
        </v-switch>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import XDatePicker from '@/components/time/X-date-picker';
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "X-filtres-gestion",
  components: {
    XDatePicker
  },
  data() {
    return {
      switchValidated: false
    }
  },
  created() {
    this.$store.dispatch('time/initialiseQuery').then(() => {
      this.$store.dispatch('agence/returnAgences');
      this.$store.dispatch('user/returnPoseursParAgence')
      this.$store.dispatch('time/returnTimeQuery');
    })
  },
  methods: {
    setDateDebut(e) {

      alert("setDateDebut")
      this.$store.dispatch('time/setQueryDateDebut', moment(e).toDate()).then( () => {
        this.$store.dispatch('time/returnTimeQuery');
        this.$forceUpdate()
      })
    },
    clearDateDebut() {
      return this.$store.dispatch('time/clearQueryDateDebut').then( () => {
        this.$store.dispatch('time/returnTimeQuery');
      })
    },
    setDateFin(e) {
      alert("setDateFin")
      this.$store.dispatch('time/setQueryDateFin', moment(e).toDate()).then( () => {
        this.$store.dispatch('time/returnTimeQuery');
        this.$forceUpdate()
      })
    },
    clearDateFin() {
      return this.$store.dispatch('time/clearQueryDateFin').then( () => {
        this.$store.dispatch('time/returnTimeQuery');
      })
    },
    setAgence(e) {
      this.$store.dispatch('time/setQueryAgence', e).then(() => {
        this.$store.dispatch('time/returnTimeQuery');
        this.$store.dispatch('user/returnPoseursParAgence');
      })
    },
    clearAgence() {
      return this.$store.dispatch('time/clearQueryAgence').then( () => {
        this.$store.dispatch('user/returnPoseursParAgence');
        this.$store.dispatch('time/returnTimeQuery');
      })
    },
    setPoseur(e) {
      this.$store.dispatch('time/setQueryPoseur', e).then(() => {
        this.$store.dispatch('time/returnTimeQuery');
      })
    },
    clearPoseur() {
      return this.$store.dispatch('time/clearQueryPoseur').then( () => {
        this.$store.dispatch('time/returnTimeQuery');
      })
    },
    setValidated(e) {
      this.$store.dispatch('time/setQueryValidated', e).then(() => {
        this.$store.dispatch('time/returnTimeQuery');
      })
    },
  },
  computed: {
    ...mapGetters(['getTurquoisePoupin']),

    ...mapGetters('user', {
      getUsers: 'getUsers'
    }),

    ...mapGetters('agence', {
      getAgences: 'getAgences'
    }),

    ...mapGetters('time', {
      getQuery: 'getQuery'
    }),
    getFiltreDateDebut(){
      return this.getQuery.dateDebut
    },
    getFiltreDateFin(){
      return this.getQuery.dateFin
    }
  },
}
</script>

<style scoped>

</style>