var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.modaleCommentaireIsVisible, scrollable: true },
      on: {
        "click:outside": function ($event) {
          return _vm.$emit("closeModaleCommentaire")
        },
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "mb-5" }, [
            _vm._v(
              " Commentaire et historique commande N° " +
                _vm._s(_vm.selected_livraison.commande.numero_commande) +
                " "
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c("v-textarea", {
                attrs: { filled: "", label: "Commentaire libre" },
                model: {
                  value: _vm.new_commentaire_libre,
                  callback: function ($$v) {
                    _vm.new_commentaire_libre = $$v
                  },
                  expression: "new_commentaire_libre",
                },
              }),
            ],
            1
          ),
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-row", { staticClass: "font-weight-bold" }, [
                        _vm._v("Commentaire(s) libre(s)"),
                      ]),
                      _c("v-col"),
                      _vm._l(
                        _vm.selected_livraison.commentaire_libre,
                        function (commentaire_libre) {
                          return _c(
                            "v-row",
                            {
                              key:
                                _vm.selected_livraison._id + commentaire_libre,
                            },
                            [_vm._v(" " + _vm._s(commentaire_libre))]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-row", { staticClass: "font-weight-bold" }, [
                        _vm._v("Commentaire(s) chargement"),
                      ]),
                      _c("v-col"),
                      _vm._l(
                        _vm.selected_livraison.commentaire_chargement,
                        function (commentaire_chargement) {
                          return _c(
                            "v-row",
                            {
                              key:
                                _vm.selected_livraison._id +
                                commentaire_chargement,
                            },
                            [_vm._v(" " + _vm._s(commentaire_chargement))]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-row", { staticClass: "font-weight-bold" }, [
                        _vm._v("Commentaire(s) réception"),
                      ]),
                      _c("v-col"),
                      _vm._l(
                        _vm.selected_livraison.commentaire_reception,
                        function (commentaire_reception) {
                          return _c(
                            "v-row",
                            {
                              key:
                                _vm.selected_livraison._id +
                                commentaire_reception,
                            },
                            [_vm._v(" " + _vm._s(commentaire_reception))]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.new_commentaire_libre.length > 2
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "green", "x-large": "" },
                      on: {
                        click: function ($event) {
                          return _vm.addCommentaireLibre(
                            _vm.selected_livraison._id,
                            _vm.new_commentaire_libre
                          )
                        },
                      },
                    },
                    [_vm._v("VALIDER")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "red", "x-large": "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModaleCommentaire")
                    },
                  },
                },
                [_vm._v("FERMER")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }