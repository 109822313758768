<template>
  <v-dialog
    :value="modaleCommentaireIsVisible"
    @click:outside="$emit('closeModaleCommentaire')"
    :scrollable="true"
  >
    <v-card>
      <v-card-title class="mb-5">
        Commentaire et historique commande N°
        {{ selected_livraison.commande.numero_commande }}
      </v-card-title>
      <v-card-text>
        <v-textarea
          filled
          label="Commentaire libre"
          v-model="new_commentaire_libre"
        >
        </v-textarea>
      </v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-row class="font-weight-bold">Commentaire(s) libre(s)</v-row>
            <v-col></v-col>
            <v-row
              v-for="commentaire_libre in selected_livraison.commentaire_libre"
              :key="selected_livraison._id + commentaire_libre"
            >
              {{ commentaire_libre }}</v-row
            >
          </v-col>
          <v-col>
            <v-row class="font-weight-bold">Commentaire(s) chargement</v-row>
            <v-col></v-col>
            <v-row
              v-for="commentaire_chargement in selected_livraison.commentaire_chargement"
              :key="selected_livraison._id + commentaire_chargement"
            >
              {{ commentaire_chargement }}</v-row
            >
          </v-col>
          <v-col>
            <v-row class="font-weight-bold">Commentaire(s) réception</v-row>
            <v-col></v-col>
            <v-row
              v-for="commentaire_reception in selected_livraison.commentaire_reception"
              :key="selected_livraison._id + commentaire_reception"
            >
              {{ commentaire_reception }}</v-row
            >
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="new_commentaire_libre.length > 2"
          @click="
            addCommentaireLibre(selected_livraison._id, new_commentaire_libre)
          "
          color="green"
          x-large
          >VALIDER</v-btn
        >
        <v-btn @click="$emit('closeModaleCommentaire')" color="red" x-large
          >FERMER</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
//import moment from "moment";
export default {
  name: "X-modale-commentaire-livraison",
  data() {
    return {
      new_commentaire_libre: "",
    };
  },
  props: {
    modaleCommentaireIsVisible: {
      type: Boolean,
      default: false,
    },
    selected_livraison: null,
  },
  methods: {
    // Enregistrement d'un commentaire libre avec le nom de l'utilisateur et la date précise à la minute
    addCommentaireLibre(livraisonID, new_commentaire_libre) {
      let userAndDate = this.getUserAndDate();
      new_commentaire_libre = userAndDate + " " + new_commentaire_libre;

      let listeCommentaire = this.selected_livraison.commentaire_libre;
      listeCommentaire.push(new_commentaire_libre);
      let payload = {
        livraisonID: livraisonID,
        commentaire_libre: listeCommentaire,
      };
      this.$store.dispatch("livraison/updateOneLivraison", payload);
    },
    // Récupère le moment précis à la minute ainsi que l'utilisateur connecté et retourne une chaine de caractères
    getUserAndDate() {
      this.$store.dispatch("time/modifiedActualDate", { date: moment() });
      let now = this.actualDate.date;
      let hour = moment(now).format("LT");
      let day = moment(now).format("L");
      return (
        "Le " +
        day +
        " à " +
        hour +
        " par " +
        this.ConnectedUser.prenom +
        " " +
        this.ConnectedUser.nom +
        " : "
      );
    },
  },
  computed: {
    ...mapGetters("auth", {
      ConnectedUser: "userInSession",
    }),
    ...mapGetters("time", {
      actualDate: "getActualDateWithHour",
    }),
  },
};
</script>
