<!-- ------------------ SIGNATURE CLIENT ------------------ -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header disable-icon-rotate>
      {{ getEtatAvancementDate("3000").message }} {{ " - Début :" }}
      {{ getChantier.date_debut_premiere_pose | semaine }} {{ "Fin :" }}
      {{ getChantier.date_fin_premiere_pose | semaine }}
      <template v-slot:actions>
        <v-icon :color="getEtatAvancementDate('3000').couleur">
          {{ getEtatAvancementDate("3000").icone }}
        </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list class="mx-0">
        <!-- Coche toutes les cases -->
        <v-list-item>
          <v-icon></v-icon>
          <v-list-item-content
            ><v-label>Valider toutes les étapes ?</v-label></v-list-item-content
          >
          <v-list-item-action>
            <v-icon color="blue-grey darken-2" @click="setAllValueDatePicker()"
              >mdi-check-all</v-icon
            >
          </v-list-item-action>
        </v-list-item>
        <!-- Fondation -->
        <x-avancement-jalon-change-etat
          :checkbox="true"
          :champ_recherche="'fondation_coulees_le'"
          :nb_step="2"
          :etat="getEtatAvancementDate('fondation_coulees_le').etat"
          @choixCheckBoxEtat="
            setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
          "
        ></x-avancement-jalon-change-etat>
        <!-- Elevations -->
        <x-avancement-jalon-change-etat
          :checkbox="true"
          :champ_recherche="'elevation_le'"
          :nb_step="3"
          :etat="getEtatAvancementDate('elevation_le').etat"
          @choixCheckBoxEtat="
            setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
          "
        ></x-avancement-jalon-change-etat>
        <!-- Charpente -->
        <x-avancement-jalon-change-etat
          :checkbox="true"
          :champ_recherche="'charpente_le'"
          :nb_step="3"
          :etat="getEtatAvancementDate('charpente_le').etat"
          @choixCheckBoxEtat="
            setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
          "
        ></x-avancement-jalon-change-etat>
        <!-- Couverture -->
        <x-avancement-jalon-change-etat
          :checkbox="true"
          :champ_recherche="'couverture_le'"
          :nb_step="3"
          :etat="getEtatAvancementDate('couverture_le').etat"
          @choixCheckBoxEtat="
            setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
          "
        ></x-avancement-jalon-change-etat>
        <!-- Appuis et seuils vérifiés -->

        <!-- <x-avancement-jalon-change-etat
              v-if="getWindowWidth > 600"              
              :checkbox="false"
              :champ_recherche="'verif_details_dappui_premiere_pose.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
            >
              </x-avancement-jalon-change-etat>

              <x-avancement-jalon-change-etat-date-mobile
              v-else
              :champ_recherche="'verif_details_dappui_premiere_pose.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
              >
              </x-avancement-jalon-change-etat-date-mobile>                   -->
        <x-avancement-jalon-change-etat
          :checkbox="true"
          :champ_recherche="'verif_details_dappui_premiere_pose'"
          :nb_step="2"
          :etat="
            getEtatAvancementDate('verif_details_dappui_premiere_pose').etat
          "
          @choixCheckBoxEtat="
            setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
          "
        ></x-avancement-jalon-change-etat>
        <!-- Etat globale avancement 1ère pose -->
        <!-- <x-avancement-jalon-change-etat
              v-if="getWindowWidth > 600"              
              :checkbox="false"
              :champ_recherche="'pret_a_poser_premiere_pose.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
            >
              </x-avancement-jalon-change-etat>

              <x-avancement-jalon-change-etat-date-mobile
              v-else
              :champ_recherche="'pret_a_poser_premiere_pose.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
              >
              </x-avancement-jalon-change-etat-date-mobile>                                                   -->
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from "vuex";
import XAvancementJalonChangeEtat from "./X-avancement-jalon-change-etat.vue";
import moment from "moment";

export default {
  name: "X-avancement-premiere-pose",
  components: { XAvancementJalonChangeEtat },
  data() {
    return {
      jalons: [
        "fondation_coulees_le",
        "devis_envoye_le",
        "charpente_le",
        "couverture_le",
        "verif_details_dappui_premiere_pose",
      ],
      commandes: false,
      loading: false,
    };
  },
  methods: {
    /**
     * Mise à jour de tout les états
     */
    setAllValueDatePicker() {
      let jalons = [
        "fondation_coulees_le",
        "elevation_le",
        "charpente_le",
        "couverture_le",
        "verif_details_dappui_premiere_pose",
      ];

      for (let i = 0; i < jalons.length; i++) {
        let letat = "EC";
        let nbStep = 3;

        if (i === 0 || i == 4) {
          nbStep = 2;
        }
        this.setValueCheckboxDate(jalons[i], letat, nbStep);
      }
    },

    setValueDatePicker(stypeValeur, valeur) {
      // Type de valeur pour savoir quel champ mettre à jour dans la classe 'avancement'
      // check pour savoir si c'est coché ou pas
      // valeur si on met autre chose que la date du jour
      let payload = {
        typeValeur: stypeValeur,
        nouvelleValeur: moment(valeur)
          .toDate()
          .toISOString(),
        isChecked: false,
        datePrevi: moment(valeur).toDate(),
        etat: "",
      };

      switch (payload.typeValeur) {
        case "-1":
          // Dispatch dans le front
          this.$store
            .dispatch("chantier/setChantierDateTheorique", payload)
            .then(() => {
              this.$store.dispatch("chantier/updateDateTheoriquePremierePose");
            });
          break;

        case "-2":
          // Dispatch dans le front
          this.$store
            .dispatch("chantier/setChantierDateTheorique", payload)
            .then(() => {
              this.$store.dispatch("chantier/updateDateTheoriqueFinition");
            });
          break;
        case "verif_details_dappui_premiere_pose.date_previ":
        case "prise_cote_bso_le.date_previ":
        case "prise_cote_finitions_le.date_previ":
        case "habillage_tableaux_le.date_previ":
        case "pret_a_poser_premiere_pose.date_previ":
        case "pret_a_poser_finition.date_previ":
        case "pv_reception_signe_le.date_previ":
        case "levee_reserve_le.date_previ":
        case "pv_levee_reserve_le.date_previ":
          payload.nouvelleValeur = moment("01/01/1900").toDate();
          // Dispatch dans le front
          this.$store
            .dispatch("chantier/setChantierAvancementDatePrevi", payload)
            .then(async () => {
              await this.$store.dispatch("chantier/updateAvancement");
              this.$forceUpdate();
            });

          break;
      }
    },
    setValueCheckboxDate(stypeValeur, sEtat, nbStep) {
      // Type de valeur pour savoir quel champ mettre à jour dans la classe 'avancement'
      // check pour savoir si c'est coché ou pas
      // valeur si on met autre chose que la date du jour
      let payload = {
        typeValeur: stypeValeur,
        nouvelleValeur: moment().toDate(),
        isChecked: false,
        datePrevi: moment("01/01/1900").toDate(),
        etat: sEtat,
      };

      if (nbStep === 3) {
        if (payload.etat === "" || payload.etat === "NC") {
          payload.etat = "EC";
        } else if (payload.etat === "EC") {
          payload.etat = "TE";
        } else if (payload.etat === "TE") {
          payload.etat = "NC";
          payload.nouvelleValeur = moment("01/01/0001").toDate();
          payload.isChecked = true;
        }
      } else if (nbStep === 2) {
        if (
          payload.etat === "" ||
          payload.etat === "NC" ||
          payload.etat === "EC"
        ) {
          payload.etat = "TE";
        } else if (payload.etat === "TE") {
          payload.etat = "NC";
          payload.nouvelleValeur = moment("01/01/0001").toDate();
          payload.isChecked = true;
        }
      }

      // Dispatch dans le front
      this.$store.dispatch("chantier/setAvancementDate", payload).then(() => {
        this.$store.dispatch("chantier/updateAvancement");
      });
    },
  },
  computed: {
    ...mapGetters("chantier", {
      getChantier: "getChantier",
      getEtatAvancementDate: "getEtatAvancementDate",
    }),
    ...mapGetters("util", {
      getWindowWidth: "getWindowWidth",
    }),
  },
};
</script>

<style scoped></style>
