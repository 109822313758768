import Vue from "vue";

const state = {
  tempsDeTransport: null,
  seriesWeek: [
    {
      name: "Heures sans majoration",
      color: "#60739F",
      data: [],
    },
    {
      name: "Heures supplémentaires",
      color: "#9F6260",
      data: [],
    },
  ],
  chartOptionsWeek: {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    title: {
      text: "Heures réalisées par semaines",
    },
    noData: {
      text: "Chargement...",
    },
    xaxis: {
      title: {
        text: "Nombre d'heures",
      },
      categories: [],
      labels: {
        formatter: function(val) {
          return val;
        },
      },
    },
    yaxis: {
      title: {
        text: "Semaine",
      },
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val + "h";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  },
};

const getters = {
  getTransport(state) {
    return state.tempsDeTransport;
  },
  getSeriesWeek(state) {
    return state.seriesWeek;
  },
  getChartOptionsWeek(state) {
    return state.chartOptionsWeek;
  },
};

const mutations = {
  SET_WEEK(state, data) {
    state.seriesWeek[0].data = data.heureNormals;
    state.seriesWeek[1].data = data.heureSups;
    state.chartOptionsWeek.xaxis.categories = data.semaines;
    window.dispatchEvent(new Event("resize"));
  },
  SET_TRANSPORT(state, payload) {
    state.tempsDeTransport = payload;
  },
};

const actions = {
  returnTempsTransport({ commit }, payload) {
    Vue.axios.get(`/api/journee/transport/${payload}`).then((resultat) => {
      commit("SET_TRANSPORT", resultat.data);
    });
  },
  setWeek({ commit, rootGetters }) {
    let user = rootGetters["auth/userInSession"];

    if (typeof user === "string") {
      user = JSON.parse(rootGetters["auth/userInSession"]);
    }

    Vue.axios
      .get("/api/journee/weekstat/" + user._id)
      .then((res) => {
        commit("SET_WEEK", res.data);
      })
      .catch();
  },
  /*updateComptage() {
     
        Vue.axios.get('/api/journee/updateCalcul')
            .then( () => {
            
            })
            .catch( () => {
            
            })
    }*/
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
