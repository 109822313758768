<template>
  <v-container>
    <v-dialog
      :value="modaleAjoutLivraisonIsVisible"
      max-width="500"
      @click:outside="closeModaleAjoutLivraison()"
    >
      <v-stepper vertical v-model="stepValue">
        <v-stepper-step step="1" :complete="stepValue > 0">
          Entrez le N. de Commande
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-text-field
            label="N.Commande"
            v-model="numCommande"
            hint="5 chiffres"
          >
          </v-text-field>
          <v-select
            label="Type de commande"
            :items="typeCommande"
            v-model="commandeInterne"
          >
          </v-select>
          <v-row
            ><v-col> </v-col> <v-spacer></v-spacer>
            <v-col
              ><v-btn
                @click="findCommandeByNum()"
                color="green"
                x-large
                elevation="2"
              >
                Valider
              </v-btn></v-col
            ></v-row
          >
        </v-stepper-content>
        <v-stepper-step step="2" :complete="stepValue > 1">
          Informations Commande
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-container v-if="!newCommandeForLivraison">
            <v-row class="font-weight-bold">
              <v-col
                >Aucune commande ne correspond au N. {{ numCommande }}
              </v-col>
            </v-row>
            <v-row>
              <v-col></v-col>
              <v-col></v-col>
              <v-spacer></v-spacer>
              <v-btn elevation="2" color="red" @click="stepValue -= 1" x-large>
                Retour
              </v-btn>
            </v-row>
          </v-container>
          <v-container v-if="newCommandeForLivraison">
            <v-row>
              <v-col
                ><span class="font-weight-bold">Client </span>
                {{ " : " + newCommandeForLivraison.client }}</v-col
              >
            </v-row>
            <v-row class="font-weight-bold">
              <v-col v-if="newCommandeForLivraison.atelier">Atelier</v-col>
              <v-col v-if="newCommandeForLivraison.fournisseur"
                >Fournisseur</v-col
              >
              <v-col>Description</v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  v-if="newCommandeForLivraison.atelier"
                  elevation="0"
                  :color="chooseColorAtelier(newCommandeForLivraison.atelier)"
                  >{{ newCommandeForLivraison.atelier }}</v-btn
                >
                <v-btn
                  v-if="newCommandeForLivraison.fournisseur"
                  elevation="0"
                  >{{ newCommandeForLivraison.fournisseur }}</v-btn
                >
              </v-col>
              <v-col>
                {{ newCommandeForLivraison.description }}
              </v-col>
            </v-row>
            <v-row class="font-weight-bold">
              <v-col>Type commande</v-col>
              <v-col>Famille</v-col>
            </v-row>
            <v-row>
              <v-col v-if="newCommandeForLivraison.type === 'CDI'">
                <v-btn :color="getTurquoisePoupin" elevation="0">
                  Interne
                </v-btn>
              </v-col>
              <v-col v-if="newCommandeForLivraison.type === 'CDE'">
                <v-btn elevation="0"> Externe </v-btn>
              </v-col>
              <v-col>
                {{ newCommandeForLivraison.famille }}
              </v-col>
            </v-row>
            <v-row class="text-center">
              <v-col></v-col>
              <v-col
                ><v-btn
                  color="green"
                  x-large
                  elevation="2"
                  @click="createNewLivraison(newCommandeForLivraison)"
                >
                  Créer livraison
                </v-btn></v-col
              >
            </v-row>
          </v-container>
        </v-stepper-content>
      </v-stepper>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "X-ajout-livraison",
  data() {
    return {
      numCommande: "",
      typeCommande: ["Interne", "Externe"],
      commandeInterne: "Interne",
      newCommande: this.newCommandeForLivraison,
      stepValue: 1,
    };
  },
  props: {
    modaleAjoutLivraisonIsVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async findCommandeByNum() {
      let payload = {
        numCommande: this.numCommande,
        type: this.commandeInterne,
      };
      await this.$store
        .dispatch("livraison/findCommandeByNum", payload)
        .then((this.stepValue += 1));
    },
    chooseColorAtelier(atelier) {
      let color = "white";
      switch (atelier) {
        case "BOIS":
          color = this.getVertBois;
          break;
        case "ALU":
          color = this.getOrangeAlu;
          break;
        case "PVC":
          color = this.getJaunePVC;
          break;
        case "Magasin":
          color = this.getRedMagasin;
          break;
        case "Volet":
          color = this.getPurpleVolet;
          break;
      }
      return color;
    },
    // Création livraison par le livreur
    async createNewLivraison(commande) {
      this.$store
        .dispatch("livraison/createNewLivraison", commande)
        .then(
          (this.stepValue -= 1),
          (this.numCommande = ""),
          (this.commandeInterne = "Interne"),
          this.$emit("closeModaleAjoutLivraison")
        );
    },

    closeModaleAjoutLivraison() {
      this.stepValue = 1;
      this.$emit("closeModaleAjoutLivraison");
    },
  },
  computed: {
    ...mapGetters("livraison", {
      newCommandeForLivraison: "getNewCommande", // Récupère commande à ajouter à la livraison
    }),
    ...mapGetters([
      "getTurquoisePoupin",
      "getOrangeAlu",
      "getVertBois",
      "getJaunePVC",
      "getRedMagasin",
      "getPurpleVolet",
      "getCouleurCommandeInterne",
    ]),
    ...mapGetters("livraison", {
      actualQuery: "getQuery", // Récupère liste des filtres
    }),
  },
};
</script>
