<!-- ------------------ SIGNATURE CLIENT ------------------ -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header disable-icon-rotate>
      {{ getEtatAvancementDate("5000").message }}
      <template v-slot:actions>
        <v-icon :color="getEtatAvancementDate('5000').couleur">
          {{ getEtatAvancementDate("5000").icone }}
        </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list class="mx-0">
        <!-- reception_effectuee_le -->
        <x-avancement-jalon-change-etat
          :checkbox="true"
          :champ_recherche="'reception_effectuee_le'"
          :nb_step="2"
          :etat="getEtatAvancementDate('reception_effectuee_le').etat"
          @choixCheckBoxEtat="
            setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
          "
        ></x-avancement-jalon-change-etat>
        <!-- pv_reception_signe_le.date_previ -->
        <!-- <x-avancement-jalon-change-etat
              v-if="getWindowWidth > 600"              
              :checkbox="false"
              :champ_recherche="'pv_reception_signe_le.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
            >
              </x-avancement-jalon-change-etat>

              <x-avancement-jalon-change-etat-date-mobile
              v-else
              :champ_recherche="'pv_reception_signe_le.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
              >
              </x-avancement-jalon-change-etat-date-mobile>                                                -->
        <!-- pv_reception_signe_le -->
        <x-avancement-jalon-change-etat
          :checkbox="true"
          :champ_recherche="'pv_reception_signe_le'"
          :nb_step="2"
          :etat="getEtatAvancementDate('pv_reception_signe_le').etat"
          @choixCheckBoxEtat="
            setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
          "
        ></x-avancement-jalon-change-etat>

        <!-- Par défaut : levée de réserve + pv levée de réserve doit être masqué pour éviter de mettre trop d'informations
                et que les personnes qui pointent s'y retrouve 
                De plus : 1 chantier qui se passe bien n'a pas de levée de réserve -->

        <v-list-item>
          <v-list-item-content>
            <v-switch
              :false-value="false"
              :true-value="true"
              v-model="levee_reserve"
              label="Avez-vous des réserves sur ce chantier ?"
              hide-details
            ></v-switch>
          </v-list-item-content>
        </v-list-item>

        <div v-if="this.shouldDisplay">
          <!-- levee_reserve_le.date_previ -->
          <!-- <x-avancement-jalon-change-etat
              v-if="getWindowWidth > 600"              
              :checkbox="false"
              :champ_recherche="'levee_reserve_le.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
            >
              </x-avancement-jalon-change-etat>

              <x-avancement-jalon-change-etat-date-mobile
              v-else
              :champ_recherche="'levee_reserve_le.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
              >
              </x-avancement-jalon-change-etat-date-mobile> -->
          <!-- prise_cote_finitions_le -->
          <x-avancement-jalon-change-etat
            :checkbox="true"
            :champ_recherche="'levee_reserve_le'"
            :nb_step="2"
            :etat="getEtatAvancementDate('levee_reserve_le').etat"
            @choixCheckBoxEtat="
              setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
            "
          ></x-avancement-jalon-change-etat>
          <!-- pv_levee_reserve_le.date_previ -->
          <!-- <x-avancement-jalon-change-etat
              v-if="getWindowWidth > 600"              
              :checkbox="false"
              :champ_recherche="'pv_levee_reserve_le.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
            >
              </x-avancement-jalon-change-etat>

              <x-avancement-jalon-change-etat-date-mobile
              v-else
              :champ_recherche="'pv_levee_reserve_le.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
              >
              </x-avancement-jalon-change-etat-date-mobile>  -->
          <!-- prise_cote_finitions_le -->
          <x-avancement-jalon-change-etat
            :checkbox="true"
            :champ_recherche="'pv_levee_reserve_le'"
            :nb_step="2"
            :etat="getEtatAvancementDate('pv_levee_reserve_le').etat"
            @choixCheckBoxEtat="
              setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
            "
          ></x-avancement-jalon-change-etat>
        </div>
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from "vuex";
import XAvancementJalonChangeEtat from "./X-avancement-jalon-change-etat.vue";
import moment from "moment";

export default {
  name: "X-avancement-reception",
  components: { XAvancementJalonChangeEtat },
  data() {
    return {
      jalons: [
        "demande_prix_recue_le",
        "devis_envoye_le",
        "devis_receptionne_valide_le",
        "avenant_encours_non_valide",
        "avenant_valide_le",
        "choix_finaux_client_realises",
      ],
      loading: false,
      levee_reserve: false,
    };
  },

  created() {
    this.levee_reserve = this.getEtatAvancementDate("5000-1").fait;
  },

  methods: {
    setValueDatePicker(stypeValeur, valeur) {
      // Type de valeur pour savoir quel champ mettre à jour dans la classe 'avancement'
      // check pour savoir si c'est coché ou pas
      // valeur si on met autre chose que la date du jour
      let payload = {
        typeValeur: stypeValeur,
        nouvelleValeur: moment(valeur)
          .toDate()
          .toISOString(),
        isChecked: false,
        datePrevi: moment(valeur).toDate(),
        etat: "",
      };

      switch (payload.typeValeur) {
        case "-1":
          // Dispatch dans le front
          this.$store
            .dispatch("chantier/setChantierDateTheorique", payload)
            .then(() => {
              this.$store.dispatch("chantier/updateDateTheoriquePremierePose");
            });
          break;

        case "-2":
          // Dispatch dans le front
          this.$store
            .dispatch("chantier/setChantierDateTheorique", payload)
            .then(() => {
              this.$store.dispatch("chantier/updateDateTheoriqueFinition");
            });
          break;
        case "verif_details_dappui_premiere_pose.date_previ":
        case "prise_cote_bso_le.date_previ":
        case "prise_cote_finitions_le.date_previ":
        case "habillage_tableaux_le.date_previ":
        case "pret_a_poser_premiere_pose.date_previ":
        case "pret_a_poser_finition.date_previ":
        case "pv_reception_signe_le.date_previ":
        case "levee_reserve_le.date_previ":
        case "pv_levee_reserve_le.date_previ":
          payload.nouvelleValeur = moment("01/01/1900").toDate();
          // Dispatch dans le front
          this.$store
            .dispatch("chantier/setChantierAvancementDatePrevi", payload)
            .then(async () => {
              await this.$store.dispatch("chantier/updateAvancement");
              this.$forceUpdate();
            });

          break;
      }
    },
    setValueCheckboxDate(stypeValeur, sEtat, nbStep) {
      // Type de valeur pour savoir quel champ mettre à jour dans la classe 'avancement'
      // check pour savoir si c'est coché ou pas
      // valeur si on met autre chose que la date du jour
      let payload = {
        typeValeur: stypeValeur,
        nouvelleValeur: moment().toDate(),
        isChecked: false,
        datePrevi: moment("01/01/1900").toDate(),
        etat: sEtat,
      };

      if (nbStep === 3) {
        if (payload.etat === "" || payload.etat === "NC") {
          payload.etat = "EC";
        } else if (payload.etat === "EC") {
          payload.etat = "TE";
        } else if (payload.etat === "TE") {
          payload.etat = "NC";
          payload.nouvelleValeur = moment("01/01/0001").toDate();
          payload.isChecked = true;
        }
      } else if (nbStep === 2) {
        if (
          payload.etat === "" ||
          payload.etat === "NC" ||
          payload.etat === "EC"
        ) {
          payload.etat = "TE";
        } else if (payload.etat === "TE") {
          payload.etat = "NC";
          payload.nouvelleValeur = moment("01/01/0001").toDate();
          payload.isChecked = true;
        }
      }

      // Dispatch dans le front
      this.$store.dispatch("chantier/setAvancementDate", payload).then(() => {
        this.$store.dispatch("chantier/updateAvancement");
      });
    },
  },
  computed: {
    ...mapGetters("chantier", {
      getChantier: "getChantier",
      getEtatAvancementDate: "getEtatAvancementDate",
      getModaleAvancement: "getModaleAvancement",
    }),
    ...mapGetters("util", {
      getWindowWidth: "getWindowWidth",
    }),

    shouldDisplay: function() {
      let bReturn = false;

      if (this.getEtatAvancementDate("5000-1").fait || this.levee_reserve) {
        bReturn = true;
      }
      return bReturn;
    },
  },
  watch: {
    // On bascule la valeur 'levee_reserve' à false quand on ferme la fenêtre davancement
    // Détection de la fenêtre qui se ferme = quand getModaleAvancement == false
    // Inversement : quand la page s'ouvre, on initialise la valeur du 'switch
    getModaleAvancement(newValue) {
      if (newValue === false) {
        this.levee_reserve = newValue;
      } else {
        this.levee_reserve = this.getEtatAvancementDate("5000-1").fait;
      }
    },
  },
};
</script>

<style scoped></style>
