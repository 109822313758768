<template>
  <admin></admin>
</template>

<script>
import Admin from '../components/admin/AdminComponent'
export default {
  data () {
    return {
    }
  },
  components: {
    Admin
  }
}
</script>
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>