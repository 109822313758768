<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="computedDateFormatted"
        :label="title"
        :color="getTurquoisePoupin"
        persistent-hint
        prepend-icon="mdi-calendar"
        :rules="rule"
        v-bind="attrs"
        v-on="on"
        readonly
        clearable
        @click:clear="clean"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="date"
      :color="getTurquoisePoupin"
      first-day-of-week="1"
      locale-first-day-of-year="1"
      locale="fr"
      :show-week="true"
      no-title
      @input="emit($event)"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import moment from "moment";

import { mapGetters } from "vuex";
/*import moment from 'moment';*/
export default {
  name: "X-date-picker",
  data() {
    return {
      menu: false,
    };
  },
  props: {
    date: {
      type: String,
    },
    title: {
      type: String,
      default: 'Nom de prop: "title"',
    },
    afficherSemaine: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      let tempBis = moment(date).toDate();
      //let temp = date;
      const year1 = moment(tempBis).format("yyyy");
      const month1 = moment(tempBis).format("MM");
      const day1 = moment(tempBis).format("DD");
      //const [year, month, day] = temp.split("T")[0].split('-')

      if (this.afficherSemaine) {        
        return "S" + moment(tempBis).format("W - yyyy");
      } else {
        return `${day1}/${month1}/${year1}`;
      }
    },
    emit(event) {
      this.menu = false;
      this.$emit("datepicked", event);
    },
    clean() {
      this.$emit("clean");
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    rule() {
      if (this.required) {
        return [(v) => !!v || "Veuillez sélectionner une date"];
      }
      return [];
    },
    ...mapGetters(["getTurquoisePoupin"]),
  },
};
</script>
<style scoped></style>
