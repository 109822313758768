var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": false, "max-width": "290" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        value: _vm.computedDateFormattedMomentjs,
                        clearable: "",
                        label: _vm.title,
                        readonly: "",
                        "prepend-icon": "mdi-calendar",
                        dense: "",
                      },
                      on: {
                        "click:clear": function ($event) {
                          _vm.date = null
                        },
                      },
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu1,
        callback: function ($$v) {
          _vm.menu1 = $$v
        },
        expression: "menu1",
      },
    },
    [
      _c("v-date-picker", {
        attrs: {
          locale: "fr",
          "first-day-of-week": "1",
          max: _vm.max,
          "allowed-dates": _vm.allowedDates,
          color: _vm.getTurquoisePoupin,
        },
        on: { change: _vm.save },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }