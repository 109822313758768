import Vue from "vue";
import moment from "moment";

const state = {
  livraisons: [],
  query: {},
  tabLivraisonsChargeesTotales: [], // Récupère une liste de livraison pour rédaction du mail récapitulatif
  tabLivraisonsChargeesPartielles: [], // Idem avec des livraisons partielles
  newCommande: null, // Commande ajoutée à la main par les livreurs
};

const getters = {
  getLivraisons(state) {
    return state.livraisons;
  },
  getQuery(state) {
    return state.query;
  },
  getTabLivraisonsChargeesTotales(state) {
    return state.tabLivraisonsChargeesTotales;
  },
  getTabLivraisonsChargeesPartielles(state) {
    return state.tabLivraisonsChargeesPartielles;
  },
  getNewCommande(state) {
    return state.newCommande;
  },
};

const mutations = {
  SET_QUERY(state, payload) {
    state.query = payload;
  },
  SET_QUERY_DATE_DEBUT(state, payload) {
    state.query.dateDebut = payload;
    state.query.currentWeek = moment(payload).format("W");
  },
  DELETE_QUERY_DATE_DEBUT(state) {
    delete state.query.dateDebut;
    delete state.query.currentWeek;
  },
  SET_QUERY_AGENCE(state, payload) {
    state.query.agence = payload;
  },
  DELETE_QUERY_AGENCE(state) {
    delete state.query.agence;
  },
  SET_LIVRAISONS(state, livraisons) {
    state.livraisons = livraisons;
  },
  SET_PUSH_NEW_LIVRAISON(state, payload) {
    let booleanCheck = false;
    state.livraisons.forEach((livraison) => {
      if (livraison.idERP === payload.idERP) {
        livraison = payload;
        booleanCheck = true;
      }
    });
    if (!booleanCheck) {
      state.livraisons.push(payload);
    }
  },
  SET_QUERY_NOM_CLIENT(state, payload) {
    state.query.nom_client = payload;
  },
  DELETE_QUERY_NOM_CLIENT(state) {
    delete state.query.nom_client;
  },
  SET_QUERY_NUMERO_COMMANDE_INTERNE(state, payload) {
    state.query.numero_commande_interne = payload;
    delete state.query.numero_commande_externe;
  },
  DELETE_QUERY_NUMERO_COMMANDE_INTERNE(state) {
    delete state.query.numero_commande_interne;
  },
  SET_QUERY_NUMERO_COMMANDE_EXTERNE(state, payload) {
    state.query.numero_commande_externe = payload;
    delete state.query.numero_commande_interne;
  },
  DELETE_QUERY_NUMERO_COMMANDE_EXTERNE(state) {
    delete state.query.numero_commande_externe;
  },
  // Mutation livraison (chargee, statut, commentaire_libre)
  SET_ONE_LIVRAISON(state, payload) {
    let id = payload.livraisonID;
    state.livraisons.forEach((livraison) => {
      if (livraison._id === id) {
        // Modif "chargee"
        if (
          payload.chargee_value !== livraison.chargee_value &&
          payload.chargee_value !== null
        ) {
          livraison.chargee = payload.chargee_value;
        }
        // Modif statut
        if (payload.statut_value === 3) {
          livraison.etat_demandes = payload.statut_value;
          livraison.date_livraison_partielle = payload.date_jour;
        }
        if (payload.statut_value === 4) {
          livraison.etat_demandes = payload.statut_value;
          livraison.date_livraison_reelle = payload.date_jour;
        }
        if (payload.statut_value === 5) {
          livraison.etat_demandes = payload.statut_value;
        }
      }
    });
  },
  SET_TAB_LIVRAISONS_CHARGEES_TOTALES(state, payload) {
    state.tabLivraisonsChargeesTotales = payload.tabLivraisons;
  },
  DELETE_TAB_LIVRAISONS_CHARGEES_TOTALES() {
    state.tabLivraisonsChargeesTotales = [];
  },
  SET_TAB_LIVRAISONS_CHARGEES_PARTIELLES(state, payload) {
    state.tabLivraisonsChargeesPartielles = payload.tabLivraisons;
  },
  DELETE_TAB_LIVRAISONS_CHARGEES_PARTIELLES(state) {
    state.tabLivraisonsChargeesPartielles = [];
  },
  SET_NEW_COMMANDE(state, payload) {
    state.newCommande = payload;
  },
};

const actions = {
  // Modification livraison (chargee, statut, commentaire_libre). La modification est faite en fonction des paramètres fournis par l'utilisateur
  updateOneLivraison({ commit }, payload) {
    // Permet de modifier le statut de la livraison sur "en attente de livraison" si commande indiqué chargée
    payload.date_jour = moment().toDate();
    if (payload.chargee_value === 1 || payload.chargee_value === 2) {
      payload.statut_value = 5;
    } else if (payload.chargee_value === 0) {
      payload.statut_value = 2;
    }

    Vue.axios
      .put("/api/livraison/" + payload.livraisonID, {
        chargee_value: payload.chargee_value,
        statut_value: payload.statut_value,
        commentaire_chargement: payload.commentaire_chargement,
        commentaire_reception: payload.commentaire_reception,
        commentaire_libre: payload.commentaire_libre,
      })
      .then((res) => {
        if (res.status === 200) {
          commit("SET_ONE_LIVRAISON", payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  // Récupération des livraisons en fonction des filtres agence et date (de base filtrées par agence de l'utilisateur connecté et par la semaine en cours)
  async returnLivraisons({ commit, getters }) {
    let date = {};
    if (!getters.getQuery.currentWeek) {
      date.years = moment().format("YYYY");
    } else {
      date.years = moment(getters.getQuery.dateDebut).format("YYYY");
    }

    await Vue.axios
      .get(
        "/api/livraison/" +
          getters.getQuery.agence +
          "/" +
          getters.getQuery.currentWeek +
          "/" +
          date.years
      )
      .then((res) => {
        commit("SET_LIVRAISONS", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  // Création d'une nouvelle livraison directement par le livreur
  async createNewLivraison({ getters, commit }, payload) {
    let agence = getters.getQuery.agence;
    let date = moment().toDate();
    if (payload) {
      await Vue.axios
        .post("/api/livraison/commande/newLivraison", {
          commande: payload,
          date_livraison_prevue: date,
          agence: agence,
        })
        .then((res) => {
          commit("SET_PUSH_NEW_LIVRAISON", res.data);
        });
    }
  },

  // Récupération des livraisons pour un client
  returnLivraisonsByClient({ commit, getters }) {
    if (getters.getQuery.nom_client) {
      Vue.axios
        .get("/api/livraison/" + getters.getQuery.nom_client)
        .then((res) => {
          commit("SET_LIVRAISONS", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  // Recherche d'une livraison via son numéro de commande et son type de commande
  returnLivraisonByNumeroCommande({ commit, getters }) {
    let typeCommande;
    let numCommande;
    if (getters.getQuery.numero_commande_interne) {
      typeCommande = "CDI";
      numCommande = getters.getQuery.numero_commande_interne;
    } else {
      typeCommande = "CDE";
      numCommande = getters.getQuery.numero_commande_externe;
    }
    Vue.axios
      .get("/api/livraison/" + numCommande + "/" + typeCommande)
      .then((res) => {
        commit("SET_LIVRAISONS", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  // Récupérer une commande via le numéro rentré par le livreur lorsqu'il souhaite ajouter une livraison
  async findCommandeByNum({ commit }, payload) {
    let typeCommande;
    let numCommande;
    numCommande = payload.numCommande;
    if (payload.type == "Interne") {
      typeCommande = "CDI";
    } else {
      typeCommande = "CDE";
    }
    await Vue.axios
      .get("/api/livraison/commande/test/" + numCommande + "/" + typeCommande)
      .then((res) => {
        commit("SET_NEW_COMMANDE", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  sendMailRecapLivraison({ getters }, payload) {
    payload.livraisonsChargeesTotales = getters.getTabLivraisonsChargeesTotales;
    Vue.axios.post("/api/livraison/sendEmailRecapChargement", payload);
  },
  sendMailDebutChargement({ getters }, payload) {
    console.log(getters);
    Vue.axios.post("/api/livraison/sendEmailDebutChargement", payload);
  },

  initialiseQuery({ commit, rootGetters }) {
    commit("SET_QUERY", {
      dateDebut: moment().toDate(),
      agence: rootGetters["auth/userInSession"].agence._id,
      currentWeek: moment().format("W"),
    });
  },
  // Récupère let numéro de la semaine et la date sélectionnée
  setQuerySemaine({ commit }, payload) {
    commit("SET_QUERY_DATE_DEBUT", payload);
  },
  clearQueryDateDebut({ commit }) {
    commit("DELETE_QUERY_DATE_DEBUT");
  },
  setQueryAgence({ commit }, payload) {
    commit("SET_QUERY_AGENCE", payload);
  },
  clearQueryAgence({ commit }) {
    commit("DELETE_QUERY_AGENCE");
  },
  setQueryNomClient({ commit }, payload) {
    commit("SET_QUERY_NOM_CLIENT", payload);
  },
  clearQueryNomClient({ commit }) {
    commit("DELETE_QUERY_NOM_CLIENT");
  },
  setQueryNumeroCommandeInterne({ commit }, payload) {
    commit("SET_QUERY_NUMERO_COMMANDE_INTERNE", payload);
  },
  clearQueryNumeroCommandeInterne({ commit }) {
    commit("DELETE_QUERY_NUMERO_COMMANDE_INTERNE");
  },
  setQueryNumeroCommandeExterne({ commit }, payload) {
    commit("SET_QUERY_NUMERO_COMMANDE_EXTERNE", payload);
  },
  clearQueryNumeroCommandeExterne({ commit }, payload) {
    commit("DELETE_QUERY_NUMERO_COMMANDE_EXTERNE", payload);
  },
  setTabLivraisonsChargeesTotales({ commit, getters }, payload) {
    let tabLivraisons = getters.getTabLivraisonsChargeesTotales;
    tabLivraisons.push(payload.livraison);
    commit("SET_TAB_LIVRAISONS_CHARGEES_TOTALES", {
      tabLivraisons: tabLivraisons,
    });
  },
  clearTabLivraisonsChargeesTotales({ commit }) {
    commit("DELETE_TAB_LIVRAISONS_CHARGEES_TOTALES");
  },
  setTabLivraisonsChargeesPartielles({ commit, getters }, payload) {
    let tabLivraisons = getters.getTabLivraisonsChargeesPartielles;
    tabLivraisons.push(payload.livraison);
    commit("SET_TAB_LIVRAISONS_CHARGEES_PARTIELLES", {
      tabLivraisons: tabLivraisons,
    });
  },
  clearTabLivraisonsChargeesPartielles({ commit }) {
    commit("DELETE_TAB_LIVRAISONS_CHARGEES_PARTIELLES");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
