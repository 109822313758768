<template>
  <v-dialog
    :value="getModaleChantier"
    @click:outside="hideModaleChantier"
    max-width="800px"
  >
    <v-card>
      <v-card-title>Détails chantier</v-card-title>
      <v-card-subtitle>
        {{ getChantier.nom_client }}
      </v-card-subtitle>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>Suivi par:</td>
                <td>{{ getChantier.suivi_par }}</td>
                <td>
                  <v-btn fab small text color="green" @click="printTel">
                    <v-icon>mdi-phone</v-icon>
                  </v-btn>
                  <v-btn fab small text color="blue" @click="printMail">
                    <v-icon>mdi-mail</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td colspan="2">Déclarer un incident</td>
                <td>
                  <v-btn fab small text color="red" @click="showModaleIncident">
                    <v-icon>mdi-alert-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div>
          <v-checkbox
            v-for="(item, i) in getPrepas"
            :key="i"
            :label="item.commentaire"
            disabled
          ></v-checkbox>
        </div>
      </v-card-text>
      <!--Ma modale d'historique des incidents-->
      <x-incident-historique-modale></x-incident-historique-modale>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import XIncidentHistoriqueModale from "@/components/incident/X-incident-historique-modale";
import Swal from "sweetalert2";

export default {
  name: "X-chantier-modale",
  components: {
    XIncidentHistoriqueModale,
  },
  computed: {
    ...mapGetters("chantier", {
      getChantier: "getChantier",
      getModaleChantier: "getModaleChantier",
    }),

    ...mapGetters("prepas", {
      getPrepas: "getPrepas",
    }),
  },
  methods: {
    ...mapActions("chantier", {
      hideModaleChantier: "hideModaleChantier",
    }),

    ...mapActions("incident", {
      showModaleIncident: "showModaleIncident",
      showModaleHistorique: "showModaleHistorique",
      returnIncidentsChantier: "returnIncidentsChantier",
    }),
    ...mapActions("procesVerbal", {
      showModaleProcesVerbal: "showModaleProcesVerbal",
    }),
    printTel() {
      if (this.getChantier.telephone_suivi_par) {
        Swal.fire({
          text: `${this.getChantier.telephone_suivi_par}`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Appeler",
          cancelButtonText: "Fermer",
          confirmButtonColor: "#7fd087",
          cancelButtonColor: "#192847",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(`tel:${this.getChantier.telephone_suivi_par}`);
          }
        });
      } else {
        Swal.fire({
          text: `Le numéro n'est pas renseigné`,
        });
      }
    },
    printMail() {
      if (this.getChantier.mail_suivi_par) {
        Swal.fire({
          text: `${this.getChantier.mail_suivi_par}`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Envoyer un mail",
          cancelButtonText: "Fermer",
          confirmButtonColor: "#7fd087",
          cancelButtonColor: "#192847",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(`mailto:${this.getChantier.mail_suivi_par}`);
          }
        });
      } else {
        Swal.fire({
          text: `Le mail n'est pas renseigné`,
        });
      }
    },
  },
};
</script>

<style scoped></style>
