<template>
  <v-container>
    <v-tabs-items :value="getTab">
      <v-tab-item>
        <div class="hello">
          <!--<v-btn color="orange" dark @click="majCalcul">Actualiser comptage d'heures</v-btn>-->
          <v-container>
            <v-layout row wrap>
              <!--<v-flex xs12 sm12 md6 lg6 xl6>
                <apexchart type="bar" :options="getChartOptionsWeek" :series="getSeriesWeek"></apexchart>
              </v-flex>-->
            </v-layout>
            <v-select
              label="Semaine"
              :items="semaines"
              @change="setSemaine"
            ></v-select>
            <v-btn @click="tempsTransport">Temps de transport</v-btn>
          </v-container>
        </div>
      </v-tab-item>
      <v-tab-item>
        <gestion-user></gestion-user>
      </v-tab-item>
      <v-tab-item>
        <agence-form></agence-form>
      </v-tab-item>
      <v-tab-item>
        <societe-form></societe-form>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import GestionUser from "../user/X-gestion-user";
import AgenceForm from "./AgenceForm";
import SocieteForm from "./SocieteForm";

export default {
  name: "Admin",
  data: function() {
    return {
      semaines: [],
      semaine: 0,
    };
  },
  beforeCreate() {
    this.$store.dispatch("stats/setWeek");
  },
  created() {
    for (let i = 1; i < 54; i++) {
      this.semaines.push(i);
    }
  },
  components: {
    GestionUser,
    AgenceForm,
    SocieteForm,
  },
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters("admin", {
      getTab: "getTab",
    }),

    ...mapGetters("stats", {
      getSeriesWeek: "getSeriesWeek",
      getChartOptionsWeek: "getChartOptionsWeek",
      getTransport: "getTransport",
    }),
  },
  methods: {
    /*semaine() {
      this.$store.dispatch('stats/setWeek')
    }
    ,
    majCalcul() {
      this.$store.dispatch('stats/updateComptage')
    }*/
    tempsTransport() {
      this.$store
        .dispatch("stats/returnTempsTransport", this.semaine)
        .then(() => {});
    },
    fournisseurs() {
      this.$store.dispatch("incident/insertFournisseurs");
    },
    causes() {
      this.$store.dispatch("incident/insertCauses");
    },
    responsibilite() {
      this.$store.dispatch("incident/insertResponsibilite");
    },
    processes() {
      this.$store.dispatch("incident/insertProcesses");
    },
    setSemaine(e) {
      this.semaine = e;
    },
  },
};
</script>

<style scoped></style>
