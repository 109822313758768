import Vue from "vue";

const state = {
  currentRh: {},
  heuresRh: [],
};

const getters = {
  getRh(state) {
    return state.currentRh;
  },
  getHeuresRh(state) {
    return state.heuresRh;
  },
};

const mutations = {
  SET_RH(state, payload) {
    state.currentRh = payload;
  },
};

const actions = {
  returnMoisRh({ commit }, query) {
    Vue.axios
      .get(`/api/rh/${JSON.stringify(query)}`)
      .then((res) => {
        commit("SET_RH", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
