var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "my-2", attrs: { width: "92%", "max-width": "700px" } },
    [
      _c(
        "v-list-item",
        { attrs: { "three-line": "" } },
        [
          _c(
            "v-list-item-avatar",
            [
              _vm.user.role == "Poseur"
                ? _c(
                    "v-icon",
                    {
                      class: `${_vm.codeCouleur(_vm.inter.type_intervention)}`,
                    },
                    [_vm._v(" mdi-wrench ")]
                  )
                : _vm._e(),
              _vm.user.role == "PoseurRenovation" ||
              _vm.user.role == "ResponsableRenovation"
                ? _c("v-icon", { on: { click: _vm.openInfosChantiers } }, [
                    _vm._v("mdi-information-outline"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", [
                _vm._v(_vm._s(_vm.inter.chantier.nom_client.split(/[0-9]/)[0])),
              ]),
              _c("v-list-item-title", [
                _vm._v(
                  _vm._s(
                    _vm.inter.chantier.nom_client.substring(
                      _vm.inter.chantier.nom_client.search(/[0-9]/)
                    )
                  )
                ),
              ]),
              _c("v-list-item-subtitle", [
                _vm._v(_vm._s(_vm._f("fullDate")(_vm.inter.date_intervention))),
              ]),
            ],
            1
          ),
          this.today &&
          (_vm.user.role == "PoseurRenovation" ||
            _vm.user.role == "ResponsableRenovation")
            ? _c(
                "v-list-item-action",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                color:
                                  _vm.inter.temps_passe > 10 ? "green" : "blue",
                              },
                              on: { click: _vm.setDebut },
                            },
                            [_c("v-icon", [_vm._v(" mdi-timer ")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.inter.temps_passe > 10
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "font-weight-bold text-center green--text",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatNumberInHeureMinute(
                                      _vm.inter.temps_passe / 60
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("v-divider"),
      _vm.user.role == "PoseurRenovation" ||
      _vm.user.role == "ResponsableRenovation"
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "span",
                    { staticClass: "text-caption font-weight-bold mx-2" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatNumberInHeureMinute(
                              _vm.inter.chantier.temps_pose_deja_realise / 60
                            )
                          ) +
                          " "
                      ),
                      _c("span", { staticClass: "text-caption" }, [
                        _vm._v("effectuées /"),
                      ]),
                    ]
                  ),
                  _c("span", { staticClass: "text-caption font-weight-bold" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumberInHeureMinute(
                          (_vm.inter.chantier.temps_pose_prevu_finitions +
                            _vm.inter.chantier.temps_pose_prevu_premiere_pose) /
                            60
                        )
                      ) + " "
                    ),
                    _c("span", { staticClass: "text-caption" }, [
                      _vm._v("vendues"),
                    ]),
                  ]),
                  _c("v-progress-linear", {
                    attrs: {
                      color: _vm.definirCouleurProgressBarChantier(
                        _vm.inter.chantier.temps_pose_prevu_finitions +
                          _vm.inter.chantier.temps_pose_prevu_premiere_pose,
                        _vm.inter.chantier.temps_pose_deja_realise
                      ),
                      height: "10",
                      value: _vm.calculPourcentageAvanceeChantier(
                        _vm.inter.chantier.temps_pose_prevu_finitions +
                          _vm.inter.chantier.temps_pose_prevu_premiere_pose,
                        _vm.inter.chantier.temps_pose_deja_realise
                      ),
                      striped: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-actions",
        { staticClass: "my-0 py-0 mx-0 px-0" },
        [
          _c(
            "v-btn",
            {
              attrs: { width: "25%", text: "", tile: "", color: "blue" },
              on: { click: _vm.openGoogleMap },
            },
            [_c("v-icon", [_vm._v("mdi-google-maps")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { text: "", tile: "", color: "blue", width: "25%" },
              on: { click: _vm.openContacts },
            },
            [_c("v-icon", [_vm._v("mdi-phone-outline")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { text: "", tile: "", color: "blue", width: "25%" },
              on: {
                click: function ($event) {
                  _vm.booleanModalePhoto = true
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-camera")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { text: "", tile: "", color: "red", width: "20%" },
              on: { click: _vm.openModaleIncident },
            },
            [_c("v-icon", [_vm._v("mdi-alert-outline")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px", "content-class": "mx-2" },
          model: {
            value: _vm.modalHeure,
            callback: function ($$v) {
              _vm.modalHeure = $$v
            },
            expression: "modalHeure",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "mb-2" }, [
                _vm._v("HEURES CHANTIER"),
              ]),
              _c("v-card-subtitle", [
                _vm._v(" " + _vm._s(_vm.inter.chantier.nom_client) + " "),
              ]),
              _c(
                "v-card-text",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-row",
                    { staticClass: "text-center black--text text-subtitle-2" },
                    [
                      _c("v-col", [_vm._v("Intégrer la pause du midi ?")]),
                      _c(
                        "v-col",
                        [
                          _c("v-checkbox", {
                            attrs: {
                              disabled: _vm.booleanPause1h30,
                              label: "1h",
                            },
                            model: {
                              value: _vm.booleanPause1h,
                              callback: function ($$v) {
                                _vm.booleanPause1h = $$v
                              },
                              expression: "booleanPause1h",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-checkbox", {
                            attrs: {
                              disabled: _vm.booleanPause1h,
                              label: "1h30",
                            },
                            model: {
                              value: _vm.booleanPause1h30,
                              callback: function ($$v) {
                                _vm.booleanPause1h30 = $$v
                              },
                              expression: "booleanPause1h30",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "black--text font-weight-bold" },
                        [_vm._v(" Présent sur cette intervention ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "text-center" },
                    _vm._l(
                      _vm.listePoseurIntervention,
                      function (poseur, index) {
                        return _c(
                          "v-col",
                          { key: poseur._id },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                disabled: poseur.id == _vm.user._id,
                                label: poseur.prenom,
                              },
                              model: {
                                value:
                                  _vm.tabBooleanCheckboxPoseurs[index].selected,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tabBooleanCheckboxPoseurs[index],
                                    "selected",
                                    $$v
                                  )
                                },
                                expression:
                                  "tabBooleanCheckboxPoseurs[index].selected",
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                  _c("v-row", { staticClass: "mb-2" }),
                  _c("v-time-picker", {
                    attrs: {
                      format: "24hr",
                      locale: "fr",
                      "allowed-minutes": _vm.allowedStep,
                      min: "6:00",
                      max: "20:00",
                      color: _vm.getTurquoisePoupin,
                    },
                    model: {
                      value: _vm.date_debut,
                      callback: function ($$v) {
                        _vm.date_debut = $$v
                      },
                      expression: "date_debut",
                    },
                  }),
                  _c("v-time-picker", {
                    attrs: {
                      format: "24hr",
                      locale: "fr",
                      "allowed-minutes": _vm.allowedStep,
                      min: "6:00",
                      max: "20:00",
                      color: _vm.getTurquoisePoupin,
                    },
                    model: {
                      value: _vm.date_fin,
                      callback: function ($$v) {
                        _vm.date_fin = $$v
                      },
                      expression: "date_fin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.modalHeure = false
                        },
                      },
                    },
                    [_vm._v("Annuler")]
                  ),
                  _vm.date_debut && _vm.date_fin
                    ? _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", color: "green" },
                          on: {
                            click: function ($event) {
                              return _vm.enregistreHeureChantier(
                                _vm.date_debut,
                                _vm.date_fin
                              )
                            },
                          },
                        },
                        [_vm._v("Valider ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: "",
                            outlined: "",
                            color: _vm.getTurquoisePoupin,
                          },
                        },
                        [_vm._v(" valider ")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.booleanIncident,
            callback: function ($$v) {
              _vm.booleanIncident = $$v
            },
            expression: "booleanIncident",
          },
        },
        [
          _c("x-incident-modale", {
            on: { closeModale: _vm.closeModaleIncident },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.booleanModaleContactClient,
            callback: function ($$v) {
              _vm.booleanModaleContactClient = $$v
            },
            expression: "booleanModaleContactClient",
          },
        },
        [
          _c("x-modale-contact-reno", {
            on: { closeModale: _vm.closeModaleContacts },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.booleanModalePhoto,
            callback: function ($$v) {
              _vm.booleanModalePhoto = $$v
            },
            expression: "booleanModalePhoto",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(" Ajouter des photos pour le book photos "),
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-file-input", {
                    attrs: {
                      outlined: "",
                      label: "Photos du chantier terminé",
                      "truncate-length": "15",
                    },
                    model: {
                      value: _vm.photoChantierActuelle,
                      callback: function ($$v) {
                        _vm.photoChantierActuelle = $$v
                      },
                      expression: "photoChantierActuelle",
                    },
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: { label: "Commentaire photos" },
                            model: {
                              value: _vm.commentairePhoto,
                              callback: function ($$v) {
                                _vm.commentairePhoto = $$v
                              },
                              expression: "commentairePhoto",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.listePhotoChantier, function (item, index) {
                    return _c(
                      "v-row",
                      { key: Math.random() * index },
                      [
                        _c(
                          "v-col",
                          [
                            _vm._v(" " + _vm._s(item.name) + " "),
                            _c("v-progress-linear", {
                              staticClass: "mb-2",
                              attrs: {
                                value: "100",
                                striped: "",
                                color: "green",
                                height: "8px",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "red white--text" },
                              on: {
                                click: function ($event) {
                                  _vm.booleanModalePhoto = false
                                },
                              },
                            },
                            [_vm._v("Retour")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "green white--text" },
                              on: { click: _vm.envoiEmailPhotoChantier },
                            },
                            [_vm._v("Envoyer ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.booleanModaleInfoChantier,
            callback: function ($$v) {
              _vm.booleanModaleInfoChantier = $$v
            },
            expression: "booleanModaleInfoChantier",
          },
        },
        [
          _c("x-modale-infos-chantier", {
            on: { close: _vm.closeModaleInfoChantier },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }