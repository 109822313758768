<template>
  <div>
    <!--Modale de nouvel utilisateur -->
    <v-dialog v-model="newModal" @click:outside="showNew" max-width="800px">
      <v-card>
        <v-card-title>Nouvel utilisateur</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <user-form @close="showNew"></user-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Modale de modification des infos utilisateur-->
    <v-dialog v-model="infoModal" @click:outside="showInfo" max-width="800px">
      <v-card>
        <v-card-title>Modifier informations utilisateur</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <user-form-info @close="showInfo"></user-form-info>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Modale de modification du mot de passe-->
    <v-dialog
      v-model="passwordModal"
      @click:outside="showPassword"
      max-width="800px"
    >
      <v-card>
        <v-card-title
          >Modifier mot de passe {{ getUser.nom }}
          {{ getUser.prenom }}</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text>
          <user-m-d-p-form @close="showPassword"></user-m-d-p-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Modale de modification des heures par défaut-->
    <v-dialog
      v-model="horaireModal"
      @click:outside="showHoraire"
      max-width="800px"
    >
      <v-card>
        <v-card-title
          >Modifier horaires par défaut {{ getUser.nom }}
          {{ getUser.prenom }}</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text>
          <user-form-horaires @close="showHoraire"></user-form-horaires>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-btn @click="showNew" color="success"
      ><span>Nouvel utilisateur</span><v-icon>mdi-plus</v-icon></v-btn
    >

    <v-data-table
      :headers="headers"
      :items="getUsers"
      :items-per-page="22"
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr disabled="">
          <td>
            <v-icon v-if="row.item.locked">mdi-lock-outline</v-icon>
            {{ row.item.nom }}
          </td>
          <td>{{ row.item.prenom }}</td>
          <td>{{ row.item.username }}</td>
          <td>{{ row.item.role }}</td>
          <td>{{ row.item.email }}</td>
          <td>{{ row.item.phone }}</td>
          <td>{{ row.item.idERP }}</td>
          <td v-if="row.item.agence">{{ row.item.agence.libelle }}</td>
          <td v-if="row.item.societe">{{ row.item.societe.libelle }}</td>
          <td>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  fab
                  tile
                  dark
                  @click="setUser(row.item)"
                  v-bind="attrs"
                  v-on="on"
                  class="ma-1"
                >
                  <v-icon>mdi-account-edit-outline</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="showInfo">
                  <v-list-item-title>Infos utilisateur</v-list-item-title>
                </v-list-item>
                <v-list-item @click="showPassword">
                  <v-list-item-title>Mot de passe</v-list-item-title>
                </v-list-item>
                <v-list-item @click="showHoraire">
                  <v-list-item-title>Horaires par défaut</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="error"
                  fab
                  tile
                  dark
                  @click="setUser(row.item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-shield-account-outline</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="!row.item.locked" @click="setLock(true)">
                  <v-list-item-title>Vérrouiller</v-list-item-title>
                </v-list-item>
                <v-list-item v-else @click="setLock(false)">
                  <v-list-item-title>Dévérrouiller</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteUser">
                  <v-list-item-title>Supprimer</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserForm from "./UserForm";
import UserFormInfo from "./UserFormInfo";
import UserMDPForm from "./UserFormMDP";
import Swal from "sweetalert2";
import UserFormHoraires from "./UserFormHoraires";

export default {
  name: "GestionUser",
  components: {
    UserFormHoraires,
    UserMDPForm,
    UserFormInfo,
    UserForm,
  },
  data() {
    return {
      headers: [
        {
          text: "Nom",
          align: "start",
          sortable: true,
          value: "nom",
        },
        { text: "Prénom", value: "prenom" },
        { text: "Nom d'utilisateur", value: "username" },
        { text: "Rôle", value: "role" },
        { text: "E-mail", value: "email" },
        { text: "Téléphone", value: "phone" },
        { text: "Id ERP", value: "idERP" },
        { text: "Agence", value: "agence" },
        { text: "Société", value: "societe" },
        { text: "Actions" },
      ],
      newModal: false,
      infoModal: false,
      passwordModal: false,
      horaireModal: false,
    };
  },
  created() {
    this.$store.dispatch("user/returnUsers");
  },
  methods: {
    showNew() {
      this.newModal = !this.newModal;
      this.$store.dispatch("user/setUser", {});
    },
    showInfo() {
      this.infoModal = !this.infoModal;
    },
    showPassword() {
      this.passwordModal = !this.passwordModal;
    },
    showHoraire() {
      this.horaireModal = !this.horaireModal;
    },
    setUser(user) {
      this.$store.dispatch("user/setUser", user);
    },
    setLock(value) {
      this.$store.dispatch("user/setLocked", value);
    },
    deleteUser() {
      Swal.fire({
        text: `Supprimer le profil utilisateur de ${this.getUser.nom} ${this.getUser.prenom} DEFINITIVEMENT ?`,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Oui, valider",
        confirmButtonColor: "#69d27a",
        showCancelButton: true,
        cancelButtonText: "Non",
        cancelButtonColor: "#d97502",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("user/deleteUser");
        }
      });
    },
  },
  computed: {
    ...mapGetters("user", {
      getUsers: "getUsers",
      getUser: "getUser",
    }),
  },
};
</script>

<style scoped></style>
