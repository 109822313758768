var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.visible,
        "max-width": "850px",
        "content-class": "ma-2 pa-0",
        scrollable: "",
      },
      on: {
        "click:outside": function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.getChantier && _vm.getChantier.nom_client
                      ? _vm.getChantier.nom_client.split(/[0-9]/)[0]
                      : ""
                  ) +
                  " "
              ),
              _c(
                "v-tabs",
                {
                  attrs: { "fixed-tabs": "" },
                  model: {
                    value: _vm.tabs,
                    callback: function ($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs",
                  },
                },
                [
                  _c("v-tabs-slider"),
                  _c(
                    "v-tab",
                    {
                      staticClass: "primary--text",
                      attrs: { href: "#mobile-tabs-5-1" },
                    },
                    [_vm._v(" Avancement ")]
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass: "primary--text",
                      attrs: { href: "#mobile-tabs-5-2" },
                    },
                    [_vm._v(" Commandes ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tabs,
                    callback: function ($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "mobile-tabs-5-1" } },
                    [
                      _c("x-avancement", {
                        on: {
                          close: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "mobile-tabs-5-2" } },
                    [
                      _c("x-chantier-commandes", {
                        attrs: { commandes: _vm.getChantier.commandes },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Fermer")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }