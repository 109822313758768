<template>
  <div>
    <v-subheader v-if="testDateAvecChantierPrecedent()" inset>
      <v-toolbar color="white" dense flat>
        <v-icon>mdi-calendar-blank</v-icon>
        <v-toolbar-title
          ><v-icon></v-icon>
          {{ chantier.prochaine_intervention | fullDate }}</v-toolbar-title
        >
      </v-toolbar>
    </v-subheader>

    <v-spacer v-if="testDateAvecChantierPrecedent()"></v-spacer>

    <v-card :color="color">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ chantier.nom_client.split(/[0-9]/)[0] }}</v-list-item-title
          >
          <v-list-item-subtitle class="text--primary">{{
            chantier.adresse_client
          }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{
            `Numéro de devis: ${chantier.numero_devis}`
          }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="chantier.maitre_doeuvre">{{
            `Maitre d'oeuvre: ${chantier.maitre_doeuvre.Nom}`
          }}</v-list-item-subtitle>
          <v-list-item-subtitle
            >Prochaine intervention le
            {{
              chantier.prochaine_intervention | fullDate
            }}</v-list-item-subtitle
          >
          <!-- <v-list-item-subtitle>État prochaine inter.
            <v-icon x-small>mdi-arrow-right</v-icon>
            {{ chantier.etat_prochaine_intervention ? chantier.etat_prochaine_intervention : 'Non renseigné'}}
          </v-list-item-subtitle> -->
          <v-list-item-subtitle
            >Mis en fab par {{ chantier.mis_en_fab_par }}</v-list-item-subtitle
          >
          <v-list-item-subtitle v-if="isBE"
            >Temps ordo. {{ chantier.temps_ordo }}h</v-list-item-subtitle
          >
          <v-list-item-subtitle v-if="isBE"
            >Montant devis
            {{
              Number(chantier.montant_devis).toFixed(0)
            }}</v-list-item-subtitle
          >
          <v-list-item-subtitle v-if="isBE"
            >Date prévi ordo
            {{ chantier.date_previ_ordo | semaine }}</v-list-item-subtitle
          >
          <v-list-item-subtitle
            >Maçon :
            {{
              chantier.nom_macon ? chantier.nom_macon : "Non renseigné"
            }}</v-list-item-subtitle
          >
          <div
            v-if="
              chantier.avancement.premiere_pose === 'TE' ||
                chantier.avancement.finitions === 'TE'
            "
          >
            <span class="red--text darken-2">En attente de facturation</span
            ><v-icon class="ml-2" color="red darken-2">mdi-alert</v-icon>
          </div>
        </v-list-item-content>

        <v-list-item-action>
          <v-icon @click="openInfosChantiers">mdi-information-outline</v-icon>
          <v-icon
            v-if="!favoris"
            color="grey lighten-1"
            @click="ajouterFavoris"
          >
            mdi-flag-outline
          </v-icon>

          <v-icon v-else color="red darken-2" @click="supprimerFavoris">
            mdi-flag
          </v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-progress-linear
        :color="avancementCouleur"
        height="15"
        :value="avancement"
        striped
        class="mb-3"
      >
        <strong>{{ avancementStr }}</strong>
      </v-progress-linear>
      <v-divider></v-divider>
      <v-card-actions class="ma-0 pa-0">
        <v-btn
          text
          :height="buttonHeight"
          style="min-width:0px"
          tile
          width="16%"
          @click="openGoogleMap"
          ><v-icon>mdi-google-maps</v-icon></v-btn
        >
        <v-divider vertical></v-divider>
        <v-btn
          text
          :height="buttonHeight"
          style="min-width:0px"
          tile
          width="16%"
          @click="openContacts"
          ><v-icon>mdi-phone-outline</v-icon></v-btn>
        <v-divider vertical></v-divider>
        <v-btn text 
        :height="buttonHeight" 
          style="min-width:0px"
        tile 
        width="16%" 
        @click="openFiles"
          ><v-icon>mdi-folder-outline</v-icon></v-btn
        >
        <v-divider vertical></v-divider>
        <v-btn
          text
          :height="buttonHeight"
          style="min-width:0px"
          tile
          width="16%"
          @click="openIncidents"
          ><v-icon>mdi-alert-outline</v-icon></v-btn
        >
        <v-divider vertical></v-divider>
        <v-btn
          text
          :height="buttonHeight"
          style="min-width:0px"
          tile
          width="16%"
          @click="openProgress"
          ><v-icon>mdi-progress-wrench</v-icon></v-btn
        >
        <v-divider vertical></v-divider>
        <v-btn
          text
          :height="buttonHeight"
          style="min-width:0px"
          tile
          width="16%"
          @click="booleanModalePhoto = true"
          ><v-icon>mdi-camera</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
    <!--Ma modale d'incidents-->
    <v-dialog v-model="booleanIncident" width="500px">
      <x-incident-modale @closeModale="closeModaleIncident"></x-incident-modale>
    </v-dialog>

    <!-- MODALE PHOTOS CHANTIER -->
    <v-dialog v-model="booleanModalePhoto" width="500px">
      <v-card>
        <v-card-title>
          Envoyer des photos sur le répertoire 'Sinapse' du client
        </v-card-title>
        <v-card-text>
          <v-file-input
            v-model="photoChantierActuelle"
            outlined
            label="Photos du chantier"
            truncate-length="15"
          ></v-file-input>
          <v-row>
            <v-col>
              <v-textarea
                v-model="commentairePhoto"
                label="Commentaire"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row
            v-for="(item, index) in listePhotoChantier"
            :key="Math.random() * index"
          >
            <v-col>
              {{ item.name }}
              <v-progress-linear
                value="100"
                striped
                color="green"
                height="8px"
                class="mb-2"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn color="red white--text" @click="booleanModalePhoto = false"
                >Retour</v-btn
              >
            </v-col>
            <v-col class="text-right"
              ><v-btn color="green white--text" @click="envoiEmailPhotoChantier"
                >Envoyer
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import XIncidentModale from "@/components/incident/X-incident-modale";
export default {
  name: "X-chantier-card",
  components: { XIncidentModale },
  data() {
    return {
      active: false,
      // Incidents
      booleanIncident: false,
      // Photos
      booleanModalePhoto: false,
      photoChantierActuelle: null,
      listePhotoChantier: [],
      commentairePhoto: "",
    };
  },
  props: {
    chantier: {
      type: Object,
    },
    color: {
      type: String,
      default: "",
    },
    premiere_pose: {
      type: Object,
    },
    finition: {
      type: Object,
    },
    reception: {
      type: Object,
    },
    lancement_fab: {
      type: Object,
    },
    chantier_precedent: {
      type: Object,
    },
  },
  methods: {
    ...mapActions("chantier", {
      showModaleContact: "showModaleContact",
      showModaleGraph: "showModaleGraph",
      showModaleAvancement: "showModaleAvancement",
      showModaleCommandeInterne: "showModaleCommandeInterne",
      showModaleInfosChantier: "showModaleInfosChantier",
      returnInfosChantier: "returnInfosChantier",
    }),

    ...mapActions("incident", {
      showModaleIncident: "showModaleIncident",
    }),

    ajouterFavoris() {
      this.$store.dispatch("user/ajouterFavoris", this.chantier);
    },
    supprimerFavoris() {
      this.$store.dispatch("user/supprimerFavoris", this.chantier);
    },
    setChantier() {
      return this.$store.dispatch("chantier/setChantier", this.chantier);
    },
    async openContacts() {
      console.log("Contact");
      await this.setChantier();
      this.showModaleContact();
    },
    openFiles() {
      this.setChantier();
      this.showModaleGraph();
    },
    openIncidents() {
      this.setChantier();
      this.booleanIncident = true;
    },
    async openProgress() {
      await this.setChantier();
      await this.loadCommandes();
      this.showModaleAvancement();
    },
    openPasserCommande() {
      console.log("Passer commande");
      this.setChantier();
      this.showModaleCommandeInterne();
    },
    async openInfosChantiers() {
      console.log("Infos chantiers");
      await this.setChantier();
      await this.returnInfosChantier();
      this.showModaleInfosChantier();
    },
    toDate(nom) {
      return moment(this.chantier.avancement[nom])
        .toDate()
        .getFullYear();
    },
    getAvancement(nom) {
      return this.chantier.avancement[nom].fait;
    },
    loadCommandes() {
      this.loading = true;
      console.log("Commence récup commandes");
      this.$store.dispatch("chantier/returnCommandesChantier").then(() => {
        console.log("Récup commandes OK");
        this.loading = false;
      });
    },
    testDateAvecChantierPrecedent() {
      let bValue = true;
      if (this.chantier_precedent) {
        // Si le chantier précédent à la même date que le chantier en cours, on n'affiche pas la date en mode 'subheader'
        if (
          moment(this.chantier_precedent.prochaine_intervention).format(
            "ddMMyyyy"
          ) == moment(this.chantier.prochaine_intervention).format("ddMMyyyy")
        ) {
          bValue = false;
        }
      }
      return bValue;
    },
    openGoogleMap() {
      const urlSuffix = this.chantier.adresse_client_gps
        ? this.chantier.adresse_client_gps
        : this.chantier.adresse_client;

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    closeModaleIncident() {
      this.booleanIncident = false;
    },

   // UNIQUEMENT LA RENO POUR LE MOMENT
    envoiEmailPhotoChantier() {
      let tabReceptionMail;

      // if (this.user.agence.libelle == "NEUF") {
      //   tabReceptionMail = [
      //     "coordinateurPlanningNeufRuillé",
      //     "responsableOrdonnancement",
      //   ];
      // }
      
      this.setChantier();
      tabReceptionMail = ["oscar.photos@poupin.com"];            

      let payload = {
        listePhotos: this.listePhotoChantier,
        emailCommercial: "oscar.photos@poupin.com",
        commentaire: this.commentairePhoto,
        listeDestinataires: tabReceptionMail,
        numCompteur: this.chantier.compteur,
        chantier: this.chantier,
      };

      this.$store
        .dispatch("intervention/envoiEmailPhotoChantier", payload)
        .then(() => {
          this.booleanModalePhoto = false;
          this.photoChantierActuelle = null;
          this.listePhotoChantier = [];
          this.commentairePhoto = "";
        })
        .catch((err) => {
          console.log(err);
        });
    },

  },
  computed: {
    avancement() {
      let avancement = 0;
      // A développer pour renvoyer un score pondéré en fonction des champs complétés dans le document avancement correspondant au chantier
      // Exemple: Si Première pose === TE alors avancement += 20
      // Exemple: Si Première pose === EC alors avancement += 10
      avancement = 5;
      if (
        this.chantier.avancement.precommande_1erepose_termine_le.etat ===
          "TE" ||
        this.chantier.avancement.lancementfab_1erepose_termine_le.etat ===
          "TE" ||
        this.chantier.avancement.lancementfab_finition_termine_le.etat === "TE"
      ) {
        avancement = 20;
      }
      // if(this.premiere_pose.fait){
      //   avancement = 60;
      // }
      if (
        this.chantier.avancement.verif_details_dappui_premiere_pose.etat ===
        "TE"
      ) {
        avancement = 60;
      }
      // if(this.reception.fait){
      //   avancement = 95;
      // }
      if (this.chantier.avancement.habillage_tableaux_le.etat === "TE") {
        avancement = 90;
      }
      if (this.chantier.avancement.reception_effectuee_le.etat === "TE") {
        avancement = 95;
      }

      return avancement;
    },
    avancementCouleur() {
      let couleur = "#3F51B5";
      if (
        this.chantier.avancement.precommande_1erepose_termine_le.etat ===
          "TE" ||
        this.chantier.avancement.lancementfab_1erepose_termine_le.etat ===
          "TE" ||
        this.chantier.avancement.lancementfab_finition_termine_le.etat === "TE"
      ) {
        couleur = "#8BC34A";
      }
      // if(this.premiere_pose.fait){
      //   avancement = 60;
      // }
      if (
        this.chantier.avancement.verif_details_dappui_premiere_pose.etat ===
        "TE"
      ) {
        couleur = "#FFEB3B";
        console.log("Vérif détails d appuis OK");
      }
      // if(this.reception.fait){
      //   avancement = 95;
      // }
      if (this.chantier.avancement.habillage_tableaux_le.etat === "TE") {
        couleur = "#E91E63";
      }
      if (this.chantier.avancement.reception_effectuee_le.etat === "TE") {
        couleur = "#FF9800";
        console.log("Vérif détails d appuis OK");
      }

      return couleur;
    },
    avancementStr() {
      let couleur = "";
      if (
        this.chantier.avancement.precommande_1erepose_termine_le.etat ===
          "TE" ||
        this.chantier.avancement.lancementfab_1erepose_termine_le.etat ===
          "TE" ||
        this.chantier.avancement.lancementfab_finition_termine_le.etat === "TE"
      ) {
        couleur = "Lancement en fabrication";
      }
      // if(this.premiere_pose.fait){
      //   avancement = 60;
      // }
      if (
        this.chantier.avancement.verif_details_dappui_premiere_pose.etat ===
        "TE"
      ) {
        couleur = "1ère pose";
      }
      // if(this.reception.fait){
      //   avancement = 95;
      // }
      if (this.chantier.avancement.habillage_tableaux_le.etat === "TE") {
        couleur = "Finition";
      }
      if (this.chantier.avancement.reception_effectuee_le.etat === "TE") {
        couleur = "Réception";
      }

      return couleur;
    },
    buttonHeight() {
      if (this.windowWidth < 650) {
        return 50;
      } else {
        return 35;
      }
    },
    favoris() {
      return _.includes(
        this.userInSession.chantiers_favoris,
        this.chantier._id
      );
    },

    ...mapGetters("util", {
      windowWidth: "getWindowWidth",
    }),

    ...mapGetters("chantier", {
      getChantier: "getChantier",
      getChantiersFavoris: "getChantiersFavoris",
    }),

    ...mapGetters("auth", {
      userInSession: "userInSession",
      isBE: "isBE",
    }),
  },

  
  watch: {
    photoChantierActuelle(newValue) {
      if (this.photoChantierActuelle) {
        this.listePhotoChantier.push(newValue);
      }
    },
  },
};
</script>

<style scoped>

</style>
