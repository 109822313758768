//import Vue from 'vue';

//
//this.options = new MSALAuthenticationProviderOptions(this.msalRequest.scopes);
//let authProvider = new ImplicitMSALAuthenticationProvider(this.msalClient, this.options);

const state = {
  graphClient: null,
  graphUser: null,
  options: null,
  authProvider: null,
  msalRequest: {
    scopes: [
      "user.read",
      "files.read.all",
      "sites.read.all",
      "mailboxsettings.read",
      "calendars.readwrite",
    ],
  },
};

const getters = {
  getGraphClient(state) {
    return state.graphClient;
  },
  getMsalRequest(state) {
    return state.msalRequest;
  },
  getGraphUser(state) {
    return state.graphUser;
  },
  getOptions(state) {
    return state.options;
  },
  getAuthProvider(state) {
    return state.authProvider;
  },
};

const mutations = {
  SET_GRAPH_CLIENT(state, payload) {
    state.graphClient = payload;
    localStorage.setItem("msalGraphClient", JSON.stringify(payload));
  },
  SET_GRAPH_USER(state, payload) {
    state.graphUser = payload;
  },
  SET_OPTIONS(state, payload) {
    state.options = payload;
  },
  SET_AUTH_PROVIDER(state, payload) {
    state.authProvider = payload;
  },
};

const actions = {
  setGraphClient({ commit }, payload) {
    commit("SET_GRAPH_CLIENT", payload);
  },
  setGraphUser({ commit }, payload) {
    commit("SET_GRAPH_USER", payload);
  },
  setOptions({ commit }, payload) {
    commit("SET_OPTIONS", payload);
  },
  setAuthProvider({ commit }, payload) {
    commit("SET_AUTH_PROVIDER", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
