var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { on: { submit: _vm.addSociete } },
    [
      _c("v-text-field", {
        attrs: {
          type: "text",
          label: "Libellé Société",
          value: _vm.getSociete.libelle,
        },
        on: { change: _vm.setLibelleSociete },
      }),
      _c("v-text-field", {
        attrs: {
          type: "number",
          label: "Id Société (ERP)",
          value: _vm.getSociete.idERP,
        },
        on: { change: _vm.setIdSociete },
      }),
      _c("v-btn", { attrs: { type: "submit" } }, [_vm._v("Enregistrer")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }