<!-- ------------------ SIGNATURE CLIENT ------------------ -->
<template>
      <v-expansion-panel>
        <v-expansion-panel-header disable-icon-rotate>
          {{ getEtatAvancementDate('1000').message }}
          <template v-slot:actions>
            <v-icon :color="getEtatAvancementDate('1000').couleur">
              {{ getEtatAvancementDate('1000').icone }}
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
        <v-list class="mx-0">    
          <x-avancement-jalon-icone-texte 
            v-for="(item, index) in jalons"
            :key="index + 'k'"
            :champ_recherche="item"
            ></x-avancement-jalon-icone-texte>                                                               
        </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import XAvancementJalonIconeTexte from './X-avancement-jalon-icone-texte.vue';


export default {
  name: "X-avancement-vente",
  components: { XAvancementJalonIconeTexte},
  data() {
    return {
      jalons: [
        'demande_prix_recue_le','devis_envoye_le','devis_receptionne_valide_le','avenant_encours_non_valide','avenant_valide_le','choix_finaux_client_realises'
      ],
      commandes: false,
      loading: false      
      
    }
  },
  methods: {

  },
  computed: {
    ...mapGetters('chantier', {   
      getEtatAvancementDate: 'getEtatAvancementDate'
    }),   
  },
}
</script>

<style scoped>

</style>