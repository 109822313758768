var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "ma-0 pa-0",
      attrs: { value: _vm.visible, "max-width": "500px" },
      on: {
        "click:outside": function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-subtitle", { staticClass: "pt-4" }, [
            _c("h3", [_vm._v("Commentaire")]),
          ]),
          _c("v-divider"),
          _vm.edit && _vm.getCurrentJournee.journeeCorrigee
            ? _c(
                "v-card-text",
                [
                  _c("v-textarea", {
                    attrs: {
                      filled: "",
                      value: _vm.getCurrentJournee.journeeCorrigee.commentaire,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("change", $event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm.edit
            ? _c("v-card-text", { staticClass: "pt-4" }, [
                _vm._v(" Pas de journée corrigée "),
              ])
            : _c("v-card-text", { staticClass: "pt-4" }, [
                _vm._v(" " + _vm._s(_vm.commentaire) + " "),
              ]),
          _c(
            "v-card-actions",
            { staticClass: "mt-0 pt-0" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Fermer")]
              ),
              _vm.edit
                ? _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "orange" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("set-commentaire")
                        },
                      },
                    },
                    [_vm._v("Ajouter commentaire")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }