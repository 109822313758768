<template>
  <div>
    <v-form @submit="addUser" method="post" ref="userform">
      <v-text-field
        type="text"
        :value="getUser.username"
        label="Nom d'utilisateur"
        @change="setUsername"
      ></v-text-field>
      <v-text-field
        type="text"
        :value="getUser.nom"
        label="Nom"
        @change="setNom"
      ></v-text-field>
      <v-text-field
        type="text"
        :value="getUser.prenom"
        label="Prénom"
        @change="setPrenom"
      ></v-text-field>
      <v-text-field
        type="number"
        :value="getUser.idERP"
        label="Id ERP"
        @change="setIdERP"
      ></v-text-field>
      <v-text-field
        type="email"
        :value="getUser.email"
        label="E-mail"
        @change="setMail"
      ></v-text-field>
      <v-text-field
        type="text"
        :value="getUser.phone"
        label="Téléphone"
        @change="setPhone"
      ></v-text-field>
      <v-text-field
        type="password"
        :value="getUser.password"
        label="Mot de passe"
        @change="setPassword"
      ></v-text-field>

      <v-select
        :items="getAgences"
        :rules="[(v) => !!v || 'Agence obligatoire']"
        :value="getUser.agence"
        item-text="libelle"
        item-value="_id"
        @change="setAgence"
        label="Agence"
      ></v-select
      ><v-select
        :items="getSocietes"
        :rules="[(v) => !!v || 'Société obligatoire']"
        :value="getUser.societe"
        item-text="libelle"
        item-value="_id"
        @change="setSociete"
        label="Société"
      ></v-select>
      <v-select
        :items="roles"
        :rules="[(v) => !!v || 'Role obligatoire']"
        :value="getUser.role"
        item-text="nom"
        item-value="nom"
        @change="setRole"
        label="Rôle"
      ></v-select>

      <v-btn @click="$emit('close')" text>Fermer</v-btn>
      <v-btn type="submit" outlined color="green">Créer</v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "userForm",
  data() {
    return {
      roles: [
        { nom: "Poseur" },
        { nom: "Admin" },
        { nom: "Conducteur" },
        { nom: "Gestionnaire" },
        { nom: "Direction" },
        { nom: "BE" },
        { nom: "Livreur" },
        { nom: "ResponsableAlu" },
        { nom: "ResponsablePvc" },
        { nom: "ResponsableBois" },
        { nom: "PoseurRenovation" },
        { nom: "ResponsableRenovation" },
      ],
    };
  },
  methods: {
    addUser(e) {
      this.$store.dispatch("user/addUser").then(() => {
        this.$emit("close");
      });
      e.preventDefault();
    },
    setUsername(e) {
      this.$store.dispatch("user/setUsername", e);
    },
    setNom(e) {
      this.$store.dispatch("user/setNomUser", e);
    },
    setPrenom(e) {
      this.$store.dispatch("user/setPrenomUser", e);
    },
    setIdERP(e) {
      this.$store.dispatch("user/setIdErpUser", e);
    },
    setPassword(e) {
      this.$store.dispatch("user/setPasswordUser", e);
    },
    setMail(e) {
      this.$store.dispatch("user/setMailUser", e);
    },
    setPhone(e) {
      this.$store.dispatch("user/setPhoneUser", e);
    },
    setAgence(e) {
      this.$store.dispatch("user/setAgenceUser", e);
    },
    setSociete(e) {
      this.$store.dispatch("user/setSocieteUser", e);
    },
    setRole(e) {
      this.$store.dispatch("user/setRoleUser", e);
    },
  },
  computed: {
    ...mapGetters("user", {
      getUser: "getUser",
    }),

    ...mapGetters("agence", {
      getAgences: "getAgences",
    }),

    ...mapGetters("societe", {
      getSocietes: "getSocietes",
    }),
  },
  created() {
    this.$store.dispatch("user/returnUsers");
    this.$store.dispatch("agence/returnAgences");
    this.$store.dispatch("societe/returnSocietes");
  },
};
</script>

<style scoped>
</style>