<template>
  <v-menu
      v-model="menu1"
      :close-on-content-click="false"
      max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :value="computedDateFormattedMomentjs"
          clearable
          :label="title"
          readonly
          v-bind="attrs"
          prepend-icon="mdi-calendar"
          v-on="on"
          @click:clear="date = null"
          dense
      ></v-text-field>
    </template>
    <v-date-picker
        locale="fr"
        first-day-of-week="1"
        v-model="date"
        :max="max"
        :allowed-dates="allowedDates"
        :color="getTurquoisePoupin"
        @change="save"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment'
import { format, parseISO } from 'date-fns';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    menu1: false,
    max: moment(new Date()).toISOString()
  }),
  props: {
    title: {
      type: String,
      default: 'Un composant pour choisir une date'
    }
  },
  computed: {
    computedDateFormattedMomentjs () {
      return this.getCurrentJournee.date ? moment(this.getCurrentJournee.date).format('dddd Do MMMM YYYY') : ''
    },
    ...mapGetters('time', {
      getCurrentJournee: 'getCurrentJournee'
    }),

    ...mapGetters(['getTurquoisePoupin'])
  },
  methods: {
    save() {
      let currentDate = moment.utc(this.date).startOf('day').toDate()
      this.$store.dispatch('time/setDate', currentDate);
      this.menu1 = false;
    },
    allowedDates: val => new Date(val).getDay() != 0 && new Date(val).getDay() != 6
  },
}
</script>

<style scoped>

</style>