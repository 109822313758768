var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-btn",
        {
          staticClass: "mt-3 white--text",
          attrs: {
            href: "https://m365.eu.vadesecure.com/safeproxy/v4?f=JlKwaXDbHQ1ZYerq3Ol-ie_BSx_VVXKEXN6Sn-5sVh2SrOwdVEKVwR7DEEHWCpma&i=MXQkl5HCv_ctFEEMLW47akH0CIMVafg5C6XOyG4PeLdwBwP-x7XBW0UVt22XREaV1-JrrvjoqfQExFFS8awp1Q&k=gFhP&r=oYWqIaaCjp5487ahvnmJBDGMhG3Tr1e_JdWXvfSAT1YzqK9nuu9jvF8QnJfXTE-2&s=d787d686a19bc3102118bcfeda7788d0a346673c0d7bd8a4297458ffc9cabdad&u=https%3A%2F%2Fdrive.google.com%2Fdrive%2Ffolders%2F1Aj40zLJntsVr9jbVLCRMmh40c-gQPDSM%3Fusp%3Dsharing",
            target: "_blank",
            width: "100%",
            color: _vm.getBleu,
          },
        },
        [_vm._v(" Drive - documentation de pose ")]
      ),
      _c(
        "v-expansion-panels",
        {
          staticClass: "mt-3",
          attrs: { accordion: "", focusable: "", popout: "" },
        },
        [
          _vm.isPoseur || _vm.isGestionnaire || _vm.isAdmin
            ? _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v("Tickets restaurants : Informations générales"),
                  ]),
                  _c("v-expansion-panel-content", [
                    _c("br"),
                    _c("p", [
                      _vm._v(
                        "Le titre-restaurant est un titre spécial de paiement «des repas» remis par l’employeur au salarié."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Les titres sont valables pour des aliments immédiatement consommables ou qui serviront à la préparation de votre repas. Notamment:"
                      ),
                    ]),
                    _c("ul", [
                      _c("li", [_vm._v("D’un repas,")]),
                      _c("li", [
                        _vm._v(
                          "De préparation alimentaires directement consommables,"
                        ),
                      ]),
                      _c("li", [_vm._v("De fruits et légumes,")]),
                      _c("li", [_vm._v("De produits laitiers.")]),
                    ]),
                    _c("br"),
                    _c("p", [
                      _vm._v(
                        "Le titre-restaurant étant considéré comme un avantage social il est généralement admis qu’il doit être accordé à tous les membres d’une même entreprise."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Le titre restaurant est en partie financé par l'employeur, qui doit obligatoirement prendre à sa charge entre 50% et 60% de sa valeur. Il reste entre 40% et 50% de la valeur du ticket à la charge du salarié. (Dans le cas de l’entreprise Poupin, 60% du ticket restaurant est financé par l’employeur et 40% par l’employé, sauf dans le cas particulier d’un poseur qui lui dispose d’un panier repas légal de 9€33 payé par l’entreprise auquel vient s’ajouter sa participation)."
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isPoseur || _vm.isGestionnaire || _vm.isAdmin
            ? _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(
                      "Tickets restaurants : Conditions d'attribution et d'utilisation"
                    ),
                  ]),
                  _c("v-expansion-panel-content", [
                    _c("br"),
                    _c("h4", [_vm._v("Condition d’attribution")]),
                    _c("p", [
                      _vm._v(
                        "Il ne peut être attribué qu’un seul titre-restaurant par jour de travail et à condition que le repas soit compris dans l’horaire de travail journalier. Ainsi un salarié travaillant 5 jours de 8 heures à 17 heures pourra bénéficier de 5 titres-restaurant, au contraire un salarié n’effectuant pour une raison x que des demies journées ne pourra donc pas en bénéficier."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Dans le cas particulier d’un grand déplacement une indemnité de repas est déjà attribuée, un titre restaurant ne sera donc pas octroyé en sus de cette indemnité."
                      ),
                    ]),
                    _c("h4", [
                      _vm._v("Neutralisation des périodes d’absences"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Les salariés absents (congés annuels, récupération du temps de travail, maladie, …) ne bénéficient pas des titres-restaurant pour les jours d’absences."
                      ),
                    ]),
                    _c("h4", [_vm._v("Condition d’utilisation")]),
                    _c("p", [
                      _vm._v(
                        "Les commerçants ne sont pas dans l'obligation d'accepter les titres-restaurant. Chaque enseigne de magasins fixe sa propre liste de produits payables par titre restaurant. Il peut donc y avoir des différences d'une enseigne à une autre. Vous pouvez utiliser vos titres-restaurant dans la limite de 19€ par jour. Si vous utilisez des titres papier, le commerçant n'a pas le droit de vous rendre la monnaie."
                      ),
                    ]),
                    _c("h4", [_vm._v("Bon à savoir:")]),
                    _c("p", [
                      _vm._v(
                        "C’est la commission nationale des titres-restaurant qui fixe les conditions d’attribution des titres-restaurant."
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isPoseur || _vm.isGestionnaire || _vm.isAdmin
            ? _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v("Indemnité de trajet : Petits déplacements"),
                  ]),
                  _c("v-expansion-panel-content", [
                    _c("br"),
                    _c("p", [
                      _vm._v(
                        "Il existe des indemnités de trajets journalier pour les «Petits déplacements»."
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Il s’agit d’un forfait légal qui suit la grille régionale (de 0 à 80km à vol d’oiseau) auquel l’entreprise Poupin à ajouté une extension pour les déplacements à la journée dans la fourchette des 80km à 160km (bien que pour des raison de sécurité il soit recommandé de passer sur des grands déplacements)."
                      ),
                    ]),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.downloadFile.dir,
                          download: "TableauZones.png",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          { attrs: { color: "blue", outlined: "" } },
                          [
                            _c("v-icon", [_vm._v("mdi-download")]),
                            _vm._v(" Tableau des zones 2020"),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c("br"),
                    _c("p", [
                      _vm._v(
                        "Pour les déplacements de plus de 80km et qui nécessitent d’effectuer une ou des nuitées sur place on parle de «Grands déplacements», ces trajets peuvent être indemnisés de deux manières différentes."
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isPoseur || _vm.isGestionnaire || _vm.isAdmin
            ? _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(
                      "Indemnité de trajet : Grands déplacements (Frais réels)"
                    ),
                  ]),
                  _c("v-expansion-panel-content", [
                    _c("br"),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Dans le cas des frais réel l’entreprise propose de régler directement les frais liés au déplacement."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Un prime de déplacement de 35€ est octroyé par nuitée."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Le logement est organisé avec l’entreprise (avec pièces individuelles pour le couchage)."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Les paiements des repas doivent être réglés par CB dans la limite de 16€ MAX le midi et de 24€ MAX le soir."
                        ),
                      ]),
                    ]),
                    _c("br"),
                    _c("h4", [_vm._v("À noter:")]),
                    _c("p", [
                      _vm._v(
                        "L’indemnité de grand déplacement correspond aux dépenses journalières normales qu’engage le déplacé en plus des dépenses habituelles qu’il engagerait s’il n’était pas déplacé."
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isPoseur || _vm.isGestionnaire || _vm.isAdmin
            ? _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(
                      "Indemnité de trajet : Grands déplacements (Allocation forfaitaire)"
                    ),
                  ]),
                  _c("v-expansion-panel-content", [
                    _c("br"),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Dans le cas de l’allocation forfaitaire l’entreprise propose de verser par virement un forfait avec montant fixe lié aux coûts du déplacement."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Un prime de déplacement de 28€ est octroyé par nuitée (tous départements confondus)."
                        ),
                      ]),
                      _c("li", [
                        _vm._v("Le logement est organisé avec l’entreprise."),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Le salarié bénéficie d’une indemnité repas de 19€ le midi ET le soir."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "72€ d’indemnités de couchage (logement et petit déjeuners) sont versés par nuitée pour la région parisienne."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "55€ d’indemnités de couchage (logement et petit déjeuners) sont versés par nuitée pour les autres régions."
                        ),
                      ]),
                    ]),
                    _c("br"),
                    _c("h4", [_vm._v("À noter:")]),
                    _c("p", [
                      _vm._v(
                        "L’indemnité de grand déplacement correspond aux dépenses journalières normales qu’engage le déplacé en plus des dépenses habituelles qu’il engagerait s’il n’était pas déplacé."
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [_vm._v("Documentation de pose")]),
              _c(
                "v-expansion-panel-content",
                [
                  _c("br"),
                  _c("iframe", {
                    attrs: {
                      width: "100%",
                      height: "auto",
                      src: "https://www.youtube.com/embed/rfiqfPcc0VU",
                      title: "YouTube video player",
                      frameborder: "0",
                      allow:
                        "clipboard-write; encrypted-media; picture-in-picture",
                      allowfullscreen: "",
                    },
                  }),
                  _c("br"),
                  _c("iframe", {
                    attrs: {
                      width: "100%",
                      height: "auto",
                      src: "https://www.youtube.com/embed/1kWeT3SQFfY",
                      title: "YouTube video player",
                      frameborder: "0",
                      allow:
                        "clipboard-write; encrypted-media; picture-in-picture",
                      allowfullscreen: "",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-2 mb-1" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-2 mx-0",
                          attrs: { width: "100%" },
                          on: {
                            click: function ($event) {
                              _vm.current_pdf = _vm.pdf_1
                              _vm.currentPage = 1
                            },
                          },
                        },
                        [_vm._v("Calepin chantier")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-2",
                          attrs: { width: "100%" },
                          on: {
                            click: function ($event) {
                              _vm.current_pdf = _vm.pdf_2
                              _vm.currentPage = 1
                            },
                          },
                        },
                        [_vm._v("Normes garde corps")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-2",
                          attrs: { width: "100%" },
                          on: {
                            click: function ($event) {
                              _vm.current_pdf = _vm.pdf_3
                              _vm.currentPage = 1
                            },
                          },
                        },
                        [_vm._v("Guide pose neuf")]
                      ),
                    ],
                    1
                  ),
                  _c("v-pagination", {
                    attrs: { length: _vm.pageCount },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                  _c("pdf", {
                    staticClass: "mt-3",
                    attrs: { src: _vm.current_pdf, page: _vm.currentPage },
                    on: {
                      "num-pages": function ($event) {
                        _vm.pageCount = $event
                      },
                      "page-loaded": function ($event) {
                        _vm.currentPage = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }