<template>
  <livraison></livraison>
</template>
<script>
import Livraison from "../components/livraison/LivraisonComponent.vue";

export default {
  components: {
    Livraison,
  },
};
</script>
