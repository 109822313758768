<!-- ------------------ SIGNATURE CLIENT ------------------ -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header disable-icon-rotate>
      {{ getEtatAvancementDate("4000").message }} {{ " - Début :" }}
      {{ getChantier.date_debut_finition | semaine }} {{ "Fin :" }}
      {{ getChantier.date_fin_finition | semaine }}
      <template v-slot:actions>
        <v-icon :color="getEtatAvancementDate('4000').couleur">
          {{ getEtatAvancementDate("4000").icone }}
        </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list class="mx-0">
        <!-- Coche toutes les cases -->
        <v-list-item>
          <v-icon></v-icon>
          <v-list-item-content
            ><v-label>Valider toutes les étapes ?</v-label></v-list-item-content
          >
          <v-list-item-action>
            <v-icon color="blue-grey darken-2" @click="setAllValueDatePicker()"
              >mdi-check-all</v-icon
            >
          </v-list-item-action>
        </v-list-item>

        <!-- chape_coulees_le -->
        <x-avancement-jalon-change-etat
          :checkbox="true"
          :champ_recherche="'chape_coulees_le'"
          :nb_step="3"
          :etat="getEtatAvancementDate('chape_coulees_le').etat"
          @choixCheckBoxEtat="
            setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
          "
        ></x-avancement-jalon-change-etat>
        <!-- bande_placo_le -->
        <x-avancement-jalon-change-etat
          :checkbox="true"
          :champ_recherche="'bande_placo_le'"
          :nb_step="3"
          :etat="getEtatAvancementDate('bande_placo_le').etat"
          @choixCheckBoxEtat="
            setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
          "
        ></x-avancement-jalon-change-etat>
        <!-- prise_cote_bso_le.date_previ -->
        <!-- <x-avancement-jalon-change-etat
              v-if="getWindowWidth > 600"              
              :checkbox="false"
              :champ_recherche="'prise_cote_bso_le.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
            >
              </x-avancement-jalon-change-etat>

              <x-avancement-jalon-change-etat-date-mobile
              v-else
              :champ_recherche="'prise_cote_bso_le.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
              >
              </x-avancement-jalon-change-etat-date-mobile>                                                              -->
        <!-- prise_cote_bso_le -->
        <x-avancement-jalon-change-etat
          v-if="getEtatAvancementDate('prise_cote_bso_le').existe_sur_chantier"
          :checkbox="true"
          :champ_recherche="'prise_cote_bso_le'"
          :nb_step="2"
          :etat="getEtatAvancementDate('prise_cote_bso_le').etat"
          @choixCheckBoxEtat="
            setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
          "
        ></x-avancement-jalon-change-etat>
        <!-- prise_cote_finitions_le.date_previ -->
        <!-- <x-avancement-jalon-change-etat
              v-if="getWindowWidth > 600"              
              :checkbox="false"
              :champ_recherche="'prise_cote_finitions_le.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
            >
              </x-avancement-jalon-change-etat>

              <x-avancement-jalon-change-etat-date-mobile
              v-else
              :champ_recherche="'prise_cote_finitions_le.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
              >
              </x-avancement-jalon-change-etat-date-mobile>  -->
        <!-- prise_cote_finitions_le -->
        <x-avancement-jalon-change-etat
          :checkbox="true"
          :champ_recherche="'prise_cote_finitions_le'"
          :nb_step="2"
          :etat="getEtatAvancementDate('prise_cote_finitions_le').etat"
          @choixCheckBoxEtat="
            setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
          "
        ></x-avancement-jalon-change-etat>
        <!-- habillage_tableaux_le.date_previ -->
        <!-- <x-avancement-jalon-change-etat
              v-if="getWindowWidth > 600"              
              :checkbox="false"
              :champ_recherche="'habillage_tableaux_le.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
            >
              </x-avancement-jalon-change-etat>

              <x-avancement-jalon-change-etat-date-mobile
              v-else
              :champ_recherche="'habillage_tableaux_le.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
              >
              </x-avancement-jalon-change-etat-date-mobile>                 -->
        <!-- prise_cote_finitions_le -->
        <x-avancement-jalon-change-etat
          :checkbox="true"
          :champ_recherche="'habillage_tableaux_le'"
          :nb_step="2"
          :etat="getEtatAvancementDate('habillage_tableaux_le').etat"
          @choixCheckBoxEtat="
            setValueCheckboxDate($event.libelle, $event.etat, $event.nb_step)
          "
        ></x-avancement-jalon-change-etat>
        <!-- pret_a_poser_finition.date_previ -->
        <!-- <x-avancement-jalon-change-etat
              v-if="getWindowWidth > 600"              
              :checkbox="false"
              :champ_recherche="'pret_a_poser_finition.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
            >
              </x-avancement-jalon-change-etat>

              <x-avancement-jalon-change-etat-date-mobile
              v-else
              :champ_recherche="'pret_a_poser_finition.date_previ'"
              @choixDatePrevi="setValueDatePicker($event.libelle,$event.data)"
              >
              </x-avancement-jalon-change-etat-date-mobile>                 -->
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from "vuex";
import XAvancementJalonChangeEtat from "./X-avancement-jalon-change-etat.vue";
import moment from "moment";

export default {
  name: "X-avancement-finition",
  components: { XAvancementJalonChangeEtat },
  data() {
    return {
      jalons: [
        "demande_prix_recue_le",
        "devis_envoye_le",
        "devis_receptionne_valide_le",
        "avenant_encours_non_valide",
        "avenant_valide_le",
        "choix_finaux_client_realises",
      ],
      commandes: false,
      loading: false,
    };
  },
  methods: {
    /**
     * Mise à jour de tout les états
     */
    setAllValueDatePicker() {
      let jalons = [
        "chape_coulees_le",
        "bande_placo_le",
        "prise_cote_finitions_le",
        "prise_cote_bso_le",
        "habillage_tableaux_le",
      ];

      for (let i = 0; i < jalons.length; i++) {
        let letat = "EC";
        let nbStep = 3;

        if (i > 1) {
          nbStep = 2;
        }
        this.setValueCheckboxDate(jalons[i], letat, nbStep);
      }
    },

    setValueDatePicker(stypeValeur, valeur) {
      // Type de valeur pour savoir quel champ mettre à jour dans la classe 'avancement'
      // check pour savoir si c'est coché ou pas
      // valeur si on met autre chose que la date du jour
      let payload = {
        typeValeur: stypeValeur,
        nouvelleValeur: moment(valeur)
          .toDate()
          .toISOString(),
        isChecked: false,
        datePrevi: moment(valeur).toDate(),
        etat: "",
      };
      switch (payload.typeValeur) {
        case "-1":
          // Dispatch dans le front
          this.$store
            .dispatch("chantier/setChantierDateTheorique", payload)
            .then(() => {
              this.$store.dispatch("chantier/updateDateTheoriquePremierePose");
            });
          break;

        case "-2":
          // Dispatch dans le front
          this.$store
            .dispatch("chantier/setChantierDateTheorique", payload)
            .then(() => {
              this.$store.dispatch("chantier/updateDateTheoriqueFinition");
            });
          break;
        case "verif_details_dappui_premiere_pose.date_previ":
        case "prise_cote_bso_le.date_previ":
        case "prise_cote_finitions_le.date_previ":
        case "habillage_tableaux_le.date_previ":
        case "pret_a_poser_premiere_pose.date_previ":
        case "pret_a_poser_finition.date_previ":
        case "pv_reception_signe_le.date_previ":
        case "levee_reserve_le.date_previ":
        case "pv_levee_reserve_le.date_previ":
          payload.nouvelleValeur = moment("01/01/1900").toDate();
          // Dispatch dans le front
          this.$store
            .dispatch("chantier/setChantierAvancementDatePrevi", payload)
            .then(async () => {
              await this.$store.dispatch("chantier/updateAvancement");
              this.$forceUpdate();
            });

          break;
      }
    },
    setValueCheckboxDate(stypeValeur, sEtat, nbStep) {
      // Type de valeur pour savoir quel champ mettre à jour dans la classe 'avancement'
      // check pour savoir si c'est coché ou pas
      // valeur si on met autre chose que la date du jour
      let payload = {
        typeValeur: stypeValeur,
        nouvelleValeur: moment().toDate(),
        isChecked: false,
        datePrevi: moment("01/01/1900").toDate(),
        etat: sEtat,
      };

      if (nbStep === 3) {
        if (payload.etat === "" || payload.etat === "NC") {
          payload.etat = "EC";
        } else if (payload.etat === "EC") {
          payload.etat = "TE";
        } else if (payload.etat === "TE") {
          payload.etat = "NC";
          payload.nouvelleValeur = moment("01/01/0001").toDate();
          payload.isChecked = true;
        }
      } else if (nbStep === 2) {
        if (
          payload.etat === "" ||
          payload.etat === "NC" ||
          payload.etat === "EC"
        ) {
          payload.etat = "TE";
        } else if (payload.etat === "TE") {
          payload.etat = "NC";
          payload.nouvelleValeur = moment("01/01/0001").toDate();
          payload.isChecked = true;
        }
      }

      // Dispatch dans le front
      this.$store.dispatch("chantier/setAvancementDate", payload).then(() => {
        this.$store.dispatch("chantier/updateAvancement");
      });
    },
  },
  computed: {
    ...mapGetters("chantier", {
      getChantier: "getChantier",
      getEtatAvancementDate: "getEtatAvancementDate",
    }),
    ...mapGetters("util", {
      getWindowWidth: "getWindowWidth",
    }),
  },
};
</script>

<style scoped></style>
