var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [_c("h1", [_vm._v("Créer un nouvel utilisateur")]), _c("user-form")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }