<template>
  <v-app>
    <x-nav v-if="isConnected"></x-nav>
    <v-main style="min-width: 100%">
      <!--Le composant correspondant à la route sera rendu ici-->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import XNav from "./components/nav/X-nav";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    XNav,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  created() {
    this.$store.dispatch("util/setWindowWidth");
    this.setWidth();
    window.addEventListener("resize", this.setWidth);
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    setWidth() {
      this.$store.commit("util/SET_WINDOW_WIDTH");
    },
  },
  computed: {
    ...mapGetters("auth", {
      isConnected: "isConnected",
    }),
  },
};
</script>
