import { render, staticRenderFns } from "./InfosUtiles.vue?vue&type=template&id=22ae2f4b&scoped=true&"
import script from "./InfosUtiles.vue?vue&type=script&lang=js&"
export * from "./InfosUtiles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22ae2f4b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bitnami/deploy/oscar_front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('22ae2f4b')) {
      api.createRecord('22ae2f4b', component.options)
    } else {
      api.reload('22ae2f4b', component.options)
    }
    module.hot.accept("./InfosUtiles.vue?vue&type=template&id=22ae2f4b&scoped=true&", function () {
      api.rerender('22ae2f4b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/InfosUtiles.vue"
export default component.exports