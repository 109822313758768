var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v("Suivi des infos chantier")]),
      _c("v-card-subtitle", [_vm._v(_vm._s(_vm.getChantier.nom_client))]),
      _c(
        "v-card-text",
        [_c("x-infos-chantier", { attrs: { btnVisible: false } })],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Fermer")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }