<template>
  <div>
    <v-list>
      <v-list-group prepend-icon="mdi-star">
        <template v-slot:activator>
          <v-list-item-title
            >Favoris ({{ getChantiersFavoris.length }})</v-list-item-title
          >
        </template>
        <x-chantier-card
          color="blue lighten-5"
          v-for="(chantier, i) in getChantiersFavoris"
          :key="-i - 1"
          :chantier="chantier"
          class="my-2"
        ></x-chantier-card>
        <v-subheader v-if="getChantiersFavoris.length === 0">
          Vous pouvez ajouter des favoris en cliquant sur le petit drapeau en
          bout de chantier
        </v-subheader>
      </v-list-group>
      <v-list-group prepend-icon="mdi-filter-variant">
        <template v-slot:activator>
          <v-list-item-title>Filtres</v-list-item-title>
        </template>

        <v-card>
          <v-card-text>
            <v-text-field
              label="Mots clés"
              v-model="query.mot_cle"
              clearable
              hint="Nom de Client, Ville ou Code Postal"
              @input="checkSearchStr"
            ></v-text-field>
            <v-text-field
              label="Numéro de devis"
              v-model="query.numero_devis"
              clearable
              hint="Cinq chiffres ou Cinq chiffres et une lettre"
              @input="setNumeroDevis"
            ></v-text-field>
            <v-select
              label="Étape d'avancement"
              v-model="query.etape"
              :items="etapes"
              item-text="nom"
              item-value="value"
              clearable
              @change="setEtape"
            >
            </v-select>
          </v-card-text>
        </v-card>
      </v-list-group>
    </v-list>    
    <x-chantier-card
      v-for="(chantier, i) in getChantiers"
      :key="i"
      :chantier="chantier"
      :chantier_precedent="getChantiers[i - 1]"
      class="my-2"
    >
    </x-chantier-card>

    <!--Modale d'accès aux contacts d'un chantier (Client, MOE)-->
    <x-modale-contact
      :visible="getModaleContact"
      @close="hideModaleContact"
    ></x-modale-contact>
    <!--Modale d'accès aux fichiers relatifs à un chantier-->
    <x-modale-graph
      v-if="getModaleGraph"
      @close="hideModaleGraph"
    ></x-modale-graph>

    <!--Modale de mise à jour de l'avancement du chantier-->
    <x-modale-notes-chantier
      :visible="getModaleAvancement"
      @close="hideModaleAvancement"
    ></x-modale-notes-chantier>
    <!--Modale de mise à jour de l'avancement du chantier-->
    <x-modale-infos-chantier
      :visible="getModaleInfosChantier"
      @close="hideModaleInfosChantier"
    ></x-modale-infos-chantier>
    <!--Modale de commandes internes-->
    <!-- <bc-global
      :visible="getModaleCommandeInterne"
      @close="hideModaleCommandeInterne"
    ></bc-global> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import XChantierCard from "@/components/chantier/X-chantier-card";
import XModaleContact from "@/components/chantier/X-modale-contact";
import XModaleNotesChantier from "@/components/chantier/avancement/X-modale-notes-chantier";

// import BcGlobal from "@/components/bc/template/global-bc";
import XModaleInfosChantier from "./info_chantier/X-modale-infos-chantier.vue";
import XModaleGraph from "./graph/X-modale-graph.vue";
import _ from "lodash";

export default {
  name: "ChantierComponent",
  components: {
    XModaleNotesChantier,
    XModaleContact,
    XChantierCard,
    XModaleInfosChantier,
    XModaleGraph,
  },
  data() {
    return {
      expand: false,
      userCompleteTextBox: false,
      etapes: [
        {
          nom: "Afficher les 1ères poses à venir",
          value: "voir_les_premieres_poses",
        },
        {
          nom: "Afficher les dossiers 'en attente de prise de cotes finitions'",
          value: "premiere_pose_ok_mais_finition_pas_vu",
        },
        {
          nom: "Affichier tous mes chantiers",
          value: "tous_mes_chantiers",
        },
        // {
        //   nom: 'Première pose terminée',
        //   value: 'PPT'
        // },
        // {
        //   nom: 'Finitions terminées',
        //   value: 'FT'
        // },
        {
          nom: "En attente de facturation",
          value: "ADF",
        },
      ],
      query: {},
    };
  },
  created() {
    if (
      this.userInSession.chantiers_favoris &&
      this.userInSession.chantiers_favoris.length > 0
    ) {
      this.$store.dispatch("chantier/returnChantiersFavoris");
    }
    this.$store.dispatch("chantier/returnAllChantiers");
  },
  methods: {
    ...mapActions("chantier", {
      hideModaleGraph: "hideModaleGraph",
      hideModaleContact: "hideModaleContact",
      hideModaleAvancement: "hideModaleAvancement",
      hideModaleCommandeInterne: "hideModaleCommandeInterne",
      hideModaleInfosChantier: "hideModaleInfosChantier",
      returnAllChantiers: "returnAllChantiers",
    }), 

    setMotCle(e) {
      this.userCompleteTextBox = true;
      let recherche = this.query.mot_cle;

      if (
        (recherche && recherche.length >= 3) ||
        (recherche && recherche.length === 0) ||
        !recherche
      ) {
        if (recherche) {
          recherche = e.trim();
        }
        this.returnQuery();
      }
    },
    checkSearchStr: _.debounce(function(e) {
      let recherche = this.query.mot_cle;

      if (
        (recherche && recherche.length >= 3) ||
        (recherche && recherche.length === 0) ||
        !recherche
      ) {
        if (recherche) {
          recherche = e.trim();
        }
        this.returnQuery();
      }
    }, 1000),

    onCancel() {
      console.log("User cancelled the loader.");
    },

    setNumeroDevis(e) {
      let devis = this.query.numero_devis;

      if ((devis && devis.length > 4 && devis.length < 7) || !devis) {
        if (devis) {
          devis = this.noSpace(e);
        }
        this.returnQuery();
      }
    },

    setEtape(e) {
      this.query.etape = e;
      this.returnQuery();
    },

    returnQuery() {
      if (this.query.mot_cle || this.query.numero_devis || this.query.etape) {
        console.log(
          `IF --- Mon mot clé ${this.query.mot_cle}, mon numéro de devis ${this.query.numero_devis}, mon étape ${this.query.etape}`
        );
        this.$store.dispatch("chantier/returnChantierFiltres", this.query);
        this.userCompleteTextBox = false;
      } else {
        console.log(
          `ELSE --- Mon mot clé ${this.query.mot_cle}, mon numéro de devis ${this.query.numero_devis}, mon étape ${this.query.etape}`
        );
        this.returnAllChantiers();
        this.userCompleteTextBox = false;
      }
    },

    noSpace(string) {
      let input = string.trim();
      return (input = input.replaceAll(" ", ""));
    },
  },
  computed: {
    ...mapGetters("chantier", {
      getChantiers: "getChantiers",
      getChantiersFavoris: "getChantiersFavoris",
      getModaleGraph: "getModaleGraph",
      getModaleContact: "getModaleContact",
      getModaleAvancement: "getModaleAvancement",
      getModaleCommandeInterne: "getModaleCommandeInterne",
      getModaleInfosChantier: "getModaleInfosChantier",
      getEtatAvancementDate: "getEtatAvancementDate",
    }),
    ...mapGetters("auth", {
      userInSession: "userInSession",
    }),  
  },
};
</script>

<style scoped></style>
