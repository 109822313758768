var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      Object.keys(_vm.getCurrentJournee).length !== 0
        ? _c(
            "v-form",
            {
              ref: "timeform",
              attrs: { "lazy-validation": "" },
              on: { submit: _vm.addTime },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c("date-picker-component", { attrs: { title: "Date du jour" } }),
              _c("v-checkbox", {
                attrs: {
                  "input-value": _vm.getCurrentJournee.journeeCorrigee
                    ? _vm.getCurrentJournee.journeeCorrigee.lieu_depart
                    : _vm.getCurrentJournee.lieu_depart,
                  "prepend-icon": "mdi-home-outline",
                  label: "Passage à l'entreprise le matin ?",
                  color: _vm.getTurquoisePoupin,
                  "true-value": "Entreprise",
                  "false-value": "Domicile",
                },
                on: { change: _vm.setLieuDepart },
              }),
              _c("x-time-picker", {
                attrs: {
                  title:
                    _vm.getLieuDepart === "Entreprise"
                      ? "Arrivée sur l'entreprise"
                      : "Départ du domicile",
                  usertime: _vm.getCurrentJournee.journeeCorrigee
                    ? _vm.getCurrentJournee.journeeCorrigee.heure_depart
                    : _vm.getCurrentJournee.heure_depart,
                },
                on: { timepicked: _vm.setHeureDepart },
              }),
              _c("v-select", {
                attrs: {
                  dense: "",
                  items: _vm.temps,
                  rules: [(v) => !!v || "Temps de pause obligatoire"],
                  value: _vm.getCurrentJournee.journeeCorrigee
                    ? _vm.getCurrentJournee.journeeCorrigee.temps_pause
                    : _vm.getCurrentJournee.temps_pause,
                  "item-text": "name",
                  "item-value": "value",
                  "menu-props": "auto",
                  label: "Temps de pause",
                  "prepend-icon": "mdi-coffee",
                },
                on: { change: _vm.setTempsPause },
              }),
              _c("x-time-picker", {
                attrs: {
                  title: "Départ du dernier chantier",
                  usertime: _vm.getCurrentJournee.journeeCorrigee
                    ? _vm.getCurrentJournee.journeeCorrigee
                        .heure_depart_dernier_chantier
                    : _vm.getCurrentJournee.heure_depart_dernier_chantier,
                },
                on: { timepicked: _vm.setHeureDepartDernierChantier },
              }),
              _c("v-checkbox", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.getCurrentJournee.horaire_retour,
                    expression: "!getCurrentJournee.horaire_retour",
                  },
                ],
                attrs: {
                  "input-value": _vm.getCurrentJournee.journeeCorrigee
                    ? _vm.getCurrentJournee.journeeCorrigee.lieu_arrivee
                    : _vm.getCurrentJournee.lieu_arrivee,
                  "prepend-icon": "mdi-home-outline",
                  label: "Travail sur l'entreprise le soir ?",
                  color: _vm.getTurquoisePoupin,
                  "true-value": "Entreprise",
                  "false-value": "Domicile",
                  dense: "",
                },
                on: { change: _vm.setLieuRetour },
              }),
              (_vm.isAdmin || _vm.isPoseur) &&
              _vm.getCurrentJournee.lieu_arrivee == "Domicile"
                ? _c("v-checkbox", {
                    attrs: {
                      "input-value": _vm.getCurrentJournee.horaire_retour
                        ? _vm.getCurrentJournee.horaire_retour
                        : false,
                      "prepend-icon": "mdi-truck",
                      label: "Retour grand déplacement ?",
                      color: _vm.getTurquoisePoupin,
                      dense: "",
                    },
                    on: { change: _vm.setHoraireRetour },
                  })
                : _vm._e(),
              (
                _vm.getCurrentJournee.journeeCorrigee
                  ? _vm.getCurrentJournee.journeeCorrigee.lieu_arrivee ===
                      "Entreprise" ||
                    _vm.getCurrentJournee.journeeCorrigee.trajetSoir ||
                    _vm.getCurrentJournee.horaire_retour
                  : _vm.getLieuRetour === "Entreprise" ||
                    _vm.getCurrentJournee.horaire_retour
              )
                ? _c("x-time-picker", {
                    attrs: {
                      title: _vm.getCurrentJournee.journeeCorrigee
                        ? _vm.getCurrentJournee.journeeCorrigee.lieu_arrivee ===
                          "Entreprise"
                          ? "Arrivée à l'entreprise"
                          : "Heure de fin de trajet"
                        : "Arrivée à l'entreprise",
                      usertime: _vm.getCurrentJournee.journeeCorrigee
                        ? _vm.getCurrentJournee.journeeCorrigee.heure_arrivee
                        : _vm.getCurrentJournee.heure_arrivee,
                    },
                    on: { timepicked: _vm.setHeureRetour },
                  })
                : _vm._e(),
              (
                _vm.getCurrentJournee.journeeCorrigee
                  ? _vm.getCurrentJournee.journeeCorrigee.lieu_arrivee ===
                    "Entreprise"
                  : _vm.getLieuRetour === "Entreprise"
              )
                ? _c("x-time-picker", {
                    attrs: {
                      title: "Départ de l'entreprise",
                      usertime: _vm.getCurrentJournee.journeeCorrigee
                        ? _vm.getCurrentJournee.journeeCorrigee
                            .heure_depart_entreprise
                        : _vm.getCurrentJournee.heure_depart_entreprise,
                    },
                    on: { timepicked: _vm.setHeureDepartEntreprise },
                  })
                : _vm._e(),
              _c("v-autocomplete", {
                attrs: {
                  id: "villeauto",
                  label: "Ville la plus lointaine",
                  "item-text": "Description",
                  "prepend-icon": "mdi-map-marker",
                  clearable: "",
                  "return-object": "",
                  "hide-no-data": "",
                  "hide-details": "",
                  items: _vm.items,
                  loading: _vm.isLoading,
                  "search-input": _vm.search,
                  value: _vm.getCurrentJournee.journeeCorrigee
                    ? _vm.getCurrentJournee.journeeCorrigee.villeLaPlusLointaine
                    : _vm.getCurrentJournee.villeLaPlusLointaine,
                  rules: [(v) => !!v || "Ville la plus lointaine obligatoire"],
                  dense: "",
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.search = $event
                  },
                  change: _vm.setVilleLaPlusLointaine,
                },
              }),
              (_vm.isAdmin || _vm.isGestionnaire) &&
              _vm.getCurrentJournee.journeeCorrigee
                ? _c("v-checkbox", {
                    attrs: {
                      "input-value": _vm.getCurrentJournee.journeeCorrigee.reel,
                      "prepend-icon": "mdi-av-timer",
                      label: "Calcul temps réel (inférieur à 8h)",
                      color: _vm.getTurquoisePoupin,
                    },
                    on: { change: _vm.setReel },
                  })
                : _vm._e(),
              (_vm.isAdmin || _vm.isGestionnaire) &&
              _vm.getCurrentJournee.journeeCorrigee
                ? _c("v-checkbox", {
                    attrs: {
                      "input-value":
                        _vm.getCurrentJournee.journeeCorrigee.trajetSoir,
                      "prepend-icon": "mdi-truck",
                      label: "Ajouter le temps de trajet du soir ?",
                      color: _vm.getTurquoisePoupin,
                    },
                    on: { change: _vm.setTrajetSoir },
                  })
                : _vm._e(),
              (_vm.isAdmin || _vm.isGestionnaire) &&
              _vm.getCurrentJournee.journeeCorrigee
                ? _c("v-checkbox", {
                    attrs: {
                      "input-value":
                        _vm.getCurrentJournee.journeeCorrigee.samedi,
                      "prepend-icon": "mdi-calendar",
                      label: "Importer dans RH sur un samedi",
                      color: _vm.getTurquoisePoupin,
                    },
                    on: { change: _vm.setSamedi },
                  })
                : _vm._e(),
              (_vm.isAdmin || _vm.isGestionnaire) &&
              _vm.getCurrentJournee.journeeCorrigee &&
              _vm.getCurrentJournee.journeeCorrigee.samedi
                ? _c("v-select", {
                    attrs: {
                      label: "Choisir un Samedi",
                      "prepend-icon": "mdi-alpha-s-circle",
                      items: _vm.getListeSamedi,
                      "item-text": "format",
                      "item-value": "samedi",
                    },
                    on: { input: _vm.setDateRH },
                  })
                : _vm._e(),
              _c("v-divider", { staticClass: "mt-3" }),
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1 mt-4",
                      attrs: { color: "black", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v(" Annuler ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1 mt-4",
                      attrs: {
                        type: "submit",
                        outlined: "",
                        disabled: !_vm.valid,
                        color: _vm.getTurquoisePoupin,
                      },
                    },
                    [_vm._v(" Enregistrer ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout, centered: "" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "blue", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.snack = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Fermer ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snack,
            callback: function ($$v) {
              _vm.snack = $$v
            },
            expression: "snack",
          },
        },
        [_vm._v(" " + _vm._s(_vm.errText) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }