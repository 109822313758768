var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _vm.isConnected ? _c("x-nav") : _vm._e(),
      _c(
        "v-main",
        { staticStyle: { "min-width": "100%" } },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }