import Vue from "vue";
import _ from "lodash";
import Swal from "sweetalert2";
/*import router from "../../router";*/

const state = {
  panier: [],
  message:
    "Bonjour, Pour compléter le stock du camion nous aurions besoin des articles suivants. Merci",
  treeview: [
    {
      id: 2,
      name: "Silicone, ciment",
      img: "etancheite/silicone.jpg",
      children: [
        {
          id: 4,
          name: "Silicone Blanc poche - FS125 (carton)",
          quantite: 0,
          img: "etancheite/silicone_poche.webp",
          ref_fournisseur: "335276",
        },
        {
          id: 493,
          name: "Silicone Noir poche - FS125 (carton)",
          quantite: 0,
          img: "etancheite/silicone_poche.webp",
          ref_fournisseur: "333052",
        },
        {
          id: 5,
          name: "Silicone Blanc cartouche - FS125 (carton)",
          quantite: 0,
          img: "etancheite/silicone.jpg",
          ref_fournisseur: "334680",
        },
        {
          id: 500,
          name: "Silicone Blanc cartouche - FA101 (carton)",
          quantite: 0,
          img: "etancheite/silicone.jpg",
          ref_fournisseur: "334680",
        },
        {
          id: 6,
          name: "Silicone Blanc acrylique - LD704 - intérieur (carton)",
          quantite: 0,
          img: "etancheite/silicone.jpg",
          ref_fournisseur: "",
        },
        {
          id: 601,
          name: "Silicone Blanc acrylique - LD705 - extérieur (carton)",
          quantite: 0,
          img: "etancheite/silicone.jpg",
          ref_fournisseur: "",
        },
        {
          id: 7,
          name: "Silicone Noir cartouche - FS125 (carton)",
          quantite: 0,
          img: "etancheite/silicone.jpg",
          ref_fournisseur: "396412",
        },
        {
          id: 700,
          name: "Silicone Noir cartouche - FA101 (carton)",
          quantite: 0,
          img: "etancheite/silicone.jpg",
          ref_fournisseur: "396412",
        },
        {
          id: 8,
          name: "Silicone Transparent - FA101 (carton)",
          quantite: 0,
          img: "etancheite/silicone.jpg",
          ref_fournisseur: "334674",
        },
        {
          id: 9,
          name: "Silicone Ivoire - FA101 (carton)",
          quantite: 0,
          img: "etancheite/silicone.jpg",
          ref_fournisseur: "328387",
        },
        {
          id: 10,
          name: "Silicone Ton pierre - FA101 (carton)",
          quantite: 0,
          img: "etancheite/silicone.jpg",
          ref_fournisseur: "500007",
        },
        {
          id: 11,
          name: "Silicone Gris - FA101 (carton)",
          quantite: 0,
          img: "etancheite/silicone.jpg",
          ref_fournisseur: "328329",
        },
        {
          id: 12,
          name: "Silicone Gris anthracite - FA101 (carton)",
          quantite: 0,
          img: "etancheite/silicone.jpg",
          ref_fournisseur: "390209",
        },
        {
          id: 17,
          name: "Mousse PU (cartouche)",
          quantite: 0,
          img: "etancheite/mousse_pu.png",
          ref_fournisseur: "334024",
        },
        {
          id: 21,
          name: "Ciment rapide Berner Gris (cartouche)",
          quantite: 0,
          img: "etancheite/ciment_rapide_gris_berner.webp",
          ref_fournisseur: "372625",
        },
        {
          id: 22,
          name: "Ciment rapide Berner Ton pierre (cartouche)",
          quantite: 0,
          img: "etancheite/ciment_rapide_gris_berner.webp",
          ref_fournisseur: "373008",
        },
        {
          id: 220,
          name: "Ciment rapide CREPIX blanc (cartouche)",
          quantite: 0,
          img: "etancheite/colle_crepix.jpg",
          ref_fournisseur: "373008",
        },
        {
          id: 27,
          name: "Produit de lissage (unite)",
          quantite: 0,
          img: "etancheite/produit_de_lissage.jpg",
          ref_fournisseur: "3206338",
        },
      ],
    },
    {
      id: 256,
      name: "Colle",
      img: "colles/colle_sp_50.png",
      children: [
        {
          id: 14,
          name: "Colle SP 50 Noir (cartouche)",
          quantite: 0,
          img: "colles/colle_sp_50.png",
          ref_fournisseur: "378319",
        },
        {
          id: 15,
          name: "Colle SP 50 Blanc (cartouche)",
          quantite: 0,
          img: "colles/colle_sp_50.png",
          ref_fournisseur: "378317",
        },
        {
          id: 16,
          name: "Colle SP 50 Crystal (cartouche)",
          quantite: 0,
          img: "colles/colle_sp_50.png",
          ref_fournisseur: "378295",
        },
        {
          id: 150,
          name: "Colle SP 30 Translucide (cartouche)",
          quantite: 0,
          img: "colles/colle_sp_50.png",
          ref_fournisseur: "378317",
        },
        {
          id: 18,
          name: "Colle PU escalier (cartouche)",
          quantite: 0,
          img: "colles/colle_pu_legallais.jpg",
          ref_fournisseur: "",
        },
        {
          id: 19,
          name: "Mastic colle PU40 blanc Berner (cartouche)",
          quantite: 0,
          img: "colles/mastic_colle_blanc_berner.webp",
          ref_fournisseur: "183781",
        },
        {
          id: 191,
          name: "Mastic colle PU40 brun Berner (cartouche)",
          quantite: 0,
          img: "colles/colle_mastic_berner_brun.webp",
          ref_fournisseur: "183931",
        },
        {
          id: 192,
          name: "Colle cyanoacrylate rapide (unite)",
          quantite: 0,
          img: "colles/colle_cyano.png",
          ref_fournisseur: "CA9S",
        },
        {
          id: 193,
          name: "Activateur de colle (unite)",
          quantite: 0,
          img: "colles/activateur_colle.png",
          ref_fournisseur: "5110",
        },
      ],
    },
    {
      id: 23,
      name: "Nettoyage",
      img: "nettoyage/produit_nettoyage_vert.jpg",
      children: [
        {
          id: 24,
          name: "Produit vert (unite)",
          quantite: 0,
          img: "nettoyage/produit_nettoyage_vert.jpg",
          ref_fournisseur: "",
        },
        {
          id: 25,
          name: "Produit bleu (unite)",
          quantite: 0,
          img: "nettoyage/produit_nettoyage_bleu.webp",
          ref_fournisseur: "",
        },
        {
          id: 255,
          name: "Nettoyant pistolet mousse PU AA290 (unite)",
          quantite: 0,
          img: "nettoyage/nettoyant_pistolet_AA290.png",
          ref_fournisseur: "325717",
        },
        {
          id: 26,
          name: "Carton bobine d’essuyage (unite)",
          quantite: 0,
          img: "nettoyage/carton_bobine_essuyage.jpeg",
          ref_fournisseur: "9000469173",
        },
      ],
    },
    {
      id: 28,
      name: "Lubrifiants",
      img: "lube/eccosil.png",
      children: [
        {
          id: 29,
          name: "eccosil lubrifiant silicone (unite)",
          quantite: 0,
          img: "lube/eccosil(zoom).png",
          ref_fournisseur: "30T30490",
        },
        {
          id: 30,
          name: "exalub lubrifiant de synthèse (unite)",
          quantite: 0,
          img: "lube/exalub(zoom).png",
          ref_fournisseur: "30S10290",
        },
        {
          id: 31,
          name: "flonix graisse (unite)",
          quantite: 0,
          img: "lube/flonix(zoom).png",
          ref_fournisseur: "30S10690",
        },
      ],
    },
    {
      id: 32,
      name: "Compribande",
      img: "compriband/compriband_illmod_600.jpg",
      children: [
        {
          id: 33,
          name: "Acryl 300 (Rouleau 18m)",
          quantite: 0,
          img: "compriband/compriband_acryl_300.jpg",
          ref_fournisseur: "300079",
        },
        {
          id: 34,
          name: "Illmod TP600 12/5-11 (Rouleau 5m60)",
          quantite: 0,
          img: "compriband/compriband_illmod_600.jpg",
          ref_fournisseur: "318800",
        },
        {
          id: 341,
          name: "Illmod TP600 20/7-14 (Rouleau 4m30)",
          quantite: 0,
          img: "compriband/compriband_illmod_600.jpg",
          ref_fournisseur: "318826",
        },
      ],
    },
    {
      id: 35,
      name: "Cales",
      img: "cales/cale_plate.png",
      children: [
        {
          id: 37,
          name: "Cales Fourchette 1mm (recharge)",
          quantite: 0,
          img: "cales/cale_fourchette.webp",
          ref_fournisseur: "310044",
        },
        {
          id: 38,
          name: "Cales Fourchette 3mm (recharge)",
          quantite: 0,
          img: "cales/cale_fourchette.webp",
          ref_fournisseur: "310046",
        },
        {
          id: 39,
          name: "Cales Fourchette 5mm (recharge)",
          quantite: 0,
          img: "cales/cale_fourchette.webp",
          ref_fournisseur: "310048",
        },
        {
          id: 41,
          name: "Cales Plate 1mm (recharge)",
          quantite: 0,
          img: "cales/cale_plate.png",
          ref_fournisseur: "300040",
        },
        {
          id: 42,
          name: "Cales Plate 2mm (recharge)",
          quantite: 0,
          img: "cales/cale_plate.png",
          ref_fournisseur: "300041",
        },
        {
          id: 43,
          name: "Cales Plate 3mm (recharge)",
          quantite: 0,
          img: "cales/cale_plate.png",
          ref_fournisseur: "300042",
        },
        {
          id: 44,
          name: "Cales Plate 4mm (recharge)",
          quantite: 0,
          img: "cales/cale_plate.png",
          ref_fournisseur: "300043",
        },
        {
          id: 45,
          name: "Cales Plate 5mm (recharge)",
          quantite: 0,
          img: "cales/cale_plate.png",
          ref_fournisseur: "300044",
        },
        {
          id: 46,
          name: "Cale en coin orange (recharge)",
          quantite: 0,
          img: "cales/cale_orange.jpg",
          ref_fournisseur: "KDSOM8F",
        },
        {
          id: 460,
          name: "Mallette assortiment de cale fourchette",
          quantite: 0,
          img: "cales/malette_calefourchette.jpg",
          ref_fournisseur: "KDSOM8F",
        },
        {
          id: 461,
          name: "Mallette assortiment de cale plate",
          quantite: 0,
          img: "cales/malette_caleplate.jpg",
          ref_fournisseur: "KDSOM8F",
        },
      ],
    },
    {
      id: 47,
      name: "Scotch papier",
      img: "scotch/scotch.jpg",
      children: [
        {
          id: 48,
          name: "Scotch papier 25mm (unite)",
          quantite: 0,
          img: "scotch/scotch.jpg",
          ref_fournisseur: "34010001",
        },
        {
          id: 49,
          name: "Scotch papier 38mm (unite)",
          quantite: 0,
          img: "scotch/scotch.jpg",
          ref_fournisseur: "34010002",
        },
        {
          id: 50,
          name: "Scotch papier 48mm (unite)",
          quantite: 0,
          img: "scotch/scotch.jpg",
          ref_fournisseur: "34010003",
        },
        {
          id: 501,
          name: "Scotch orange PVC (unite)",
          quantite: 0,
          img: "scotch/scotch_orange.webp",
          ref_fournisseur: "",
        },
        {
          id: 502,
          name: "Scotch havane carton (unite)",
          quantite: 0,
          img: "scotch/scotch_marron.jpg",
          ref_fournisseur: "",
        },
      ],
    },
    {
      id: 51,
      name: "Disques",
      img: "disque_meuleuse/disque_meuleuse_alu.jpeg",
      children: [
        {
          id: 52,
          name: "Disque à tronçonner Acier (unite)",
          quantite: 0,
          img: "disque_meuleuse/disque_meuleuse_inox.png",
          ref_fournisseur: "",
        },
        {
          id: 53,
          name: "Disque à tronçonner Aluminium (unite)",
          quantite: 0,
          img: "disque_meuleuse/disque_meuleuse_alu.jpeg",
          ref_fournisseur: "",
        },
        {
          id: 531,
          name: "Disque Diamant Beton (unite)",
          quantite: 0,
          img: "disque_meuleuse/disque_meuleuse_diamant_beton.png",
          ref_fournisseur: "",
        },
        {
          id: 532,
          name: "Disque Diamant Carrelage (unite)",
          quantite: 0,
          img: "disque_meuleuse/disque_meuleuse_diamant_carelage.png",
          ref_fournisseur: "",
        },
      ],
    },
    {
      id: 61,
      name: "Lames",
      img: "lames/scie_sabre/lame_scie_sabre_bois_617K.png",
      children: [
        {
          id: 63,
          name: "Lames Cutter",
          quantite: 0,
          img: "lames/lame_cutter.jpeg",
          children: [
            {
              id: 631,
              name: "Lames Cutter 18mm (Par 10)",
              quantite: 0,
              img: "lames/lame_cutter.jpeg",
              ref_fournisseur: "021500033",
            },
            {
              id: 632,
              name: "Lames Cutter 25mm (Par 10)",
              quantite: 0,
              img: "lames/lame_cutter.jpeg",
              ref_fournisseur: "106431",
            },
          ],
        },
        {
          id: 661,
          name: "Scie sabre",
          quantite: 0,
          img: "lames/scie_sabre/lame_scie_sabre_bois_617K.png",
          children: [
            {
              id: 66,
              name: "Lames Scie sabre Bois S617K (paquet)",
              quantite: 0,
              img: "lames/scie_sabre/lame_scie_sabre_bois_617K.png",
              ref_fournisseur: "4000837550",
            },
            {
              id: 67,
              name: "Lames Scie sabre Bois/Métal 611DF (paquet)",
              quantite: 0,
              img: "lames/scie_sabre/lame_scie_sabre_bois_metal_611DF.jpg",
              ref_fournisseur: "258067/6327460060/4000837542",
            },
            {
              id: 671,
              name: "Lames Scie sabre Bois/Métal 922VF (paquet)",
              quantite: 0,
              img: "lames/scie_sabre/lame_scie_sabre_bois_metal_922VF.png",
              ref_fournisseur: "4000837546",
            },
            {
              id: 672,
              name: "Lames Scie sabre Bois/Métal 3456XF (paquet)",
              quantite: 0,
              img: "lames/scie_sabre/lame_scie_sabre_bois_metal_3456XF.png",
              ref_fournisseur: "6327460066/4000837549",
            },
            {
              id: 68,
              name: "Lames Scie sabre Métal 922AF (paquet)",
              quantite: 0,
              img: "lames/scie_sabre/lame_scie_sabre_metal_922AF.png",
              ref_fournisseur: "240482/6327460045/4000837556",
            },
            {
              id: 681,
              name: "Lames Scie sabre Métal 922BF (paquet)",
              quantite: 0,
              img: "lames/scie_sabre/lame_scie_sabre_metal_922BF.png",
              ref_fournisseur: "4000837554/6327460025",
            },
            {
              id: 682,
              name: "Lames Scie sabre Métal 1122BF (paquet)",
              quantite: 0,
              img: "lames/scie_sabre/lame_scie_sabre_metal_1122BF.png",
              ref_fournisseur: "",
            },
            {
              id: 683,
              name: "Lames Scie sabre Platre S641HM (paquet)",
              quantite: 0,
              img: "lames/scie_sabre/lame_scie_sabre_platre_641HM.png",
              ref_fournisseur: "4000837559",
            },
            {
              id: 684,
              name: "Lames Scie sabre Brique 150mm 3040/150HM (paquet)",
              quantite: 0,
              img: "lames/scie_sabre/lame_scie_sabre_brique_3040-150HM.png",
              ref_fournisseur: "211611500001",
            },
            {
              id: 685,
              name: "Lames Scie sabre Brique 225mm 3041/225HM (paquet)",
              quantite: 0,
              img: "lames/scie_sabre/lame_scie_sabre_brique_3041-225HM.png",
              ref_fournisseur: "211622200001",
            },
          ],
        },
        {
          id: 701,
          name: "Scie FEIN",
          quantite: 0,
          img: "lames/lame_scie_fein.webp",
          children: [
            {
              id: 70,
              name: "Lames Scie FEIN Bois FM5110-12 (unite)",
              quantite: 0,
              img: "lames/lame_scie_fein.webp",
              ref_fournisseur: "",
            },
            {
              id: 71,
              name: "Lames Scie FEIN Métal/Plastique FM5108-12 (unite)",
              quantite: 0,
              img: "lames/lame_scie_fein.webp",
              ref_fournisseur: "",
            },
            {
              id: 731,
              name: "Lames Scie FEIN Bois SP5110-12 (unite)",
              quantite: 0,
              img: "lames/lame_scie_fein02.webp",
              ref_fournisseur: "",
            },
            {
              id: 741,
              name: "Lames Scie FEIN Métal SP5108-12 (unite)",
              quantite: 0,
              img: "lames/lame_scie_fein02.webp",
              ref_fournisseur: "",
            },
            {
              id: 751,
              name: "Lames Scie FEIN Métal cuvette 69109 (unite)",
              quantite: 0,
              img: "lames/lame_fein_ot13.webp",
              ref_fournisseur: "",
            },
            {
              id: 761,
              name: "Lames Scie FEIN Bois cuvette 69095 (unite)",
              quantite: 0,
              img: "lames/lame_fein_otk14.webp",
              ref_fournisseur: "",
            },
          ],
        },
        {
          id: 710,
          name: "Scie sauteuse",
          quantite: 0,
          img: "lames/scie_sauteuse/sauteuse_bois_T101BF.png",
          children: [
            {
              id: 711,
              name: "Lames Scie sauteuse Bois Bosch T101BF (unite)",
              quantite: 0,
              img: "lames/scie_sauteuse/sauteuse_bois_T101BF.png",
              ref_fournisseur: "4000837517",
            },
            {
              id: 712,
              name: "Lames Scie sauteuse Bois Bosch T101BR (unite)",
              quantite: 0,
              img: "lames/scie_sauteuse/sauteuse_bois_T101BR.png",
              ref_fournisseur: "4000837508",
            },
            {
              id: 713,
              name: "Lames Scie sauteuse Bois Wilpu HC123STAR (unite)",
              quantite: 0,
              img: "lames/scie_sauteuse/sauteuse_bois_HC123STAR.png",
              ref_fournisseur: "200210500005",
            },
            {
              id: 714,
              name: "Lames Scie sauteuse Bois et Métal Bosch T345XF (unite)",
              quantite: 0,
              img: "lames/scie_sauteuse/sauteuse_bois_metal_T345XF.png",
              ref_fournisseur: "4000837518",
            },
            {
              id: 715,
              name: "Lames Scie sauteuse Métal Bosch T118G (unite)",
              quantite: 0,
              img: "lames/scie_sauteuse/sauteuse_metal_T118G.png",
              ref_fournisseur: "4000837520",
            },
            {
              id: 716,
              name: "Lames Scie sauteuse Métal Bosch T318A (unite)",
              quantite: 0,
              img: "lames/scie_sauteuse/sauteuse_metal_T318A.png",
              ref_fournisseur: "",
            },
            {
              id: 717,
              name: "Lames Scie sauteuse Métal Bosch T318B (unite)",
              quantite: 0,
              img: "lames/scie_sauteuse/sauteuse_metal_T318B.png",
              ref_fournisseur: "4000837525",
            },
            {
              id: 718,
              name: "Lames Scie sauteuse Métal Wilpu MG1014BI (unite)",
              quantite: 0,
              img: "lames/scie_sauteuse/sauteuse_metal_MG1014BI.png",
              ref_fournisseur: "200262100005",
            },
          ],
        },
      ],
    },
    {
      id: 72,
      name: "Forets",
      img: "forêt.jpg",
      children: [
        {
          id: 73,
          name: "Forêts à Béton",
          quantite: 0,
          img: "forêt.jpg",
          children: [
            {
              id: 760,
              name: "Forêts à Béton 6mm (210) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 761,
              name: "Forêts à Béton 6mm (260) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "291279",
            },
            {
              id: 762,
              name: "Forêts à Béton 6mm (310) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "284660",
            },
            {
              id: 763,
              name: "Forêts à Béton 6,5mm (210) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "32074",
            },
            {
              id: 764,
              name: "Forêts à Béton 6,5mm (260) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 765,
              name: "Forêts à Béton 6,5mm (310) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "32073",
            },
            {
              id: 766,
              name: "Forêts à Béton 7mm (260) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 767,
              name: "Forêts à Béton 7mm (310) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 768,
              name: "Forêts à Béton 8mm (260) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "32057",
            },
            {
              id: 769,
              name: "Forêts à Béton 8mm (310) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "36686",
            },
            {
              id: 770,
              name: "Forêts à Béton 10mm (210) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "32060",
            },
            {
              id: 771,
              name: "Forêts à Béton 10mm (260) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 772,
              name: "Forêts à Béton 10mm (310) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "32062",
            },
            {
              id: 773,
              name: "Forêts à Béton 12mm (210) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 774,
              name: "Forêts à Béton 12mm (310) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 775,
              name: "Forêts à Béton 14mm (210) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 776,
              name: "Forêts à Béton 14mm (310) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "36691",
            },
            {
              id: 777,
              name: "Forêts à Béton 16mm (310) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "36699",
            },
            {
              id: 778,
              name: "Forêts à Béton 18mm (260) (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
          ],
        },
        {
          id: 74,
          name: "Forêts à Métaux ",
          quantite: 0,
          img: "forêt.jpg",
          children: [
            {
              id: 741,
              name: "Forêts à Métaux 2mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "211314",
            },
            {
              id: 742,
              name: "Forêts à Métaux 2,5mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 743,
              name: "Forêts à Métaux 3mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 744,
              name: "Forêts à Métaux 3,5mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 745,
              name: "Forêts à Métaux 4mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 746,
              name: "Forêts à Métaux 4,5mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 747,
              name: "Forêts à Métaux 5mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 748,
              name: "Forêts à Métaux 5,5mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 749,
              name: "Forêts à Métaux 6mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 750,
              name: "Forêts à Métaux 6,5mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 751,
              name: "Forêts à Métaux 7mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 752,
              name: "Forêts à Métaux 7,5mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 753,
              name: "Forêts à Métaux 8mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 754,
              name: "Forêts à Métaux 8,5mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 755,
              name: "Forêts à Métaux 9mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 756,
              name: "Forêts à Métaux 9,5mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 757,
              name: "Forêts à Métaux 10mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 758,
              name: "Forêts à Métaux 10,5mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
            {
              id: 759,
              name: "Forêts à Métaux LONG Réno 6,5mm (unite)",
              quantite: 0,
              img: "forêt.jpg",
              ref_fournisseur: "",
            },
          ],
        },
      ],
    },
    {
      id: 82,
      name: "Fixations",
      img: "vis/vis.jpeg",
      children: [
        {
          id: 821,
          name: "Visserie",
          img: "vis/vis.jpeg",
          children: [
            {
              id: 83,
              name: "Vis à bois",
              img: "vis/vis-bois.jpg",
              children: [
                {
                  id: 85,
                  name: "Vis à bois < 3mm",
                  img: "vis/vis-bois.jpg",
                  children: [
                    {
                      id: 84,
                      name: "Vis à bois 2,5x12 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 86,
                      name: "Vis à bois 3x16 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 87,
                      name: "Vis à bois 3x20 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 88,
                      name: "Vis à bois 3x25 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 89,
                      name: "Vis à bois 3x30 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 90,
                      name: "Vis à bois 3x35 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 91,
                      name: "Vis à bois 3x40 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 92,
                      name: "Vis à bois 3x45 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                  ],
                },
                {
                  id: 93,
                  name: "Vis à bois 3,5mm",
                  img: "vis/vis-bois.jpg",
                  children: [
                    {
                      id: 94,
                      name: "Vis à bois 3,5x13 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 95,
                      name: "Vis à bois 3,5x16 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 96,
                      name: "Vis à bois 3,5x20 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 97,
                      name: "Vis à bois 3,5x25 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 98,
                      name: "Vis à bois 3,5x30 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 99,
                      name: "Vis à bois 3,5x35 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 100,
                      name: "Vis à bois 3,5x40 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 101,
                      name: "Vis à bois 3,5x45 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 102,
                      name: "Vis à bois 3,5x50 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                  ],
                },
                {
                  id: 103,
                  name: "Vis à bois 4mm",
                  img: "vis/vis-bois.jpg",
                  children: [
                    {
                      id: 104,
                      name: "Vis à bois 4x16 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 105,
                      name: "Vis à bois 4x20 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 106,
                      name: "Vis à bois 4x25 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 107,
                      name: "Vis à bois 4x30 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 108,
                      name: "Vis à bois 4x35 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 109,
                      name: "Vis à bois 4x40 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 110,
                      name: "Vis à bois 4x45 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 111,
                      name: "Vis à bois 4x50 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 112,
                      name: "Vis à bois 4x55 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 113,
                      name: "Vis à bois 4x60 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 114,
                      name: "Vis à bois 4x65 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 115,
                      name: "Vis à bois 4x70 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 116,
                      name: "Vis à bois 4x75 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 117,
                      name: "Vis à bois 4x80 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                  ],
                },
                {
                  id: 118,
                  name: "Vis à bois 4,5mm",
                  img: "vis/vis-bois.jpg",
                  children: [
                    {
                      id: 119,
                      name: "Vis à bois 4,5x30 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 120,
                      name: "Vis à bois 4,5x35 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 121,
                      name: "Vis à bois 4,5x40 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 122,
                      name: "Vis à bois 4,5x45 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 123,
                      name: "Vis à bois 4,5x50 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 124,
                      name: "Vis à bois 4,5x55 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 125,
                      name: "Vis à bois 4,5x60 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                  ],
                },
                {
                  id: 126,
                  name: "Vis à bois 5mm",
                  img: "vis/vis-bois.jpg",
                  children: [
                    {
                      id: 127,
                      name: "Vis à bois 5x25 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 128,
                      name: "Vis à bois 5x30 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 129,
                      name: "Vis à bois 5x40 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 130,
                      name: "Vis à bois 5x50 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 131,
                      name: "Vis à bois 5x60 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 132,
                      name: "Vis à bois 5x70 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 133,
                      name: "Vis à bois 5x80 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 134,
                      name: "Vis à bois 5x90 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 135,
                      name: "Vis à bois 5x100 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 136,
                      name: "Vis à bois 5x110 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 137,
                      name: "Vis à bois 5x120 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                  ],
                },
                {
                  id: 138,
                  name: "Vis à bois 6 mm",
                  img: "vis/vis-bois.jpg",
                  children: [
                    {
                      id: 139,
                      name: "Vis à bois 6x50 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 140,
                      name: "Vis à bois 6x60 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 141,
                      name: "Vis à bois 6x70 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 142,
                      name: "Vis à bois 6x80 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 143,
                      name: "Vis à bois 6x90 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 144,
                      name: "Vis à bois 6x100 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 145,
                      name: "Vis à bois 6x110 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 146,
                      name: "Vis à bois 6x120 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 147,
                      name: "Vis à bois 6x130 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                    {
                      id: 148,
                      name: "Vis à bois 6x140 (Boite)",
                      quantite: 0,
                      img: "vis/vis-bois.jpg",
                      ref_fournisseur: "",
                    },
                  ],
                },
              ],
            },
            {
              id: 149,
              name: "Vis penture",
              img: "vis/vis-penture.webp",
              children: [
                {
                  id: 151,
                  name: "Vis penture Noire 6x20 (Boite)",
                  quantite: 0,
                  img: "vis/vis-penture.webp",
                  ref_fournisseur: "",
                },
                {
                  id: 152,
                  name: "Vis penture Noire 6x30 (Boite)",
                  quantite: 0,
                  img: "vis/vis-penture.webp",
                  ref_fournisseur: "",
                },
                {
                  id: 153,
                  name: "Vis penture Noire 6x40 (Boite)",
                  quantite: 0,
                  img: "vis/vis-penture.webp",
                  ref_fournisseur: "",
                },
                {
                  id: 154,
                  name: "Vis penture Noire 6x50 (Boite)",
                  quantite: 0,
                  img: "vis/vis-penture.webp",
                  ref_fournisseur: "",
                },
                {
                  id: 155,
                  name: "Vis penture Noire 6x60 (Boite)",
                  quantite: 0,
                  img: "vis/vis-penture.webp",
                  ref_fournisseur: "",
                },
                {
                  id: 156,
                  name: "Vis penture Noire 7x70 (Boite)",
                  quantite: 0,
                  img: "vis/vis-penture.webp",
                  ref_fournisseur: "",
                },

                {
                  id: 158,
                  name: "Vis penture Zingué 6x20 (Boite)",
                  quantite: 0,
                  img: "vis/vis-penture_zingue.webp",
                  ref_fournisseur: "",
                },
                {
                  id: 159,
                  name: "Vis penture Zingué 6x30 (Boite)",
                  quantite: 0,
                  img: "vis/vis-penture_zingue.webp",
                  ref_fournisseur: "",
                },
                {
                  id: 160,
                  name: "Vis penture Zingué 6x40 (Boite)",
                  quantite: 0,
                  img: "vis/vis-penture_zingue.webp",
                  ref_fournisseur: "",
                },
                {
                  id: 161,
                  name: "Vis penture Zingué 6x50 (Boite)",
                  quantite: 0,
                  img: "vis/vis-penture_zingue.webp",
                  ref_fournisseur: "",
                },
                {
                  id: 162,
                  name: "Vis penture Zingué 7x70 (Boite)",
                  quantite: 0,
                  img: "vis/vis-penture_zingue.webp",
                  ref_fournisseur: "",
                },

                {
                  id: 164,
                  name: "Vis penture Inox 6x20 (Boite)",
                  quantite: 0,
                  img: "vis/vis_penture_inox.jpg",
                  ref_fournisseur: "",
                },
                {
                  id: 165,
                  name: "Vis penture Inox 6x30 (Boite)",
                  quantite: 0,
                  img: "vis/vis_penture_inox.jpg",
                  ref_fournisseur: "",
                },
                {
                  id: 166,
                  name: "Vis penture Inox 6x40 (Boite)",
                  quantite: 0,
                  img: "vis/vis_penture_inox.jpg",
                  ref_fournisseur: "",
                },
                {
                  id: 167,
                  name: "Vis penture Inox 6x50 (Boite)",
                  quantite: 0,
                  img: "vis/vis_penture_inox.jpg",
                  ref_fournisseur: "",
                },
              ],
            },
            {
              id: 168,
              name: "Vis réno",
              img: "vis/vis-reno.jpg",
              children: [
                {
                  id: 169,
                  name: "Vis réno 7,5x62 (Boite)",
                  quantite: 0,
                  img: "vis/vis-reno.jpg",
                  ref_fournisseur: "",
                },
                {
                  id: 170,
                  name: "Vis réno 7,5x72 (Boite)",
                  quantite: 0,
                  img: "vis/vis-reno.jpg",
                  ref_fournisseur: "",
                },
                {
                  id: 171,
                  name: "Vis réno 7,5x82 (Boite)",
                  quantite: 0,
                  img: "vis/vis-reno.jpg",
                  ref_fournisseur: "",
                },
                {
                  id: 172,
                  name: "Vis réno 7,5x92 (Boite)",
                  quantite: 0,
                  img: "vis/vis-reno.jpg",
                  ref_fournisseur: "",
                },
                {
                  id: 173,
                  name: "Vis réno 7,5x112 (Boite)",
                  quantite: 0,
                  img: "vis/vis-reno.jpg",
                  ref_fournisseur: "",
                },
                {
                  id: 174,
                  name: "Vis réno 7,5x132 (Boite)",
                  quantite: 0,
                  img: "vis/vis-reno.jpg",
                  ref_fournisseur: "",
                },
                {
                  id: 175,
                  name: "Vis réno 7,5x152 (Boite)",
                  quantite: 0,
                  img: "vis/vis-reno.jpg",
                  ref_fournisseur: "",
                },
                {
                  id: 176,
                  name: "Vis réno 7,5x182 (Boite)",
                  quantite: 0,
                  img: "vis/vis-reno.jpg",
                  ref_fournisseur: "",
                },
                {
                  id: 177,
                  name: "Vis réno 7,5x212 (Boite)",
                  quantite: 0,
                  img: "vis/vis-reno.jpg",
                  ref_fournisseur: "",
                },
              ],
            },
            {
              id: 1698,
              name: "Vis sous-face",
              img: "vis/vis_sous_face.jpg",
              children: [
                {
                  id: 1699,
                  name: "Vis sous-face RAL 9016 4,3x25 (Boite)",
                  quantite: 0,
                  img: "vis/vis_sous_face.jpg",
                  ref_fournisseur: "",
                },
                {
                  id: 1700,
                  name: "Vis sous-face RAL 9016 4,3x30 (Boite)",
                  quantite: 0,
                  img: "vis/vis_sous_face.jpg",
                  ref_fournisseur: "",
                },
              ],
            },
          ],
        },
        {
          id: 75,
          name: "Chevilles",
          img: "fixations/chevilles/cheville_tout_materiaux.webp",
          children: [
            {
              id: 77,
              name: "Chevilles Tous matériaux Berner Diam. 6 (Recharge)",
              quantite: 0,
              img: "fixations/chevilles/cheville_tout_materiaux.webp",
              ref_fournisseur: "",
            },
            {
              id: 78,
              name: "Chevilles Tous matériaux Berner Diam. 8 (Recharge)",
              quantite: 0,
              img: "fixations/chevilles/cheville_tout_materiaux.webp",
              ref_fournisseur: "",
            },
            {
              id: 80,
              name: "Chevilles Rouges Diam. 6 (Boite de 100)",
              quantite: 0,
              img: "fixations/chevilles/cheville_rouge.jpg",
              ref_fournisseur: "",
            },
            {
              id: 81,
              name: "Chevilles Rouges Diam. 8 (Boite de 100)",
              quantite: 0,
              img: "fixations/chevilles/cheville_rouge.jpg",
              ref_fournisseur: "",
            },
            {
              id: 811,
              name: "Chevilles Molly M4x34mm",
              quantite: 0,
              img: "fixations/chevilles/cheville_molly.png",
              ref_fournisseur: "",
            },
            {
              id: 812,
              name: "Chevilles Molly M6x33mm",
              quantite: 0,
              img: "fixations/chevilles/cheville_molly.png",
              ref_fournisseur: "",
            },
          ],
        },
        {
          id: 54,
          name: "Pointe pistolet",
          img: "fixations/pointe_pistolet.jpg",
          children: [
            {
              id: 55,
              name: "Pointe pistolet 15mm (barette)",
              quantite: 0,
              img: "fixations/pointe_pistolet.jpg",
              ref_fournisseur: "",
            },
            {
              id: 56,
              name: "Pointe pistolet 20mm (barette)",
              quantite: 0,
              img: "fixations/pointe_pistolet.jpg",
              ref_fournisseur: "",
            },
            {
              id: 57,
              name: "Pointe pistolet 30mm (barette)",
              quantite: 0,
              img: "fixations/pointe_pistolet.jpg",
              ref_fournisseur: "",
            },
            {
              id: 58,
              name: "Pointe pistolet 38mm (barette)",
              quantite: 0,
              img: "fixations/pointe_pistolet.jpg",
              ref_fournisseur: "",
            },
            {
              id: 59,
              name: "Pointe pistolet 45mm (barette)",
              quantite: 0,
              img: "fixations/pointe_pistolet.jpg",
              ref_fournisseur: "",
            },
            {
              id: 60,
              name: "Pointe pistolet 56mm (barette)",
              quantite: 0,
              img: "fixations/pointe_pistolet.jpg",
              ref_fournisseur: "",
            },
          ],
        },
      ],
    },
    {
      id: 300,
      name: "Électricité",
      img: "electricite/prise_etanche.webp",
      children: [
        {
          id: 301,
          name: "Boite plexo 65x65x45 (unité)",
          quantite: 0,
          img: "electricite/boite_plexo.jpg",
          ref_fournisseur: "",
        },
        {
          id: 302,
          name: "Boite plexo 80x80x45 (unité)",
          quantite: 0,
          img: "electricite/boite_plexo.jpg",
          ref_fournisseur: "",
        },
        {
          id: 303,
          name: "Disjoncteur 10A (unité)",
          quantite: 0,
          img: "electricite/disjoncteur.jpg",
          ref_fournisseur: "",
        },
        {
          id: 304,
          name: "Disjoncteur 16A (unité)",
          quantite: 0,
          img: "electricite/disjoncteur.jpg",
          ref_fournisseur: "",
        },
        {
          id: 305,
          name: "Prise étanche (unité)",
          quantite: 0,
          img: "electricite/prise_etanche.webp",
          ref_fournisseur: "",
        },
        {
          id: 306,
          name: "Wago 221-412 (boite de 100)",
          quantite: 0,
          img: "electricite/wago_221-412.png",
          ref_fournisseur: "",
        },
        {
          id: 307,
          name: "Wago 221-413 (boite de 25)",
          quantite: 0,
          img: "electricite/wago_221-413.png",
          ref_fournisseur: "",
        },
        {
          id: 308,
          name: "Wago 221-415 (boite de 100)",
          quantite: 0,
          img: "electricite/wago_221-415.png",
          ref_fournisseur: "",
        },
        {
          id: 309,
          name: "Cable R2V3G 1.5 (100m)",
          quantite: 0,
          img: "electricite/cable.jpg",
          ref_fournisseur: "",
        },
        {
          id: 310,
          name: "Cable R2V3G 2.5 (100m)",
          quantite: 0,
          img: "electricite/cable.jpg",
          ref_fournisseur: "",
        },
        {
          id: 311,
          name: "Gaine diam. 16mm (100m)",
          quantite: 0,
          img: "electricite/gaine_electrique.webp",
          ref_fournisseur: "",
        },
        {
          id: 312,
          name: "Gaine diam. 20mm (100m)",
          quantite: 0,
          img: "electricite/gaine_electrique.webp",
          ref_fournisseur: "",
        },
        {
          id: 313,
          name: "Tube iro 305 N16 diam. 16mm (3m)",
          quantite: 0,
          img: "electricite/tube_iro_305.webp",
          ref_fournisseur: "",
        },
        {
          id: 314,
          name: "Tube iro 305 N16 diam. 20mm (3m)",
          quantite: 0,
          img: "electricite/tube_iro_305.webp",
          ref_fournisseur: "",
        },
        {
          id: 315,
          name: "Manchon tube iro diam. 16mm",
          quantite: 0,
          img: "electricite/manchon_tube_iro.jpeg",
          ref_fournisseur: "",
        },
        {
          id: 316,
          name: "Coude tube iro diam. 16mm",
          quantite: 0,
          img: "electricite/coude_tube_iro.jpeg",
          ref_fournisseur: "",
        },
        {
          id: 317,
          name: "Attache tube iro",
          quantite: 0,
          img: "electricite/attache_tube_iro.webp",
          ref_fournisseur: "",
        },
      ],
    },
    {
      id: 400,
      name: "Finition",
      img: "finition/papier_verre/papier_verre.webp",
      children: [
        {
          id: 511,
          name: "Papier de verre grains 80 (unite)",
          quantite: 0,
          img: "finition/papier_verre/papier_verre.webp",
          ref_fournisseur: "",
        },
        {
          id: 512,
          name: "Papier de verre grains 120 (unite)",
          quantite: 0,
          img: "finition/papier_verre/papier_verre.webp",
          ref_fournisseur: "",
        },
        {
          id: 513,
          name: "Pinceau 14 (unite)",
          quantite: 0,
          img: "finition/pinceau/pinceau_14.png",
          ref_fournisseur: "",
        },
        {
          id: 514,
          name: "Pinceau 20 (unite)",
          quantite: 0,
          img: "finition/pinceau/pinceau_20.png",
          ref_fournisseur: "",
        },
        {
          id: 515,
          name: "Pinceau 30 (unite)",
          quantite: 0,
          img: "finition/pinceau/pinceau_30.png",
          ref_fournisseur: "",
        },
        {
          id: 516,
          name: "Sac aspirateur Blanc CT/CTL/CTM 26 (ref. 496187)",
          quantite: 0,
          img: "finition/sac_aspirateur/sac_aspirateur_blanc_496187.png",
          ref_fournisseur: "",
        },
        {
          id: 517,
          name: "Sac aspirateur Beige FIS/CT 22 (ref.452970)",
          quantite: 0,
          img: "finition/sac_aspirateur/sac_aspirateur_beige_452970.png",
          ref_fournisseur: "",
        },
        {
          id: 526,
          name: "Film protecteur bande cache",
          quantite: 0,
          img: "finition/film_protecteur_bande_cache.png",
          ref_fournisseur: "",
        },
        {
          id: 518,
          name: "Sintobois 250g Sapin",
          quantite: 0,
          img: "finition/rebouchage/sintobois_250.webp",
          ref_fournisseur: "",
        },
        {
          id: 519,
          name: "Sintobois 250g Chêne clair",
          quantite: 0,
          img: "finition/rebouchage/sintobois_250.webp",
          ref_fournisseur: "",
        },
        {
          id: 520,
          name: "Sintobois 250g Sapin (prêt à l'emploi)",
          quantite: 0,
          img: "finition/rebouchage/sintobois_500.webp",
          ref_fournisseur: "",
        },
        {
          id: 529,
          name: "Sintobois 500g Sapin",
          quantite: 0,
          img: "finition/rebouchage/sintobois_500.webp",
          ref_fournisseur: "",
        },
        {
          id: 521,
          name: "Sintobois 500g Chêne clair",
          quantite: 0,
          img: "finition/rebouchage/sintobois_500.webp",
          ref_fournisseur: "",
        },
        {
          id: 522,
          name: "Sintobois 500g Acajou",
          quantite: 0,
          img: "finition/rebouchage/sintobois_500.webp",
          ref_fournisseur: "",
        },
        {
          id: 528,
          name: "Durcisseur sintobois",
          quantite: 0,
          img: "finition/rebouchage/durcisseur.webp",
          ref_fournisseur: "",
        },
        {
          id: 523,
          name: "Pâte à bois Surgand Hêtre",
          quantite: 0,
          img: "finition/rebouchage/pate_bois_surgand.webp",
          ref_fournisseur: "",
        },
        {
          id: 524,
          name: "Pâte à bois Surgand Acajou",
          quantite: 0,
          img: "finition/rebouchage/pate_bois_surgand.webp",
          ref_fournisseur: "",
        },
        {
          id: 525,
          name: "Pâte à bois Surgand Citronnier",
          quantite: 0,
          img: "finition/rebouchage/pate_bois_surgand.webp",
          ref_fournisseur: "",
        },
        {
          id: 527,
          name: "Enduit de rebouchage",
          quantite: 0,
          img: "finition/rebouchage/enduit.webp",
          ref_fournisseur: "",
        },
      ],
    },
    {
      id: 1000,
      name: "Outillage",
      img: "outillage/echelle_7m.png",
      children: [
        { id: 1001, name: "Laser", quantite: 0, img: "outillage/laser.png" },
        {
          id: 1002,
          name: "Visseuse à batterie",
          quantite: 0,
          img: "outillage/visseuse_batterie.png",
          ref_fournisseur: "",
        },
        {
          id: 1003,
          name: "Décapeur thermique",
          quantite: 0,
          img: "outillage/decapeur_thermique.png",
          ref_fournisseur: "",
        },
        {
          id: 1004,
          name: "Scie FEIN",
          quantite: 0,
          img: "outillage/scie_fein.png",
          ref_fournisseur: "",
        },
        {
          id: 1023,
          name: "Rainureuse",
          quantite: 0,
          img: "outillage/rainureuse.webp",
          ref_fournisseur: "",
        },
        {
          id: 1022,
          name: "Scie sauteuse",
          quantite: 0,
          img: "outillage/scie_sauteuse.jpg",
          ref_fournisseur: "",
        },
        {
          id: 1021,
          name: "Scie sabre",
          quantite: 0,
          img: "outillage/scie_sabre.jpg",
          ref_fournisseur: "",
        },
        {
          id: 1005,
          name: "Meuleuse filaire Ø125mm",
          quantite: 0,
          img: "outillage/meuleuse_125.png",
          ref_fournisseur: "",
        },
        {
          id: 1006,
          name: "Meuleuse filaire Ø230mm",
          quantite: 0,
          img: "outillage/meuleuse_230.webp",
          ref_fournisseur: "",
        },
        {
          id: 1007,
          name: "Rabot électrique",
          quantite: 0,
          img: "outillage/rabot_electrique.png",
          ref_fournisseur: "",
        },
        {
          id: 1026,
          name: "Burineur",
          quantite: 0,
          img: "outillage/burineur.png",
          ref_fournisseur: "",
        },
        {
          id: 1027,
          name: "Découpeuse dormants",
          quantite: 0,
          img: "outillage/decoupeuse_dormants.webp",
          ref_fournisseur: "",
        },
        {
          id: 1029,
          name: "CRAB (MACC)",
          quantite: 0,
          img: "outillage/crab_macc.webp",
          ref_fournisseur: "",
        },
        {
          id: 1009,
          name: "Escabeau 7 marches (MACC)",
          quantite: 0,
          img: "outillage/escabeau_macc.png",
          ref_fournisseur: "",
        },
        {
          id: 1010,
          name: "Echelle 3 plans 7m",
          quantite: 0,
          img: "outillage/echelle_7m.png",
          ref_fournisseur: "",
        },
        {
          id: 1011,
          name: "Lève store",
          quantite: 0,
          img: "outillage/leve store.jpg",
          ref_fournisseur: "",
        },
        {
          id: 1024,
          name: "Ventouse à main",
          quantite: 0,
          img: "outillage/ventouse_main.png",
          ref_fournisseur: "",
        },
        {
          id: 1008,
          name: "Ventouse à main sur batterie",
          quantite: 0,
          img: "outillage/ventouse_batterie.png",
          ref_fournisseur: "",
        },
        {
          id: 1012,
          name: "Ventouse pour grue camion",
          quantite: 0,
          img: "outillage/palonnier_ventouse.png",
          ref_fournisseur: "",
        },
        {
          id: 1013,
          name: "Echaffaudage larg. 1m80 haut. 7m25",
          quantite: 0,
          img: "outillage/echaffaudage.png",
          ref_fournisseur: "",
        },
        {
          id: 1014,
          name: "Echaffaudage larg. 2m85 haut. 7m50",
          quantite: 0,
          img: "outillage/echaffaudage.png",
          ref_fournisseur: "",
        },
        {
          id: 1018,
          name: "SYAM",
          quantite: 0,
          img: "outillage/syam.png",
          ref_fournisseur: "",
        },
        {
          id: 1015,
          name: "Etai (grand)",
          quantite: 0,
          img: "outillage/etai.webp",
          ref_fournisseur: "",
        },
        {
          id: 1016,
          name: "Etai (moyen)",
          quantite: 0,
          img: "outillage/etai.webp",
          ref_fournisseur: "",
        },
        {
          id: 1017,
          name: "Etai (petit)",
          quantite: 0,
          img: "outillage/etai.webp",
          ref_fournisseur: "",
        },
        {
          id: 1019,
          name: "Sacoche dalle de protection (MACC)",
          quantite: 0,
          img: "outillage/tapimac.png",
          ref_fournisseur: "",
        },
        {
          id: 1020,
          name: "Compresseur",
          quantite: 0,
          img: "outillage/compresseur.jpeg",
          ref_fournisseur: "",
        },
        {
          id: 1028,
          name: "Onduleur",
          quantite: 0,
          img: "outillage/onduleur_volets.webp",
          ref_fournisseur: "",
        },
        {
          id: 1025,
          name: "Palan",
          quantite: 0,
          img: "outillage/palan.png",
          ref_fournisseur: "",
        },
        {
          id: 1030,
          name: "Diable (lève porte garage)",
          quantite: 0,
          img: "outillage/diable_leve_porte_garage.png",
          ref_fournisseur: "",
        },
        {
          id: 1031,
          name: "Rampe de chargement 3m (1 paire)",
          quantite: 0,
          img: "outillage/rampe_chargement.png",
          ref_fournisseur: "",
        },
        {
          id: 1032,
          name: "Planché alu 3m x 0.36",
          quantite: 0,
          img: "outillage/planche_alu.png",
          ref_fournisseur: "",
        },
        {
          id: 1033,
          name: "Planché alu télescopique 3m x 0.31",
          quantite: 0,
          img: "outillage/planche_alu_telescopique.png",
          ref_fournisseur: "",
        },
        {
          id: 1034,
          name: "Meuleuse droite",
          quantite: 0,
          img: "outillage/meuleuse_droite.png",
          ref_fournisseur: "",
        },
        {
          id: 1035,
          name: "Diable à ventouses électriques",
          quantite: 0,
          img: "outillage/diable_ventouse_electrique.png",
          ref_fournisseur: "",
        },
        {
          id: 1036,
          name: "Échelle de toit alu 5 éléments L 6.60M",
          quantite: 0,
          img: "outillage/echelle_toit.png",
          ref_fournisseur: "",
        },
        {
          id: 1037,
          name: "Tapis de sécurité antidérapant (10m x 0.9m)",
          quantite: 0,
          img: "outillage/tapis_chantier.png",
          ref_fournisseur: "",
        },
        {
          id: 1038,
          name: "Tapis de sécurité antidérapant (10m x 0.2m)",
          quantite: 0,
          img: "outillage/tapis_chantier.png",
          ref_fournisseur: "",
        },
      ],
    },
    {
      id: 8200,
      name: "Autres",
      img: "autres/ecrou.jpg",
      children: [
        {
          id: 820,
          name: "Tige carré 7mm (1m)",
          quantite: 0,
          img: "autres/tige_carre.jpg",
          ref_fournisseur: "",
        },
        {
          id: 822,
          name: "Tige carré 8mm (1m)",
          quantite: 0,
          img: "autres/tige_carre.jpg",
          ref_fournisseur: "",
        },
        {
          id: 823,
          name: "Tige filetée 1m diam. 6mm Inox",
          quantite: 0,
          img: "autres/tige_filetee.jpg",
          ref_fournisseur: "",
        },
        {
          id: 824,
          name: "Tige filetée 1m diam. 8mm Inox",
          quantite: 0,
          img: "autres/tige_filetee.jpg",
          ref_fournisseur: "",
        },
        {
          id: 825,
          name: "Tige filetée 1m diam. 10mm Inox",
          quantite: 0,
          img: "autres/tige_filetee.jpg",
          ref_fournisseur: "",
        },
        {
          id: 826,
          name: "Tige filetée 1m diam. 12mm Inox",
          quantite: 0,
          img: "autres/tige_filetee.jpg",
          ref_fournisseur: "",
        },
        {
          id: 827,
          name: "Tige filetée 1m diam. 14mm Inox",
          quantite: 0,
          img: "autres/tige_filetee.jpg",
          ref_fournisseur: "",
        },
        {
          id: 828,
          name: "Tige filetée 1m diam. 6mm Zingué",
          quantite: 0,
          img: "autres/tige_filetee.jpg",
          ref_fournisseur: "",
        },
        {
          id: 829,
          name: "Tige filetée 1m diam. 8mm Zingué",
          quantite: 0,
          img: "autres/tige_filetee.jpg",
          ref_fournisseur: "",
        },
        {
          id: 830,
          name: "Tige filetée 1m diam. 10mm Zingué",
          quantite: 0,
          img: "autres/tige_filetee.jpg",
          ref_fournisseur: "",
        },
        {
          id: 831,
          name: "Tige filetée 1m diam. 12mm Zingué",
          quantite: 0,
          img: "autres/tige_filetee.jpg",
          ref_fournisseur: "",
        },
        {
          id: 832,
          name: "Tige filetée 1m diam. 14mm Zingué",
          quantite: 0,
          img: "autres/tige_filetee.jpg",
          ref_fournisseur: "",
        },
        {
          id: 833,
          name: "Rondelle diam. 6mm",
          quantite: 0,
          img: "autres/rondelle_inox.jpg",
          ref_fournisseur: "",
        },
        {
          id: 834,
          name: "Rondelle diam. 8mm",
          quantite: 0,
          img: "autres/rondelle_inox.jpg",
          ref_fournisseur: "",
        },
        {
          id: 835,
          name: "Rondelle diam. 10mm",
          quantite: 0,
          img: "autres/rondelle_inox.jpg",
          ref_fournisseur: "",
        },
        {
          id: 836,
          name: "Rondelle diam. 12mm",
          quantite: 0,
          img: "autres/rondelle_inox.jpg",
          ref_fournisseur: "",
        },
        {
          id: 837,
          name: "Ecrou diam. 6mm",
          quantite: 0,
          img: "autres/ecrou.jpg",
          ref_fournisseur: "",
        },
        {
          id: 838,
          name: "Ecrou diam. 8mm",
          quantite: 0,
          img: "autres/ecrou.jpg",
          ref_fournisseur: "",
        },
        {
          id: 839,
          name: "Ecrou diam. 10mm",
          quantite: 0,
          img: "autres/ecrou.jpg",
          ref_fournisseur: "",
        },
        {
          id: 840,
          name: "Ecrou diam. 12mm",
          quantite: 0,
          img: "autres/ecrou.jpg",
          ref_fournisseur: "",
        },
      ],
    },
    {
      id: 200,
      name: "Commande",
      img: "custom.webp",
      custom: true,
    },
  ],
  displayList: [],
  breadcrumbs: [],
  selectList: [],
  hasChildren: true,
};

const getters = {
  getPanier(state) {
    return state.panier;
  },
  getQuantitePanier: (state) => (item) => {
    const index = _.findIndex(state.panier, { id: item.id });
    if (index !== -1) {
      return state.panier[index].quantite;
    } else {
      return 0;
    }
  },
  getMessage(state) {
    return state.message;
  },
  getDisplayList(state) {
    return state.displayList;
  },
  getBreadcrumbs(state) {
    return state.breadcrumbs;
  },
  getSelectList(state) {
    return state.selectList;
  },
  hasChildren(state) {
    return state.hasChildren;
  },
};

const mutations = {
  INITIALISE_DISPLAY_LIST(state) {
    state.displayList = state.treeview;
  },
  INITIALISE_BREADCRUMBS(state) {
    state.breadcrumbs = [
      { id: 0, name: "Accueil", children: state.treeview, disabled: true },
    ];
  },
  INITIALISE_SELECT_LIST(state) {
    for (let i = 1; i < 21; i++) {
      state.selectList.push({ text: i, value: i });
    }
  },
  INITIALISE_PANIER(state) {
    if (localStorage.getItem("panier")) {
      state.panier = JSON.parse(localStorage.getItem("panier"));
    }
  },
  ADD_TO_PANIER(state, item) {
    let article = _.clone(item);
    state.panier.push(article);
    localStorage.setItem("panier", JSON.stringify(state.panier));
  },
  UPDATE_PANIER(state, data) {
    state.panier[data.index].quantite += data.quantite;
    localStorage.setItem("panier", JSON.stringify(state.panier));
  },
  REMOVE_FROM_PANIER(state, index) {
    if (index > -1) {
      state.panier.splice(index, 1);
      localStorage.setItem("panier", JSON.stringify(state.panier));
    }
  },
  EMPTY_PANIER(state) {
    state.panier = [];
    localStorage.removeItem("panier");
  },
  SET_DISPLAY_LIST(state, data) {
    state.displayList = data;
  },
  ADD_BREADCRUMB(state, data) {
    state.breadcrumbs.forEach((el) => {
      el.disabled = false;
    });
    data.disabled = true;
    state.breadcrumbs.push(data);
  },

  BACK_ONE_CRUMB(state) {
    state.breadcrumbs.length = state.breadcrumbs.length - 1;
    state.breadcrumbs.forEach((el) => {
      el.disabled = false;
    });
    state.breadcrumbs[state.breadcrumbs.length - 1].disabled = true;
    state.displayList =
      state.breadcrumbs[state.breadcrumbs.length - 1].children;
  },
  BACK_TO_CRUMB(state, data) {
    state.breadcrumbs.length = data + 1;
    state.breadcrumbs.forEach((el) => {
      el.disabled = false;
    });

    state.breadcrumbs[state.breadcrumbs.length - 1].disabled = true;
    state.displayList =
      state.breadcrumbs[state.breadcrumbs.length - 1].children;
  },
  HAS_CHILDREN(state, data) {
    if (data[0].children !== undefined) {
      state.hasChildren = true;
    } else {
      state.hasChildren = false;
    }
  },
};

const actions = {
  addToPanier({ commit, getters }, article) {
    let index = _.findIndex(getters.getPanier, { id: article.id });

    if (index === -1) {
      commit("ADD_TO_PANIER", article);
    } else {
      commit("UPDATE_PANIER", { quantite: article.quantite, index: index });
    }
  },
  removeFromPanier({ commit, getters }, id) {
    let index = _.findIndex(getters.getPanier, { id: id });
    commit("REMOVE_FROM_PANIER", index);
  },
  passerCommande({ commit, getters, rootGetters }) {
    Vue.axios
      .post("/api/magasin/commande", {
        articles: getters.getPanier,
        message: getters.getMessage,
        user: rootGetters["auth/userInSession"],
      })
      .then(() => {
        Swal.fire("Commande passée", "", "success");
        commit("EMPTY_PANIER");
      });
  },
  emptyPanier({ commit }) {
    commit("EMPTY_PANIER");
  },
  initialiseCatalogue({ commit }) {
    commit("INITIALISE_DISPLAY_LIST");
    commit("INITIALISE_BREADCRUMBS");
    commit("INITIALISE_SELECT_LIST");
    commit("INITIALISE_PANIER");
  },
  setCatalogue({ commit }, data) {
    new Promise((resolve) => {
      commit("HAS_CHILDREN", data);
      resolve();
    }).then(() => {
      commit("SET_DISPLAY_LIST", data);
    });
  },
  addBreadcrumb({ commit }, data) {
    commit("ADD_BREADCRUMB", data);
  },
  navigateBack({ commit, getters }, data) {
    if (data === -1 && getters.getBreadcrumbs.length > 1) {
      new Promise((resolve) => {
        commit("BACK_ONE_CRUMB");
        resolve();
      }).then(() => {
        commit(
          "HAS_CHILDREN",
          getters.getBreadcrumbs[getters.getBreadcrumbs.length - 1].children
        );
      });
    } else {
      new Promise((resolve) => {
        commit("HAS_CHILDREN", getters.getBreadcrumbs[data].children);
        resolve();
      }).then(() => {
        commit("BACK_TO_CRUMB", data);
      });
    }
  },
  verifyChildren({ commit, getters }) {
    commit("HAS_CHILDREN", getters.getDisplayList);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
