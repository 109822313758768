var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("x-modale-time", {
        attrs: {
          visible: _vm.modalCorrection,
          titre: "Correction heures",
          commentaire: "",
        },
        on: {
          close: _vm.reload,
          "open-commentaire": _vm.showModalCommentaire,
          "close-commentaire": _vm.showModalCommentaire,
        },
      }),
      _c("x-modale-commentaire", {
        attrs: { visible: _vm.modalCommentaire, edit: "" },
        on: {
          close: _vm.showModalCommentaire,
          change: _vm.setCommentaireTampon,
          "set-commentaire": _vm.setCommentaireCorrection,
        },
      }),
      _c("x-modale-commentaire", {
        attrs: {
          visible: _vm.modalCommentaireLecture,
          commentaire: _vm.getCommentaireLecture,
        },
        on: { close: _vm.showModalCommentaireLecture },
      }),
      _c("x-filtres-gestion"),
      _c("x-gestion-datatable", {
        on: {
          refresh: _vm.refreshFromDb,
          "show-commentaire-lecture": function ($event) {
            _vm.modalCommentaireLecture = true
          },
          "show-correction": function ($event) {
            _vm.modalCorrection = true
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }