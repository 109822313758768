<template>
  <div>
    <!--Ma modale de modification-->
    <x-modale-time
      :visible="modalCorrection"
      titre="Correction heures"
      commentaire
      @close="reload"
      @open-commentaire="showModalCommentaire"
      @close-commentaire="showModalCommentaire"
    ></x-modale-time>

    <!--Ma modale de création commentaire-->
    <x-modale-commentaire
      :visible="modalCommentaire"
      edit
      @close="showModalCommentaire"
      @change="setCommentaireTampon"
      @set-commentaire="setCommentaireCorrection"
    ></x-modale-commentaire>

    <!--Ma modale de lecture commentaire-->
    <x-modale-commentaire
      :visible="modalCommentaireLecture"
      :commentaire="getCommentaireLecture"
      @close="showModalCommentaireLecture"
    ></x-modale-commentaire>

    <!--Mes filtres-->
    <x-filtres-gestion></x-filtres-gestion>

    <!--Mon tableau d'heures-->
    <x-gestion-datatable
      @refresh="refreshFromDb"
      @show-commentaire-lecture="modalCommentaireLecture = true"
      @show-correction="modalCorrection = true"
    ></x-gestion-datatable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import XModaleTime from "@/components/time/X-modale-time";
import XModaleCommentaire from "@/components/time/X-modale-commentaire";
import XFiltresGestion from "@/components/time/X-filtres-gestion";
import XGestionDatatable from "@/components/time/X-gestion-datatable";

export default {
  name: "GestionTimes",
  components: {
    XGestionDatatable,
    XFiltresGestion,
    XModaleCommentaire,
    XModaleTime,
  },
  data() {
    return {
      requeteTerminee: "",
      modalCorrection: false,
      modalConfirmCorrection: false,
      modalConfirmValidation: false,
      modalConfirmMulti: false,
      modalConfirmMultiReverse: false,
      modalCommentaire: false,
      modalCommentaireLecture: false,
      commentaireTampon: "",
    };
  },
  created() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters["time/getResultatTerminee"],
      (newValue) => {
        //Si la requête est terminée, on réactualise la liste
        if (newValue === true) {
          this.errText = newValue;
          this.refreshFromDb();
        }
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  methods: {
    refreshFromDb() {
      if (this.getQuery === {}) {
        this.$store.dispatch("time/returnTimes");
      } else {
        this.$store.dispatch("time/returnTimeQuery");
      }
    },
    reload() {
      this.modalCorrection = false;
      this.refreshFromDb();
    },
    showModalCommentaire() {
      this.modalCommentaire = !this.modalCommentaire;
    },
    setCommentaireCorrection() {
      this.$store.dispatch(
        "time/setCommentaireCorrection",
        this.commentaireTampon
      );
      this.showModalCommentaire();
    },
    setCommentaireTampon(e) {
      this.commentaireTampon = e;
    },
    showModalCommentaireLecture() {
      this.modalCommentaireLecture = !this.modalCommentaireLecture;
    },
  },
  computed: {
    ...mapGetters(["getTurquoisePoupin"]),

    ...mapGetters("time", {
      getCurrentJournee: "getCurrentJournee",
      getCommentaireLecture: "getCommentaireLecture",
    }),

    quelBouton() {
      return (
        this.getCurrentJournee !== null &&
        this.getCurrentJournee.journeeCorrigee !== undefined &&
        this.getCurrentJournee.journeeCorrigee !== null &&
        this.getCurrentJournee.journeeCorrigee.commentaire !== ""
      );
    },
  },
};
</script>

<style scoped></style>
