var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "l-map",
        {
          ref: "lmapComponent",
          staticStyle: { height: "60em", width: "130em" },
          attrs: { zoom: _vm.zoom, center: _vm.center },
        },
        [
          _c("l-tile-layer", { attrs: { url: _vm.url } }),
          _vm._l(_vm.listCoordonnees, function (coord, index) {
            return _c(
              "l-marker",
              {
                key: index,
                attrs: { "lat-lng": [coord.CoordonneeX, coord.CoordonneeY] },
              },
              [
                _c("l-icon", {
                  attrs: {
                    "icon-size": [32, 32],
                    "icon-anchor": [16, 37],
                    "icon-url":
                      "https://raw.githubusercontent.com/Joachim53000/ScratBin/master/" +
                      coord.couleur +
                      ".png",
                  },
                }),
                _c("l-popup", [
                  _c(
                    "div",
                    [
                      _c("h2", [
                        _vm._v("N° devis : " + _vm._s(coord.Compteur)),
                      ]),
                      coord.Name != null
                        ? _c("div", [
                            _c("p", { staticStyle: { color: "green" } }, [
                              _vm._v(_vm._s(coord.Name)),
                            ]),
                          ])
                        : _c("div", [
                            _c("h3", { staticStyle: { color: "red" } }, [
                              _vm._v("Il n'y a pas de nom"),
                            ]),
                          ]),
                      _c(
                        "v-btn",
                        {
                          staticStyle: { "min-width": "0px" },
                          attrs: { text: "", tile: "", width: "16%" },
                          on: {
                            click: function ($event) {
                              return _vm.openGoogleMap(coord)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-google-maps")])],
                        1
                      ),
                      coord.Rue != null
                        ? _c("div", [
                            _c("p", [_vm._v("Ville : " + _vm._s(coord.Ville))]),
                            _c("p", [
                              _vm._v(
                                "Code postal : " + _vm._s(coord.CodePostal)
                              ),
                            ]),
                            _c("p", [_vm._v("Rue : " + _vm._s(coord.Rue))]),
                          ])
                        : _c("div", [
                            _c("h3", { staticStyle: { color: "red" } }, [
                              _vm._v("Il n'y a pas d'adresse"),
                            ]),
                          ]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }