const state = {
    tab: 0,
    tabs: [{ title: 'Home', key: 'Home' },
    { title: 'Utilisateurs', key: 'Users' },
    { title: 'Agences', key: 'Agencies' },
    { title: 'Sociétés', key: 'Societies' },
    { title: 'Articles', key: 'Articles' }]
};

const getters = {
    getTab(state) {
        return state.tab;
    },
    getTabs(state) {
        return state.tabs
    }
};

const mutations = {
    SET_TAB(state, tab) {
        state.tab = tab;
    }
};

const actions = {
    setTab({ commit }, tab) {
        commit('SET_TAB', tab);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};