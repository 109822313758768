import Vue from 'vue';

const state = {
    currentAgence: {},
    agences: []
};

const getters = {
    getAgence(state) {
        return state.currentAgence;
    },
    getAgences(state) {
        return state.agences;
    }
};

const mutations = {
    SET_LIBELLE_AGENCE(state, libelle) {
        state.currentAgence.libelle = libelle;
    },
    SET_ID_AGENCE(state, id) {
        state.currentAgence.idERP = id;
    },
    ADD_AGENCE(state, agence) {
        state.agences.push(agence);
    },
    SET_AGENCES(state, agences) {
        state.agences = agences;
    }
};

const actions = {
    setLibelleAgence({commit}, agence) {
        commit('SET_LIBELLE_AGENCE', agence);
    },
    setIdAgence({commit}, id) {
        commit('SET_ID_AGENCE', id);
    },
    addAgence({getters, commit}) {
        Vue.axios.post('/api/agence/', getters.getAgence)
            .then(
                res => {
                    commit('ADD_AGENCE', res.data.retour);
                }
            )
            .catch();
    },
    returnAgences({commit}) {
        Vue.axios.get('/api/agence/')
            .then(
                res => {
                    commit('SET_AGENCES', res.data)
                }
            )
            .catch( err => {
                console.log(err);
            })
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};