<template>
  <v-dialog
      :value="getModaleGraph"
      @click:outside="hideModaleGraph"
      max-width="900px"
      scrollable
      content-class="ma-1 pa-0"
  >
    <v-card>
      <v-card-title class="ma-0 pa-0">
        <v-toolbar
            :color="getBleu"
            dark
            class="mb-2 mx-0 mt-0"
        >
          <v-toolbar-title>Documents chantier</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="btnMonteNiveau" @click="goBackOneLevel()" icon>
            <v-icon>mdi-arrow-up-thick</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <label v-if="getFilesCount === 0">
          Aucun document trouvés.
        </label>
        <v-hover v-for="(item, i) in getFiles.value" :key="i"  v-slot="{ hover }" >
          <v-card class="mb-2" :elevation="hover ? 8 : 2">

            <v-card-subtitle v-if="item.folder" @click="loadChildren(item)">
              <v-icon :disabled="!haveChildren(item)" class="mr-2">{{ folderIcon(item) }}</v-icon> {{ item.name }}
            </v-card-subtitle>

            <v-card-subtitle v-if="item.file">
              <v-icon @click="downloadFile(item)" class="mr-2">mdi-file-document-outline</v-icon>
              {{ item.name }}
              <v-icon @click="downloadFile(item)" class="ml-5" color="blue">mdi-cloud-download</v-icon>
            </v-card-subtitle>

          </v-card >
        </v-hover>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Fermer</v-btn>
      </v-card-actions>
    </v-card>

    <!--En cours de chargement-->
    <v-dialog
        v-model="chargementEnCours"
        hide-overlay
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Chargement en cours
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>
<script>
import "isomorphic-fetch";
import { Client } from "@microsoft/microsoft-graph-client";
import { ImplicitMSALAuthenticationProvider } from "@microsoft/microsoft-graph-client/lib/src/ImplicitMSALAuthenticationProvider";
import { MSALAuthenticationProviderOptions } from '@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions';
import { mapGetters, mapActions } from 'vuex';
import graph_sharepoint from '../../../service/graph_sharepoint';
import auth_sharepoint from '../../../service/auth_sharepoint';

export default {
  name: "XModaleGraph",
  data() {
    return {
      // User account object synced with MSAL getAccount()
      user: null,
      // Access token fetched via MSAL for calling Graph API
      accessToken: '',
      msalConfig: {
        auth: {
          clientId: 'c0c0f133-5ebe-4177-a6d8-6a84f8536f15',
          redirectUri: "https://jeremy.poupin.fr",
          authority: 'https://login.microsoftonline.com/f3d121c7-c5e1-4c89-91a8-9ea5faca7a1c'
        }
      },
      msalClient: null,
      account: null,
      authProvider: null,
      graphClient: null,
      options: null,
      btnMonteNiveau: false,
      uriLocal: "http://localhost:8080",
      files: {},
      niveauPrecedent: {},
      cheminBreadcrumbs: '',
      chargementEnCours: false
    }
  },
  async created() {
    if(process.env.NODE_ENV === "DEVELOPEMENT"){
      this.msalConfig.auth.redirectUri = this.uriLocal;
    }


    console.log("this.userInSession.email")
    console.log(this.userInSession.email)
    // Basic setup of MSAL helper with client id, or give up
    this.msalClient = await auth_sharepoint.configure(this.msalConfig.auth.clientId, this.userInSession.email)

    if (this.getGraphUser === null) {
      //await this.setGraphClient(this.graphClient);
      await auth_sharepoint.login()
      this.user = auth_sharepoint.user()
      await this.setGraphUser(auth_sharepoint.user())
      await this.setOptions(new MSALAuthenticationProviderOptions(this.getMsalRequest.scopes))
      await this.setAuthProvider(new ImplicitMSALAuthenticationProvider(this.msalClient, this.getOptions))
      let authProvider = this.getAuthProvider
      await this.setGraphClient(Client.initWithMiddleware( { authProvider }));
    }
    console.log('this.msalClient')
    console.log(this.msalClient)
    if(this.msalClient !== null && this.msalClient !== undefined) {
      this.files = await this.getDocumentsChantier()
    }
  },
  mounted() {
    console.log('Dans le mounted de X-modale-graph')
  },
  methods: {
    ...mapActions('chantier', {
      hideModaleGraph: 'hideModaleGraph'
    }),
    ...mapActions('graph', {
      setGraphUser: 'setGraphUser',
      setGraphClient: 'setGraphClient',
      setOptions: 'setOptions',
      setAuthProvider: 'setAuthProvider'
    }),
    async getUser() {
      return await this.graphClient
          .api('/me')
          // Only get the fields used by the app
          .select('id,displayName,mail,userPrincipalName,mailboxSettings')
          .get();
    },
    async getRacine() {
      return await this.graphClient
          .api('/sites/poupin.sharepoint.com,e66d47a6-7666-42a0-ad94-b34d04ce1e4f,9b77c3d7-0c51-4ba9-b9e3-11a860bbd15e/drive/root/children')
          .select('*')
          .get();
    },
    async getDocumentsChantier() {
      this.chargementEnCours = true

      let tempFiles = await graph_sharepoint.getDocumentsChantier(`/sites/poupin.sharepoint.com,e66d47a6-7666-42a0-ad94-b34d04ce1e4f,9b77c3d7-0c51-4ba9-b9e3-11a860bbd15e/drive/root/search(q='${this.getChantier.code_client}')`,
          this.getGraphClient);

      this.cheminBreadcrumbs = ''
      if (tempFiles.length > 0){
        this.cheminBreadcrumbs = tempFiles.value[0].id.toString();
      }
      this.btnMonteNiveau = false
      console.log("getDocumentsChantier - this.cheminBreadcrumbs")
      this.chargementEnCours = false
      return tempFiles;
    },
    downloadFile(item) {
      window.open(item['@microsoft.graph.downloadUrl'], '_blank')
    },
    haveChildren(item) {
      if(item.folder.childCount > 0) {
        return true;
      }
      return false;
    },

    folderIcon(item) {
      if(this.haveChildren(item)) {
        return 'mdi-folder-outline'
      }else{
        return 'mdi-folder-remove-outline'
      }
    },

    getDernierItemBreadcrumbs(){

      let listSplit = this.cheminBreadcrumbs.split("/")
      return listSplit[listSplit.length - 1]
    },

    loadChildren(item) {
      if(this.haveChildren(item)) {
        this.chargementEnCours = true
        this.btnMonteNiveau = true
        //  Stock le click actuel pour que ça soit le niveau précédent
        //  lorsqu'on remonte
        // Si c'est déjà dans le chemin, on ajoute pas sinon redondance et on va tourner en rond
        if (this.cheminBreadcrumbs.indexOf(item.id) == -1) {
          this.cheminBreadcrumbs += "/" + item.id;
        }

        this.niveauPrecedent = item
        console.log('/////////////////////////////////');
        console.log('item - LOAD');
        console.log('/////////////////////////////////');
        console.log(this.cheminBreadcrumbs)
        this.getGraphClient
            .api(`/sites/poupin.sharepoint.com,e66d47a6-7666-42a0-ad94-b34d04ce1e4f,9b77c3d7-0c51-4ba9-b9e3-11a860bbd15e/drive/items/${item.id}/children`)
            .get()
            .then( result => {
              this.files = result;
            })
            .then( () => {
              this.chargementEnCours = false
            })
            .catch( err => {
              console.log(err)
              this.chargementEnCours = false
            })



      }
    },
    async goBackOneLevel(item) {
      this.chargementEnCours = true

      this.btnMonteNiveau = true

      console.log(item);
      // On enlève la dernière tranche du breadcrumbs quand on remonte d'un cran
      this.cheminBreadcrumbs = this.cheminBreadcrumbs.substring(0, this.cheminBreadcrumbs.lastIndexOf('/'))

      // Si c'est vide, alors il faut refaire la 1ère requête pour récupérer tout les dossiers
      if (this.getDernierItemBreadcrumbs() == ''){
        this.files = await this.getDocumentsChantier()
      }else{
        this.files = await graph_sharepoint.goBackOneLevel(`/sites/poupin.sharepoint.com,e66d47a6-7666-42a0-ad94-b34d04ce1e4f,9b77c3d7-0c51-4ba9-b9e3-11a860bbd15e/drive/items/${this.getDernierItemBreadcrumbs()}/children`,
            this.getGraphClient);
        // On stock le 1er item pour pouvoir remonter au niveau précédent par la suite
        this.niveauPrecedent = this.files.value[0]
      }
      this.chargementEnCours = false
    },
  },
  computed: {
    ...mapGetters('chantier', {
      getModaleGraph: 'getModaleGraph',
      getChantier: 'getChantier'
    }),
    ...mapGetters('graph', {
      getGraphClient: 'getGraphClient',
      getGraphUser: 'getGraphUser',
      getOptions: 'getOptions',
      getAuthProvider: 'getAuthProvider',
      getMsalRequest: 'getMsalRequest'
    }),
    ...mapGetters(['getBleu']),
    ...mapGetters('auth', {
      userInSession: 'userInSession'
    }),
    getUserValue() {
      return this.user;
    },
    getFiles() {
      console.log("computed getfiles")
      console.log(this.files);
      return this.files;
    },
    getFilesCount() {
      if (this.files === undefined || this.files === null || Object.keys(this.files).length === 0 || this.files.value.length === 0){
        return 0
      }else{
        return 1;
      }
    }
  },
}
</script>
<style scoped>
</style>
