import Vue from "vue";
import _ from "lodash";
import moment from "moment";

function dateDuJour(journee) {
  let date = moment(journee.date).toDate();
  let jour = date.getDate();
  let mois = date.getMonth();
  let annee = date.getFullYear();

  _.forOwn(journee, function(value, key) {
    if (
      key == "heure_depart" ||
      key == "heure_arrivee_premier_chantier" ||
      key == "heure_depart_dernier_chantier" ||
      key == "heure_arrivee" ||
      key == "heure_depart_entreprise"
    ) {
      journee[key] = moment(journee[key])
        .date(jour)
        .month(mois)
        .year(annee)
        .toISOString();
    }
  });
}

const state = {
  journees: [],
  liste_samedi: [],
  currentJournee: {},
  dataMois: {},
  err: null,
  resultatVille: [],
  requeteTerminee: false,
  commentaireLecture: "",
  query: {},
  actualDateWithHour: {}, // date actuelle précise à la minute
};

const getters = {
  getActualDateWithHour(state) {
    return state.actualDateWithHour;
  },

  getJourneeList(state) {
    return state.journees;
  },
  getCurrentJournee(state) {
    return state.currentJournee;
  },
  getLieuDepart(state) {
    return state.currentJournee.journeeCorrigee
      ? state.currentJournee.journeeCorrigee.lieu_depart
      : state.currentJournee.lieu_depart;
  },
  getLieuRetour(state) {
    return state.currentJournee.journeeCorrigee
      ? state.currentJournee.journeeCorrigee.lieu_arrivee
      : state.currentJournee.lieu_arrivee;
  },
  getHeureDepart(state) {
    return state.currentJournee.heure_depart;
  },
  getPremierChantier(state) {
    return state.currentJournee.heure_arrivee_premier_chantier;
  },
  getDernierChantier(state) {
    return state.currentJournee.heure_depart_dernier_chantier;
  },
  getHeureArrivee(state) {
    return state.currentJournee.heure_arrivee;
  },
  getHeureDepartEntreprise(state) {
    return state.currentJournee.heure_depart_entreprise;
  },
  getVilleLaPlusLointaine(state) {
    return state.currentJournee.villeLaPlusLointaine;
  },
  getErr(state) {
    return state.err;
  },
  getResultatVille(state) {
    return state.resultatVille;
  },
  getResultatTerminee(state) {
    return state.requeteTerminee;
  },
  getDataMois(state) {
    return state.dataMois;
  },
  getListeSamedi(state) {
    return state.liste_samedi;
  },
  getQuery(state) {
    return state.query;
  },
  getCommentaireLecture(state) {
    return state.commentaireLecture;
  },
};

const mutations = {
  SET_ACTUAL_DATE_WITH_HOUR(state, date) {
    state.actualDateWithHour = date;
  },
  SET_JOURNEE(state, journee) {
    state.currentJournee = journee;
  },
  SET_JOURNEE_DATE(state, date) {
    state.currentJournee.date = date;
  },
  SET_JOURNEE_DATE_CORRECTION(state, date) {
    state.currentJournee.journeeCorrigee.date = date;
  },
  SET_HEURE_DEPART_CORRECTION(state, heure) {
    state.currentJournee.journeeCorrigee.heure_depart = heure;
  },
  SET_HEURE_DEPART(state, heure) {
    state.currentJournee.heure_depart = heure;
  },
  SET_HEURE_DERNIER_CHANTIER_CORRECTION(state, heure) {
    state.currentJournee.journeeCorrigee.heure_depart_dernier_chantier = heure;
  },
  SET_HEURE_DERNIER_CHANTIER(state, heure) {
    state.currentJournee.heure_depart_dernier_chantier = heure;
  },
  SET_HEURE_ARRIVEE_ENTREPRISE_CORRECTION(state, heure) {
    state.currentJournee.journeeCorrigee.heure_arrivee = heure;
  },
  SET_HEURE_ARRIVEE_ENTREPRISE(state, heure) {
    state.currentJournee.heure_arrivee = heure;
  },
  SET_HEURE_DEPART_ENTREPRISE_CORRECTION(state, heure) {
    state.currentJournee.journeeCorrigee.heure_depart_entreprise = heure;
  },
  SET_HEURE_DEPART_ENTREPRISE(state, heure) {
    Vue.set(state.currentJournee, "heure_depart_entreprise", heure);
  },
  SET_LIEU_DEPART(state, lieu) {
    state.currentJournee.lieu_depart = lieu;
  },
  SET_LIEU_DEPART_CORRECTION(state, lieu) {
    state.currentJournee.journeeCorrigee.lieu_depart = lieu;
  },
  SET_LIEU_RETOUR(state, lieu) {
    state.currentJournee.lieu_arrivee = lieu;
  },
  SET_LIEU_RETOUR_CORRECTION(state, lieu) {
    state.currentJournee.journeeCorrigee.lieu_arrivee = lieu;
  },
  SET_HORAIRE_RETOUR(state, payload) {
    state.currentJournee.horaire_retour = payload;
  },
  SET_TEMPS_PAUSE(state, pause) {
    state.currentJournee.temps_pause = pause;
  },
  SET_TEMPS_PAUSE_CORRECTION(state, pause) {
    state.currentJournee.journeeCorrigee.temps_pause = pause;
  },
  ADD_JOURNEE(state, journee) {
    state.journees.push(journee);
  },
  SORT_JOURNEES(state) {
    state.journees = state.journees.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
  },
  PUT_LIST(state, journees) {
    state.journees = journees;
  },
  SET_ERROR(state, err) {
    state.err = err;
  },
  SET_VILLE_LA_PLUS_LOINTAINE(state, villeLaPlusLointaine) {
    state.currentJournee.villeLaPlusLointaine = villeLaPlusLointaine;
  },
  SET_VILLE_LA_PLUS_LOINTAINE_CORRECTION(state, villeLaPlusLointaine) {
    state.currentJournee.journeeCorrigee.villeLaPlusLointaine = villeLaPlusLointaine;
  },
  SET_RESULTAT_VILLE(state, resultatVille) {
    state.resultatVille = resultatVille;
  },
  SET_REQUETE_TERMINEE(state, etat) {
    state.requeteTerminee = etat;
  },
  SET_DATA_MOIS(state, payload) {
    state.dataMois = payload;
  },
  SET_COMMENTAIRE(state, payload) {
    state.currentJournee.journeeCorrigee.commentaire = payload;
  },
  SET_REEL(state, payload) {
    state.currentJournee.journeeCorrigee.reel = payload;
  },
  SET_TRAJET_SOIR(state, payload) {
    state.currentJournee.journeeCorrigee.trajetSoir = payload;
  },
  SET_SAMEDI(state, payload) {
    state.currentJournee.journeeCorrigee.samedi = payload;
  },
  SET_DATE_RH(state, payload) {
    state.currentJournee.journeeCorrigee.date_rh = payload;
  },
  SET_LISTE_SAMEDI(state, payload) {
    state.liste_samedi = [];
    for (let i = 1; i < 13; i++) {
      state.liste_samedi.push({
        samedi: moment(payload)
          .add(i, "w")
          .isoWeekday(6)
          .startOf("d")
          .toDate(),
        format: moment(payload)
          .add(i, "w")
          .isoWeekday(6)
          .startOf("d")
          .format("dddd Do MMMM YYYY"),
      });
    }
  },
  SET_QUERY(state, payload) {
    state.query = payload;
  },
  SET_QUERY_DATE_DEBUT(state, payload) {
    state.query.dateDebut = payload;
  },
  DELETE_QUERY_DATE_DEBUT(state) {
    delete state.query.dateDebut;
  },
  SET_QUERY_DATE_FIN(state, payload) {
    state.query.dateFin = payload;
  },
  DELETE_QUERY_DATE_FIN(state) {
    delete state.query.dateFin;
  },
  SET_QUERY_AGENCE(state, payload) {
    state.query.agence = payload;
  },
  DELETE_QUERY_AGENCE(state) {
    delete state.query.agence;
  },
  SET_QUERY_POSEUR(state, payload) {
    state.query.poseur = payload;
  },
  DELETE_QUERY_POSEUR(state) {
    delete state.query.poseur;
  },
  SET_QUERY_VALIDATED(state, payload) {
    state.query.validated = payload;
  },
  SET_COMMENTAIRE_LECTURE(state, payload) {
    state.commentaireLecture = payload;
  },
};

const actions = {
  // Récupère le jour/ heure / minute précis
  modifiedActualDate({ commit }, date) {
    commit("SET_ACTUAL_DATE_WITH_HOUR", date);
  },
  initialiseCorrection({ commit }, journee) {
    let journeeClone = _.clone(journee);
    journee.journeeCorrigee = {
      date: journeeClone.date,
      heure_depart: journeeClone.heure_depart,
      heure_arrivee_premier_chantier:
        journeeClone.heure_arrivee_premier_chantier,
      heure_depart_dernier_chantier: journeeClone.heure_depart_dernier_chantier,
      heure_arrivee: journeeClone.heure_arrivee,
      heure_depart_entreprise: journeeClone.heure_depart_entreprise,
      temps_pause: journeeClone.temps_pause,
      lieu_depart: journeeClone.lieu_depart,
      lieu_arrivee: journeeClone.lieu_arrivee,
      heuresTauxNormal: journeeClone.heuresTauxNormal,
      heuresMajorees: journeeClone.heuresMajorees,
      villeLaPlusLointaine: journeeClone.villeLaPlusLointaine,
      reel: false,
      trajetSoir: false,
      samedi: false,
      date_rh: null,
      commentaire: "",
    };
    journee.date_correction = new Date();
    commit("SET_JOURNEE", journee);
    commit("SET_LISTE_SAMEDI", journee.date);
  },
  setJournee({ commit }, journee) {
    commit("SET_JOURNEE", journee);
  },
  setDate({ commit, getters }, date) {
    if (getters.getCurrentJournee.journeeCorrigee) {
      commit("SET_JOURNEE_DATE_CORRECTION", date);
    } else {
      commit("SET_JOURNEE_DATE", date);
    }
  },
  setTime({ commit, getters }, [heure, param]) {
    if (getters.getCurrentJournee.journeeCorrigee) {
      commit("SET_JOURNEE_TIME_CORRECTION", [heure, param]);
    } else {
      commit("SET_JOURNEE_TIME", [heure, param]);
    }
  },
  setHeureDepart({ commit, getters }, heure) {
    if (getters.getCurrentJournee.journeeCorrigee) {
      commit("SET_HEURE_DEPART_CORRECTION", heure);
    } else {
      commit("SET_HEURE_DEPART", heure);
    }
  },
  setHeureDepartDernierChantier({ commit, getters }, heure) {
    if (getters.getCurrentJournee.journeeCorrigee) {
      commit("SET_HEURE_DERNIER_CHANTIER_CORRECTION", heure);
    } else {
      commit("SET_HEURE_DERNIER_CHANTIER", heure);
    }
  },
  setHeureArriveeEntreprise({ commit, getters }, heure) {
    if (getters.getCurrentJournee.journeeCorrigee) {
      commit("SET_HEURE_ARRIVEE_ENTREPRISE_CORRECTION", heure);
    } else {
      commit("SET_HEURE_ARRIVEE_ENTREPRISE", heure);
    }
  },
  setHeureDepartEntreprise({ commit, getters }, heure) {
    if (getters.getCurrentJournee.journeeCorrigee) {
      commit("SET_HEURE_DEPART_ENTREPRISE_CORRECTION", heure);
    } else {
      commit("SET_HEURE_DEPART_ENTREPRISE", heure);
    }
  },
  setPause({ commit, getters }, pause) {
    if (getters.getCurrentJournee.journeeCorrigee) {
      commit("SET_TEMPS_PAUSE_CORRECTION", pause);
    } else {
      commit("SET_TEMPS_PAUSE", pause);
    }
  },
  setLieuDepart({ commit, getters }, lieu) {
    if (getters.getCurrentJournee.journeeCorrigee) {
      commit("SET_LIEU_DEPART_CORRECTION", lieu);
    } else {
      commit("SET_LIEU_DEPART", lieu);
    }
  },
  setLieuRetour({ commit, getters }, lieu) {
    if (getters.getCurrentJournee.journeeCorrigee) {
      commit("SET_LIEU_RETOUR_CORRECTION", lieu);
    } else {
      commit("SET_LIEU_RETOUR", lieu);
    }
  },
  setHoraireRetour({ commit }, retour) {
    commit("SET_HORAIRE_RETOUR", retour);
  },
  setVilleLaPlusLointaine({ commit, getters }, villeLaPlusLointaine) {
    if (getters.getCurrentJournee.journeeCorrigee) {
      commit("SET_VILLE_LA_PLUS_LOINTAINE_CORRECTION", villeLaPlusLointaine);
    } else {
      commit("SET_VILLE_LA_PLUS_LOINTAINE", villeLaPlusLointaine);
    }
  },
  setResultatVille({ commit }, resultatVille) {
    commit("SET_RESULTAT_VILLE", resultatVille);
  },
  setCommentaireCorrection({ commit }, commentaire) {
    commit("SET_COMMENTAIRE", commentaire);
  },
  setReel({ commit }, val) {
    commit("SET_REEL", val);
  },
  setTrajetSoir({ commit }, val) {
    commit("SET_TRAJET_SOIR", val);
  },
  setSamedi({ commit }, val) {
    commit("SET_SAMEDI", val);
  },
  setDateRH({ commit }, val) {
    commit("SET_DATE_RH", val);
  },
  setError({ commit }, err) {
    commit("SET_ERROR", err);
  },
  nullError({ commit }) {
    commit("SET_ERROR", null);
  },
  addJournee({ commit, rootGetters }) {
    let data = {
      journee: rootGetters["time/getCurrentJournee"],
      user: rootGetters["auth/userInSession"],
    };
    dateDuJour(data.journee);

    return new Promise((resolve, reject) => {
      Vue.axios
        .post("/api/journee/", data)
        .then((res) => {
          res.data.retour.user = data.user;
          commit("ADD_JOURNEE", res.data.retour);
          commit("SORT_JOURNEES");
          resolve();
        })
        .catch((err) => reject(err.response.data.error));
    });
  },
  updateJournee({ commit, rootGetters, getters }) {
    let journee = getters.getCurrentJournee;

    dateDuJour(journee);

    if (journee.journeeCorrigee != null) {
      dateDuJour(journee.journeeCorrigee);
    }

    return new Promise((resolve, reject) => {
      Vue.axios
        .put("/api/journee/" + journee._id, journee)
        .then((res) => {
          res.data.retour.user = rootGetters["auth/userInSession"];
          commit("SORT_JOURNEES");
          resolve();
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  },

  async validateJournees({ getters }) {
    let promises = [];
    await getters.getJourneeList.forEach((el) => {
      if (!el.validated) {
        el.validated = true;
        promises.push(Vue.axios.put(`/api/journee/${el._id}`, el));
      }
    });
    return promises;
  },
  async unvalidateJournees({ getters }) {
    let promises = [];
    await getters.getJourneeList.forEach((el) => {
      if (el.validated) {
        el.validated = false;
        promises.push(Vue.axios.put(`/api/journee/${el._id}`, el));
      }
    });
    return promises;
  },
  getJourneesUser({ commit, rootGetters }) {
    let user = rootGetters["auth/userInSession"];
    if (typeof user === "string") {
      user = JSON.parse(rootGetters["auth/userInSession"]);
    }

    Vue.axios
      .get("/api/journee/user/" + user._id)
      .then((res) => {
        commit("PUT_LIST", res.data);
      })
      .catch();
  },
  returnJourneesFiltres({ commit, rootGetters }, payload) {
    let user = rootGetters["auth/userInSession"];

    Vue.axios
      .post("/api/journee/user/filtres", {
        id: user._id,
        debut: payload.dateDebut,
        fin: payload.dateFin,
      })
      .then((res) => {
        commit("PUT_LIST", res.data);
      })
      .catch();
  },
  returnTimes({ commit }) {
    Vue.axios
      .get("/api/journee/")
      .then((res) => {
        commit("PUT_LIST", res.data);
      })
      .catch();
  },
  initialiseQuery({ commit, rootGetters }) {
    commit("SET_QUERY", {
      validated: false,
      agence: rootGetters["auth/userInSession"].agence._id,
    });
  },
  setQueryDateDebut({ commit }, payload) {
    commit("SET_QUERY_DATE_DEBUT", payload);
  },
  clearQueryDateDebut({ commit }) {
    commit("DELETE_QUERY_DATE_DEBUT");
  },
  setQueryDateFin({ commit }, payload) {
    commit("SET_QUERY_DATE_FIN", payload);
  },
  clearQueryDateFin({ commit }) {
    commit("DELETE_QUERY_DATE_FIN");
  },
  setQueryAgence({ commit }, payload) {
    commit("SET_QUERY_AGENCE", payload);
  },
  clearQueryAgence({ commit }) {
    commit("DELETE_QUERY_AGENCE");
  },
  setQueryPoseur({ commit }, payload) {
    commit("SET_QUERY_POSEUR", payload);
  },
  clearQueryPoseur({ commit }) {
    commit("DELETE_QUERY_POSEUR");
  },
  setQueryValidated({ commit }, payload) {
    commit("SET_QUERY_VALIDATED", payload);
  },
  returnTimeQuery({ commit, getters }) {
    Vue.axios
      .post("/api/journee/gestion", getters.getQuery)
      .then((res) => {
        commit("PUT_LIST", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  setCommentaireLecture({ commit }, payload) {
    commit("SET_COMMENTAIRE_LECTURE", payload);
  },
  returnMois({ commit }, query) {
    Vue.axios
      .get(`/api/journee/mois/${JSON.stringify(query)}`)
      .then((res) => {
        commit("SET_DATA_MOIS", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  checkTest() {
    let data = {
      validated: true,
      poseur: "5fa4030f837aed37904cb03c",
      semaine: 10,
      annee: 2021,
    };
    Vue.axios.post(`/api/journee/integration`, data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
