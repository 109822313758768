<template>    
      <v-list-item>
        <!-- Demande de prix reçu le -->
        <v-list-item-avatar>
          <v-icon
              :color="getEtatAvancementDate(champ_recherche).couleur"
          >
            {{ getEtatAvancementDate(champ_recherche).icone }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <b>{{ getEtatAvancementDate(champ_recherche).message }}</b>
        </v-list-item-content>
      </v-list-item>                                           
</template>

<script>
import { mapGetters } from 'vuex';


export default {
  name: "X-avancement-jalon-icone-texte",
  data() {
    return {  
      
    }
  },
  props: {
    champ_recherche: {
      type: String
    }
  },
  methods: {

  },
  computed: {
    ...mapGetters('chantier', {   
      getEtatAvancementDate: 'getEtatAvancementDate'
    }),  
  },
}
</script>

<style scoped>

</style>