<template>
  <div>
    <v-form
      v-if="Object.keys(getCurrentJournee).length !== 0"
      v-model="valid"
      lazy-validation
      ref="timeform"
      @submit="addTime"
    >
      <date-picker-component title="Date du jour" />
      <v-checkbox
        :input-value="
          getCurrentJournee.journeeCorrigee
            ? getCurrentJournee.journeeCorrigee.lieu_depart
            : getCurrentJournee.lieu_depart
        "
        prepend-icon="mdi-home-outline"
        label="Passage à l'entreprise le matin ?"
        :color="getTurquoisePoupin"
        true-value="Entreprise"
        false-value="Domicile"
        @change="setLieuDepart"
      ></v-checkbox>
      <x-time-picker
        :title="
          getLieuDepart === 'Entreprise'
            ? 'Arrivée sur l\'entreprise'
            : 'Départ du domicile'
        "
        :usertime="
          getCurrentJournee.journeeCorrigee
            ? getCurrentJournee.journeeCorrigee.heure_depart
            : getCurrentJournee.heure_depart
        "
        @timepicked="setHeureDepart"
      ></x-time-picker>
      <v-select
        dense
        :items="temps"
        :rules="[(v) => !!v || 'Temps de pause obligatoire']"
        :value="
          getCurrentJournee.journeeCorrigee
            ? getCurrentJournee.journeeCorrigee.temps_pause
            : getCurrentJournee.temps_pause
        "
        item-text="name"
        item-value="value"
        menu-props="auto"
        label="Temps de pause"
        @change="setTempsPause"
        prepend-icon="mdi-coffee"
      ></v-select>
      <x-time-picker
        title="Départ du dernier chantier"
        :usertime="
          getCurrentJournee.journeeCorrigee
            ? getCurrentJournee.journeeCorrigee.heure_depart_dernier_chantier
            : getCurrentJournee.heure_depart_dernier_chantier
        "
        @timepicked="setHeureDepartDernierChantier"
      >
      </x-time-picker>
      <v-checkbox
        v-show="!getCurrentJournee.horaire_retour"
        :input-value="
          getCurrentJournee.journeeCorrigee
            ? getCurrentJournee.journeeCorrigee.lieu_arrivee
            : getCurrentJournee.lieu_arrivee
        "
        prepend-icon="mdi-home-outline"
        label="Travail sur l'entreprise le soir ?"
        :color="getTurquoisePoupin"
        true-value="Entreprise"
        false-value="Domicile"
        @change="setLieuRetour"
        dense
      ></v-checkbox>
      <v-checkbox
        v-if="
          (isAdmin || isPoseur) && getCurrentJournee.lieu_arrivee == 'Domicile'
        "
        :input-value="
          getCurrentJournee.horaire_retour
            ? getCurrentJournee.horaire_retour
            : false
        "
        prepend-icon="mdi-truck"
        label="Retour grand déplacement ?"
        :color="getTurquoisePoupin"
        @change="setHoraireRetour"
        dense
      ></v-checkbox>
      <x-time-picker
        v-if="
          getCurrentJournee.journeeCorrigee
            ? getCurrentJournee.journeeCorrigee.lieu_arrivee === 'Entreprise' ||
              getCurrentJournee.journeeCorrigee.trajetSoir ||
              getCurrentJournee.horaire_retour
            : getLieuRetour === 'Entreprise' || getCurrentJournee.horaire_retour
        "
        :title="
          getCurrentJournee.journeeCorrigee
            ? getCurrentJournee.journeeCorrigee.lieu_arrivee === 'Entreprise'
              ? 'Arrivée à l\'entreprise'
              : 'Heure de fin de trajet'
            : 'Arrivée à l\'entreprise'
        "
        :usertime="
          getCurrentJournee.journeeCorrigee
            ? getCurrentJournee.journeeCorrigee.heure_arrivee
            : getCurrentJournee.heure_arrivee
        "
        @timepicked="setHeureRetour"
      >
      </x-time-picker>
      <x-time-picker
        v-if="
          getCurrentJournee.journeeCorrigee
            ? getCurrentJournee.journeeCorrigee.lieu_arrivee === 'Entreprise'
            : getLieuRetour === 'Entreprise'
        "
        title="Départ de l'entreprise"
        :usertime="
          getCurrentJournee.journeeCorrigee
            ? getCurrentJournee.journeeCorrigee.heure_depart_entreprise
            : getCurrentJournee.heure_depart_entreprise
        "
        @timepicked="setHeureDepartEntreprise"
      >
      </x-time-picker>
      <v-autocomplete
        id="villeauto"
        label="Ville la plus lointaine"
        item-text="Description"
        prepend-icon="mdi-map-marker"
        clearable
        return-object
        hide-no-data
        hide-details
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        :value="
          getCurrentJournee.journeeCorrigee
            ? getCurrentJournee.journeeCorrigee.villeLaPlusLointaine
            : getCurrentJournee.villeLaPlusLointaine
        "
        :rules="[(v) => !!v || 'Ville la plus lointaine obligatoire']"
        @change="setVilleLaPlusLointaine"
        dense
      ></v-autocomplete>
      <v-checkbox
        v-if="(isAdmin || isGestionnaire) && getCurrentJournee.journeeCorrigee"
        :input-value="getCurrentJournee.journeeCorrigee.reel"
        prepend-icon="mdi-av-timer"
        label="Calcul temps réel (inférieur à 8h)"
        :color="getTurquoisePoupin"
        @change="setReel"
      ></v-checkbox>
      <v-checkbox
        v-if="(isAdmin || isGestionnaire) && getCurrentJournee.journeeCorrigee"
        :input-value="getCurrentJournee.journeeCorrigee.trajetSoir"
        prepend-icon="mdi-truck"
        label="Ajouter le temps de trajet du soir ?"
        :color="getTurquoisePoupin"
        @change="setTrajetSoir"
      ></v-checkbox>
      <v-checkbox
        v-if="(isAdmin || isGestionnaire) && getCurrentJournee.journeeCorrigee"
        :input-value="getCurrentJournee.journeeCorrigee.samedi"
        prepend-icon="mdi-calendar"
        label="Importer dans RH sur un samedi"
        :color="getTurquoisePoupin"
        @change="setSamedi"
      ></v-checkbox>
      <v-select
        v-if="
          (isAdmin || isGestionnaire) &&
            getCurrentJournee.journeeCorrigee &&
            getCurrentJournee.journeeCorrigee.samedi
        "
        label="Choisir un Samedi"
        prepend-icon="mdi-alpha-s-circle"
        :items="getListeSamedi"
        item-text="format"
        item-value="samedi"
        @input="setDateRH"
      ></v-select>
      <v-divider class="mt-3"></v-divider>
      <v-row justify="end">
        <v-btn color="black" class="mr-1 mt-4" text @click="$emit('close')">
          Annuler
        </v-btn>
        <v-btn
          type="submit"
          class="mr-1 mt-4"
          outlined
          :disabled="!valid"
          :color="getTurquoisePoupin"
        >
          Enregistrer
        </v-btn>
      </v-row>
    </v-form>
    <v-snackbar v-model="snack" :timeout="timeout" centered>
      {{ errText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snack = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import XTimePicker from "./X-time-picker";
import DatePickerComponent from "./DatePickerComponent";
import EventBus from "../../plugins/event-bus";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "TimeForm",
  components: {
    DatePickerComponent,
    XTimePicker,
  },
  data() {
    return {
      isLoading: false,
      search: null,
      descriptionLimit: 60,
      valid: true,
      valeurDefaut: 90,
      lieu: {
        domicile: "Domicile",
        entreprise: "Entreprise",
      },
      errText: "no errors",
      snack: false,
      timeout: 8000,
      temps: [
        { name: "1h", value: 60 },
        { name: "1h30", value: 90 },
      ],
    };
  },
  created() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters["time/getErr"],
      // eslint-disable-next-line no-unused-vars
      (newValue, oldValue) => {
        if (newValue != null) {
          this.errText = newValue;
          this.showSnackbar();
        }
      }
    );
  },
  mounted() {
    EventBus.$on("resetValidation", this.resetValidation);
  },
  beforeDestroy() {
    this.unwatch();
  },
  methods: {
    setTempsPause(e) {
      this.$store.dispatch("time/setPause", e);
    },
    setLieuDepart(e) {
      this.$store.dispatch("time/setLieuDepart", e);
    },
    setLieuRetour(e) {
      this.$store.dispatch("time/setLieuRetour", e);
    },
    setHoraireRetour(e) {
      this.$store.dispatch("time/setHoraireRetour", e).then(() => {
        this.$forceUpdate();
      });
    },
    setHeureDepart(e) {
      this.$store.dispatch("time/setHeureDepart", e);
    },
    setHeureDepartDernierChantier(e) {
      this.$store.dispatch("time/setHeureDepartDernierChantier", e);
    },
    setHeureRetour(e) {
      this.$store.dispatch("time/setHeureArriveeEntreprise", e);
    },
    setHeureDepartEntreprise(e) {
      this.$store.dispatch("time/setHeureDepartEntreprise", e);
    },
    setVilleLaPlusLointaine(e) {
      this.$store.dispatch("time/setVilleLaPlusLointaine", e);
      this.$store.dispatch("time/setResultatVille", []);
    },
    setJourneeCourante(val) {
      this.$store.dispatch("time/setJournee", val);
    },
    setReel(e) {
      this.$store.dispatch("time/setReel", e);
    },
    setTrajetSoir(e) {
      this.$store.dispatch("time/setTrajetSoir", e);
    },
    setSamedi(e) {
      this.$store.dispatch("time/setSamedi", e);
    },
    setDateRH(e) {
      this.$store.dispatch("time/setDateRH", e);
    },
    addTime(e) {
      if (this.$refs.timeform.validate()) {
        if (!this.getCurrentJournee._id) {
          this.$store
            .dispatch("time/addJournee")
            .then(() => {
              this.$emit("close");
            })
            .catch((err) => {
              this.$store.dispatch("time/setError", err);
            });
        } else {
          this.$store
            .dispatch("time/updateJournee")
            .then(() => {
              this.$emit("close");
            })
            .catch((err) => {
              this.$store.dispatch("time/setError", err);
            });
        }
      }
      e.preventDefault();
    },
    resetValidation() {
      this.$refs.timeform.resetValidation();
      this.valid = true;
    },
    showSnackbar() {
      this.snack = true;
    },
  },
  computed: {
    ...mapGetters(["getTurquoisePoupin"]),

    ...mapGetters("time", {
      getCurrentJournee: "getCurrentJournee",
      getLieuRetour: "getLieuRetour",
      getLieuDepart: "getLieuDepart",
      getListeSamedi: "getListeSamedi",
    }),

    ...mapGetters("auth", {
      isAdmin: "isAdmin",
      isGestionnaire: "isGestionnaire",
      isPoseur: "isPoseur",
    }),

    getDepartEntreprise() {
      return this.$store.state["time/currentJournee.heure_depart_entreprise"];
    },
    snackbar() {
      return this.snack;
    },
    items() {
      //Lors du 1ere chargement pendant une modification de 'journée'

      if (
        this.getCurrentJournee.journeeCorrigee
          ? !_.isEmpty(
              this.getCurrentJournee.journeeCorrigee.villeLaPlusLointaine
            )
          : !_.isEmpty(this.getCurrentJournee.villeLaPlusLointaine)
      ) {
        let cloneResultatVilles = _.cloneDeep(
          this.$store.getters["time/getResultatVille"]
        );
        cloneResultatVilles.push(
          this.getCurrentJournee.journeeCorrigee
            ? this.getCurrentJournee.journeeCorrigee.villeLaPlusLointaine
            : this.getCurrentJournee.villeLaPlusLointaine
        );
        return cloneResultatVilles.map((ville) => {
          const Description = this.getCurrentJournee.journeeCorrigee
            ? this.getCurrentJournee.journeeCorrigee.villeLaPlusLointaine.nom +
              " (" +
              this.getCurrentJournee.journeeCorrigee.villeLaPlusLointaine
                .codesPostaux +
              ")"
            : this.getCurrentJournee.villeLaPlusLointaine.nom +
              " (" +
              this.getCurrentJournee.villeLaPlusLointaine.codesPostaux +
              ")";
          return Object.assign({}, ville, { Description });
        });
      }

      return this.$store.getters["time/getResultatVille"].map((ville) => {
        const Description = ville.nom + " (" + ville.codesPostaux + ")";
        return Object.assign({}, ville, { Description });
      });
    },
  },
  watch: {
    snackbar(newValue) {
      if (newValue === false) {
        this.$store.dispatch("time/nullError");
      }
    },
    search(val) {
      //Si la valeur dans l'input est null, on stop la fonction
      if (!val) {
        return;
      }

      // Items have already been loaded
      // Servirait si on charge toute la liste en une seule fois
      //if (this.items.length > 0) return
      // Items have already been requested
      if (this.isLoading) return;
      this.isLoading = true;

      let typeRecherche = "nom";
      if (isNaN(val) === false) {
        typeRecherche = "codePostal";
      }

      let url =
        "https://geo.api.gouv.fr/communes?@TypeRecherche=@LaRecherche&fields=codesPostaux,nom,departement&boost=population&Limit=10";
      url = url.replace("@LaRecherche", val);
      url = url.replace("@TypeRecherche", typeRecherche);

      //On ne charge pas tant qu'il n'y a pas au moins 2 lettres
      if (val.length > 1) {
        fetch(url)
          .then((res) => res.json())
          .then((res) => {
            this.$store.dispatch("time/setResultatVille", res);
            this.isLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
