<template>
  <v-dialog
    :value="isVisible"
    @click:outside="$emit('closeModaleRecapitulatifCommande')"
    max-width="600"
    :scrollable="true"
  >
    <v-card class="overflow-y-hidden">
      <v-card-title class="text-center">{{
        "Commande n° " + commande.numero_commande
      }}</v-card-title>
      <v-container class="mx-2 text-center">
        <v-row class="mx-2 text-center">
          <v-col class="font-weight-bold"> Description </v-col>
          <v-col>{{ commande.description }}</v-col>
        </v-row>
        <v-row class="mx-2 text-center">
          <v-col class="font-weight-bold">Atelier</v-col>
          <v-col>{{ commande.atelier }}</v-col>
        </v-row>
        <v-row class="mx-2 text-center">
          <v-col class="font-weight-bold">Délai sortie atelier</v-col>
          <v-col>{{ dateFormat(commande.date_livraison_confirmee) }}</v-col>
        </v-row>
        <v-row class="mx-2 text-center">
          <v-col class="font-weight-bold">Famille</v-col>
          <v-col>{{ commande.famille }}</v-col>
        </v-row>
        <v-row class="mx-2 text-center">
          <v-col class="font-weight-bold">Sous-famille</v-col>
          <v-col>{{ commande.sous_famille }}</v-col>
        </v-row>
        <v-row class="mx-2 text-center">
          <v-col class="font-weight-bold">Passée par</v-col>
          <v-col>{{ commande.passee_par }}</v-col>
        </v-row>
        <v-row class="mx-2 text-center">
          <v-col class="font-weight-bold">Passée le</v-col>
          <v-col>{{ dateFormat(commande.passee_le) }}</v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
export default {
  name: "X-modale-recapitulatif-commande",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    commande: {
      type: Object,
      default: null,
    },
  },
  methods: {
    // Permet d'obtenir la date au format JJ/MM/AA
    dateFormat(date) {
      let year = moment(date).toDate().getFullYear();
      if (year === 1 || year === 1899) {
        return "";
      }
      return moment(date).format("L");
    },
  },
};
</script>