import Vue from "vue";
import moment from "moment";
import _ from "lodash";
import Swal from "sweetalert2";

const state = {
  interventionsToday: [],
  interventionsTomorrow: [],
  interventionsOtherDays: [],
  interventionsPasse: [],
};

const getters = {
  getInterventionsToday(state) {
    return state.interventionsToday;
  },
  getInterventionsTomorrow(state) {
    return state.interventionsTomorrow;
  },
  getInterventionsOtherDays(state) {
    return state.interventionsOtherDays;
  },
  getInterventionsPasse(state) {
    return state.interventionsPasse;
  },
};

const mutations = {
  SET_INTERVENTIONS_LIST_TODAY(state, payload) {
    state.interventionsToday = payload;
  },
  UPDATE_INTERVENTION_TODAY(state, payload) {
    let index = _.findIndex(state.interventionsToday, { _id: payload._id });
    if (index !== -1) {
      state.interventionsToday.splice(index, 1, payload);
    }
  },
  SET_INTERVENTIONS_LIST_TOMORROW(state, payload) {
    state.interventionsTomorrow = payload;
  },
  SET_INTERVENTIONS_LIST_OTHER_DAYS(state, payload) {
    state.interventionsOtherDays = payload;
  },
  SET_INTERVENTION_PASSE(state, payload) {
    state.interventionsPasse = payload;
  },
  UPDATE_INTERVENTION_PASSEE(state, payload) {
    console.log(payload, "UPDATE INTERVENTION");
    let index = _.findIndex(state.interventionsPasse, { _id: payload._id });
    console.log(index);
    if (index !== -1) {
      state.interventionsPasse.splice(index, 1, payload);
    }
  },
  UPDATE_TEMPS_GLOBAL_CHANTIER(state, payload) {
    console.log(state);
    for (let intervention of this.getInterventionsToday) {
      if (intervention.compteur == payload.compteur) {
        intervention.chantier == payload.chantier;
      }
    }
    for (let intervention of this.getInterventionsTomorrow) {
      if (intervention.compteur == payload.compteur) {
        intervention.chantier == payload.chantier;
      }
    }
    for (let intervention of this.getInterventionsOtherDays) {
      if (intervention.compteur == payload.compteur) {
        intervention.chantier == payload.chantier;
      }
    }
    for (let intervention of this.getInterventionsPasse) {
      if (intervention.compteur == payload.compteur) {
        intervention.chantier == payload.chantier;
      }
    }
  },
};

const actions = {
  returnInterventionsPoseur({ commit, rootGetters }) {
    
    console.log(rootGetters["auth/userInSession"], "Infos sur l'utilisateur")

    if(rootGetters["auth/userInSession"].role == "ResponsableRenovation"){
Vue.axios.get(`/api/intervention/poseur/responsable/${rootGetters["auth/userInSession"].agence._id}`)
      .then(async (res) => {
        let deuxSemainesEtTroisJoursAvant = moment()
          .subtract(2, "week")
          .subtract(3, "day")
          .set("hour", 5)
          .set("minutes", 0);

        let interventionsToday = [];
        let interventionsTomorrow = [];
        let interventionsOtherDays = [];
        let interventionPassees = [];
        await res.data.interventions.forEach((el) => {
          let jour = moment(el.date_intervention)
            .toDate()
            .getDate();
          let mois = moment(el.date_intervention)
            .toDate()
            .getMonth();
          let annee = moment(el.date_intervention)
            .toDate()
            .getFullYear();

          let jourToday = moment()
            .toDate()
            .getDate();
          let moisToday = moment()
            .toDate()
            .getMonth();
          let anneeToday = moment()
            .toDate()
            .getFullYear();
          let jourSemaineToday = moment().isoWeekday();

          let jourSupp = 1;

          // Si on est vendredi -> on va chercher les interventions de lundi
          if (jourSemaineToday == 5) {
            jourSupp = 3;
          }

          // Si c'est la date du jour
          if (
            annee === anneeToday &&
            mois === moisToday &&
            jour === jourToday
          ) {
            interventionsToday.push(el);

          //si c'est demain
          } else if (
            annee === anneeToday &&
            mois === moisToday &&
            jour === jourToday + jourSupp
          ) {
            interventionsTomorrow.push(el);
          } else {
            // si c'est dans le futur
            if (moment(el.date_intervention).isAfter(moment())) {
              
              // On réinitialise les 2 dates pour qu'elles soient à la même heure
              // sinon l'écart en jours peut varier si jamais il est 17h et que l'inter est à 08h...              
              var dateInter = moment( 
                  [moment(el.date_intervention).toDate().getFullYear(),
                   moment(el.date_intervention).toDate().getMonth(),
                   moment(el.date_intervention).toDate().getDate()]
              );
              var dateJour = moment([moment().toDate().getFullYear(),
                moment().toDate().getMonth(),
                moment().toDate().getDate()]);
              
              let ecartJours = moment(dateInter).diff(dateJour, 'days');

              if(ecartJours >= 1 && ecartJours <= 5){
                interventionsOtherDays.push(el);
              }
          }

            if (
              moment(el.date_intervention).isBefore(moment()) &&
              moment(el.date_intervention).isAfter(
                deuxSemainesEtTroisJoursAvant
              )
            ) {
              interventionPassees.push(el);
            }
          }
        });
        interventionPassees = interventionPassees.sort((a, b) => {
          if (a.date_intervention < b.date_intervention) {
            return -1;
          }
          if (a.date_intervention > b.date_intervention) {
            return 1;
          }
        });
        commit("SET_INTERVENTIONS_LIST_TODAY", interventionsToday);
        commit("SET_INTERVENTIONS_LIST_TOMORROW", interventionsTomorrow);
        commit("SET_INTERVENTIONS_LIST_OTHER_DAYS", interventionsOtherDays);
        commit("SET_INTERVENTION_PASSE", interventionPassees);
      });
    }else{
Vue.axios
      .get(`/api/intervention/poseur/${rootGetters["auth/userInSession"]._id}`)
      .then(async (res) => {
        let deuxSemainesEtTroisJoursAvant = moment()
          .subtract(2, "week")
          .subtract(3, "day")
          .set("hour", 5)
          .set("minutes", 0);

        let interventionsToday = [];
        let interventionsTomorrow = [];
        let interventionsOtherDays = [];
        let interventionPassees = [];
        await res.data.interventions.forEach((el) => {
          let jour = moment(el.date_intervention)
            .toDate()
            .getDate();
          let mois = moment(el.date_intervention)
            .toDate()
            .getMonth();
          let annee = moment(el.date_intervention)
            .toDate()
            .getFullYear();

          let jourToday = moment()
            .toDate()
            .getDate();
          let moisToday = moment()
            .toDate()
            .getMonth();
          let anneeToday = moment()
            .toDate()
            .getFullYear();
          let jourSemaineToday = moment().isoWeekday();

          let jourSupp = 1;

          // Si on est vendredi -> on va chercher les interventions de lundi
          if (jourSemaineToday == 5) {
            jourSupp = 3;
          }

          // Si c'est la date du jour
          if (
            annee === anneeToday &&
            mois === moisToday &&
            jour === jourToday
          ) {
            interventionsToday.push(el);

          //si c'est demain
          } else if (
            annee === anneeToday &&
            mois === moisToday &&
            jour === jourToday + jourSupp
          ) {
            interventionsTomorrow.push(el);
          } else {
            // si c'est dans le futur
            if (moment(el.date_intervention).isAfter(moment())) {
              
              // On réinitialise les 2 dates pour qu'elles soient à la même heure
              // sinon l'écart en jours peut varier si jamais il est 17h et que l'inter est à 08h...              
              var dateInter = moment( 
                  [moment(el.date_intervention).toDate().getFullYear(),
                   moment(el.date_intervention).toDate().getMonth(),
                   moment(el.date_intervention).toDate().getDate()]
              );
              var dateJour = moment([moment().toDate().getFullYear(),
                moment().toDate().getMonth(),
                moment().toDate().getDate()]);
              
              let ecartJours = moment(dateInter).diff(dateJour, 'days');

              if(ecartJours >= 1 && ecartJours <= 5){
                interventionsOtherDays.push(el);
              }
          }

            if (
              moment(el.date_intervention).isBefore(moment()) &&
              moment(el.date_intervention).isAfter(
                deuxSemainesEtTroisJoursAvant
              )
            ) {
              interventionPassees.push(el);
            }
          }
        });
        interventionPassees = interventionPassees.sort((a, b) => {
          if (a.date_intervention < b.date_intervention) {
            return -1;
          }
          if (a.date_intervention > b.date_intervention) {
            return 1;
          }
        });
        commit("SET_INTERVENTIONS_LIST_TODAY", interventionsToday);
        commit("SET_INTERVENTIONS_LIST_TOMORROW", interventionsTomorrow);
        commit("SET_INTERVENTIONS_LIST_OTHER_DAYS", interventionsOtherDays);
        commit("SET_INTERVENTION_PASSE", interventionPassees);
      });
    }


    
  },


  setDateFin({ commit }, payload) {
    Vue.axios.post("/api/intervention/dateFin", payload).then((response) => {
      console.log(response.data);
      commit("UPDATE_INTERVENTION_TODAY", response.data.retour);
      Swal.fire("Intervention validée", "", "success");
    });
  },

  setDateDebut({ commit }, payload) {
    Vue.axios.post("/api/intervention/dateDebut", payload).then((response) => {
      if (
        moment(response.data.retour.date_intervention).isAfter(
          moment().set("h", 1)
        )
      ) {
        commit("UPDATE_INTERVENTION_TODAY", response.data.retour);
      } else {
        commit("UPDATE_INTERVENTION_PASSEE", response.data.retour);
      }
      // commit("UPDATE_TEMPS_GLOBAL_CHANTIER", response.data.retour);
      Swal.fire("Enregistrement réussi", "", "success");
    });
  },

  async envoiEmailPhotoChantier({ rootGetters }, payload) {
    let infoEmailPhoto = new FormData();
    await payload.listePhotos.map((el) => {
      infoEmailPhoto.append("files", el);
    });

    await infoEmailPhoto.append("commentaire", payload.commentaire);
    await infoEmailPhoto.append("numCompteur", payload.numCompteur);
    await infoEmailPhoto.append("emailCommercial", payload.emailCommercial);
    await infoEmailPhoto.append("chantier", JSON.stringify(payload.chantier));
    await infoEmailPhoto.append(
      "user",
      JSON.stringify(rootGetters["auth/userInSession"])
    );
    await infoEmailPhoto.append(
      "listeDestinataires",
      JSON.stringify(payload.listeDestinataires)
    );

    Vue.axios.post("/api/intervention/photo", infoEmailPhoto).then(() => {
      Swal.fire("Photos envoyées", "", "success");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
