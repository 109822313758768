<template>
  <div>
    <x-filtres-livraison></x-filtres-livraison>
    <x-livraison-datatable
      :livraisons="getAllLivraison"
    ></x-livraison-datatable>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import XFiltresLivraison from "../livraison/X-filtres-livraison";
import XLivraisonDatatable from "./X-livraison-datatable.vue";

export default {
  name: "Livraison",
  components: {
    XFiltresLivraison,
    XLivraisonDatatable,
  },
  data() {
    return {
      reload: 0,
    };
  },
  created() {
    this.returntAllLivraisons();
  },
  computed: {
    ...mapGetters("livraison", {
      getAllLivraison: "getLivraisons",
    }),
    ...mapGetters("auth", {
      connectedUser: "userInSession",
    }),
    ...mapGetters("time", {
      currentDate: "getActualDateWithHour",
    }),
  },
  methods: {
    returntAllLivraisons() {
      this.$store.dispatch("livraison/initialiseQuery").then(() => {
        this.$store.dispatch("livraison/returnLivraisons");
      });
    },
  },
};
</script>
