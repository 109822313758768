import Vue from "vue";

///////////Vuex et store
import Vuex from "vuex";
import store from "../store";

///////////Axios pour les requêtes
import axios from "axios";
import VueAxios from "vue-axios";

///////////Utilitaires
import router from "../router";
import Swal from "sweetalert2";

/////////////////Modules
import util from "./modules/util";
import auth from "./modules/auth";
import time from "./modules/time";
import user from "./modules/user";
import admin from "./modules/adminTabs";
import stats from "./modules/statsTime";
import catalog from "./modules/catalog";
import agence from "./modules/agence";
import societe from "./modules/societe";
import rh from "./modules/rh";
import intervention from "./modules/interventions";
import chantier from "./modules/chantier";
import graph from "./modules/graph_msal";
import incident from "./modules/incident";
import prepas from "./modules/prepas";
import ordonnancement from "./modules/ordonnancement";
import livraison from "./modules/livraison";
import palette from "./modules/palette";
import coordonneesGPS from "./modules/coordonneesGPS";
//import articleCatalogue from "./modules/articleCatalogue";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = "http://localhost:3000/";

if (process.env.NODE_ENV === "PRODUCTION") {
  Vue.axios.defaults.baseURL = "https://oscar.poupin.fr:3000/";
}

axios.interceptors.request.use(function(config) {
  const token = "Bearer " + store.getters["auth/getToken"];
  config.headers.Authorization = token;

  return config;
});

// 498 response interceptor
axios.interceptors.response.use(
  function(response) {
    if (response.headers.authorization) {
      store.commit(
        "auth/SET_TOKEN",
        response.headers.authorization.split(" ")[1]
      );
    }
    return response;
  },
  function(error) {
    if (498 === error.response.status) {
      Swal.fire({
        title: "Session expiré",
        text: "Votre session a expiré, merci de vous reconnecter.",
        type: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        if (result) {
          store.dispatch("auth/logout").then(() => {
            if (router.history.current.path.includes("login") === false) {
              router.push("/login");
            }
          });
          Swal.fire("Déconnexion réussie", "", "success");
        }
      });
    } else {
      return Promise.reject(error);
    }
  }
);

export default new Vuex.Store({
  state: {
    turquoisePoupin: "#5f9ea0",
    bleuPalette: "#244A67",
    grisPalette: "#D4D5D3",
    blancPalette: "#E0ECE8",
    orangePalette: "#DA8824",
    orangeAlu: "#F35B04",
    vertBois: "#43a047",
    jaunePVC: "#FFBD00",
    purpleVolet: "#ed3bb6",
    redMagasin: "#f7164b",
    couleurCommandeInterne: "#5baaaa",
    couleurCommandeExterne: "",
  },
  getters: {
    getTurquoisePoupin(state) {
      return state.turquoisePoupin;
    },
    getBleu(state) {
      return state.bleuPalette;
    },
    getGris(state) {
      return state.grisPalette;
    },
    getBlanc(state) {
      return state.blancPalette;
    },
    getOrange(state) {
      return state.orangePalette;
    },
    getOrangeAlu(state) {
      return state.orangeAlu;
    },
    getVertBois(state) {
      return state.vertBois;
    },
    getJaunePVC(state) {
      return state.jaunePVC;
    },
    getPurpleVolet(state) {
      return state.purpleVolet;
    },
    getRedMagasin(state) {
      return state.redMagasin;
    },
    getCouleurCommandeInterne(state) {
      return state.couleurCommandeInterne;
    },
    getCouleurCommandeExterne(state) {
      return state.couleurCommandeExterne;
    },
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    time,
    user,
    admin,
    stats,
    catalog,
    agence,
    societe,
    rh,
    intervention,
    util,
    chantier,
    graph,
    incident,
    prepas,
    ordonnancement,
    livraison,
    palette,    
    coordonneesGPS
  },
});
