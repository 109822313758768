<template>
  <!--Ma carte d'intervention-->
  <v-card width="92%" max-width="700px" class="my-2">
    <v-list-item three-line>
      <v-list-item-avatar >
        <v-icon
          v-if="user.role == 'Poseur'"
          :class="`${codeCouleur(inter.type_intervention)}`"
        >
          mdi-wrench
        </v-icon>           
      <v-icon 
          v-if="user.role == 'PoseurRenovation' || user.role == 'ResponsableRenovation'"
       @click="openInfosChantiers">mdi-information-outline</v-icon>   
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{
          inter.chantier.nom_client.split(/[0-9]/)[0]
        }}</v-list-item-title>
        <v-list-item-title>{{
          inter.chantier.nom_client.substring(
            inter.chantier.nom_client.search(/[0-9]/)
          )
        }}</v-list-item-title>
        <v-list-item-subtitle>{{
          inter.date_intervention | fullDate
        }}</v-list-item-subtitle>
      </v-list-item-content>
      <!-- Barre d'heures seulement pour les poseurs de la réno pour le moment -->
      <v-list-item-action v-if="this.today && (user.role == 'PoseurRenovation' || user.role == 'ResponsableRenovation')">
        <v-row>
          <v-col>
            <v-btn
              :color="inter.temps_passe > 10 ? 'green' : 'blue'"
              class="white--text"
              @click="setDebut"
            >
              <v-icon>
                mdi-timer
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="inter.temps_passe > 10">
          <v-col class="font-weight-bold text-center green--text">
            {{ formatNumberInHeureMinute(inter.temps_passe / 60) }}
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>
    <v-row  v-if="user.role == 'PoseurRenovation' || user.role == 'ResponsableRenovation'">
      <v-col>
        <span class="text-caption font-weight-bold mx-2">
          {{
            formatNumberInHeureMinute(
              inter.chantier.temps_pose_deja_realise / 60
            )
          }}
          <span class="text-caption">effectuées /</span>
        </span>

        <span class="text-caption font-weight-bold"
          >{{
            formatNumberInHeureMinute(
              (inter.chantier.temps_pose_prevu_finitions +
                inter.chantier.temps_pose_prevu_premiere_pose) /
                60
            )
          }}
          <span class="text-caption">vendues</span></span
        >
        <v-progress-linear
          :color="
            definirCouleurProgressBarChantier(
              inter.chantier.temps_pose_prevu_finitions +
                inter.chantier.temps_pose_prevu_premiere_pose,
              inter.chantier.temps_pose_deja_realise
            )
          "
          height="10"
          :value="
            calculPourcentageAvanceeChantier(
              inter.chantier.temps_pose_prevu_finitions +
                inter.chantier.temps_pose_prevu_premiere_pose,
              inter.chantier.temps_pose_deja_realise
            )
          "
          striped
        ></v-progress-linear>
      </v-col>
    </v-row>
    <!-- V-CARD ACTION -->
    <v-card-actions class="my-0 py-0 mx-0 px-0">
      <!-- GPS -->
      <v-btn 
        width="25%" text tile color="blue" @click="openGoogleMap">
        <v-icon>mdi-google-maps</v-icon>
      </v-btn>       
      <!-- TEL/MAIL CLIENT -->
      <v-btn
        text
        tile
        color="blue"
        width="25%"
        @click="openContacts"
        ><v-icon>mdi-phone-outline</v-icon>
      </v-btn>
      <!-- BOOK PHOTOS -->
      <v-btn
        text
        tile
        color="blue"
        width="25%"
        @click="booleanModalePhoto = true"
      >
        <v-icon>mdi-camera</v-icon>
      </v-btn>

      <!-- INCIDENT -->
      <v-btn text tile color="red" @click="openModaleIncident" width="20%">
        <v-icon>mdi-alert-outline</v-icon>
      </v-btn>      
    </v-card-actions>
    <!-- MODALE HEURE DE DEBUT -->
    <v-dialog v-model="modalHeure" max-width="400px" content-class="mx-2">
      <v-card>
        <v-card-title class="mb-2">HEURES CHANTIER</v-card-title>
        <v-card-subtitle>
          {{ inter.chantier.nom_client }}
        </v-card-subtitle>
        <v-card-text class="text-center">
          <v-row class="text-center  black--text text-subtitle-2">
            <v-col>Intégrer la pause du midi ?</v-col>
            <v-col>
              <v-checkbox
                v-model="booleanPause1h"
                :disabled="booleanPause1h30"
                label="1h"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="booleanPause1h30"
                :disabled="booleanPause1h"
                label="1h30"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="black--text font-weight-bold">
              Présent sur cette intervention
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col
              v-for="(poseur, index) in listePoseurIntervention"
              :key="poseur._id"
            >
              <v-checkbox
                v-model="tabBooleanCheckboxPoseurs[index].selected"
                :disabled="poseur.id == user._id"
                :label="poseur.prenom"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mb-2"> </v-row>
        <v-time-picker
            v-model="date_debut"
            format="24hr"
            locale="fr"
            :allowed-minutes="allowedStep"
            min="6:00"
            max="20:00"
            :color="getTurquoisePoupin"
          ></v-time-picker>
          <v-time-picker
            v-model="date_fin"
            format="24hr"
            locale="fr"
            :allowed-minutes="allowedStep"
            min="6:00"
            max="20:00"
            :color="getTurquoisePoupin"
          ></v-time-picker>        
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="modalHeure = false">Annuler</v-btn>
          <v-btn
            v-if="date_debut && date_fin"
            outlined
            color="green"
            @click="enregistreHeureChantier(date_debut, date_fin)"
            >Valider
          </v-btn>
          <v-btn v-else disabled outlined :color="getTurquoisePoupin">
            valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MODALE INCIDENT CHANTIER -->
    <v-dialog v-model="booleanIncident" width="500px">
      <x-incident-modale @closeModale="closeModaleIncident"></x-incident-modale>
    </v-dialog>


        <!--Modale d'accès aux contacts d'un chantier (Client, MOE)-->
    <v-dialog v-model="booleanModaleContactClient" width="500px">
    <x-modale-contact-reno
      @closeModale="closeModaleContacts"
    ></x-modale-contact-reno>
    </v-dialog>

    <!-- MODALE PHOTOS CHANTIER -->
    <v-dialog v-model="booleanModalePhoto" width="500px">
      <v-card>
        <v-card-title>
          Ajouter des photos pour le book photos
        </v-card-title>
        <v-card-text>
          <v-file-input
            v-model="photoChantierActuelle"
            outlined
            label="Photos du chantier terminé"
            truncate-length="15"
          ></v-file-input>
          <v-row>
            <v-col>
              <v-textarea
                v-model="commentairePhoto"
                label="Commentaire photos"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row
            v-for="(item, index) in listePhotoChantier"
            :key="Math.random() * index"
          >
            <v-col>
              {{ item.name }}
              <v-progress-linear
                value="100"
                striped
                color="green"
                height="8px"
                class="mb-2"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn color="red white--text" @click="booleanModalePhoto = false"
                >Retour</v-btn
              >
            </v-col>
            <v-col class="text-right"
              ><v-btn color="green white--text" @click="envoiEmailPhotoChantier"
                >Envoyer
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Modale de mise à jour de l'avancement du chantier-->
    <v-dialog v-model="booleanModaleInfoChantier"  width="500px" >
      <x-modale-infos-chantier @close="closeModaleInfoChantier"></x-modale-infos-chantier>

    </v-dialog>


  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import Vue from "vue";
import XIncidentModale from "@/components/incident/X-incident-modale";
import XModaleContactReno from "@/components/intervention/X-modale-contact-reno";
import XModaleInfosChantier from '../chantier/info_chantier/X-modale-infos-chantier-reno.vue';
export default {
  name: "X-intervention-card",
  components: { XIncidentModale, XModaleContactReno, XModaleInfosChantier },
  data() {
    return {
      modalHeure: false,
      modalDuree: false,
      date_fin: null,
      date_debut: null,
      booleanPause1h: false,
      booleanPause1h30: false,
      listePoseurIntervention: [],
      tabBooleanCheckboxPoseurs: [],
      // Photos
      booleanModalePhoto: false,
      photoChantierActuelle: null,
      listePhotoChantier: [],
      commentairePhoto: "",
      // Incidents
      booleanIncident: false,
      //Contacts client      
      booleanModaleContactClient: false,
      //Infos chantier
      booleanModaleInfoChantier: false,
      nbInfosChantier: 0
    };
  },
  props: {
    inter: {
      type: Object,
      default() {
        return {
          chantier: {
            nom_client: "une patate",
          },
          date_intervention: "une carotte",
          type_intervention: "ein kartoffel",
        };
      },
    },
    today: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  mounted() {
    this.recupListePoseurs();
  },
  methods: {
    allowedStep: (m) => m % 5 === 0, 

    codeCouleur(type) {
      if (type === "1ère pose") {
        return "black--text yellow darken-1";
      } else if (type === "Finitions") {
        return "white--text pink darken-1";
      } else {
        return "orange darken-3 white--text";
      }
    },
    openGoogleMap() {
      const urlSuffix = this.inter.chantier.adresse_client_gps
        ? this.inter.chantier.adresse_client_gps
        : this.inter.chantier.adresse_client;

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    async recupListePoseurs() {
      let dateDebut = moment(this.inter.date_intervention)
        .set("hour", 6)
        .set("minute", 0)
        .toDate();
      let dateFin = moment(this.inter.date_intervention)
        .set("hour", 23)
        .set("minute", 59)
        .toDate();
      let idCompteur = this.inter.compteur;

      await Vue.axios
        .get(
          "/api/intervention/intervention/" +
            idCompteur +
            "/" +
            dateDebut +
            "/" +
            dateFin
        )
        .then((res) => {
          for (let intervention of res.data.interventions) {
            for (let poseur of intervention.poseurs) {
              if (!this.listePoseurIntervention.includes(poseur._id)) {
                this.listePoseurIntervention.push({
                  id: poseur._id,
                  prenom: poseur.prenom,
                });
                this.tabBooleanCheckboxPoseurs.push({
                  selected: true,
                  idPoseur: poseur._id,
                });
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openModaleIncident() {
      this.setChantier(this.inter.chantier);
      this.booleanIncident = true;
    },
    openContacts() {
      this.setChantier(this.inter.chantier);
      this.booleanModaleContactClient = true;
    },
    closeModaleContacts() {      
      console.log("HIDE MODALE DANS PARENT");
      this.booleanModaleContactClient = false;
    },
    closeModaleIncident() {
      console.log("HIDE MODALE DANS PARENT");
      this.booleanIncident = false;
    },
    closeModaleInfoChantier() {      
      //console.log("HIDE MODALE DANS PARENT");
      this.booleanModaleInfoChantier = false;
    },

    async openInfosChantiers() {
      console.log("Infos chantiers");
      this.setChantier(this.inter.chantier);   
      this.booleanModaleInfoChantier = true;
    },

    setChantier(chantier) {
      this.$store.dispatch("chantier/setChantier", chantier).then(() => {
        this.$store.dispatch("prepas/returnPrepas");
        this.$store.dispatch("chantier/returnInfosChantier");
      });
    },
    setDebut() {
      this.date_debut = moment(this.inter.date_intervention).toDate();
      this.date_fin =
        moment(this.inter.date_fin).year() > 1
          ? moment(this.inter.date_fin).toDate()
          : moment(this.inter.date_intervention)
              .set("hour", 17)
              .set("minute", 0);

      this.date_debut = this.date_debut
        ? this.date_debut
        : moment()
            .set("hour", 8)
            .set("minute", 0)
            .toDate();
      this.modalHeure = true;
    },
    setTerminee() {
      this.date_fin = this.date_fin ? this.date_fin : new Date();
      this.modalHeureFin = true;
    },
    setDuree() {
      this.modalDuree = true;
    },
    updateTerminee() {
      this.date_fin = moment(this.inter.date_fin).format("LT");
      this.modalHeureFin = true;
    },
    setDateFin(val) {
      this.$store.dispatch("intervention/setDateFin", {
        id: this.inter._id,
        heure: val,
      });
    },
    setDateDebut(debut, fin, pause1h, pause1h30, tabPoseur) {
      let chantier = this.inter.chantier;
      this.$store.dispatch("intervention/setDateDebut", {
        intervention: this.inter,
        compteur: this.inter.compteur,
        chantier: chantier,
        heureDebut: debut,
        heureFin: fin,
        pause1h: pause1h,
        pause1h30: pause1h30,
        tabPoseur,
      });
    },
    calculPourcentageAvanceeChantier(tempsPrevu, temps_passe) {
      return (temps_passe * 100) / tempsPrevu;
    },
    definirCouleurProgressBarChantier(tempsPrevu, temps_passe) {
      if (this.calculPourcentageAvanceeChantier(tempsPrevu, temps_passe) < 50) {
        return "green";
      }
      if (
        this.calculPourcentageAvanceeChantier(tempsPrevu, temps_passe) > 50 &&
        this.calculPourcentageAvanceeChantier(tempsPrevu, temps_passe) < 75
      ) {
        return "blue";
      }
      if (
        this.calculPourcentageAvanceeChantier(tempsPrevu, temps_passe) > 74 &&
        this.calculPourcentageAvanceeChantier(tempsPrevu, temps_passe) < 100
      ) {
        return "orange";
      }
      if (
        this.calculPourcentageAvanceeChantier(tempsPrevu, temps_passe) > 100
      ) {
        return "red";
      }
    },
    // Convertir les minutes en format heure (ex : 09h03, 17h16 ...)
    formatNumberInHeureMinute(number) {
      let heures = Math.trunc(number);
      if (heures === Infinity) {
        return 0;
      }
      let minutes =
        Math.round((number % 1) * 60) > 9 // Récupération des chiffres après la virgule et conversion en minutes
          ? Math.round((number % 1) * 60)
          : "0" + Math.round((number % 1) * 60); // La ternaire évite les formats 1h0, 2h4 ... en cas de résultat = 0, 4 par exemple ( attendu 1h00, 2h04)
      if (Math.round((number % 1) * 60) < 0) {
        minutes = minutes
          .toString()
          .replace("-", "")
          .substring(1, 3); // Gestion des chiffres négatifs
        if (minutes.length < 2) {
          // Correction format 1h2 pours les chiffres négatifs
          minutes = "0" + minutes;
        }
      }
      let numberFormated = (heures + "h" + minutes).replace("-", "");
      return numberFormated;
    },
    // MODIFIER LES HEURES CHANTIERS
    enregistreHeureChantier(heureDebut, heureFin) {

        let heureDebutChantier = moment(heureDebut, "hh:mm").toDate();
        let heureFinChantier = moment(heureFin, "hh:mm").toDate();

        this.modalHeure = false;
        Swal.fire({
          text: `Confirmez-vous la saisie d'heures pour le chantier ${this.inter.chantier.nom_client} ?`,
          showCloseButton: true,
          showDenyButton: true,
          confirmButtonText: "Valider",
          confirmButtonAriaLabel: "Thumbs up, great!",
          denyButtonText: "Retour",
          denyButtonColor: "red",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.setDateDebut(
              heureDebutChantier,
              heureFinChantier,
              this.booleanPause1h,
              this.booleanPause1h30,
              this.tabBooleanCheckboxPoseurs
            );
            // this.setDateFin(heureFinChantier);
          } else if (result.isDenied) {
            this.modalHeure = true;
          }
        });

 
    },
    verifDateFin(dateFin) {
      if (moment(dateFin).year() > 2000) {
        return true;
      }
      return false;
    },
    // UNIQUEMENT LA RENO POUR LE MOMENT
    envoiEmailPhotoChantier() {
      let tabReceptionMail;

      // if (this.user.agence.libelle == "NEUF") {
      //   tabReceptionMail = [
      //     "coordinateurPlanningNeufRuillé",
      //     "responsableOrdonnancement",
      //   ];
      // }
      
      if (this.user.agence.libelle == "RENO RUILLEE") {
        tabReceptionMail = ["accueilLaval"];
      }

      let payload = {
        listePhotos: this.listePhotoChantier,
        emailCommercial: this.inter.chantier.adresseEmailCommercial,
        commentaire: this.commentairePhoto,
        listeDestinataires: tabReceptionMail,
        numCompteur: this.inter.compteur,
        chantier: this.inter.chantier,
      };

      this.$store
        .dispatch("intervention/envoiEmailPhotoChantier", payload)
        .then(() => {
          this.booleanModalePhoto = false;
          this.photoChantierActuelle = null;
          this.listePhotoChantier = [];
          this.commentairePhoto = "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters(["getTurquoisePoupin"]),
    ...mapGetters("auth", {
      user: "userInSession",
    }),
    ...mapActions("incident", {
      showModaleIncident: "showModaleIncident",
      // showModaleHistorique: "showModaleHistorique",
      // returnIncidentsChantier: "returnIncidentsChantier",
    }),

    
  },
  watch: {
    photoChantierActuelle(newValue) {
      if (this.photoChantierActuelle) {
        this.listePhotoChantier.push(newValue);
      }
    },
  },
};
</script>

<style scoped></style>
