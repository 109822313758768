import Vue from "vue";
import moment from "moment";

const state = {
  livraisons: [],
  query: {},
  tabLivraisonsChargeesTotales: [], // Récupère une liste de livraison pour rédaction du mail récapitulatif
  tabLivraisonsChargeesPartielles: [], // Idem avec des livraisons partielles
  newCommande: null, // Commande ajoutée à la main par les livreurs
};

const getters = {
  getLivraisons(state) {
    return state.livraisons;
  },
  getQuery(state) {
    return state.query;
  },
  getTabLivraisonsChargeesTotales(state) {
    return state.tabLivraisonsChargeesTotales;
  },
  getTabLivraisonsChargeesPartielles(state) {
    return state.tabLivraisonsChargeesPartielles;
  },
  getNewCommande(state) {
    return state.newCommande;
  },
};

const mutations = {
  SET_QUERY(state, payload) {
    state.query = payload;
  },
  SET_QUERY_DATE_DEBUT(state, payload) {
    state.query.dateDebut = payload;
    state.query.currentWeek = moment(payload).format("W");
  },
  DELETE_QUERY_DATE_DEBUT(state) {
    delete state.query.dateDebut;
    delete state.query.currentWeek;
  },
  SET_QUERY_AGENCE(state, payload) {
    state.query.agence = payload;
  },
  DELETE_QUERY_AGENCE(state) {
    delete state.query.agence;
  },
  SET_LIVRAISONS(state, livraisons) {
    state.livraisons = livraisons;
  },
  SET_PUSH_NEW_LIVRAISON(state, payload) {
    let booleanCheck = false;
    state.livraisons.forEach((livraison) => {
      if (livraison.idERP === payload.idERP) {
        livraison = payload;
        booleanCheck = true;
      }
    });
    if (!booleanCheck) {
      state.livraisons.push(payload);
    }
  },
  SET_QUERY_NOM_CLIENT(state, payload) {
    state.query.nom_client = payload;
  },
  DELETE_QUERY_NOM_CLIENT(state) {
    delete state.query.nom_client;
  },
  SET_QUERY_NUMERO_COMMANDE_INTERNE(state, payload) {
    state.query.numero_commande_interne = payload;
    delete state.query.numero_commande_externe;
  },
  DELETE_QUERY_NUMERO_COMMANDE_INTERNE(state) {
    delete state.query.numero_commande_interne;
  },
  SET_QUERY_NUMERO_COMMANDE_EXTERNE(state, payload) {
    state.query.numero_commande_externe = payload;
    delete state.query.numero_commande_interne;
  },
  DELETE_QUERY_NUMERO_COMMANDE_EXTERNE(state) {
    delete state.query.numero_commande_externe;
  },
  // Mutation livraison (chargee, statut, commentaire_libre)
  SET_ONE_LIVRAISON(state, payload) {
    let id = payload.livraisonID;
    state.livraisons.forEach((livraison) => {
      if (livraison._id === id) {
        // Modif "chargee"
        if (
          payload.chargee_value !== livraison.chargee_value &&
          payload.chargee_value !== null
        ) {
          livraison.chargee = payload.chargee_value;
        }
        // Modif statut
        if (payload.statut_value === 3) {
          livraison.etat_demandes = payload.statut_value;
          livraison.date_livraison_partielle = payload.date_jour;
        }
        if (payload.statut_value === 4) {
          livraison.etat_demandes = payload.statut_value;
          livraison.date_livraison_reelle = payload.date_jour;
        }
        if (payload.statut_value === 5) {
          livraison.etat_demandes = payload.statut_value;
        }
      }
    });
  },
  SET_TAB_LIVRAISONS_CHARGEES_TOTALES(state, payload) {
    state.tabLivraisonsChargeesTotales = payload.tabLivraisons;
  },
  DELETE_TAB_LIVRAISONS_CHARGEES_TOTALES() {
    state.tabLivraisonsChargeesTotales = [];
  },
  SET_TAB_LIVRAISONS_CHARGEES_PARTIELLES(state, payload) {
    state.tabLivraisonsChargeesPartielles = payload.tabLivraisons;
  },
  DELETE_TAB_LIVRAISONS_CHARGEES_PARTIELLES(state) {
    state.tabLivraisonsChargeesPartielles = [];
  },
  SET_NEW_COMMANDE(state, payload) {
    state.newCommande = payload;
  },
};

const actions = {
  // Création d'une nouvelle palette directement par le livreur
  async createNewPalette({ getters, commit }, payload) {
    console.log(getters);
    console.log(commit);
    let date = moment().toDate();
    payload.date_jour = date;
    payload.id_palette = 1;

    if (payload) {
      await Vue.axios.post("/api/palette", payload).then((res) => {
        //commit('SET_PUSH_NEW_LIVRAISON', res.data)
        console.log(res);
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
