import Vue from "vue";
import App from "./App.vue";

//////////Plugins
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@/plugins/apexcharts";
require("dotenv").config();

//////////Utilitaires
const moment = require("moment");
const _ = require("lodash");

//////////Config
Vue.config.productionTip = false;
Vue.config.performance = true;
moment.locale("fr");


/// Leafmap
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

//////////Filtres
Vue.filter("toIso", function(value) {
  if (!value) return "";
  let dValue = moment(value).toDate();
  let sValue = dValue.toISOString();
  return sValue;
});

Vue.filter("simpleDate", function(value) {
  if (!value) return "";
  value = moment(value).format("L");
  return value;
});

Vue.filter("simpleTime", function(value) {
  if (!value) return "";
  value = moment(value).format("HH") + "h" + moment(value).format("mm");
  return value;
});

Vue.filter("jourDate", function(value) {
  if (!value) return "";
  value = moment(value).format("dddd Do");
  return value.toUpperCase();
});

Vue.filter("fullDate", function(value) {
  if (!value) return "";
  value = moment(value).format("ddd Do MMM YYYY");
  return value;
});

Vue.filter("toIso", function(value) {
  if (!value) return "";
  let dValue = moment(value);
  let sValue = dValue.toDate().toISOString();
  return sValue;
});

Vue.filter("semaineEtDate", function(value) {
  if (!value) return "";
  let dValue = moment(value);
  let sValue =
    "S" +
    dValue.format("W - yyyy") +
    " (" +
    dValue.format("ddd Do MMM YYYY") +
    ")";
  return sValue;
});

Vue.filter("semaine", function(value) {
  if (!value) return "";
  let dValue = moment(value);
  let sValue = "S" + dValue.format("W - yyyy");
  return sValue;
});

Vue.filter("uppercase", function(value) {
  if (!value) return "";
  value = _.upperCase(value);
  return value;
});

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = _.startCase(_.lowerCase(value));
  return value;
});

Vue.filter("humantime", function(value) {
  if (typeof value == "number") {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    if (hours !== 0 && minutes !== 0) {
      return `${hours}h${minutes}min`;
    } else if (hours === 0 && minutes !== 0) {
      return `${minutes}min`;
    } else {
      return `${hours}h`;
    }
  } else {
    return "Pas de données";
  }
});

/////////////Instance de vue
let app = new Vue({
  /*beforeCreate() {
    this.$store.commit('auth/INITIALISATION_STORE')
  },*/
  router,
  store,
  vuetify,
  render: (h) => h(App),
});

app.$mount("#app");

if (window.Cypress) {
  window.app = app;
}
