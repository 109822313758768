<template>
  <div>
    <v-form
        method="post"
        ref="mdpform"
        v-model="valid"
        lazy-validation
    >

      <v-text-field
          :type="hide ? 'password' : 'text'"
          v-model="mdp"
          label="Mot de passe"
          :rules="[ v => !!v || 'Mot de passe obligatoire' ]"
          :append-icon="hide ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword"
      ></v-text-field>

      <v-btn @click="$emit('close')" text>Fermer</v-btn>
      <v-btn outlined color="green" :disabled="!valid" @click="validate">Modifier</v-btn>

    </v-form>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "UserMDPForm",
  data() {
    return {
      valid: true,
      mdp: null,
      hide: true
    }
  },
  methods: {
    validate(e) {
      new Promise( resolve => {
        this.$refs.mdpform.validate();
        resolve();
      }).then( () => {
            if(this.valid) {
              this.updatePassword(e)
            }
          })
    },
    updatePassword(e) {
      this.$store.dispatch('user/updateUserPassword', this.mdp)
          .then( () => {
            this.$emit('close')
          })
      e.preventDefault()
    },
    showPassword() {
      this.hide = !this.hide;
    }
  },
  computed: {
    ...mapGetters('user', {
      getUser: 'getUser'
    })
  },
}
</script>

<style scoped>

</style>