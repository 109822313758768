<template>
  <v-dialog
      :value="getModaleHistorique"
      @click:outside="hideModaleHistorique"
      max-width="550px"
  >
    <v-card>
      <v-card-title>{{ 'Historique des incidents' | uppercase }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-2">
        <v-expansion-panels inset>
          <div v-if="getIncidents.length = 0">Pas d'incidents déclarés</div>
          <v-expansion-panel v-for="(incident, i) in getIncidents" :key="i">
            <v-expansion-panel-header ripple>{{ incident.creeLe | fullDate }} {{ incident.creePar.nom }} {{ incident.creePar.prenom }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item two-line>
                <v-list-item-content>
                  <div><span>Commentaire:</span> <q class="font-italic">{{ incident.commentaire }}</q></div>
                </v-list-item-content>

              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer><v-btn text @click="hideModaleHistorique">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';


export default {
  name: "X-incident-historique-modale",
  methods: {
    ...mapActions('incident', {
      hideModaleHistorique: 'hideModaleHistorique',
    })
  },
  computed: {
    ...mapGetters('incident', {
      getModaleHistorique: 'getModaleHistorique',
      getIncidents: 'getIncidents'
    }),

    ...mapGetters('chantier', {
      getChantier: 'getChantier',
    }),
  },
}
</script>

<style scoped>

</style>