var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.isVisible, "max-width": "600", scrollable: true },
      on: {
        "click:outside": function ($event) {
          return _vm.$emit("closeModaleRecapitulatifCommande")
        },
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "overflow-y-hidden" },
        [
          _c("v-card-title", { staticClass: "text-center" }, [
            _vm._v(_vm._s("Commande n° " + _vm.commande.numero_commande)),
          ]),
          _c(
            "v-container",
            { staticClass: "mx-2 text-center" },
            [
              _c(
                "v-row",
                { staticClass: "mx-2 text-center" },
                [
                  _c("v-col", { staticClass: "font-weight-bold" }, [
                    _vm._v(" Description "),
                  ]),
                  _c("v-col", [_vm._v(_vm._s(_vm.commande.description))]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-2 text-center" },
                [
                  _c("v-col", { staticClass: "font-weight-bold" }, [
                    _vm._v("Atelier"),
                  ]),
                  _c("v-col", [_vm._v(_vm._s(_vm.commande.atelier))]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-2 text-center" },
                [
                  _c("v-col", { staticClass: "font-weight-bold" }, [
                    _vm._v("Délai sortie atelier"),
                  ]),
                  _c("v-col", [
                    _vm._v(
                      _vm._s(
                        _vm.dateFormat(_vm.commande.date_livraison_confirmee)
                      )
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-2 text-center" },
                [
                  _c("v-col", { staticClass: "font-weight-bold" }, [
                    _vm._v("Famille"),
                  ]),
                  _c("v-col", [_vm._v(_vm._s(_vm.commande.famille))]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-2 text-center" },
                [
                  _c("v-col", { staticClass: "font-weight-bold" }, [
                    _vm._v("Sous-famille"),
                  ]),
                  _c("v-col", [_vm._v(_vm._s(_vm.commande.sous_famille))]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-2 text-center" },
                [
                  _c("v-col", { staticClass: "font-weight-bold" }, [
                    _vm._v("Passée par"),
                  ]),
                  _c("v-col", [_vm._v(_vm._s(_vm.commande.passee_par))]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-2 text-center" },
                [
                  _c("v-col", { staticClass: "font-weight-bold" }, [
                    _vm._v("Passée le"),
                  ]),
                  _c("v-col", [
                    _vm._v(_vm._s(_vm.dateFormat(_vm.commande.passee_le))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }