import Vue from "vue";

const state = {
  prepas: {},
};

const getters = {
  getPrepas(state) {
    return state.prepas;
  },
};

const mutations = {
  SET_PREPAS(state, payload) {
    state.prepas = payload;
  },
};

const actions = {
  returnPrepas({ commit, rootGetters }) {
    Vue.axios
      .get(
        `/api/intervention/prepas/${rootGetters["chantier/getChantier"]._id}`
      )
      .then((response) => {
        commit("SET_PREPAS", response.data.retour);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
