import Vue from "vue";
import _ from "lodash";

const state = {
  currentUser: {},
  users: [],
  errors: [],
};

const getters = {
  getUser(state) {
    return state.currentUser;
  },
  getUsers(state) {
    return state.users;
  },
};

const mutations = {
  SET_USER(state, user) {
    state.currentUser = user;
  },
  ADD_USER(state, user) {
    state.users.push(user);
  },
  UPDATE_USER(state, user) {
    let index = _.findIndex(state.users, { _id: user._id });
    state.users.splice(index, 1, user);
  },
  DELETE_USER(state, idUser) {
    let index = _.findIndex(state.users, { _id: idUser });
    state.users.splice(index, 1);
  },
  SET_USERNAME(state, username) {
    state.currentUser.username = username;
  },
  SET_NOM_USER(state, nom) {
    state.currentUser.nom = nom;
  },
  SET_PRENOM_USER(state, prenom) {
    state.currentUser.prenom = prenom;
  },
  SET_ID_ERP_USER(state, idERP) {
    state.currentUser.idERP = idERP;
  },
  SET_PASSWORD_USER(state, password) {
    state.currentUser.password = password;
  },
  SET_MAIL_USER(state, mail) {
    state.currentUser.email = mail;
  },
  SET_PHONE_USER(state, phone) {
    state.currentUser.phone = phone;
  },
  SET_AGENCE_USER(state, idAgence) {
    state.currentUser.idAgence = idAgence;
  },
  SET_SOCIETE_USER(state, idSociete) {
    state.currentUser.idSociete = idSociete;
  },
  SET_ROLE_USER(state, role) {
    state.currentUser.role = role;
  },
  SET_DEFAULT_LIEU_DEPART(state, payload) {
    state.currentUser.timeDefault.lieu_depart = payload;
  },
  SET_DEFAULT_LIEU_ARRIVEE(state, payload) {
    state.currentUser.timeDefault.lieu_arrivee = payload;
  },
  SET_DEFAULT_HEURE_DEPART(state, payload) {
    state.currentUser.timeDefault.heure_depart = payload;
  },
  SET_DEFAULT_PREMIER_CHANTIER(state, payload) {
    state.currentUser.timeDefault.heure_arrivee_premier_chantier = payload;
  },
  SET_DEFAULT_TEMPS_PAUSE(state, payload) {
    state.currentUser.timeDefault.temps_pause = payload;
  },
  SET_DEFAULT_DERNIER_CHANTIER(state, payload) {
    state.currentUser.timeDefault.heure_depart_dernier_chantier = payload;
  },
  SET_DEFAULT_HEURE_ARRIVEE(state, payload) {
    state.currentUser.timeDefault.heure_arrivee = payload;
  },
  SET_USERS(state, users) {
    state.users = users;
  },
};

const actions = {
  addUser({ commit, getters }) {
    Vue.axios
      .post("/api/auth/signup", getters.getUser)
      .then((res) => {
        commit("ADD_USER", res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  updateUserInfo({ getters }) {
    Vue.axios
      .post("/api/auth/update/info", getters.getUser)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  },
  updateUserPassword({ getters }, password) {
    Vue.axios
      .post("/api/auth/update/password", {
        user: getters.getUser._id,
        password: password,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  updateHoraireDefaut({ getters }) {
    Vue.axios.post("/api/auth/horaires", {
      user: getters.getUser._id,
      horaire: getters.getUser.timeDefault,
    });
  },
  deleteUser({ commit, getters, rootGetters }) {
    Vue.axios
      .get(
        `/api/auth/delete/${getters.getUser._id}/${rootGetters["auth/userInSession"]._id}`
      )
      .then(() => {
        commit("DELETE_USER", getters.getUser._id);
      });
  },
  setLocked({ commit, getters }, value) {
    Vue.axios
      .get(`/api/auth/lock/${getters.getUser._id}/${value}`)
      .then((res) => {
        commit("UPDATE_USER", res.data);
      });
  },
  setUser({ commit }, user) {
    commit("SET_USER", user);
  },
  setUsername({ commit }, username) {
    commit("SET_USERNAME", username);
  },
  setNomUser({ commit }, nom) {
    commit("SET_NOM_USER", nom);
  },
  setPrenomUser({ commit }, prenom) {
    commit("SET_PRENOM_USER", prenom);
  },
  setIdErpUser({ commit }, idERP) {
    commit("SET_ID_ERP_USER", idERP);
  },
  setPasswordUser({ commit }, password) {
    commit("SET_PASSWORD_USER", password);
  },
  setMailUser({ commit }, mail) {
    commit("SET_MAIL_USER", mail);
  },
  setPhoneUser({ commit }, phone) {
    commit("SET_PHONE_USER", phone);
  },
  setAgenceUser({ commit }, idAgence) {
    commit("SET_AGENCE_USER", idAgence);
  },
  setSocieteUser({ commit }, idSociete) {
    commit("SET_SOCIETE_USER", idSociete);
  },
  setRoleUser({ commit }, role) {
    commit("SET_ROLE_USER", role);
  },
  setDefaultLieuDepart({ commit }, payload) {
    commit("SET_DEFAULT_LIEU_DEPART", payload);
  },
  setDefaultLieuArrivee({ commit }, payload) {
    commit("SET_DEFAULT_LIEU_ARRIVEE", payload);
  },
  setDefaultHeureDepart({ commit }, payload) {
    commit("SET_DEFAULT_HEURE_DEPART", payload);
  },
  setDefaultPremierChantier({ commit }, payload) {
    commit("SET_DEFAULT_PREMIER_CHANTIER", payload);
  },
  setDefaultTempsPause({ commit }, payload) {
    commit("SET_DEFAULT_TEMPS_PAUSE", payload);
  },
  setDefaultDernierChantier({ commit }, payload) {
    commit("SET_DEFAULT_DERNIER_CHANTIER", payload);
  },
  setDefaultHeureArrivee({ commit }, payload) {
    commit("SET_DEFAULT_HEURE_ARRIVEE", payload);
  },
  returnPoseursParAgence({ commit, rootGetters }) {
    let agence = rootGetters["time/getQuery"].agence
      ? rootGetters["time/getQuery"].agence
      : null;
    if (agence) {
      Vue.axios
        .get(`/api/auth/poseurs/agence/${agence}`)
        .then((res) => {
          commit("SET_USERS", res.data);
        })
        .catch();
    } else {
      Vue.axios
        .get("/api/auth/poseurs")
        .then((res) => {
          commit("SET_USERS", res.data);
        })
        .catch();
    }
  },
  returnUsers({ commit }) {
    Vue.axios
      .get("/api/auth/users")
      .then((res) => {
        commit("SET_USERS", res.data);
      })
      .catch();
  },
  async ajouterFavoris({ commit, rootGetters }, payload) {
    let user = await rootGetters["auth/userInSession"];
    if (
      user.chantiers_favoris === undefined ||
      user.chantiers_favoris === null
    ) {
      commit("auth/INITIALISATION_FAVORIS", null, { root: true });
    }
    await commit("auth/ADD_FAVORIS", payload._id, { root: true });
    await commit("chantier/ADD_CHANTIERS_FAVORIS", payload, { root: true });

    Vue.axios.post("/api/auth/update/info", user).then((retour) => {
      console.log(retour);
    });
  },
  async supprimerFavoris({ commit, rootGetters }, payload) {
    let user = await rootGetters["auth/userInSession"];
    if (
      user.chantiers_favoris === undefined ||
      user.chantiers_favoris === null
    ) {
      return;
    }

    await commit("auth/REMOVE_FAVORIS", payload, { root: true });
    await commit("chantier/REMOVE_CHANTIERS_FAVORIS", payload, { root: true });

    Vue.axios.post("/api/auth/update/info", user).then((retour) => {
      console.log(retour.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
