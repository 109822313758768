<template>
  <v-container>
    <v-form>
      <v-checkbox
        :input-value="getUser.timeDefault.lieu_depart"
        prepend-icon="mdi-home-outline"
        label="Départ de l'entreprise ?"
        :color="getTurquoisePoupin"
        true-value="Entreprise"
        false-value="Domicile"
        @change="setLieuDepart($event)"
      ></v-checkbox>
      <x-time-picker
        :title="
          getUser.timeDefault.lieu_depart === 'Entreprise'
            ? 'Arrivée sur l\'entreprise'
            : 'Départ du domicile'
        "
        :usertime="getUser.timeDefault.heure_depart"
        @timepicked="setDepart($event)"
      />
      <x-time-picker
        title="Arrivée premier chantier"
        :usertime="getUser.timeDefault.heure_arrivee_premier_chantier"
        @timepicked="setPremierChantier($event)"
      />
      <v-select
        :value="getUser.timeDefault.temps_pause"
        :items="temps"
        item-text="name"
        item-value="value"
        prepend-icon="mdi-coffee-outline"
        label="Temps de pause"
        :color="getTurquoisePoupin"
        @change="setTempsPause($event)"
      ></v-select>
      <x-time-picker
        title="Départ du dernier chantier"
        :usertime="getUser.timeDefault.heure_depart_dernier_chantier"
        @timepicked="setDernierChantier($event)"
      />
      <x-time-picker
        :title="
          getUser.timeDefault.lieu_arrivee === 'Entreprise'
            ? 'Départ de l\'entreprise'
            : 'Arrivée domicile'
        "
        :usertime="getUser.timeDefault.heure_arrivee"
        @timepicked="setFin($event)"
      />
      <v-checkbox
        :input-value="getUser.timeDefault.lieu_arrivee"
        prepend-icon="mdi-home-outline"
        label="Repasse par l'entreprise le soir ?"
        :color="getTurquoisePoupin"
        true-value="Entreprise"
        false-value="Domicile"
        @change="setLieuArrivee($event)"
      ></v-checkbox>
      <v-row justify="end">
        <v-btn color="black" class="ma-2" text @click="$emit('close')">
          Annuler
        </v-btn>
        <v-btn
          type="submit"
          class="ma-2"
          outlined
          :color="getTurquoisePoupin"
          @click="setHoraireDefaut"
        >
          Enregistrer
        </v-btn>
      </v-row>
    </v-form>
    <v-snackbar v-model="snack" :timeout="timeout" centered>
      {{ errText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snack = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import XTimePicker from "../time/X-time-picker";
import { mapGetters } from "vuex";

export default {
  name: "TimeForm",
  components: {
    XTimePicker,
  },
  data() {
    return {
      timeout: 5000,
      snack: false,
      errText: "",
      temps: [
        { name: "1h", value: 60 },
        { name: "1h30", value: 90 },
      ],
    };
  },
  methods: {
    setLieuDepart(val) {
      this.$store.dispatch("user/setDefaultLieuDepart", val);
    },
    setLieuArrivee(val) {
      this.$store.dispatch("user/setDefaultLieuArrivee", val);
    },
    setDepart(val) {
      this.$store.dispatch("user/setDefaultHeureDepart", val);
    },
    setPremierChantier(val) {
      this.$store.dispatch("user/setDefaultPremierChantier", val);
    },
    setTempsPause(val) {
      this.$store.dispatch("user/setDefaultTempsPause", val);
    },
    setDernierChantier(val) {
      this.$store.dispatch("user/setDefaultDernierChantier", val);
    },
    setFin(val) {
      this.$store.dispatch("user/setDefaultHeureArrivee", val);
    },
    setHoraireDefaut(e) {
      this.$store.dispatch("user/updateHoraireDefaut").then(() => {
        this.$emit("close");
      });
      e.preventDefault();
    },
  },
  computed: {
    ...mapGetters(["getTurquoisePoupin"]),

    ...mapGetters("user", {
      getUser: "getUser",
    }),

    snackbar() {
      return this.snack;
    },
  },
  watch: {
    snackbar(newValue) {
      if (newValue === false) {
        this.$store.dispatch("time/nullError");
      }
    },
  },
  created() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters["time/getErr"],
      (newValue) => {
        if (newValue != null) {
          this.errText = newValue;
          this.showSnackbar();
        }
      }
    );
  },
  mounted() {},
  beforeDestroy() {
    this.unwatch();
  },
};
</script>

<style scoped></style>
