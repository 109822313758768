import * as msal from "msal";

// MSAL object used for signing in users with MS identity platform
let msalApp;
let login_scopes_custom;

export default {
  //
  // Configure with clientId or empty string/null to set in "demo" mode
  //
  async configure(clientId, mailUser) {
    // Can only call configure once
    if (msalApp) {
      return msalApp;
    }

    login_scopes_custom = {
      scopes: [
        "user.read",
        "openid",
        "profile",
        "email",
        "sites.read.all",
        "files.read.all",
      ],
      loginHint: mailUser,
    };

    const config = {
      auth: {
        clientId: clientId,
        redirectUri: window.location.origin,
        authority:
          "https://login.microsoftonline.com/f3d121c7-c5e1-4c89-91a8-9ea5faca7a1c",
      },
      cache: {
        cacheLocation: "sessionStorage",
      },
    };

    // Create our shared/static MSAL app object
    msalApp = new msal.UserAgentApplication(config);

    return msalApp;
  },
  //
  // Return the configured client id
  //
  clientId() {
    if (!msalApp) {
      return null;
    }

    return msalApp.clientId;
  },

  //
  // Login a user with a popup
  //
  async login() {
    //msalApp.handleRedirectCallback(this.authCallback);

    if (!msalApp) {
      return;
    }

    try {
      await msalApp.ssoSilent(login_scopes_custom);
    } catch (err) {
      console.log(err);
    }
  },

  //
  // Logout any stored user
  //
  async logout() {
    if (!msalApp) {
      return;
    }

    await msalApp.logoutPopup();
  },

  //
  // Call to get user, probably cached and stored locally by MSAL
  //
  user() {
    if (!msalApp) {
      return null;
    }

    return msalApp.getAccount();
  },

  //
  // Call through to acquireTokenSilent or acquireTokenPopup
  //
  async acquireToken() {
    if (!msalApp) {
      return null;
    }

    // Set scopes for token request
    // const accessTokenRequest = {
    //   scopes
    // }

    let tokenResp;
    try {
      // 1. Try to acquire token silently
      tokenResp = await msalApp.acquireTokenSilent(login_scopes_custom);
    } catch (err) {
      // 2. Silent process might have failed so try via popup
      tokenResp = await msalApp.acquireTokenPopup(login_scopes_custom);
    }

    // Just in case check, probably never triggers
    if (!tokenResp.accessToken) {
      throw new Error("### accessToken not found in response, that's bad");
    }

    return tokenResp.accessToken;
  },

  //
  // Clear any stored/cached user
  //
  clearLocal() {
    if (msalApp) {
      msalApp.cacheStorage.clear();
    }
  },
};
