var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.testDateAvecChantierPrecedent()
        ? _c(
            "v-subheader",
            { attrs: { inset: "" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", dense: "", flat: "" } },
                [
                  _c("v-icon", [_vm._v("mdi-calendar-blank")]),
                  _c(
                    "v-toolbar-title",
                    [
                      _c("v-icon"),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("fullDate")(
                              _vm.chantier.prochaine_intervention
                            )
                          )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.testDateAvecChantierPrecedent() ? _c("v-spacer") : _vm._e(),
      _c(
        "v-card",
        { attrs: { color: _vm.color } },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(
                      " " + _vm._s(_vm.chantier.nom_client.split(/[0-9]/)[0])
                    ),
                  ]),
                  _c("v-list-item-subtitle", { staticClass: "text--primary" }, [
                    _vm._v(_vm._s(_vm.chantier.adresse_client)),
                  ]),
                  _c("v-list-item-subtitle", [
                    _vm._v(
                      _vm._s(`Numéro de devis: ${_vm.chantier.numero_devis}`)
                    ),
                  ]),
                  _vm.chantier.maitre_doeuvre
                    ? _c("v-list-item-subtitle", [
                        _vm._v(
                          _vm._s(
                            `Maitre d'oeuvre: ${_vm.chantier.maitre_doeuvre.Nom}`
                          )
                        ),
                      ])
                    : _vm._e(),
                  _c("v-list-item-subtitle", [
                    _vm._v(
                      "Prochaine intervention le " +
                        _vm._s(
                          _vm._f("fullDate")(
                            _vm.chantier.prochaine_intervention
                          )
                        )
                    ),
                  ]),
                  _c("v-list-item-subtitle", [
                    _vm._v(
                      "Mis en fab par " + _vm._s(_vm.chantier.mis_en_fab_par)
                    ),
                  ]),
                  _vm.isBE
                    ? _c("v-list-item-subtitle", [
                        _vm._v(
                          "Temps ordo. " + _vm._s(_vm.chantier.temps_ordo) + "h"
                        ),
                      ])
                    : _vm._e(),
                  _vm.isBE
                    ? _c("v-list-item-subtitle", [
                        _vm._v(
                          "Montant devis " +
                            _vm._s(
                              Number(_vm.chantier.montant_devis).toFixed(0)
                            )
                        ),
                      ])
                    : _vm._e(),
                  _vm.isBE
                    ? _c("v-list-item-subtitle", [
                        _vm._v(
                          "Date prévi ordo " +
                            _vm._s(
                              _vm._f("semaine")(_vm.chantier.date_previ_ordo)
                            )
                        ),
                      ])
                    : _vm._e(),
                  _c("v-list-item-subtitle", [
                    _vm._v(
                      "Maçon : " +
                        _vm._s(
                          _vm.chantier.nom_macon
                            ? _vm.chantier.nom_macon
                            : "Non renseigné"
                        )
                    ),
                  ]),
                  _vm.chantier.avancement.premiere_pose === "TE" ||
                  _vm.chantier.avancement.finitions === "TE"
                    ? _c(
                        "div",
                        [
                          _c("span", { staticClass: "red--text darken-2" }, [
                            _vm._v("En attente de facturation"),
                          ]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-2",
                              attrs: { color: "red darken-2" },
                            },
                            [_vm._v("mdi-alert")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c("v-icon", { on: { click: _vm.openInfosChantiers } }, [
                    _vm._v("mdi-information-outline"),
                  ]),
                  !_vm.favoris
                    ? _c(
                        "v-icon",
                        {
                          attrs: { color: "grey lighten-1" },
                          on: { click: _vm.ajouterFavoris },
                        },
                        [_vm._v(" mdi-flag-outline ")]
                      )
                    : _c(
                        "v-icon",
                        {
                          attrs: { color: "red darken-2" },
                          on: { click: _vm.supprimerFavoris },
                        },
                        [_vm._v(" mdi-flag ")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-progress-linear",
            {
              staticClass: "mb-3",
              attrs: {
                color: _vm.avancementCouleur,
                height: "15",
                value: _vm.avancement,
                striped: "",
              },
            },
            [_c("strong", [_vm._v(_vm._s(_vm.avancementStr))])]
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { "min-width": "0px" },
                  attrs: {
                    text: "",
                    height: _vm.buttonHeight,
                    tile: "",
                    width: "16%",
                  },
                  on: { click: _vm.openGoogleMap },
                },
                [_c("v-icon", [_vm._v("mdi-google-maps")])],
                1
              ),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c(
                "v-btn",
                {
                  staticStyle: { "min-width": "0px" },
                  attrs: {
                    text: "",
                    height: _vm.buttonHeight,
                    tile: "",
                    width: "16%",
                  },
                  on: { click: _vm.openContacts },
                },
                [_c("v-icon", [_vm._v("mdi-phone-outline")])],
                1
              ),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c(
                "v-btn",
                {
                  staticStyle: { "min-width": "0px" },
                  attrs: {
                    text: "",
                    height: _vm.buttonHeight,
                    tile: "",
                    width: "16%",
                  },
                  on: { click: _vm.openFiles },
                },
                [_c("v-icon", [_vm._v("mdi-folder-outline")])],
                1
              ),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c(
                "v-btn",
                {
                  staticStyle: { "min-width": "0px" },
                  attrs: {
                    text: "",
                    height: _vm.buttonHeight,
                    tile: "",
                    width: "16%",
                  },
                  on: { click: _vm.openIncidents },
                },
                [_c("v-icon", [_vm._v("mdi-alert-outline")])],
                1
              ),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c(
                "v-btn",
                {
                  staticStyle: { "min-width": "0px" },
                  attrs: {
                    text: "",
                    height: _vm.buttonHeight,
                    tile: "",
                    width: "16%",
                  },
                  on: { click: _vm.openProgress },
                },
                [_c("v-icon", [_vm._v("mdi-progress-wrench")])],
                1
              ),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c(
                "v-btn",
                {
                  staticStyle: { "min-width": "0px" },
                  attrs: {
                    text: "",
                    height: _vm.buttonHeight,
                    tile: "",
                    width: "16%",
                  },
                  on: {
                    click: function ($event) {
                      _vm.booleanModalePhoto = true
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-camera")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.booleanIncident,
            callback: function ($$v) {
              _vm.booleanIncident = $$v
            },
            expression: "booleanIncident",
          },
        },
        [
          _c("x-incident-modale", {
            on: { closeModale: _vm.closeModaleIncident },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.booleanModalePhoto,
            callback: function ($$v) {
              _vm.booleanModalePhoto = $$v
            },
            expression: "booleanModalePhoto",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " Envoyer des photos sur le répertoire 'Sinapse' du client "
                ),
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-file-input", {
                    attrs: {
                      outlined: "",
                      label: "Photos du chantier",
                      "truncate-length": "15",
                    },
                    model: {
                      value: _vm.photoChantierActuelle,
                      callback: function ($$v) {
                        _vm.photoChantierActuelle = $$v
                      },
                      expression: "photoChantierActuelle",
                    },
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: { label: "Commentaire" },
                            model: {
                              value: _vm.commentairePhoto,
                              callback: function ($$v) {
                                _vm.commentairePhoto = $$v
                              },
                              expression: "commentairePhoto",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.listePhotoChantier, function (item, index) {
                    return _c(
                      "v-row",
                      { key: Math.random() * index },
                      [
                        _c(
                          "v-col",
                          [
                            _vm._v(" " + _vm._s(item.name) + " "),
                            _c("v-progress-linear", {
                              staticClass: "mb-2",
                              attrs: {
                                value: "100",
                                striped: "",
                                color: "green",
                                height: "8px",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "red white--text" },
                              on: {
                                click: function ($event) {
                                  _vm.booleanModalePhoto = false
                                },
                              },
                            },
                            [_vm._v("Retour")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "green white--text" },
                              on: { click: _vm.envoiEmailPhotoChantier },
                            },
                            [_vm._v("Envoyer ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }