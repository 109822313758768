<template>
  <v-dialog
      :value="visible"
      max-width="500px"
      class="ma-0 pa-0"
      @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-subtitle class="pt-4">
        <h3>Commentaire</h3>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text v-if="edit && getCurrentJournee.journeeCorrigee">
        <v-textarea
            filled
            :value="getCurrentJournee.journeeCorrigee.commentaire"
            @change="$emit('change', $event)"
        ></v-textarea>
      </v-card-text>
      <v-card-text v-else-if="edit" class="pt-4">
        Pas de journée corrigée
      </v-card-text>
      <v-card-text v-else class="pt-4">
        {{ commentaire }}
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="$emit('close')">Fermer</v-btn>
        <v-btn v-if="edit" outlined color="orange" @click="$emit('set-commentaire')">Ajouter commentaire</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "X-modale-commentaire",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    commentaire: {
      type: String,
      default: "Un commentaire en lecture seule"
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('time', {
      getCurrentJournee: 'getCurrentJournee'
    })
  },
}
</script>

<style scoped>

</style>