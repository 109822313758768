import Vue from 'vue';

const state = {
    currentSociete: {},
    societes: []
};

const getters = {
    getSociete(state) {
        return state.currentSociete;
    },
    getSocietes(state) {
        return state.societes;
    }

};

const mutations = {
    SET_LIBELLE_SOCIETE(state, libelle) {
        state.currentSociete.libelle = libelle;
    },
    SET_ID_SOCIETE(state, id) {
        state.currentSociete.idERP = id;
    },
    ADD_SOCIETE(state, societe) {
        state.societes.push(societe);
    },
    SET_SOCIETES(state, societes) {
        state.societes = societes;
    }
};

const actions = {
    setLibelleSociete({commit}, societe) {
        commit('SET_LIBELLE_SOCIETE', societe);
    },
    setIdSociete({commit}, id) {
        commit('SET_ID_SOCIETE', id);
    },
    addSociete({commit, getters}) {
        Vue.axios.post('/api/societe/', getters.getSociete)
            .then(
                res => {
                    commit('ADD_SOCIETE', res.data.retour);
                }
            )
            .catch(
                (error) => {
                    if(error.response.data.error.message.includes('expected `username` to be unique')){
                        this.errors = [];
                        this.errors.push("Cet email est déjà enregistré en base");
                    }
                }
            );
    },
    returnSocietes({commit}) {
        Vue.axios.get('/api/societe/')
            .then(
                res => {
                    commit('SET_SOCIETES', res.data)
                }
            )
            .catch( err => {
                console.log(err);
            })
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};