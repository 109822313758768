import Vue from "vue";
import _ from "lodash";
import moment from "moment";

const state = {
  currentChantier: {},
  chantierList: [],
  chantierFavoris: [],

  infosChantier: [],
  ordonnancement: { rows: [], items: [] },

  modaleChantier: false,
  modaleCommande: false,
  modaleIncident: false,
  modaleContact: false,
  modaleGraph: false,
  modaleAvancement: false,
  modaleCommandeInterne: false,
  modaleInfosChantier: false,

  graphClient: null,
};

const getters = {
  getChantier(state) {
    return state.currentChantier;
  },
  getChantiers(state) {
    return state.chantierList;
  },
  getChantiersFavoris(state) {
    return state.chantierFavoris;
  },
  getInfosChantier(state) {
    return state.infosChantier;
  },
  getOrdonnancement(state) {
    return state.ordonnancement;
  },
  getModaleChantier(state) {
    return state.modaleChantier;
  },
  getModaleCommande(state) {
    return state.modaleCommande;
  },
  getModaleContact(state) {
    return state.modaleContact;
  },
  getModaleGraph(state) {
    return state.modaleGraph;
  },
  getModaleIncident(state) {
    return state.modaleIncident;
  },
  getModaleAvancement(state) {
    return state.modaleAvancement;
  },
  getModaleCommandeInterne(state) {
    return state.modaleCommandeInterne;
  },
  getModaleInfosChantier(state) {
    return state.modaleInfosChantier;
  },

  getEtatAvancementDate: (state) => (typeValeur) => {
    let info = {
      fait: false,
      couleur: "orange",
      icone: "mdi-dots-horizontal-circle-outline",
      message: "---Pas commencé",
      action: "",
      etat: "",
      dValeur: moment().toDate(),
    };

    //let strEtape = 'Élévations'
    switch (typeValeur) {
      case "-2":
        if (
          parseInt(
            moment(state.currentChantier.delai_theorique_finition).format(
              "YYYY"
            )
          ) !== 1
        ) {
          info.fait = true;
          info.message = "Délai finition attendue (par client/MO)";
        } else {
          info.message = "Délai finition pas renseigné";
        }
        break;

      case "-1":
        if (
          parseInt(
            moment(state.currentChantier.delai_theorique_premiere_pose).format(
              "YYYY"
            )
          ) !== 1
        ) {
          info.fait = true;
          info.message = "Délai 1ère pose attendue (par client/MO)";
        } else {
          info.message = "Délai 1ère pose pas renseigné";
        }
        break;
      case "elevation_le":
      case "charpente_le":
      case "couverture_le":
      case "chape_coulees_le":
      case "bande_placo_le":
      case "precommande_1erepose_termine_le":
      case "lancementfab_1erepose_termine_le":
      case "lancementfab_finition_termine_le":
        info.message = state.currentChantier.avancement[`${typeValeur}`].msg_NC;
        info.fait = false;
        info.icone = "mdi-close-circle-outline";
        info.couleur = "red";
        info.action = "mdi-debug-step-over";
        info.etat = "NC";
        // On test l'existance de l'objet avancement et de son enfant 'élévation le'
        if (
          typeof state.currentChantier.avancement[`${typeValeur}`] === "object"
        ) {
          info.etat = state.currentChantier.avancement[`${typeValeur}`].etat;
          info.action = "mdi-debug-step-over";
          if (state.currentChantier.avancement[`${typeValeur}`].etat === "TE") {
            info.fait = true;
            info.message =
              state.currentChantier.avancement[`${typeValeur}`].msg_TE +
              " " +
              moment(
                state.currentChantier.avancement[`${typeValeur}`].date
              ).format("dddd Do MMMM YYYY");
          } else if (
            state.currentChantier.avancement[`${typeValeur}`].etat === "EC"
          ) {
            info.couleur = "orange";
            info.message =
              state.currentChantier.avancement[`${typeValeur}`].msg_EC +
              " " +
              moment(
                state.currentChantier.avancement[`${typeValeur}`].date
              ).format("dddd Do MMMM YYYY");
          } else {
            info.message =
              state.currentChantier.avancement[`${typeValeur}`].msg_NC;
          }
        }
        break;
      case "verif_details_dappui_premiere_pose":
      case "fondation_coulees_le":
      case "prise_cote_bso_le":
      case "prise_cote_finitions_le":
      case "habillage_tableaux_le":
      case "pv_reception_signe_le":
      case "levee_reserve_le":
      case "pv_levee_reserve_le":
      case "reception_effectuee_le":
      case "demande_prix_recue_le":
      case "devis_envoye_le":
      case "devis_receptionne_valide_le":
      case "avenant_encours_non_valide":
      case "avenant_valide_le":
      case "choix_finaux_client_realises":
        info.message = state.currentChantier.avancement[`${typeValeur}`].msg_NC;
        info.fait = false;
        info.icone = "mdi-close-circle-outline";
        info.couleur = "red";
        info.action = "mdi-calendar";
        info.etat = "NC";
        if (typeValeur == "prise_cote_bso_le") {
          info.existe_sur_chantier =
            state.currentChantier.avancement[
              `${typeValeur}`
            ].existe_sur_chantier;
        }

        // On test l'existance de l'objet avancement et de son enfant 'élévation le'
        if (
          typeof state.currentChantier.avancement[`${typeValeur}`] === "object"
        ) {
          info.etat = state.currentChantier.avancement[`${typeValeur}`].etat;
          info.action = "mdi-debug-step-over";
          if (state.currentChantier.avancement[`${typeValeur}`].etat === "TE") {
            info.fait = true;
            info.message =
              state.currentChantier.avancement[`${typeValeur}`].msg_TE +
              " " +
              moment(
                state.currentChantier.avancement[`${typeValeur}`].date
              ).format("dddd Do MMMM YYYY");
          }
        }
        break;
      case "verif_details_dappui_premiere_pose.date_previ":
      case "prise_cote_bso_le.date_previ":
      case "prise_cote_finitions_le.date_previ":
      case "habillage_tableaux_le.date_previ":
      case "pret_a_poser_premiere_pose.date_previ":
      case "pret_a_poser_finition.date_previ":
      case "pv_reception_signe_le.date_previ":
      case "levee_reserve_le.date_previ":
      case "pv_levee_reserve_le.date_previ":
        info.message =
          _.get(
            state.currentChantier.avancement,
            _.split(`${typeValeur}`, ".")[0]
          ).msg_PR +
          " " +
          "AUCUNE DATE";
        info.fait = false;
        info.icone = "mdi-close-circle-outline";
        info.couleur = "red";
        info.action = "mdi-calendar";
        info.etat = "NC";
        if (
          typeof _.get(
            state.currentChantier.avancement,
            _.split(`${typeValeur}`, ".")[0]
          ) === "object"
        ) {
          if (
            moment(_.get(state.currentChantier.avancement, `${typeValeur}`))
              .toDate()
              .getFullYear() > 2010
          ) {
            info.dValeur = moment(
              _.get(
                state.currentChantier.avancement,
                _.split(`${typeValeur}`, ".")[0]
              ).date_previ
            ).toDate();
            info.message = _.get(
              state.currentChantier.avancement,
              _.split(`${typeValeur}`, ".")[0]
            ).msg_PR;
            info.couleur = "blue";
            info.etat = "EC";
          }
        }
        break;
      // case 'precommande_1erepose_termine_le':
      // case 'lancementfab_1erepose_termine_le':
      // case 'lancementfab_finition_termine_le':
      //     info.message = state.currentChantier.avancement[`${typeValeur}`].msg_NC;
      //     info.fait = false;
      //     if(state.currentChantier.avancement[`${typeValeur}`].cree_par != ''){
      //         info.fait = true;
      //         info.message = state.currentChantier.avancement[`${typeValeur}`].msg_TE + moment(state.currentChantier.avancement[`${typeValeur}`].date).format('dddd Do MMMM YYYY');
      //     }
      //     break;
      case "1000":
        info.message = "Vente";
        if (
          typeof state.currentChantier.avancement === "object" &&
          typeof state.currentChantier.avancement.demande_prix_recue_le ===
            "object" &&
          typeof state.currentChantier.avancement.devis_envoye_le ===
            "object" &&
          typeof state.currentChantier.avancement
            .devis_receptionne_valide_le === "object" &&
          typeof state.currentChantier.avancement.avenant_encours_non_valide ===
            "object" &&
          typeof state.currentChantier.avancement.avenant_valide_le ===
            "object" &&
          typeof state.currentChantier.avancement
            .choix_finaux_client_realises === "object"
        ) {
          let strComparaisonEtat =
            state.currentChantier.avancement.demande_prix_recue_le.etat +
            "" +
            state.currentChantier.avancement.devis_envoye_le.etat +
            "" +
            state.currentChantier.avancement.devis_receptionne_valide_le.etat +
            "" +
            state.currentChantier.avancement.devis_receptionne_valide_le.etat +
            "" +
            state.currentChantier.avancement.avenant_encours_non_valide.etat +
            "" +
            state.currentChantier.avancement.avenant_valide_le.etat;
          +"" +
            state.currentChantier.avancement.choix_finaux_client_realises.etat;

          if (
            state.currentChantier.avancement.demande_prix_recue_le.etat ===
              "TE" &&
            state.currentChantier.avancement.devis_envoye_le.etat === "TE" &&
            state.currentChantier.avancement.devis_receptionne_valide_le
              .etat === "TE" &&
            state.currentChantier.avancement.avenant_encours_non_valide.etat ===
              "TE" &&
            state.currentChantier.avancement.avenant_valide_le.etat === "TE" &&
            state.currentChantier.avancement.choix_finaux_client_realises
              .etat === "TE"
          ) {
            info.fait = true;
          } else if (
            strComparaisonEtat.includes("TE") ||
            strComparaisonEtat.includes("EC")
          ) {
            info.fait = false;
          } else {
            info.fait = false;
            info.icone = "mdi-close-circle-outline";
            info.couleur = "red";
          }
        }

        break;
      case "2000":
        info.message = "Lancement en fabrication";

        if (
          typeof state.currentChantier.avancement === "object" &&
          typeof state.currentChantier.avancement
            .precommande_1erepose_termine_le === "object" &&
          typeof state.currentChantier.avancement
            .lancementfab_1erepose_termine_le === "object" &&
          typeof state.currentChantier.avancement
            .lancementfab_finition_termine_le === "object"
        ) {
          let strComparaisonEtat =
            state.currentChantier.avancement.precommande_1erepose_termine_le
              .etat +
            "" +
            state.currentChantier.avancement.lancementfab_1erepose_termine_le
              .etat +
            "" +
            state.currentChantier.avancement.lancementfab_finition_termine_le
              .etat;

          if (
            state.currentChantier.avancement.precommande_1erepose_termine_le
              .etat === "TE" &&
            state.currentChantier.avancement.lancementfab_1erepose_termine_le
              .etat === "TE" &&
            state.currentChantier.avancement.lancementfab_finition_termine_le
              .etat === "TE"
          ) {
            info.fait = true;
          } else if (
            strComparaisonEtat.includes("TE") ||
            strComparaisonEtat.includes("EC")
          ) {
            info.fait = false;
          } else {
            info.fait = false;
            info.icone = "mdi-close-circle-outline";
            info.couleur = "red";
          }
        }
        break;
      case "3000":
        info.message = "1ère pose";

        if (
          typeof state.currentChantier.avancement === "object" &&
          typeof state.currentChantier.avancement.fondation_coulees_le ===
            "object" &&
          typeof state.currentChantier.avancement.elevation_le === "object" &&
          typeof state.currentChantier.avancement.charpente_le === "object" &&
          typeof state.currentChantier.avancement.couverture_le === "object" &&
          typeof state.currentChantier.avancement
            .verif_details_dappui_premiere_pose === "object"
        ) {
          let strComparaisonEtat =
            state.currentChantier.avancement.fondation_coulees_le.etat +
            "" +
            state.currentChantier.avancement.elevation_le.etat +
            "" +
            state.currentChantier.avancement.charpente_le.etat +
            "" +
            state.currentChantier.avancement.couverture_le.etat +
            "" +
            state.currentChantier.avancement.verif_details_dappui_premiere_pose
              .etat;

          if (
            state.currentChantier.avancement.fondation_coulees_le.etat ===
              "TE" &&
            state.currentChantier.avancement.elevation_le.etat === "TE" &&
            state.currentChantier.avancement.charpente_le.etat === "TE" &&
            state.currentChantier.avancement.couverture_le.etat === "TE" &&
            state.currentChantier.avancement.verif_details_dappui_premiere_pose
              .etat === "TE"
          ) {
            info.fait = true;
          } else if (
            strComparaisonEtat.includes("TE") ||
            strComparaisonEtat.includes("EC")
          ) {
            info.fait = false;
          } else {
            info.fait = false;
            info.icone = "mdi-close-circle-outline";
            info.couleur = "red";
          }
        }
        break;
      case "4000":
        info.message = "Finitions ";

        if (
          typeof state.currentChantier.avancement === "object" &&
          typeof state.currentChantier.avancement.chape_coulees_le ===
            "object" &&
          typeof state.currentChantier.avancement.bande_placo_le === "object" &&
          typeof state.currentChantier.avancement.prise_cote_bso_le ===
            "object" &&
          typeof state.currentChantier.avancement.prise_cote_finitions_le ===
            "object" &&
          typeof state.currentChantier.avancement.habillage_tableaux_le ===
            "object"
        ) {
          let strComparaisonEtat =
            state.currentChantier.avancement.chape_coulees_le.etat +
            "" +
            state.currentChantier.avancement.bande_placo_le.etat +
            "" +
            state.currentChantier.avancement.prise_cote_bso_le.etat +
            "" +
            state.currentChantier.avancement.prise_cote_finitions_le.etat +
            "" +
            state.currentChantier.avancement.habillage_tableaux_le.etat;

          if (
            state.currentChantier.avancement.chape_coulees_le.etat === "TE" &&
            state.currentChantier.avancement.bande_placo_le.etat === "TE" &&
            state.currentChantier.avancement.prise_cote_bso_le.etat === "TE" &&
            state.currentChantier.avancement.prise_cote_finitions_le.etat ===
              "TE" &&
            state.currentChantier.avancement.habillage_tableaux_le.etat === "TE"
          ) {
            info.fait = true;
          } else if (
            strComparaisonEtat.includes("TE") ||
            strComparaisonEtat.includes("EC")
          ) {
            info.fait = false;
          } else {
            info.fait = false;
            info.icone = "mdi-close-circle-outline";
            info.couleur = "red";
          }
        }
        break;
      case "5000":
        info.message = "Réceptions";
        if (
          typeof state.currentChantier.avancement === "object" &&
          typeof state.currentChantier.avancement.pv_reception_signe_le ===
            "object" &&
          typeof state.currentChantier.avancement.levee_reserve_le ===
            "object" &&
          typeof state.currentChantier.avancement.pv_levee_reserve_le ===
            "object" &&
          typeof state.currentChantier.avancement.reception_effectuee_le ===
            "object"
        ) {
          let strComparaisonEtat = "";
          let bChantierTermine = true;
          strComparaisonEtat =
            state.currentChantier.avancement.levee_reserve_le.etat +
            "" +
            state.currentChantier.avancement.pv_levee_reserve_le.etat;

          // Si il y a une levée de réservée ou un pv de levée de réserve
          // Il faut bien vérifier qu'il y ai les 2
          if (
            strComparaisonEtat.includes("TE") ||
            strComparaisonEtat.includes("EC")
          ) {
            bChantierTermine = false;

            if (
              state.currentChantier.avancement.levee_reserve_le.etat === "TE" &&
              state.currentChantier.avancement.pv_levee_reserve_le.etat === "TE"
            ) {
              info.fait = true;
            } else {
              info.fait = false;
            }
          }

          // Sinon il faut juste aller voir la réception et son pv
          if (bChantierTermine) {
            strComparaisonEtat =
              state.currentChantier.avancement.pv_reception_signe_le.etat +
              "" +
              state.currentChantier.avancement.reception_effectuee_le.etat;
            // Si la réception est faite et que le pv a été signée sans réserve
            // On passe en vert
            if (
              state.currentChantier.avancement.pv_reception_signe_le.etat ===
                "TE" &&
              state.currentChantier.avancement.reception_effectuee_le.etat ===
                "TE"
            ) {
              info.fait = true;
            } else if (
              strComparaisonEtat.includes("TE") ||
              strComparaisonEtat.includes("EC")
            ) {
              info.fait = false;
            } else {
              info.fait = false;
              info.icone = "mdi-close-circle-outline";
              info.couleur = "red";
            }
          }
        }

        break;

      case "5000-1":
        info.message = "Avez-vous des réserves sur ce chantier ?";
        if (
          typeof state.currentChantier.avancement === "object" &&
          typeof state.currentChantier.avancement.levee_reserve_le ===
            "object" &&
          typeof state.currentChantier.avancement.pv_levee_reserve_le ===
            "object"
        ) {
          let strComparaisonEtat =
            state.currentChantier.avancement.levee_reserve_le.etat +
            "" +
            state.currentChantier.avancement.pv_levee_reserve_le.etat;

          info.couleur = "red";

          if (
            state.currentChantier.avancement.levee_reserve_le.etat === "TE" &&
            state.currentChantier.avancement.pv_levee_reserve_le.etat === "TE"
          ) {
            info.fait = true;
          } else if (
            strComparaisonEtat.includes("TE") ||
            strComparaisonEtat.includes("EC")
          ) {
            info.fait = true;
          } else {
            info.fait = false;
            info.couleur = "";
          }
        }
        break;
      default:
    }

    // Date prévi
    // Vérif détails d'appui prévi : conduc qui la met
    // Prise de cote finitions prévi : ordo qui la met
    // Prise de cote BSO prévi : ordo qui la met
    // Habillage tableaux prévi : ordo qui la met

    if (info.fait) {
      info.couleur = "green";
      info.icone = "mdi-check-circle-outline";
      info.action = info.icone;
    }

    //return infor[`${}`]
    return info;
  },

  getPorteGarage() {
    if (
      state.currentChantier.avancement &&
      state.currentChantier.avancement.porte_garage
    ) {
      return state.currentChantier.avancement.porte_garage;
    } else {
      let pg = {
        type_pose: "",
        longueur_rallonge_suspente: "",
        longueur_bastaing: "",
        date: moment().toDate(),
      };
      return pg;
    }
  },
};

const mutations = {
  //Gestion de la liste des chantiers, des favoris et du chantier en cours
  //////////////////////////////////////////////////////////////
  SET_CHANTIER(state, payload) {
    state.currentChantier = payload;
  },
  SET_CHANTIERS(state, payload) {
    state.chantierList = payload;
  },
  SET_CHANTIERS_FAVORIS(state, payload) {
    state.chantierFavoris = payload;
  },
  ADD_CHANTIERS_FAVORIS(state, payload) {
    state.chantierFavoris.push(payload);
  },
  REMOVE_CHANTIERS_FAVORIS(state, payload) {
    let index = _.findIndex(state.chantierFavoris, { _id: payload._id });
    if (index !== -1) {
      state.chantierFavoris.splice(index, 1);
    }
  },
  SET_INFOS_CHANTIER(state, payload) {
    state.infosChantier = payload;
  },
  ADD_INFOS_CHANTIER(state, payload) {
    state.infosChantier.unshift(payload);
  },
  REMOVE_INFOS_CHANTIER(state, payload) {
    let index = _.findIndex(state.infosChantier, { _id: payload });
    if (index !== -1) {
      state.infosChantier.splice(index, 1);
    }
  },
  SET_COMMANDES_CHANTIER(state, payload) {
    state.currentChantier.commandes = payload.commandes;
  },
  SET_ORDONNANCEMENT(state, payload) {
    state.ordonnancement = payload;
  },

  //Mutations des modales interventions poseur
  ///////////////////////////////////////////////
  SET_MODALE_CHANTIER(state, payload) {
    state.modaleChantier = payload;
  },
  SET_MODALE_COMMANDE(state, payload) {
    state.modaleCommande = payload;
  },

  //Mutations des modales chantiers conducteur
  ////////////////////////////////////////////////
  SET_MODALE_CONTACT(state, payload) {
    state.modaleContact = payload;
  },
  SET_MODALE_GRAPH(state, payload) {
    state.modaleGraph = payload;
  },
  SET_MODALE_INCIDENT(state, payload) {
    state.modaleAvancement = payload;
  },
  SET_MODALE_AVANCEMENT(state, payload) {
    state.modaleAvancement = payload;
  },
  SET_MODALE_COMMANDE_INTERNE(state, payload) {
    state.modaleCommandeInterne = payload;
  },
  SET_MODALE_INFOS_CHANTIER(state, payload) {
    state.modaleInfosChantier = payload;
  },

  //Modification de l'avancement d'un chantier
  //////////////////////////////////////////////////
  SET_VERIF_APPUI(state, payload) {
    state.currentChantier.avancement.verification_details_dappui_premiere_pose = payload;
  },
  SET_ETAT_PREMIERE_POSE(state, payload) {
    state.currentChantier.avancement.premiere_pose = payload;
  },
  SET_COTE_FINITIONS(state, payload) {
    state.currentChantier.avancement.prise_cote_finitions = payload;
  },
  SET_ETAT_FINITIONS(state, payload) {
    state.currentChantier.avancement.finitions = payload;
  },
  SET_RECEPTION(state, payload) {
    state.currentChantier.avancement.reception_effectuee = payload;
  },
  SET_LEVEE_RESERVES(state, payload) {
    state.currentChantier.avancement.levee_des_reserves = payload;
  },
  SET_EAU(state, payload) {
    state.currentChantier.avancement.eau = payload;
  },
  SET_ELECTRICITE(state, payload) {
    state.currentChantier.avancement.electricite = payload;
  },

  SET_VALEUR_DATE(state, payload) {
    // Selon la valeur a mettre à jour
    //On remet a vide le crée par
    // peut servir pour faire des filtres ou autres plus facilement... sans aller voir les dates
    if (payload.isChecked === false) {
      payload.cree_par = "";
    }

    state.currentChantier.avancement[`${payload.typeValeur}`].etat =
      payload.etat;
    state.currentChantier.avancement[`${payload.typeValeur}`].date =
      payload.nouvelleValeur;
    state.currentChantier.avancement[`${payload.typeValeur}`].cree_par =
      payload.cree_par;
  },

  SET_VALEUR_PG(state, payload) {
    // Selon la valeur a mettre à jour
    //On remet a vide le crée par
    // peut servir pour faire des filtres ou autres plus facilement... sans aller voir les dates
    if (payload.isChecked === false) {
      payload.cree_par = "";
    }
    state.currentChantier.avancement.porte_garage[`${payload.typeValeur}`] =
      payload.nouvelleValeur;
    state.currentChantier.avancement.porte_garage.cree_par = payload.cree_par;
  },

  SET_VALEUR_DATE_AVANCEMENT_PREVI(state, payload) {
    // Selon la valeur a mettre à jour

    _.set(
      state.currentChantier.avancement,
      payload.typeValeur,
      payload.datePrevi
    );
  },

  SET_VALEUR_DATE_CHANTIER(state, payload) {
    // Selon la valeur a mettre à jour

    //Selon le type de valeur envoyé par le front
    switch (payload.typeValeur) {
      case "-1":
        state.currentChantier.delai_theorique_premiere_pose =
          payload.nouvelleValeur;
        break;
      case "-2":
        state.currentChantier.delai_theorique_finition = payload.nouvelleValeur;
        break;
    }
  },
};

const actions = {
  //Gestion de la liste des chantiers, des favoris et du chantier en cours
  //////////////////////////////////////////////////////////////
  setChantier({ commit }, payload) {
    commit("SET_CHANTIER", payload);
  },
  returnAllChantiers({ commit, rootGetters }) {
    let idConduc;
    if (rootGetters["auth/userInSession"].role === "Conducteur") {
      idConduc = rootGetters["auth/userInSession"].idERP;
    } else {
      idConduc = 2444666668888888;
    }
    Vue.axios.get(`/api/chantier/${idConduc}`).then((chantiers) => {
      commit("SET_CHANTIERS", chantiers.data);
    });
  },
  returnChantiersFavoris({ commit, rootGetters }) {
    Vue.axios
      .post("/api/chantier/favoris", rootGetters["auth/userInSession"])
      .then((retour) => {
        commit("SET_CHANTIERS_FAVORIS", retour.data.chantiers);
      });
  },
  returnChantierFiltres({ commit, rootGetters }, payload) {
    if (rootGetters["auth/userInSession"].role === "Conducteur") {
      payload.id = rootGetters["auth/userInSession"].idERP;
    } else {
      payload.id = 2444666668888888;
    }
    Vue.axios.post("api/chantier/filtres", payload).then((retour) => {
      commit("SET_CHANTIERS", retour.data.chantiers);
    });
  },
  returnChantiersPasses({ commit, rootGetters }) {
    let id;
    if (rootGetters["auth/userInSession"].role === "Conducteur") {
      id = rootGetters["auth/userInSession"].idERP;
    } else {
      id = 2444666668888888;
    }
    Vue.axios.post("api/chantier/passes", { id: id }).then((retour) => {
      commit("SET_CHANTIERS", retour.data);
    });
  },
  returnInfosChantier({ commit, getters }) {
    return Vue.axios
      .get(`api/chantier/infos/${getters.getChantier._id}`)
      .then((result) => {
        commit("SET_INFOS_CHANTIER", result.data);
      });
  },
  addInfoChantier({ commit, getters, rootGetters }, payload) {
    let info = {
      chantier: getters.getChantier,
      numero_devis: getters.getChantier.numero_devis,
      compteur: getters.getChantier.compteur,
      societe: getters.getChantier.societe,
      commentaire: payload.message,
      cree_par: rootGetters["auth/userInSession"].username,
      cree_le: new Date(),
    };

    Vue.axios
      .post(`api/chantier/infos`, {
        info: info,
        destinataires: payload.destinataires,
      })
      .then((retour) => {
        commit("ADD_INFOS_CHANTIER", retour.data);
      });
  },
  deleteInfoChantier({ commit }, payload) {
    Vue.axios.delete(`api/chantier/infos/${payload}`).then(() => {
      commit("REMOVE_INFOS_CHANTIER", payload);
    });
  },
  returnCommandesChantier({ commit, getters }) {
    return Vue.axios
      .get(`api/chantier/commandes/${getters.getChantier._id}`)
      .then((retour) => {
        commit("SET_COMMANDES_CHANTIER", retour.data);
      });
  },
  returnOrdonnancement({ commit }) {
    return Vue.axios.get(`api/chantier/ordonnancement`).then((retour) => {
      commit("SET_ORDONNANCEMENT", retour.data);
    });
  },

  //Actions des modales interventions poseur
  ///////////////////////////////////////////////
  showModaleChantier({ commit }) {
    commit("SET_MODALE_CHANTIER", true);
  },
  hideModaleChantier({ commit }) {
    commit("SET_MODALE_CHANTIER", false);
  },
  showModaleCommande({ commit }) {
    commit("SET_MODALE_COMMANDE", true);
  },
  hideModaleCommande({ commit }) {
    commit("SET_MODALE_COMMANDE", false);
  },

  //Actions des modales chantiers conducteur
  ////////////////////////////////////////////////
  showModaleContact({ commit }) {
    commit("SET_MODALE_CONTACT", true);
  },
  hideModaleContact({ commit }) {
    commit("SET_MODALE_CONTACT", false);
  },
  showModaleGraph({ commit }) {
    commit("SET_MODALE_GRAPH", true);
  },
  hideModaleGraph({ commit }) {
    commit("SET_MODALE_GRAPH", false);
  },
  showModaleIncident({ commit }) {
    commit("SET_MODALE_INCIDENT", true);
  },
  hideModaleIncident({ commit }) {
    commit("SET_MODALE_INCIDENT", false);
  },
  showModaleAvancement({ commit }) {
    commit("SET_MODALE_AVANCEMENT", true);
  },
  hideModaleAvancement({ commit }) {
    commit("SET_MODALE_AVANCEMENT", false);
  },
  showModaleCommandeInterne({ commit }) {
    commit("SET_MODALE_COMMANDE_INTERNE", true);
  },
  hideModaleCommandeInterne({ commit }) {
    commit("SET_MODALE_COMMANDE_INTERNE", false);
  },
  showModaleInfosChantier({ commit }) {
    commit("SET_MODALE_INFOS_CHANTIER", true);
  },
  hideModaleInfosChantier({ commit }) {
    commit("SET_MODALE_INFOS_CHANTIER", false);
  },

  //Modification de l'avancement d'un chantier
  //////////////////////////////////////////////////
  setAvancementVerifAppui({ commit }) {
    commit("SET_VERIF_APPUI", new Date());
  },
  setAvancementEtatPremierePose({ commit, getters }) {
    let etat;
    if (getters.getChantier.avancement.premiere_pose === "NC") {
      etat = "EC";
    } else if (getters.getChantier.avancement.premiere_pose === "EC") {
      etat = "TE";
    } else if (getters.getChantier.avancement.premiere_pose === "TE") {
      etat = "FA";
    }
    commit("SET_ETAT_PREMIERE_POSE", etat);
  },
  setAvancementCoteFinition({ commit }) {
    commit("SET_COTE_FINITIONS", new Date());
  },
  setAvancementEtatFinition({ commit, getters }) {
    let etat;
    if (getters.getChantier.avancement.finitions === "NC") {
      etat = "EC";
    } else if (getters.getChantier.avancement.finitions === "EC") {
      etat = "TE";
    } else if (getters.getChantier.avancement.finitions === "TE") {
      etat = "FA";
    }
    commit("SET_ETAT_FINITIONS", etat);
  },
  setAvancementReception({ commit }) {
    commit("SET_RECEPTION", new Date());
  },
  setAvancementLeveeReserves({ commit }) {
    commit("SET_LEVEE_RESERVES", new Date());
  },
  setAvancementEau({ commit }) {
    commit("SET_EAU", true);
  },
  setAvancementElectricite({ commit }) {
    commit("SET_ELECTRICITE", true);
  },
  updateAvancement({ getters }) {
    let avancement = getters.getChantier.avancement;
    return Vue.axios.put("/api/chantier/avancement", avancement);
  },
  updateDateTheoriquePremierePose({ getters }) {
    let chantier = getters.getChantier;
    Vue.axios.put("/api/chantier/delai_theorique_premiere_pose", chantier);
  },
  updateDateTheoriqueFinition({ getters }) {
    let chantier = getters.getChantier;
    Vue.axios.put("/api/chantier/delai_theorique_finition", chantier);
  },
  setAvancementDate({ commit, rootGetters }, payload) {
    payload.cree_par = rootGetters["auth/userInSession"].username;
    commit("SET_VALEUR_DATE", payload);
  },
  setAvancementPorteGarage({ commit, rootGetters }, payload) {
    payload.cree_par = rootGetters["auth/userInSession"].username;
    commit("SET_VALEUR_PG", payload);
  },
  setChantierAvancementDatePrevi({ commit, rootGetters }, payload) {
    payload.cree_par = rootGetters["auth/userInSession"].username;
    commit("SET_VALEUR_DATE_AVANCEMENT_PREVI", payload);
  },
  setChantierDateTheorique({ commit, rootGetters }, payload) {
    payload.cree_par = rootGetters["auth/userInSession"].username;
    commit("SET_VALEUR_DATE_CHANTIER", payload);
  },
  updateCommentaireOrdo({ commit }, payload) {
    console.log(commit);
    Vue.axios.post("/api/chantier/commentaire/ordo", {
      id: payload._id,
      commentaire: payload.commentaire_ordo,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
