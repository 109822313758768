<template>
<div>
  <h1>Créer un nouvel utilisateur</h1>
  <user-form></user-form>
</div>
</template>

<script>
import UserForm from "../components/user/UserForm";

export default {
  name: "Signup",
  components: {
    UserForm
  }
}
</script>

<style scoped>

</style>