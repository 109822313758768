var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "my-0 py-0" },
    [
      _c(
        "v-row",
        { staticClass: "ma-2", attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-col",
            { staticClass: "mx-2" },
            [
              _c("x-date-picker", {
                attrs: {
                  title: "Semaine",
                  required: false,
                  date: _vm._f("toIso")(_vm.getQuery.dateDebut),
                  afficherSemaine: true,
                },
                on: { datepicked: _vm.setDateDebut, clean: _vm.clearDateDebut },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mx-2" },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Nom client",
                  clearable: "",
                  hint: "En majuscule",
                },
                on: {
                  change: _vm.setNomClient,
                  "click:clear": _vm.clearNomClient,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "ma-2", attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-col",
            { staticClass: "mx-2" },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Numéro commande interne",
                  clearable: "",
                  hint: "5 chiffres",
                },
                on: {
                  change: _vm.setNumeroCommandeInterne,
                  "click:clear": _vm.clearNumeroCommandeInterne,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mx-2" },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Numéro commande externe",
                  clearable: "",
                  hint: "5 chiffres",
                },
                on: {
                  change: _vm.setNumeroCommandeExterne,
                  "click:clear": _vm.clearNumeroCommandeExterne,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "ma-2", attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-col",
            { attrs: { "align-self": "center" } },
            [
              _c("v-select", {
                attrs: {
                  label: "Agence",
                  "prepend-icon": "mdi-home",
                  value: _vm.getQuery.agence ? _vm.getQuery.agence : null,
                  color: _vm.getTurquoisePoupin,
                  items: _vm.getAgences,
                  "item-text": "libelle",
                  "item-value": "_id",
                  clearable: "",
                },
                on: { "click:clear": _vm.clearAgence, change: _vm.setAgence },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }