<template>
  <v-container>
    <!--Ma modale-->
    <v-dialog v-model="modalPanier" max-width="500px" class="ma-0 pa-0">
      <v-card>
        <v-card-title>
          Mon panier
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container v-if="getPanier.length !== 0">
            <v-card outlined v-for="item in getPanier" :key="item.id">
              <v-card-text class="ma-1 px-0 py-2">
                <v-list-item two-line>
                  <v-list-item-avatar
                    tile
                    size="65"
                    color="white"
                    class="my-0 pa-0"
                  >
                    <v-img
                      v-if="item.img"
                      :src="require(`@/assets/catalog/${item.img}`)"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content class="my-0 pa-0">
                    <div class="overline">
                      {{ item.name }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
              <v-card-actions class="pa-0 ma-0" style="height: 50px">
                <v-container fluid>
                  <v-row justify="center" style="height: 60px">
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="red"
                      class="my-0 mx-0 pa-0"
                      @click="removeFromCart(item.id)"
                      ><v-icon>mdi-delete-outline</v-icon></v-btn
                    >
                    <v-select
                      dense
                      outlined
                      class="my-0 mr-2 pa-0"
                      :items="getSelectList"
                      v-model="item.quantite"
                      :color="getBleu"
                      label="Qte"
                      style="width: 0px"
                    ></v-select>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-container>
          <v-container v-else fluid class="fill-height">
            <v-row align="center" justify="center">
              <h3>Panier vide</h3>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            dark
            v-if="getPanier.length !== 0"
            @click="showConfirm"
          >
            <v-icon>mdi-cart-remove</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="showPanier">Fermer</v-btn>
          <v-btn
            :color="getTurquoisePoupin"
            outlined
            @click="passerCommande"
            v-if="getPanier.length !== 0"
            >Commander</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Fin de modale-->
    <!--Ma modale de confirmation-->
    <v-dialog v-model="modalConfirm" max-width="500px">
      <v-card>
        <v-card-title>
          Êtes vous sûr(e) de vouloir supprimer tous les éléments de votre
          panier ?
        </v-card-title>
        <v-card-actions>
          <span></span><v-spacer></v-spacer
          ><v-btn text color="red" @click="showConfirm">Annuler</v-btn
          ><v-btn outlined color="green" @click="emptyCart">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Fin de ma modale de confirmation-->
    <!--Ma modale d'article custom-->
    <v-dialog
      v-model="modalCustom"
      max-width="500px"
      @click:outside="resetValidation"
    >
      <v-card>
        <v-card-title>
          Commande spéciale
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-3">
          <v-form ref="customForm" v-model="valid" lazy-validation>
            <v-text-field
              type="text"
              v-model="nomCustom"
              :rules="[(v) => !!v || 'Le nom est obligatoire']"
              outlined
              label="Nom de l'article"
              :color="getBleu"
            >
            </v-text-field>
            <v-select
              v-model="qteCustom"
              :rules="[(v) => !!v || 'La quantité doit être renseignée']"
              outlined
              label="Qte"
              :items="getSelectList"
              :color="getBleu"
            >
            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <span></span>
          <v-spacer></v-spacer>
          <v-btn text color="black" @click="showCustom">Fermer</v-btn>
          <v-btn
            @click="validate"
            :disabled="!valid"
            outlined
            :color="getTurquoisePoupin"
            >Ajouter</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Fin de ma modale d'article custom-->
    <v-breadcrumbs class="pa-0 ma-0">
      <v-breadcrumbs-item
        class="pa-0 ma-0"
        v-for="(crumb, index) in getBreadcrumbs"
        :key="index"
      >
        <v-btn
          small
          text
          class="pa-1 ma-1"
          :color="getTurquoisePoupin"
          :disabled="crumb.disabled"
          @click="navigateCrumbs(crumb, index)"
          >{{ crumb.name }}</v-btn
        >
        <v-breadcrumbs-divider class="pa-1 ma-1"> > </v-breadcrumbs-divider>
      </v-breadcrumbs-item>
    </v-breadcrumbs>
    <v-layout row wrap v-if="hasChildren">
      <v-flex xs4 sm4 md3 lg2 xl1 v-for="item in getDisplayList" :key="item.id">
        <v-card
          max-width="130px"
          class="ma-1"
          color="white"
          @click="navigateCards(item)"
        >
          <v-img :src="require(`@/assets/catalog/${item.img}`)">
            <v-layout class="fill-height">
              <v-flex align-self-end>
                <v-row>
                  <v-card-subtitle
                    class="py-0 mb-3"
                    :style="
                      `
                      background-color: ${getBleu};
                      width: 100%; color: ${getBlanc};
                      `
                    "
                  >
                    {{ item.name }}
                  </v-card-subtitle>
                </v-row>
              </v-flex>
            </v-layout>
          </v-img>
        </v-card>
      </v-flex>
    </v-layout>
    <div v-else-if="!hasChildren">
      <v-card
        class="mx-auto"
        outlined
        v-for="item in getDisplayList"
        :key="item.id"
      >
        <v-list-item three-line>
          <v-list-item-avatar tile size="80" color="white">
            <v-img :src="require(`@/assets/catalog/${item.img}`)"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <div class="overline mb-2">
              {{ item.name }}
            </div>
          </v-list-item-content>
          <v-list-item-action>
            <v-spacer></v-spacer>
            <span
              :style="`color: ${getOrange}`"
              v-if="getQuantitePanier(item) !== 0"
              >{{ getQuantitePanier(item) }} dans le panier</span
            >
            <v-select
              dense
              outlined
              filled
              class="ma-2"
              :items="getSelectList"
              v-model="item.quantite"
              :color="getBleu"
              style="width: 90px"
              label="Qte"
            ></v-select>
            <v-btn
              small
              fab
              tile
              dark
              :color="getTurquoisePoupin"
              @click="addToCart(item)"
              ><v-icon>mdi-cart-plus</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </div>
    <div style="height: 70px"></div>

    <v-btn
      elevation="2"
      fab
      bottom
      fixed
      right
      class="white--text"
      :color="getBleu"
      @click="showPanier"
    >
      <v-badge
        :color="getOrange"
        bordered
        v-if="getPanier.length !== 0"
        :content="getPanier.length"
      >
        <v-icon>mdi-cart-outline</v-icon>
      </v-badge>
      <v-icon v-else>mdi-cart-outline</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "X-catalog",
  data() {
    return {
      modalPanier: false,
      modalConfirm: false,
      modalCustom: false,
      valid: true,
      nomCustom: null,
      qteCustom: null,
    };
  },
  created() {
    this.$store.dispatch("catalog/initialiseCatalogue");
  },
  mounted() {
    this.$store.dispatch("catalog/verifyChildren");
    let that = this;
    window.onpopstate = function() {
      that.handleBackBtn();
    };
  },
  beforeDestroy() {
    window.onpopstate = function() {};
  },
  methods: {
    navigateCards(item) {
      if (item.custom) {
        this.modalCustom = true;
      } else {
        history.pushState({}, "STAY", window.location.href);
        this.$store.dispatch("catalog/addBreadcrumb", item);
        this.$store.dispatch("catalog/setCatalogue", item.children);
      }
    },
    navigateCrumbs(crumb, index) {
      history.pushState({}, "NO MOVING", window.location.href);
      this.$store.dispatch("catalog/navigateBack", index);
    },
    addToCart(item) {
      this.$store.dispatch("catalog/addToPanier", item).then(() => {
        item.quantite = null;
      });
    },
    removeFromCart(id) {
      this.$store.dispatch("catalog/removeFromPanier", id);
    },
    emptyCart() {
      this.$store.dispatch("catalog/emptyPanier");
      this.showConfirm();
    },
    showPanier() {
      this.modalPanier = !this.modalPanier;
    },
    showConfirm() {
      this.modalConfirm = !this.modalConfirm;
    },
    showCustom() {
      this.resetValidation();
      this.modalCustom = !this.modalCustom;
    },
    passerCommande() {
      this.$store.dispatch("catalog/passerCommande");
      this.showPanier();
    },
    addCustom() {
      this.$store
        .dispatch("catalog/addToPanier", {
          name: this.nomCustom,
          quantite: this.qteCustom,
          id: _.uniqueId(),
          img: "custom.webp",
        })
        .then(() => {
          this.showCustom();
          this.qteCustom = null;
          this.nomCustom = null;
          this.resetValidation();
        });
    },
    resetValidation() {
      this.$refs.customForm.resetValidation();
    },
    validate() {
      new Promise((resolve) => {
        this.$refs.customForm.validate();
        resolve();
      }).then(() => {
        if (this.valid) {
          this.addCustom();
        }
      });
    },
    handleBackBtn() {
      this.$store.dispatch("catalog/navigateBack", -1);
    },
  },
  computed: {
    ...mapGetters("catalog", {
      getDisplayList: "getDisplayList",
      getSelectList: "getSelectList",
      getBreadcrumbs: "getBreadcrumbs",
      hasChildren: "hasChildren",
      getPanier: "getPanier",
      getQuantitePanier: "getQuantitePanier",
    }),

    ...mapGetters(["getTurquoisePoupin", "getBleu", "getBlanc", "getOrange"]),
  },
};
</script>

<style scoped></style>
