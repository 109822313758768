<template>
      <v-list three-line>
      <div v-if="btnVisible">
        <v-btn
            v-if="!text"
            text
            color="blue"
            :style="{left: '50%', transform:'translateX(-50%)'}"
            class="mb-3"
            @click="nouvelleInfo"
        >
          <v-icon>mdi-plus</v-icon> Ajouter une info
        </v-btn>
        <div v-else>
          <v-icon
              @click="text = false"
              class="mb-3"
          >mdi-close</v-icon>
          <v-form>
            <v-textarea
                v-model="info.message"
                outlined
                label="Info chantier ..."
                height="55px"
            ></v-textarea>
            <v-autocomplete
                v-model="info.destinataires"
                :items="getContacts"
                item-text="nom"
                item-value="email"
                multiple
                outlined
                label="Destinataires"
            ></v-autocomplete>
          </v-form>
          <v-row
              justify="center"
              class="mb-4"
          >
          <v-btn
              text
              color="green"
              @click="addInfo"
          >
            <v-icon>mdi-check</v-icon> Valider l'info
          </v-btn>
          </v-row>
        </div>
        </div>

        <template v-for="(item, index) in getInfosChantier">
          <v-divider
              :key="index + 'a'"
              :inset="true"
          ></v-divider>
          <v-list-item
              :key="index + 'b'"
          >
            <v-list-item-avatar>
              <v-icon>mdi-text</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ item.cree_le | fullDate }}</v-list-item-title>
              <v-list-item-subtitle>Crée par : {{ item.cree_par }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ item.commentaire }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon v-if="item.idERP" color="green">mdi-check</v-icon>
              <div  v-else>
                <v-icon>mdi-timer-sand</v-icon>
                <v-icon color="red darken-2" @click="deleteInfo(item._id)">mdi-close</v-icon>
              </div>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Swal from "sweetalert2";

export default {
  name: "X-infos-chantier",
  props: {
    btnVisible: {
      type: Boolean,
      default: false
    },
  },  
  data() {
    return {
      text: false,
      info: {
        message: '',
        destinataires: null
      }
    }
  },
  methods: {
    ...mapActions('chantier', {
      returnInfosChantier: 'returnInfosChantier'
    }),

    nouvelleInfo() {
      this.text = true;
    },
    addInfo() {
      this.$store.dispatch('chantier/addInfoChantier', this.info)
          .then(() => {
            this.text = false;
          })
    },
    deleteInfo(id) {
      Swal.fire({
        text: `Supprimer l'info chantier ? (Vous ne pourrez plus la supprimer une fois sur Sinapse)`,
        icon: 'question',
        showConfirmButton: true,
        confirmButtonText: 'Oui, valider',
        confirmButtonColor: '#69d27a',
        showCancelButton: true,
        cancelButtonText: 'Non',
        cancelButtonColor: '#d97502',
        reverseButtons: true
      }).then( result => {
        if(result.isConfirmed) {
          this.$store.dispatch('chantier/deleteInfoChantier', id)
        }
      })
    }
  },
  computed: {
    ...mapGetters('chantier', {
      getInfosChantier : 'getInfosChantier'
    }),
    ...mapGetters('util', {
      getContacts : 'getContacts'
    }),
  },
}
</script>

<style scoped>

</style>