<template>
  <v-container class="mx-2 text-center">
    <v-dialog
      :value="modaleChargeeIsVisible"
      max-width="500"
      @click:outside="$emit('closeModaleChargeeOutside')"
    >
      <v-card>
        <v-container class="mx-2 text-center">
          <v-row>
            <v-col>
              <span class="font-weight-bold"
                >Etat chargement Commande N°
                {{ selected_livraison.commande.numero_commande }}</span
              >
            </v-col>
          </v-row>
          <v-row
            class="mx-2 text-center"
            :class="modified_chargee_value === 0 ? 'red' : ''"
          >
            <v-col>
              <v-btn
                @click="modifiedChargementIsNotChargee()"
                elevation="5"
                color="red"
                class="mx-2"
                rounded
                x-large
              >
                Non chargée</v-btn
              >
            </v-col></v-row
          >
          <v-row
            class="mx-2 text-center"
            :class="modified_chargee_value === 2 ? 'green' : ''"
          >
            <v-col>
              <v-btn
                @click="modifiedChargementIsTotal()"
                elevation="5"
                color="green"
                class="mx-2"
                rounded
                x-large
              >
                Chargée</v-btn
              >
            </v-col>
          </v-row>
          <v-row
            class="mx-2 text-center"
            :class="modified_chargee_value === 1 ? 'yellow' : ''"
          >
            <v-col>
              <v-btn
                @click="modifiedChargementIsPartiel()"
                elevation="5"
                color="yellow"
                class="mx-2"
                rounded
                x-large
              >
                partiellement</v-btn
              >
            </v-col>
          </v-row>

          <v-row class="mx-2 text-center">
            <v-col v-if="chargement_is_partiel"
              ><v-text-field
                v-model="quantite_fourniture_manquante"
                type="number"
                label="Fournitures manquantes"
                hint="Indiquer le nombre de fournitures manquantes"
                min="1"
                :max="selected_livraison.commande.quantite"
              ></v-text-field
            ></v-col>
            <v-col>
              <v-btn
                v-if="modified_chargee_value !== selected_livraison.chargee"
                x-large
                color="green"
                @click="
                  modifiedChargeeLivraison(
                    selected_livraison._id,
                    modified_chargee_value
                  )
                "
                >Valider</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "X-chargee-modale-livraison",
  data() {
    return {
      // Variable permettant de mofidier la valeur de selected_livraison.chargee
      modified_chargee_value: this.selected_livraison.chargee,
      // Variables liées à un chargement partiel
      chargement_is_partiel: false,
      quantite_fourniture_manquante: 1,
    };
  },
  props: {
    modaleChargeeIsVisible: {
      type: Boolean,
      default: false,
    },
    selected_livraison: null,
  },
  methods: {
    // Modification des variables si non chargée
    modifiedChargementIsNotChargee() {
      this.modified_chargee_value = 0;
      this.chargement_is_partiel = false;
    },

    // Modification des variables si chargement partiel
    modifiedChargementIsPartiel() {
      this.chargement_is_partiel = true;
      this.modified_chargee_value = 1;
    },

    // Modification des variables si chargement total
    modifiedChargementIsTotal() {
      this.modified_chargee_value = 2;
      this.chargement_is_partiel = false;
    },

    // Modifie "chargée" et ajoute un commentaire
    modifiedChargeeLivraison(livraisonID, chargee_value) {
      let newCommentaire = this.createCommentaireChargement(chargee_value);
      let listeCommentaire = this.selected_livraison.commentaire_chargement
        ? this.selected_livraison.commentaire_chargement
        : [];
      listeCommentaire.push(newCommentaire);
      let payload = {
        livraisonID: livraisonID,
        chargee_value: chargee_value,
        commentaire_chargement: listeCommentaire,
      };
      this.$store.dispatch("livraison/updateOneLivraison", payload);

      this.$emit("closeModaleChargee", {
        chargee_value: chargee_value,
        livraison: this.selected_livraison,
      });
    },
    // Récupère le moment précis à la minute ainsi que l'utilisateur connecté afin d'enregistrer un commentaire dans l'historique de livraison
    // Le commentaire est modifié en fonction de la valeur de chargee_value
    createCommentaireChargement(chargee_value) {
      let etat_livraison;

      if (chargee_value === 1) {
        etat_livraison =
          " chargée partiellement : MANQUE " +
          this.quantite_fourniture_manquante +
          " X " +
          this.selected_livraison.commande.description;
      } else if (chargee_value === 2) {
        etat_livraison = " Chargée totalement";
      } else {
        etat_livraison = " non chargée";
      }

      this.$store.dispatch("time/modifiedActualDate", { date: moment() });
      let now = this.actualDate.date;
      let hour = moment(now).format("LT");
      let day = moment(now).format("L");
      return (
        "Le " +
        day +
        " à " +
        hour +
        " par " +
        this.ConnectedUser.prenom +
        " " +
        this.ConnectedUser.nom +
        " : " +
        etat_livraison
      );
    },
  },
  computed: {
    ...mapGetters("auth", {
      ConnectedUser: "userInSession",
    }),
    ...mapGetters("time", {
      actualDate: "getActualDateWithHour",
    }),
  },
};
</script>