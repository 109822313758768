<template>
  <div>
    <x-catalog></x-catalog>
  </div>
</template>

<script>
import XCatalog from "../components/magasin/X-catalog";
export default {
  name: "Commande",
  components: {XCatalog},
  created() {
    this.$store.dispatch('auth/tokenIsValid');
  }
}
</script>

<style scoped>

</style>