<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formattedTime"
        :label="title"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
        :color="getTurquoisePoupin"
        dense
        class="my-2"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="time"
      format="24hr"
      @click:minute="$refs.menu.save(time)"
      :color="getTurquoisePoupin"
      :allowed-minutes="allowedStep"
      @input="emit"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      time: null,
      menu: false,
    };
  },
  props: {
    title: {
      type: String,
      default: 'Nom de prop: "title"',
    },
    usertime: {
      type: String,
    },
  },
  methods: {
    emit() {
      this.menu = false;
      this.$emit("timepicked", moment(this.time, "hh:mm").toISOString());
    },
    allowedStep: (m) => m % 5 === 0,
  },
  computed: {
    ...mapGetters(["getTurquoisePoupin"]),

    formattedTime() {
      return moment(this.usertime).format("HH:mm");
    },
  },
};
</script>
<style scoped></style>
