import Vue from "vue";
import Swal from "sweetalert2";
import _ from "lodash";

const state = {
  incidents: [],
  modalIncident: false,
  modalHistorique: false,
  responsabilites: [],
  fournisseurs: [],
  processes: [],
};

const getters = {
  getIncidents(state) {
    return state.incidents;
  },
  getModaleIncident(state) {
    return state.modalIncident;
  },
  getModaleHistorique(state) {
    return state.modalHistorique;
  },
  getResponsabilites(state) {
    return state.responsabilites;
  },
  getFournisseurs(state) {
    return state.fournisseurs;
  },
  getProcesses(state) {
    return state.processes;
  },
};

const mutations = {
  SET_RESPONSABILITES(state, payload) {
    state.responsabilites = payload;
  },
  SET_FOURNISSEURS(state, payload) {
    state.fournisseurs = payload;
  },
  SET_PROCESSES(state, payload) {
    state.processes = payload;
  },
  SET_INCIDENTS(state, payload) {
    state.incidents = payload;
  },
  SET_MODAL_INCIDENT(state, payload) {
    state.modalIncident = payload;
  },
  SET_MODAL_HISTORIQUE(state, paylaod) {
    state.modalHistorique = paylaod;
  },
};

const actions = {
  showModaleIncident({ commit }) {
    commit("SET_MODAL_INCIDENT", true);
  },
  hideModaleIncident({ commit }) {
    commit("SET_MODAL_INCIDENT", false);
  },
  showModaleHistorique({ commit, rootGetters }) {
    Vue.axios
      .get(`/api/incident/chantier/${rootGetters["chantier/getChantier"]._id}`)
      .then((res) => {
        commit("SET_INCIDENTS", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    commit("SET_MODAL_HISTORIQUE", true);
  },
  hideModaleHistorique({ commit }) {
    commit("SET_MODAL_HISTORIQUE", false);
  },
  // ENVOI INCIDENT
  async sendIncident({ rootGetters }, payload) {
    let responsableOrdonnancement = true;
    let coordinateurPlanningNeufRuille = true;
    let responsableRenoRuille = true;
    let moi = true;

    let incident = new FormData();
    await payload.files.map((el) => {
      incident.append("files", el);
    });

    await incident.append("commentaire", payload.text);
    await incident.append(
      "chantier",
      JSON.stringify(rootGetters["chantier/getChantier"])
    );
    await incident.append(
      "user",
      JSON.stringify(rootGetters["auth/userInSession"])
    );
    await incident.append(
      "responsabilite",
      JSON.stringify(payload.responsabilite)
    );
    await incident.append("cause", JSON.stringify(payload.cause));
    await incident.append("fournisseur", JSON.stringify(payload.fournisseur));
    await incident.append("process", JSON.stringify(payload.process));

    if (!_.includes(payload.destinataires, "coordinateurPlanningNeufRuille")) {
      coordinateurPlanningNeufRuille = false;
    }
    if (!_.includes(payload.destinataires, "responsableOrdonnancement")) {
      responsableOrdonnancement = false;
    }
    if (!_.includes(payload.destinataires, "responsableRenoRuille")) {
      responsableRenoRuille = false;
    }
    if (!_.includes(payload.destinataires, "moi")) {
      moi = false;
    }
    await incident.append(
      "destinataires",
      JSON.stringify({
        coordinateurPlanningNeufRuille: coordinateurPlanningNeufRuille,
        responsableOrdonnancement: responsableOrdonnancement,
        responsableRenoRuille: responsableRenoRuille,
        moi: moi,
      })
    );

    //console.log(incident.files, "incident photo");

    Vue.axios.post("/api/incident/", incident).then(() => {
      Swal.fire("Incident transmit", "", "success");
    });
  },

  // RECUPERE LES INCIDENTS
  returnAllIncidents({ commit }) {
    Vue.axios
      .get(`/api/incident/`)
      .then((res) => {
        commit("SET_INCIDENTS", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  returnResponsabilites({ commit }) {
    Vue.axios.get("/api/incident/responsabilites").then((retour) => {
      commit("SET_RESPONSABILITES", retour.data);
    });
  },
  returnFournisseurs({ commit }) {
    Vue.axios.get("/api/incident/fournisseurs").then((retour) => {
      commit("SET_FOURNISSEURS", retour.data);
    });
  },
  returnProcesses({ commit }) {
    Vue.axios.get("/api/incident/processes").then((retour) => {
      commit("SET_PROCESSES", retour.data);
    });
  },
  insertFournisseurs() {
    Vue.axios.put("/api/incident/fournisseurs").then(() => {
      Swal.fire("Fournisseurs injectés", "", "success");
    });
  },
  insertCauses() {
    Vue.axios.put("/api/incident/causes").then(() => {
      Swal.fire("Causes injectées", "", "success");
    });
  },
  insertResponsibilite() {
    Vue.axios.put("/api/incident/responsabilites").then(() => {
      Swal.fire("Responsabilités injectées", "", "success");
    });
  },
  insertProcesses() {
    Vue.axios.put("/api/incident/processes").then(() => {
      Swal.fire("Processes injectées", "", "success");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
