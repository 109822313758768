<template>
  <v-container>
    <v-dialog
      :value="modaleStatutCommandeisVisible"
      max-width="500"
      @click:outside="$emit('closeModaleStatutCommande')"
    >
      <v-card>
        <v-container class="mx-2 text-center">
          <v-row>
            <v-col>
              <span class="font-weight-bold"
                >Statut commande N°
                {{ selected_livraison.commande.numero_commande }}</span
              >
            </v-col>
          </v-row>

          <v-row
            class="mx-2 text-center"
            :class="modified_statut_value === 4 ? 'green' : ''"
          >
            <v-col>
              <v-btn
                elevation="5"
                color="green"
                class="mx-2"
                rounded
                x-large
                @click="modifiedReceptionIsTotale()"
              >
                Reçu</v-btn
              >
            </v-col>
          </v-row>
          <v-row
            class="mx-2 text-center"
            :class="modified_statut_value === 3 ? 'yellow' : ''"
          >
            <v-col>
              <v-btn
                elevation="5"
                color="yellow"
                class="mx-2"
                rounded
                x-large
                @click="modifiedReceptionIsPartielle()"
              >
                Récep. partielle</v-btn
              >
            </v-col>
          </v-row>

          <v-row class="mx-2 text-center">
            <v-col v-if="reception_is_partiel"
              ><v-text-field
                v-model="quantite_fourniture_manquante"
                type="number"
                label="Fournitures manquantes"
                hint="Indiquer le nombre de fournitures manquantes"
                min="1"
                :max="selected_livraison.commande.quantite"
              ></v-text-field
            ></v-col>
            <v-col>
              <v-btn
                v-if="modified_statut_value"
                x-large
                color="green"
                @click="
                  modifiedStatutLivraison(
                    selected_livraison._id,
                    modified_statut_value
                  )
                "
                >Valider</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "X-statut-modale",
  props: {
    modaleStatutCommandeisVisible: {
      type: Boolean,
      default: false,
    },
    selected_livraison: null,
  },
  data() {
    return {
      // Variable utilisée pour modifier l'état de la commande
      modified_statut_value: null,
      // Variables utilisées si réception partielle
      reception_is_partiel: false,
      quantite_fourniture_manquante: 1,
    };
  },
  methods: {
    // Modification des variables si reception totale
    modifiedReceptionIsTotale() {
      this.reception_is_partiel = false;
      this.modified_statut_value = 4;
    },
    // Modification des variables si reception partielle
    modifiedReceptionIsPartielle() {
      this.reception_is_partiel = true;
      this.modified_statut_value = 3;
    },

    // Modifie "statut" et enregistre un commentaire
    modifiedStatutLivraison(livraisonID, statut_value) {
      let newCommentaire = this.createCommentaireReception(statut_value);
      let listeCommentaire = this.selected_livraison.commentaire_reception
        ? this.selected_livraison.commentaire_reception
        : [];
      listeCommentaire.push(newCommentaire);

      let payload = {
        livraisonID: livraisonID,
        statut_value: statut_value,
        commentaire_reception: listeCommentaire,
      };
      this.$store.dispatch("livraison/updateOneLivraison", payload);
      this.$emit("closeModaleStatutCommande");
    },
    // Récupère le moment précis à la minute ainsi que l'utilisateur connecté afin d'enregistrer un commentaire dans l'historique de livraison
    // Le commentaire est modifié en fonction de la valeur de statut_value
    createCommentaireReception(statut_value) {
      let etat_reception;
      if (statut_value === 3) {
        etat_reception =
          " Reçu partiellement : MANQUE : " +
          this.quantite_fourniture_manquante +
          " X " +
          this.selected_livraison.commande.description;
      } else if (statut_value === 4) {
        etat_reception = " Reçu totalement";
      }
      this.$store.dispatch("time/modifiedActualDate", { date: moment() });
      let now = this.actualDate.date;
      let hour = moment(now).format("LT");
      let day = moment(now).format("L");
      return (
        "Le " +
        day +
        " à " +
        hour +
        " par " +
        this.ConnectedUser.prenom +
        " " +
        this.ConnectedUser.nom +
        " : " +
        etat_reception
      );
    },
  },
  computed: {
    ...mapGetters(["getTurquoisePoupin"]),
    ...mapGetters("auth", {
      ConnectedUser: "userInSession",
    }),
    ...mapGetters("time", {
      actualDate: "getActualDateWithHour",
    }),
  },
};
</script>