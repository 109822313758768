var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "mx-2" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { "align-self": "end" } },
                [
                  !_vm.chargementIsStarted
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            elevation: "2",
                            color: "yellow",
                            "x-large": "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sendMailDebutChargement()
                            },
                          },
                        },
                        [_vm._v(" Début du chargement ")]
                      )
                    : _vm._e(),
                  _vm.chargementIsStarted && !_vm.chargementIsFinished
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            elevation: "0",
                            color: "blue",
                            "x-large": "",
                          },
                        },
                        [
                          _vm._v(" Charg. en cours "),
                          _c("v-icon", [_vm._v("mdi-dolly")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.chargementIsFinished
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            elevation: "0",
                            color: "green",
                            "x-large": "",
                          },
                        },
                        [
                          _vm._v(" Charg. terminé "),
                          _c("v-icon", [_vm._v("mdi-check")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-col"),
              _c("v-col"),
              _c("v-col"),
              _c("v-col"),
              _c("v-spacer"),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { elevation: "2", color: "blue", "x-large": "" },
                      on: {
                        click: function ($event) {
                          return _vm.openModaleAjoutLivraison()
                        },
                      },
                    },
                    [_vm._v("Ajouter livraison")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-data-table",
        {
          staticClass: "elevation-1",
          attrs: {
            headers: _vm.headers,
            items: _vm.livraisons,
            "item-key": "_id",
            "items-per-page": _vm.livraisons.length,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "" } },
                      [
                        _c("v-toolbar-title", [_vm._v("Liste des livraisons")]),
                        _c("v-spacer"),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: `item.urgence`,
                fn: function ({ item }) {
                  return [
                    item.priorite === true
                      ? _c(
                          "v-chip",
                          { attrs: { color: "error" } },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-alert"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: `item.client`,
                fn: function ({ item }) {
                  return [
                    _c("v-row", [
                      _vm._v(" " + _vm._s(`${String(item.client)}`) + " "),
                    ]),
                    _c("v-row", [
                      _vm._v(
                        _vm._s(" N°affaire" + " " + `${String(item.compteur)}`)
                      ),
                    ]),
                  ]
                },
              },
              {
                key: `item.semaine_livraison`,
                fn: function ({ item }) {
                  return [
                    _c("v-col", [
                      _vm._v(
                        "Demandée : " +
                          _vm._s(_vm.currentWeek(item.date_livraison_prevue)) +
                          " "
                      ),
                    ]),
                    _c("v-col", [
                      !item.date_livraison_reelle &&
                      !item.date_livraison_partielle
                        ? _c("span", [_vm._v("Non livrée")])
                        : _vm._e(),
                      item.date_livraison_reelle
                        ? _c("span", [_vm._v("Réelle :")])
                        : _vm._e(),
                      item.date_livraison_partielle &&
                      !item.date_livraison_reelle
                        ? _c("span", [_vm._v("Partielle : ")])
                        : _vm._e(),
                      item.date_livraison_reelle
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.currentWeek(item.date_livraison_reelle)
                              ) + " "
                            ),
                          ])
                        : _vm._e(),
                      item.date_livraison_partielle &&
                      !item.date_livraison_reelle
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.currentWeek(item.date_livraison_partielle)
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
              {
                key: `item.type_commande`,
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            item.commande.type === "CDI"
                              ? [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "datatable-btn",
                                      attrs: {
                                        elevation: "0",
                                        color: _vm.getTurquoisePoupin,
                                        "x-medium": "",
                                      },
                                    },
                                    [_vm._v(" Interne")]
                                  ),
                                ]
                              : _vm._e(),
                            item.commande.type === "CDE"
                              ? [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "datatable-btn",
                                      attrs: {
                                        elevation: "0",
                                        color: "#90caf9",
                                        "x-medium": "",
                                      },
                                    },
                                    [_vm._v(" Externe")]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "text-center font-weight-bold" },
                      [
                        _c("v-col", [
                          _vm._v(_vm._s(item.commande.numero_commande)),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: `item.atelier`,
                fn: function ({ item }) {
                  return [
                    item.commande.type === "CDI"
                      ? _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  elevation: "0",
                                  color: _vm.chooseColorAtelier(
                                    item.commande.atelier
                                  ),
                                },
                              },
                              [_vm._v(_vm._s(item.commande.atelier))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.commande.type === "CDE"
                      ? _c(
                          "v-col",
                          [
                            _c("v-btn", { attrs: { elevation: "0" } }, [
                              _vm._v(_vm._s(item.commande.fournisseur)),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: `item.description`,
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-row",
                      [
                        _c("v-col", [
                          _vm._v(_vm._s(item.commande.description)),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        item.commande.famille
                          ? _c("v-col", [
                              _vm._v(
                                _vm._s(
                                  item.commande.famille +
                                    " " +
                                    item.commande.sous_famille
                                )
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: `item.details_commande`,
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-icon",
                      {
                        attrs: { large: "" },
                        on: {
                          click: function ($event) {
                            return _vm.openModaleRecapitulatifCommande(item)
                          },
                        },
                      },
                      [_vm._v("mdi-clipboard-text-outline")]
                    ),
                  ]
                },
              },
              {
                key: `item.etat_chargement`,
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-col",
                      [
                        item.chargee === 0
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  elevation: "2",
                                  color: "red",
                                  rounded: "",
                                  "x-large": "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openModaleChargee(item)
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v(" mdi-dolly")])],
                              1
                            )
                          : _vm._e(),
                        item.chargee === 1
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  elevation: "2",
                                  color: "yellow",
                                  rounded: "",
                                  "x-large": "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openModaleChargee(item)
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-truck-outline")])],
                              1
                            )
                          : _vm._e(),
                        item.chargee === 2
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  elevation: "2",
                                  color: "green",
                                  rounded: "",
                                  "x-large": "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openModaleChargee(item)
                                  },
                                },
                              },
                              [
                                _c("v-icon", [
                                  _vm._v("mdi-truck-check-outline"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: `item.etat_livraison`,
                fn: function ({ item }) {
                  return [
                    _c("v-col", [
                      _vm._v(" " + _vm._s(_vm.etatLivraison(item)) + " "),
                    ]),
                    item.commande
                      ? _c("v-col", [
                          _vm._v(
                            " " + _vm._s("(" + item.commande.etat + ")") + " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: `item.commentaire_historique`,
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-row",
                      { attrs: { justify: "center" } },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { "x-large": "" },
                            on: {
                              click: function ($event) {
                                return _vm.openModalecommentaireLivraison(item)
                              },
                            },
                          },
                          [_vm._v("mdi-comment-text-outline")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _vm.selected_livraison
            ? [_c("v-row", [_vm._v(_vm._s(_vm.selected_livraison.chargee))])]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col"),
              _c("v-col"),
              _c("v-col"),
              _c("v-col"),
              _c("v-spacer"),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { elevation: "2", color: "green", "x-large": "" },
                      on: {
                        click: function ($event) {
                          return _vm.sendMailRecapLivraison()
                        },
                      },
                    },
                    [_vm._v("Chargement terminé")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modaleRecapitulatifCommandeIsVisible
        ? _c("x-modale-recapitulatif-commande", {
            key:
              _vm.selected_livraison._id +
              _vm.selected_livraison.numero_commande,
            attrs: {
              isVisible: _vm.modaleRecapitulatifCommandeIsVisible,
              commande: _vm.selected_livraison.commande,
              id_livraison: _vm.selected_livraison._id,
            },
            on: {
              closeModaleRecapitulatifCommande:
                _vm.closeModaleRecapitulatifCommande,
            },
          })
        : _vm._e(),
      _vm.modaleCommentaireIsVisible
        ? _c("x-modale-commentaire-livraison", {
            attrs: {
              modaleCommentaireIsVisible: _vm.modaleCommentaireIsVisible,
              selected_livraison: _vm.selected_livraison,
            },
            on: { closeModaleCommentaire: _vm.closeModaleCommentaire },
          })
        : _vm._e(),
      _vm.modaleStatutCommandeisVisible
        ? _c("x-statut-modale-livraison", {
            attrs: {
              modaleStatutCommandeisVisible: _vm.modaleStatutCommandeisVisible,
              selected_livraison: _vm.selected_livraison,
            },
            on: { closeModaleStatutCommande: _vm.closeModaleStatutCommande },
          })
        : _vm._e(),
      _vm.modaleChargeeIsVisible
        ? _c("x-chargee-modale-livraison", {
            attrs: {
              modaleChargeeIsVisible: _vm.modaleChargeeIsVisible,
              selected_livraison: _vm.selected_livraison,
            },
            on: {
              closeModaleChargee: _vm.closeModaleChargee,
              closeModaleChargeeOutside: _vm.closeModaleChargeeOutside,
            },
          })
        : _vm._e(),
      _c("x-ajout-livraison", {
        attrs: {
          modaleAjoutLivraisonIsVisible: _vm.modaleAjoutLivraisonIsVisible,
        },
        on: { closeModaleAjoutLivraison: _vm.closeModaleAjoutLivraison },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }