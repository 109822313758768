var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        "return-value": _vm.time,
        transition: "scale-transition",
        "offset-y": "",
        "max-width": "290px",
        "min-width": "290px",
      },
      on: {
        "update:returnValue": function ($event) {
          _vm.time = $event
        },
        "update:return-value": function ($event) {
          _vm.time = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "my-2",
                      attrs: {
                        value: _vm.formattedTime,
                        label: _vm.title,
                        "prepend-icon": "mdi-clock-time-four-outline",
                        readonly: "",
                        color: _vm.getTurquoisePoupin,
                        dense: "",
                      },
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _vm.menu
        ? _c("v-time-picker", {
            attrs: {
              format: "24hr",
              color: _vm.getTurquoisePoupin,
              "allowed-minutes": _vm.allowedStep,
            },
            on: {
              "click:minute": function ($event) {
                return _vm.$refs.menu.save(_vm.time)
              },
              input: _vm.emit,
            },
            model: {
              value: _vm.time,
              callback: function ($$v) {
                _vm.time = $$v
              },
              expression: "time",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }