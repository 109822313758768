<template>
  <v-dialog
      :value="visible"
      :persistent="persistent"
      :max-width="max_width"
      class="ma-0 pa-0"
  >
    <v-card>
      <v-card-title
        class="mb-2"
      >
        <span>{{ titre }}</span>
      </v-card-title>
      <v-card-subtitle v-if="subtitle">{{ subtitle_text }}</v-card-subtitle>
      <v-card-text>
          <time-form @close="$emit('close')"></time-form>
          <v-btn v-if="commentaire && !quelBouton" small outlined color="blue" @click="$emit('open-commentaire')">Ajouter un commentaire</v-btn>
          <v-btn v-else-if="commentaire" small outlined color="orange" @click="$emit('close-commentaire')">Modifier le commentaire</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import TimeForm from "@/components/time/TimeForm";

export default {
  name: "X-modale-time",
  components: {
    TimeForm
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    titre: {
      type: String,
      default: "Le titre de ma modale de ReadUpdate Journee"
    },
    commentaire: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: Boolean,
      default: false
    },
    subtitle_text: {
      type: String,
      default: "La prop subtitle_text permet de customiser un sous titre ici"
    },
    persistent: {
      type: Boolean,
      default: true
    },
    max_width: {
      type: String,
      default: "500px"
    }
  },
  computed: {
    ...mapGetters('time', {
      getCurrentJournee: 'getCurrentJournee'
    }),

    quelBouton() {
      return this.getCurrentJournee !== null && this.getCurrentJournee.journeeCorrigee !== undefined && this.getCurrentJournee.journeeCorrigee !== null && this.getCurrentJournee.journeeCorrigee.commentaire !== '';
    }
  }
}
</script>

<style scoped>

</style>