<template>
  <v-container class="my-0 py-0">
    <v-row row wrap class="ma-2">
      <v-col class="mx-2">
        <x-date-picker
          :title="'Semaine'"
          :required="false"
          :date="getQuery.dateDebut | toIso"
          :afficherSemaine="true"
          @datepicked="setDateDebut"
          @clean="clearDateDebut"
        >
        </x-date-picker>
      </v-col>
      <v-col class="mx-2"
        ><v-text-field
          label="Nom client"
          clearable
          hint="En majuscule"
          @change="setNomClient"
          @click:clear="clearNomClient"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row row wrap class="ma-2">
      <v-col class="mx-2">
        <v-text-field
          label="Numéro commande interne"
          clearable
          hint="5 chiffres"
          @change="setNumeroCommandeInterne"
          @click:clear="clearNumeroCommandeInterne"
        ></v-text-field>
      </v-col>
      <v-col class="mx-2">
        <v-text-field
          label="Numéro commande externe"
          clearable
          hint="5 chiffres"
          @change="setNumeroCommandeExterne"
          @click:clear="clearNumeroCommandeExterne"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row row wrap class="ma-2">
      <v-col align-self="center">
        <v-select
          label="Agence"
          prepend-icon="mdi-home"
          :value="getQuery.agence ? getQuery.agence : null"
          :color="getTurquoisePoupin"
          :items="getAgences"
          item-text="libelle"
          item-value="_id"
          clearable
          @click:clear="clearAgence"
          @change="setAgence"
        >
        </v-select>
      </v-col>     
    </v-row>
    
    
  </v-container>
</template>
<script>
import XDatePicker from "@/components/time/X-date-picker";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "X-filtres-livraison",
  components: {
    XDatePicker,
  },
  data() {
    return {
      selected_date: null,
      isLoading: false,
      payload: {
        dateDebut: moment(),
        dateFin: Date.nom,
        agence: "",        
      },
    };
  },
  created() {
    this.$store.dispatch("agence/returnAgences");
  },
  methods: {
    // Modification de la variable query.dateDebut permettant à filter les livraisons
    // Puis appel la requête pour les livraisons
    setDateDebut(e) {
      this.$store.dispatch("livraison/setQuerySemaine", e).then(() => {
        this.$store.dispatch("livraison/returnLivraisons");
        // Force le refresh de l'affichage pour la 1ère sélection
        this.$forceUpdate();
      });
    },
    // Modification de la variable query.dateDebut permettant à filter les livraisons
    // Puis appel la requête pour les livraisons
    clearDateDebut() {
      return this.$store.dispatch("livraison/clearQueryDateDebut").then(() => {
        this.$store.dispatch("livraison/returnLivraisons");
      });
    },
    // Modification de la variable query.agence permettant à filter les livraisons
    // Puis appel la requête pour les livraisons
    setAgence(e) {
      this.$store.dispatch("livraison/setQueryAgence", e).then(() => {
        this.$store.dispatch("livraison/returnLivraisons").then(() => {
          this.$store.dispatch("livraison/clearTabLivraisonsChargeesTotales"),
            this.$store.dispatch(
              "livraison/clearTabLivraisonsChargeesPartielles"
            );
        });
      });
    },

    clearAgence() {
      return this.$store.dispatch("livraison/clearQueryAgence").then(() => {
        this.$store.dispatch("livraison/returnLivraisons");
      });
    },
    // Modification de la variable query.nomClient permettant à filter les livraisons par client
    // Puis appel la requête pour les livraisons
    setNomClient(e) {
      if (e !== "undefined" && e !== null) {
        return this.$store
          .dispatch("livraison/setQueryNomClient", e)
          .then(() => {
            this.$store.dispatch("livraison/returnLivraisonsByClient");
          });
      }
    },
    // Modification de la variable query.nomClient permettant de supprimer l'affichage du champs client
    // Puis appel la requête pour les livraisons
    clearNomClient() {
      return this.$store.dispatch("livraison/clearQueryNomClient").then(() => {
        this.$store.dispatch("livraison/returnLivraisons");
      });
    },
    // Modification de la variable query.numeroCommandeInterne permettant de filtrer par numero de commande interne
    // Puis appel la requête pour les livraisons
    setNumeroCommandeInterne(e) {
      if (e !== "undefined" && e !== null) {
        return this.$store
          .dispatch("livraison/setQueryNumeroCommandeInterne", e)
          .then(() => {
            this.$store.dispatch("livraison/returnLivraisonByNumeroCommande");
          });
      }
    },
    // Modification de la variable query.numeroCommandeInterne permettant de supprimer l'affichage du champs NumeroCommandeInterne
    // Puis appel la requête pour les livraisons
    clearNumeroCommandeInterne() {
      return this.$store
        .dispatch("livraison/clearQueryNumeroCommandeInterne")
        .then(() => {
          this.$store.dispatch("livraison/returnLivraisons");
        });
    },
    // Modification de la variable query.numeroCommandExterne permettant de filtrer par numero de commande externe
    // Puis appel la requête pour les livraisons
    setNumeroCommandeExterne(e) {
      if (e !== "undefined" && e !== null) {
        return this.$store
          .dispatch("livraison/setQueryNumeroCommandeExterne", e)
          .then(() => {
            this.$store.dispatch("livraison/returnLivraisonByNumeroCommande");
          });
      }
    },
    // Modification de la variable query.numeroCommandeExterne permettant de supprimer l'affichage du champs NumeroCommandeExterne
    // Puis appel la requête pour les livraisons
    clearNumeroCommandeExterne() {
      return this.$store
        .dispatch("livraison/clearQueryNumeroCommandeExterne")
        .then(() => {
          this.$store.dispatch("livraison/returnLivraisons");
        });
    },

   

  },
  computed: {
    ...mapGetters(["getTurquoisePoupin"]),
    ...mapGetters("livraison", {
      getQuery: "getQuery",
      returnLivraisons: "returnLivraisons",
    }),
    getFiltreDateDebut() {
      return this.getQuery.dateDebut;
    },
    getFiltreDateFin() {
      return this.getQuery.dateFin;
    },
    ...mapGetters("agence", {
      getAgences: "getAgences",
    }),
    ...mapGetters("auth", {
      ConnectedUser: "userInSession",
    }),
  },
};
</script>