var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("x-filtres-livraison"),
      _c("x-livraison-datatable", {
        attrs: { livraisons: _vm.getAllLivraison },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }