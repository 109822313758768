var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800px" },
          on: { "click:outside": _vm.showNew },
          model: {
            value: _vm.newModal,
            callback: function ($$v) {
              _vm.newModal = $$v
            },
            expression: "newModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Nouvel utilisateur")]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [_c("user-form", { on: { close: _vm.showNew } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800px" },
          on: { "click:outside": _vm.showInfo },
          model: {
            value: _vm.infoModal,
            callback: function ($$v) {
              _vm.infoModal = $$v
            },
            expression: "infoModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Modifier informations utilisateur")]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [_c("user-form-info", { on: { close: _vm.showInfo } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800px" },
          on: { "click:outside": _vm.showPassword },
          model: {
            value: _vm.passwordModal,
            callback: function ($$v) {
              _vm.passwordModal = $$v
            },
            expression: "passwordModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  "Modifier mot de passe " +
                    _vm._s(_vm.getUser.nom) +
                    " " +
                    _vm._s(_vm.getUser.prenom)
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [_c("user-m-d-p-form", { on: { close: _vm.showPassword } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800px" },
          on: { "click:outside": _vm.showHoraire },
          model: {
            value: _vm.horaireModal,
            callback: function ($$v) {
              _vm.horaireModal = $$v
            },
            expression: "horaireModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  "Modifier horaires par défaut " +
                    _vm._s(_vm.getUser.nom) +
                    " " +
                    _vm._s(_vm.getUser.prenom)
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [_c("user-form-horaires", { on: { close: _vm.showHoraire } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        { attrs: { color: "success" }, on: { click: _vm.showNew } },
        [
          _c("span", [_vm._v("Nouvel utilisateur")]),
          _c("v-icon", [_vm._v("mdi-plus")]),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.getUsers,
          "items-per-page": 22,
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (row) {
              return [
                _c("tr", { attrs: { disabled: "" } }, [
                  _c(
                    "td",
                    [
                      row.item.locked
                        ? _c("v-icon", [_vm._v("mdi-lock-outline")])
                        : _vm._e(),
                      _vm._v(" " + _vm._s(row.item.nom) + " "),
                    ],
                    1
                  ),
                  _c("td", [_vm._v(_vm._s(row.item.prenom))]),
                  _c("td", [_vm._v(_vm._s(row.item.username))]),
                  _c("td", [_vm._v(_vm._s(row.item.role))]),
                  _c("td", [_vm._v(_vm._s(row.item.email))]),
                  _c("td", [_vm._v(_vm._s(row.item.phone))]),
                  _c("td", [_vm._v(_vm._s(row.item.idERP))]),
                  row.item.agence
                    ? _c("td", [_vm._v(_vm._s(row.item.agence.libelle))])
                    : _vm._e(),
                  row.item.societe
                    ? _c("td", [_vm._v(_vm._s(row.item.societe.libelle))])
                    : _vm._e(),
                  _c(
                    "td",
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ma-1",
                                            attrs: {
                                              color: "primary",
                                              fab: "",
                                              tile: "",
                                              dark: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setUser(row.item)
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-account-edit-outline"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                { on: { click: _vm.showInfo } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Infos utilisateur"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                { on: { click: _vm.showPassword } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Mot de passe"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                { on: { click: _vm.showHoraire } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Horaires par défaut"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "error",
                                              fab: "",
                                              tile: "",
                                              dark: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setUser(row.item)
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-shield-account-outline"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              !row.item.locked
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.setLock(true)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Vérrouiller"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.setLock(false)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Dévérrouiller"),
                                      ]),
                                    ],
                                    1
                                  ),
                              _c(
                                "v-list-item",
                                { on: { click: _vm.deleteUser } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Supprimer"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }