<template>
  <v-dialog
      :value="getModaleInfosChantier"
      @click:outside="$emit('close')"
      max-width="600px"
      content-class="ma-2"
      :retain-focus="false"
      scrollable
  >
      <v-card>
        <v-card-title>Suivi des infos chantier</v-card-title>
        <v-card-subtitle>{{ getChantier.nom_client }}</v-card-subtitle>
        <v-card-text>
          <x-infos-chantier :btnVisible="true"></x-infos-chantier> 
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Fermer</v-btn>
      </v-card-actions> 
      </v-card>  
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import XInfosChantier from './X-infos-chantier.vue';

export default {
  name: "X-modale-infos-chantier",
  components: {XInfosChantier},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      tabs: null,
      notes: [
        { commentaire: 'Un commentaire' },
        { commentaire: 'Deux commentaire' },
        { commentaire: 'Trois commentaire' },
      ]
    }
  },
  computed: {
    ...mapGetters('chantier', {
      getChantier: 'getChantier',
      getModaleInfosChantier: 'getModaleInfosChantier'
    })
  },
}
</script>

<style scoped>

</style>