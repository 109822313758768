<template>
  <form @submit="addAgence">
    <v-text-field
      type="text"
      label="Libellé Agence"
      :value="getAgence.libelle"
      @change="setLibelleAgence"
    ></v-text-field>
    <v-text-field
      type="number"
      label="Id Agence (ERP)"
      :value="getAgence.idERP"
      @change="setIdAgence"
    ></v-text-field>
    <v-btn type="submit">Enregistrer</v-btn>
  </form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AgenceForm",
  data() {
    return {};
  },
  methods: {
    addAgence(e) {
      this.$store.dispatch("agence/addAgence");
      e.preventDefault();
    },
    setLibelleAgence(e) {
      this.$store.dispatch("agence/setLibelleAgence", e);
    },
    setIdAgence(e) {
      this.$store.dispatch("agence/setIdAgence", e);
    },
  },
  computed: {
    ...mapGetters("agence", {
      getAgence: "getAgence",
    }),
  },
};
</script>

<style scoped></style>
