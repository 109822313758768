<template>
  <!--  -->
  <v-list-item>
    <v-list-item-avatar>
      <v-icon :color="getEtatAvancementDate(champ_recherche).couleur">
        mdi-circle-slice-8
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <b>{{ getEtatAvancementDate(champ_recherche).message }}</b>
      <br />
    </v-list-item-content>
    <v-list-item-action>
      <v-icon
        v-if="checkbox"
        :color="getEtatAvancementDate(champ_recherche).couleur"
        @click="
          $emit('choixCheckBoxEtat', {
            etat: etat,
            libelle: champ_recherche,
            nb_step: nb_step,
          })
        "
        >{{ getEtatAvancementDate(champ_recherche).action }}</v-icon
      >

      <x-date-picker
        v-else
        :title="''"
        :afficherSemaine="true"
        :date="getDatePrevi | toIso"
        @datepicked="
          $emit('choixDatePrevi', { data: $event, libelle: champ_recherche })
        "
      ></x-date-picker>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import XDatePicker from "../../time/X-date-picker.vue";
import moment from "moment";

export default {
  name: "X-avancement-jalon-change-etat",
  components: { XDatePicker },
  data() {
    return {};
  },
  props: {
    champ_recherche: {
      type: String,
    },
    checkbox: {
      type: Boolean,
    },
    nb_step: {
      type: Number,
    },
    etat: {
      type: String,
    },
  },
  methods: {},
  computed: {
    ...mapGetters("chantier", {
      getEtatAvancementDate: "getEtatAvancementDate",
      getChantier: "getChantier",
    }),

    getDatePrevi() {
      let champParent = this.champ_recherche;
      if (this.champ_recherche.includes(".")) {
        champParent = this.champ_recherche.split(".")[0];
      }

      if (this.champ_recherche.includes(".date_previ")) {
        if (
          this.getChantier.avancement &&
          this.getChantier.avancement[champParent]
        ) {
          return moment(this.getChantier.avancement[champParent].date_previ)
            .toDate()
            .getFullYear() > 2010
            ? moment(
                this.getChantier.avancement[champParent].date_previ
              ).toDate()
            : moment().toDate();
        } else {
          return moment().toDate();
        }
      } else {
        if (
          this.getChantier.avancement &&
          this.getChantier.avancement[champParent]
        ) {
          return moment(this.getChantier.avancement[champParent].date)
            .toDate()
            .getFullYear() > 2010
            ? moment(this.getChantier.avancement[champParent].date).toDate()
            : moment().toDate();
        } else {
          return moment().toDate();
        }
      }
    },
  },
};
</script>

<style scoped></style>
